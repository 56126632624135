import {ICompany, ICreditRemaining} from "../../../../../models/ICompany";
import {useTranslation} from "react-i18next";
import InsightCard from "./InsightCard";

interface Props {
    company: ICompany;
    creditRemaining?: ICreditRemaining;
}

const CompanyInsightsTab = ({company, creditRemaining}: Props) => {
    const {t} = useTranslation("companyInsights");

    return (
        <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
            {["post_billing"].includes(company?.subscription_method as string) ? (
                <InsightCard
                    title={company?.subscription_method == "license"
                        ? t("insights.licenses_used")
                        : t("insights.credits_used")}

                >
                    <div className="text-base font-semibold">{creditRemaining?.used_credit || 0}</div>
                </InsightCard>
            ) : (
                <InsightCard
                    title={company?.subscription_method == "license"
                        ? t("insights.licenses_available")
                        : t("insights.credits_remaining")}
                    tooltip={
                        company?.subscription_method == "license"
                            ? t("tooltip.licenses")
                            : t("tooltip.credits")
                    }
                >
                    <div className="text-base font-semibold">
                        {creditRemaining?.remain_credits || 0}
                        <span className="font-normal"> /{creditRemaining?.last_added_credit || 0}</span>
                    </div>
                </InsightCard>
            )}

            <InsightCard title={t("insights.ai_bot")}>
                <p
                    className={`text-sm font-normal ${company.ai_bot ? 'text-green-600' : 'text-red-600'}`}>
                    {company.ai_bot ? t("status.activated") : t("status.not_activated")}
                </p>
            </InsightCard>

            <InsightCard title={t("insights.orientation_session")}>
                <p className={`text-sm font-normal ${company.intro_session ? 'text-green-600' : 'text-red-600'}`}>
                    {company.intro_session ? t("status.allowed") : t("status.not_allowed")}
                </p>
            </InsightCard>

            <InsightCard title={t("insights.users")}>
                <p className="text-sm font-normal text-gray-900">{company.clients || 'N/A'}</p>
            </InsightCard>


            <InsightCard
                title={t("insights.user_permission")}
                tooltip={t("tooltip.user_permission")}
            >
                <p className="text-sm font-normal text-gray-900">
                    {company.user_permission.replace(/_/g, ' ')
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                </p>
            </InsightCard>

        </div>
    )
}

export default CompanyInsightsTab;