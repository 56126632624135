import React from "react";
import {ICompany} from "../../../models/ICompany";
import CompanyProfileForm from "../forms/CompanyProfileForm";
import {subscriptionOptions, toolOptions, userPermissionOptions} from "../../../constants/data/companyData";
import {updateCompany} from "../../../services/api/companyApi";
import {toast} from "react-toastify";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/company.json";
import FR from "../locale/fr/company.json";
import {useTranslation} from "react-i18next";

interface CompanyUpdateProps {
    companyData: ICompany;
    onUpdate: (company: ICompany) => void;
}

const CompanyUpdate = ({companyData, onUpdate}: CompanyUpdateProps) => {
    i18n.addResourceBundle("en", "company", ENG);
    i18n.addResourceBundle("fr", "company", FR);
    const {t} = useTranslation("company");

    const mapToOption = (value: string, options: { value: string; label: string }[]) => {
        return options.find(option => option.value === value) || "";
    };

    const mappedInitialValues = {
        ...companyData,
        tool: mapToOption(companyData.tool, toolOptions),
        subscription_method: mapToOption(companyData.subscription_method, subscriptionOptions),
        user_permission: mapToOption(companyData.user_permission, userPermissionOptions),
    };

    const handleSubmit = (values: any) => {
        const updatedCompany = {
            ...values,
            tool: values.tool.value,
            user_permission: values.user_permission.value,
            subscription_method: values.subscription_method.value,
            primary_language_id: values.primary_language.id,
            credit_limit: parseInt(values.credit_limit, 10),
        }

        updateCompany(values.id, updatedCompany)
            .then((res) => {
                onUpdate(updatedCompany);
                toast.success(t("update_success"));
            })
            .catch((e) => {
                toast.error("update_error");
                console.log("api error");
            })
    };

    return <CompanyProfileForm initialValues={mappedInitialValues} onSubmit={handleSubmit}/>;
};

export default CompanyUpdate;
