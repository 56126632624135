import {FC, useEffect, useRef, useState} from "react";

import {useTranslation} from "react-i18next";

import AssessmentResultsSection from "./sections/AssessmentResultsSection";
import FrameModal from "../../../../shared/components/FrameModal";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import CoachingReportsSection from "./sections/CoachingReportsSection";
import Feedback360Section from "./sections/Feedback360Section";
import {getAssessmentScore} from "../../../../services/api/teams/AssessmentActions";
import {get360FeedbackPendingRequests, get360FeedbackReports} from "../../../../services/api/teams/Feedback360Actions";

import {getClientReports, getPendingReviews} from "../../../../services/api/teams/ReportActions";
import storage from "../../../../services/storage/localStorage";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/reports.json";
import FR from "../../locale/fr/reports.json";
import {useNavTitle} from "@hooks/useNavTitle";
import {useConfig} from "../../../../utils/providers/AppConfigProvider";

import {EAssessmentType} from "../../../../constants/enums";
import IAssessmentScore from "../../../../models/IAssessmentScore";
import {I360FeedbackReport, IClientReport, ISessionReport} from "../../../../models/IReport";
import useAuth from "@hooks/useAuth";
import {AICoachType} from "../AICoaching/enum";
import {ISentFeedbackRequestData} from "../../../../constants/types";
import {getSummaryOverview} from "../AIOnlyCoaching/actions";
import SessionReportsSection from "./sections/SessionReportsSection";

interface ReportProps {
    navTitle: String;
}

const Reports: FC<ReportProps> = (props) => {
    i18n.addResourceBundle("en", "reports", ENG);
    i18n.addResourceBundle("fr", "reports", FR);

    const {t} = useTranslation("reports");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {config} = useConfig();
    const {user} = useAuth();
    const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);
    const [isLoading, setIsLoading] = useState(true);

    const [assessmentScores, setAssessmentScores] = useState<IAssessmentScore>();
    const [coachingReports, setCoachingReports] = useState<IClientReport[]>();
    const [feedback360, setFeedback360] = useState<I360FeedbackReport[]>();
    const [feedbackPending, setFeedbackPending] = useState<ISentFeedbackRequestData[]>([]);
    const [aiCoachSessions, setAiCoachSessions] = useState<ISessionReport[]>();
    const [currentModalIndex, setCurrentModalIndex] = useState<number | null>(null);
    const [pendingReviewSessions, setPendingReviewSessions] = useState<any[]>([]);

    useEffect(() => {
        init();
    }, []);
    const aiOnlyCoach = user?.aiCoachType && user.aiCoachType === AICoachType.ai_only;

    const init = async () => {
        const fetchSessions = async () => {
            const summaryOverviewSessions = await getSummaryOverview();

            const SummaryOverviewSessions = summaryOverviewSessions
                .map((session: any) => ({
                    id: session.id,
                    title: session.title,
                    datetime: session.datetime,
                    ai_model: session.ai_model
                }) as ISessionReport);

            SummaryOverviewSessions.sort((a: any, b: any) => Date.parse(b.datetime) - Date.parse(a.datetime));
            setAiCoachSessions(SummaryOverviewSessions);
        }
        setIsLoading(true);

        const user = storage.get("user");
        const clientId = user.id;

        const as = await getAssessmentScore(clientId);
        setAssessmentScores(as);

        const cr = await getClientReports(clientId);
        cr.sort(
            (a: any, b: any) =>
                Date.parse(b.meeting_date) - Date.parse(a.meeting_date),
        );
        setCoachingReports(cr.sort((a: any, b: any) => b.date - a.date));

        const fb360 = await get360FeedbackReports(clientId);
        fb360.sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date));
        setFeedback360(fb360);

        const fb360PendingRequests = await get360FeedbackPendingRequests(clientId);
        setFeedbackPending(fb360PendingRequests);

        fetchSessions();

        if (!aiOnlyCoach) {
            const pendingReviews = await getPendingReviews(clientId);
            if (pendingReviews.length > 0) {
                pendingReviews.sort((a: any, b: any) => Date.parse(b.meeting_start_time) - Date.parse(a.meeting_start_time));
                setPendingReviewSessions(pendingReviews);
                setCurrentModalIndex(0);
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (pendingReviewSessions.length > 0 && currentModalIndex === 0) {
            openModalForSession(0);
        }
    }, [pendingReviewSessions, currentModalIndex]);

    const openModalForSession = (index: number) => {
        if (index >= pendingReviewSessions.length) return;

        const session = pendingReviewSessions[index];
        openModal(EAssessmentType.Review, t("coaching_reports.leave_review"), "iframe", session.id, 600);
    }

    const openModal = (key: EAssessmentType, title: string, frameElementType?: "iframe" | "video", sessionId?: number, size?: number) => {
        frameModalRef.current?.open(key, title, frameElementType, sessionId, size);
    };

    const handleReviewSubmit = (result: any) => {
        if (!result) {
            setCurrentModalIndex(null);
            return;
        }

        if (result.assessment_type == EAssessmentType.Review && !result.error) {
            const updatedReports = coachingReports?.map((report) =>
                report.id == result.meeting_id ? {...report, review: true} : report
            );

            setCoachingReports(updatedReports);

            if (currentModalIndex !== null && currentModalIndex + 1 < pendingReviewSessions.length) {
                const nextIndex = currentModalIndex + 1;
                setCurrentModalIndex(nextIndex);
                setTimeout(() => {
                    openModalForSession(nextIndex);
                }, 500);
            } else {
                setCurrentModalIndex(null);
            }
        }
    }

    return (
        <>
            {isLoading ? <div className="inset-0 fixed"><LoaderContainer/></div>:

            <div className="bg-linen_gradient flex flex-col gap-4 py-6 px-6 h-full">
                {config?.self_reflection && assessmentScores && (
                    <AssessmentResultsSection
                        data={assessmentScores}
                        openModal={openModal}
                        retake={true}
                    />
                )}
                {!aiOnlyCoach && coachingReports &&
                    <CoachingReportsSection openModal={openModal} data={coachingReports}/>}
                {aiOnlyCoach && aiCoachSessions && aiCoachSessions.length !== 0 &&
                    <SessionReportsSection data={aiCoachSessions}/>}
                {config?.flash_reflection && feedback360 && (
                    <Feedback360Section data={feedback360} openModal={openModal} sentRequests={feedbackPending}/>
                )}
                <FrameModal ref={frameModalRef} onClose={handleReviewSubmit}/>
            </div>}
        </>
    );
};

export default Reports;
