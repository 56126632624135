import React from 'react';
import AIChatTitle from "./ChatBox/AIChatTitle";

type ProgressBarProps = {
    maxSteps: number;
    currentStep: number;
    isPractice?: boolean;
};

const ProgressBar = ({
                         maxSteps,
                         currentStep,
                         isPractice
                     }: ProgressBarProps) => {

    const width = (100 * currentStep) / maxSteps || 0;

    return (
        <div className="absolute w-full h-1">
            <div className="w-full bg-gray-200 rounded-full z-10">
                <div
                    className={`${isPractice ? 'bg-practice' : 'bg-red'} h-1.5 rounded-full`}
                    style={{width: `${width}%`}}
                />
            </div>
            {<AIChatTitle/>}

        </div>
    );
}

export default ProgressBar;
