export const isEmpty = (obj: object) => {
    return obj ? 0 === Object.keys(obj).length : true;
}

export const isKeyExists = (obj: object, key: string) => {
    return obj.hasOwnProperty(key)
}

export const getMaxValueInInnerKey = (arr: Record<any, any>[], key: any) => {
    return arr.reduce((accumulator, element) => {
        if (accumulator[key] > element[key])
            return accumulator;
        return element;
    });

}
export const filterDict = (dict: { [key: string]: any }, allowedKeys: string[]) => {
    const filtered = allowedKeys
        .filter(key => {
            const keyParts = key.split('.');
            let current = dict;

            for (const part of keyParts) {
                if (current && typeof current === "object" && part in current) {
                    current = current[part];
                } else {
                    return false;
                }
            }
            return true;
        })
        .reduce((obj, key) => {
            const keyParts = key.split('.');
            let current = dict;

            for (const part of keyParts) {
                current = current[part];
            }
            return {
                ...obj,
                [key]: current && Array.isArray(current) ? current.join(" / ") : typeof current == "object" ?
                    current?.name : current
            };
        }, {});
    return filtered;
}



