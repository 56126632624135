interface SwitchToggleProps {
    option1: string;
    option2: string;
    switchToOption1: boolean;
    setSwitchToOption1: (switchToOption1: boolean) => void;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({option1, option2, switchToOption1, setSwitchToOption1}) => {

    return (
        <div className="flex font-bold text-md items-center gap-3">

            <div className="inline-block border-warm-dark border-[1px] py-2 px-1 bg-white rounded-2xl whitespace-nowrap">
                <span>
                    <input
                        onChange={(e) => setSwitchToOption1(true)}
                        type="radio"
                        id={option1}
                        className="hidden peer"
                        checked={switchToOption1}
                    />
                    <label
                        htmlFor={option1}
                        className="bg-white rounded-2xl py-1 px-3 select-none cursor-pointer peer-checked:bg-warm-light peer-checked:text-red font-normal"
                    >
                        {option1}
                    </label>
                </span>
                <span>
                    <input
                        onChange={(e) => setSwitchToOption1(false)}
                        type="radio"
                        id={option2}
                        className="hidden peer"
                        checked={!switchToOption1}
                    />
                    <label
                        htmlFor={option2}
                        className="bg-white rounded-2xl py-1 px-3 select-none cursor-pointer peer-checked:bg-warm-light peer-checked:text-red font-normal"
                    >
                        {option2}
                    </label>
                </span>
            </div>
        </div>
    )
}

export default SwitchToggle;