import React, {useEffect, useState} from "react";
import Spinner from "./Spinner";
import {AnimatePresence, motion} from "framer-motion";

type LoadingProps = {
    messages: string[];
    intervalTime?: number;
    spinner_size?: string | number;
    center?: boolean;
}

const Loading = (props: LoadingProps) => {
    const {messages, intervalTime = 2000, spinner_size = 20, center = false} = props;
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        if (messages.length > 0) {
            const intervalId = setInterval(() => {
                setCurrentMessageIndex((prevIndex) => {
                    if (prevIndex < messages.length - 1) {
                        return prevIndex + 1;
                    } else if (prevIndex === messages.length - 1) {
                        return messages.length;
                    } else {
                        return messages.length - 1;
                    }
                });
            }, intervalTime);

            return () => clearInterval(intervalId);
        }
    }, [messages, intervalTime]);

    const getMessage = () => {
        if (currentMessageIndex < messages.length) {
            return messages[currentMessageIndex];
        }
        return '';
    }

    return (
        <div className={`${center ? 'flex flex-col items-center justify-center' : 'flex flex-row'}`}>
            <Spinner size={spinner_size}/>
            {(messages.length !== 0) && (
                    <AnimatePresence mode='wait'>
                        <motion.p
                            key={currentMessageIndex}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            className="mt-1 ml-1"
                        >
                            {getMessage()}
                        </motion.p>
                    </AnimatePresence>
                )}
        </div>
    )
}

export default Loading;