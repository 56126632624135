import React, {useEffect, useMemo, useRef, useState} from "react";

import {useTranslation} from "react-i18next";
import moment from "moment";
import {useNavigate} from "react-router-dom";

import Header from "./components/Header";
import FirstSessionPrep from "./components/FirstSessionPrep";
import SessionListItem from "./components/SessionListItem";
import Button from "../../../../shared/components/Button";
import PreviousSessionModal from "./components/PreviousSessionModal";
import SessionCard from "./components/SessionCard";
import Modal from "../../components/Modal";
import FrameModal from "../../../../shared/components/FrameModal";
import Coaching from "../../../coaching/pages/Coaching";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import Recommended from "./components/Recommended";
import SessionsDetails from "./components/SessionsDetails";

import useGuideStatus from "../../../../utils/hooks/useGuideStatus";
import {getCurrentProgress, getPreviousGroupSessions} from "../../../../services/api/clientApi";
import {cancelSession} from "../../../../services/api/meetingApi";
import {useNavTitle} from "@hooks/useNavTitle";
import {useConfig} from "../../../../utils/providers/AppConfigProvider";

import {IProgressSchema, ISessionSchema} from "../../../../models/IHome";
import {EAssessmentType, EHomeDropdownAction,} from "../../../../constants/enums";
import {Meta, THomeAssessmentData} from "../../../../constants/types";

import i18n from "../../../../utils/lib/i18n";
import {quizData} from "../../../../constants";
import HomeENG from "../../locale/eng/home.json";
import HomeFR from "../../locale/fr/home.json";
import AIRecommended from "./components/AIRecommended";
import {AICoachType} from "../AICoaching/enum";
import useAuth from "@hooks/useAuth";
import AiHome from "../AiHome";
import Objectives from "./components/Objectives";
import ObjectiveModalContent from "../../components/ObjectiveModalContent";
import {getClientObjectives} from "../../../../services/api/teams/GuideActions";
import {getPendingReviews} from "../../../../services/api/teams/ReportActions";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import PreviousSessionListItem from "./components/PreviousSessionListItem";

type previousSessionPopupVisibility = {
    visible: boolean;
    alreadyPrompted: boolean;
};

const Home = ({...props}) => {
    const {config} = useConfig();
    const {t} = useTranslation("home");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const guideStatus = useGuideStatus();
    const navigate = useNavigate();
    i18n.addResourceBundle("en", "home", HomeENG);
    i18n.addResourceBundle("fr", "home", HomeFR);
    const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);
    const [progress, setProgress] = useState<IProgressSchema>();
    const [modalTitle, setModalTitle] = useState<string>();
    const [sessionModalOpen, setSessionModalOpen] = useState<boolean>(false);
    const [currentBookingCoach, setCurrentBookingCoach] = useState<number>();
    const [reschedulingSession, setReschedulingSession] = useState<number>();
    const [highlightedSession, setHighlightedSession] = useState<
        ISessionSchema | undefined
    >();
    const [currentSession, setCurrentSession] = useState<ISessionSchema>();
    const [previousSessionPopupVisible, setPreviousSessionPopupVisibility] =
        useState<previousSessionPopupVisibility>({
            visible: false,
            alreadyPrompted: false,
        });
    const [joinedSession, setJoinedSession] = useState<ISessionSchema>();
    const [cancellingSession, setCancellingSession] = useState<ISessionSchema>();
    const [pendingSessions, setPendingSessions] = useState<number[]>([]);
    const [previousSessions, setPreviousSessions] = useState<ISessionSchema[]>([]);
    const [currentReschedulingSession, setcurrentReschedulingSession] =
        useState<ISessionSchema>();
    const [confirmationSessionModalOpen, setConfirmationSessionModalOpen] =
        useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [cancelInProgress, setCancelInProgress] = useState(false);
    const [displayObjectives, setDisplayObjectives] = useState(false);
    const [objectives, setObjectives] = useState<Meta[]>([]);
    const {user} = useAuth();
    const [currentModalIndex, setCurrentModalIndex] = useState<number | null>(null);
    const [pendingReviewSessions, setPendingReviewSessions] = useState<any[]>([]);
    const [visibleSessions, setVisibleSessions] = useState(12);
    const [expanded, setExpanded] = useState(false);
    const [showPreviousSessions, setShowPreviousSessions] = useState(false);

    const aiOnlyCoach = user?.aiCoachType && user.aiCoachType === AICoachType.ai_only;

    const loadObjectives = () => {
        getClientObjectives()
            .then((objectives) => {
                if (objectives) setObjectives(objectives);
            })
            .catch((err) => console.log(err));
    };

    const handleGetPendingReviews = () => {
        if (!user) return;
        getPendingReviews(user?.id)
            .then((res) => {
                if (res.length > 0) {
                    res.sort((a: any, b: any) => Date.parse(b.meeting_start_time) - Date.parse(a.meeting_start_time));
                    setPendingReviewSessions(res);
                    setCurrentModalIndex(0);
                }
            })
    }

    useEffect(() => {
        if (pendingReviewSessions.length > 0 && currentModalIndex === 0) {
            openModalForSession(0);
        }
    }, [currentModalIndex, pendingReviewSessions]);

    const openModalForSession = (index: number) => {
        if (index >= pendingReviewSessions.length) return;
        const session = pendingReviewSessions[index];
        frameModalRef.current?.open(EAssessmentType.Review, t("leave_a_review"), "iframe", session.id, 600);
    }

    const handleCloseModal = (result: any) => {
        if (!result) {
            setCurrentModalIndex(null);
            return;
        }

        if (result.assessment_type == EAssessmentType.Review && !result.error) {
            if (currentModalIndex !== null && currentModalIndex + 1 < pendingReviewSessions.length) {
                const nextIndex = currentModalIndex + 1;
                setCurrentModalIndex(nextIndex);
                setTimeout(() => {
                    openModalForSession(nextIndex);
                }, 500);
            } else {
                setCurrentModalIndex(null);
            }
        }
    }

    useEffect(() => {
        loadObjectives();
    }, [displayObjectives]);

    useEffect(() => {
        guideStatus.checkStatus(() => {
            handleGetCurrentProcess();
            handleGetPendingReviews();
            document.addEventListener("visibilitychange", handleVisibilityChange);
        });
    }, []);

    useEffect(() => {
        if (progress) {
            setHighlightedSession(getHighlightedSession(progress));

            const currentSession = progress.sessions.find((session) =>
                moment(moment.now()).isBetween(
                    moment(session.datetime).subtract(10, "minutes"),
                    moment(session.datetime).add(70, "minutes"),
                ),
            );

            setCurrentSession(currentSession);
        }
    }, [progress]);

    useEffect(() => {
        if (joinedSession) window.open(joinedSession.join_link, "_blank");
    }, [joinedSession]);


    const handleVisibilityChange = () => {
        setPreviousSessionPopupVisibility((previousSessionPopupVisible) => {
            setJoinedSession((joinedSession) => {
                if (
                    !document.hidden &&
                    !previousSessionPopupVisible.alreadyPrompted &&
                    joinedSession?.datetime &&
                    moment().isAfter(moment(joinedSession?.datetime).add(1, "hours"))
                ) {
                    setPreviousSessionPopupVisibility({
                        visible: true,
                        alreadyPrompted: true,
                    });
                }
                return joinedSession;
            });
            return previousSessionPopupVisible;
        });
    };

    const handleconfirmationSessionModalOpen = () => {
        setConfirmationSessionModalOpen(true);
    };

    const handleGetCurrentProcess = () => {
        getCurrentProgress()
            .then((progress) => {
                if (progress) {
                    setProgress(progress);
                    const pendingSessions = [];
                    for (
                        let i = progress.sessions.length + 1;
                        i <= progress.sessions.length + progress.pending_sessions_count;
                        i++
                    ) {
                        pendingSessions.push(i);
                    }
                    setPendingSessions(pendingSessions);
                    setVisibleSessions(progress.sessions.length < 12 ? 12 - progress.sessions.length : 0)
                    getPreviousGroupSessions().then((res) => {
                        setPreviousSessions(res);
                    }).catch((err) => {
                        console.log("Failed to get previous sessions:", err);
                    })
                }
            })
            .catch((err) => {
                console.error("Failed to get current progress:", err);
            });
    };

    const handleCancelConfirm = (
        session: ISessionSchema,
        isCreditDeducted: boolean,
    ) => {
        setConfirmationModalOpen(false);
        setCancelInProgress(true);
        cancelSession(session.id, isCreditDeducted)
            .then((data) => {
                if (data.error) {
                    console.error("Failed to cancel session", data);
                } else {
                    handleGetCurrentProcess();
                }
            })
            .catch((err) => {
                console.error("Failed to cancel session", err);
            })
            .finally(() => {
                setCancelInProgress(false);
                setCancellingSession(undefined);
            });
    };

    const handleAction = (
        action: string | EHomeDropdownAction,
        session?: ISessionSchema,
        assessmentData?: THomeAssessmentData,
    ) => {
        switch (action) {
            case EHomeDropdownAction.Reshedule:
                setModalTitle(t("reschedule"));
                setCurrentBookingCoach(session?.coach_id);
                setReschedulingSession(session?.id);
                setcurrentReschedulingSession(session);
                setSessionModalOpen(true);
                break;
            case EHomeDropdownAction.LeaveReview:
                frameModalRef.current?.open("review", t("leave_a_review"), "iframe", session?.id, 600);
                break;
            case EHomeDropdownAction.Cancel:
                setCancellingSession(session);
                setConfirmationModalOpen(true);
                break;
            case EHomeDropdownAction.CheckReport:
                navigate("/dashboard/reports/view");
                break;
            case EHomeDropdownAction.JoinSession:
                const {join_link} = session as ISessionSchema;
                if (!join_link) return;
                setJoinedSession(session);
                window.open(join_link, "_blank");
                break;
            case EHomeDropdownAction.ScheduleNext:
                if (session) setCurrentBookingCoach(session.coach_id);
                setModalTitle(t("schedule_session"));
                setSessionModalOpen(true);
                break;
            case EHomeDropdownAction.Message:
                //Message action
                break;
            case EHomeDropdownAction.CompleteNow:
                const {key, quizData} = assessmentData!;
                frameModalRef.current?.open(quizData[key].key, t(quizData[key].title));
        }
    };

    const getHighlightedSession = (progress?: IProgressSchema) => {
        if (!progress?.sessions) {
            return;
        }

        const highlightedSession = progress.sessions.find(
            (session) => session.id === progress.highlighted_session_id,
        );
        if (!highlightedSession) {
            return;
        }

        return highlightedSession;
    };

    const handleCloseSessionModal = () => {
        setConfirmationSessionModalOpen(false);
        setConfirmationModalOpen(false);
        setSessionModalOpen(false);
        handleGetCurrentProcess();
        setTimeout(() => {
            setCurrentBookingCoach(undefined);
            setReschedulingSession(undefined);
            setcurrentReschedulingSession(undefined);
        }, 1000);
    };

    const latestSession = useMemo(() => {
        if (!progress || !progress.sessions.length) return;
        const reversedSessions = [...progress.sessions].reverse();
        const session = reversedSessions.find((sessionItem) => {
            return !sessionItem.orientation;
        });
        return session ?? undefined;
    }, [progress]);

    const hideSelfReflection = !config?.self_reflection;
    const hideFlashReflection = !config?.flash_reflection;
    const hideImpactReflection = !config?.impact_reflection;

    const isMoreThan24Hours = (session: ISessionSchema): boolean => {
        const sessionStartTime = new Date(session.datetime).getTime();
        const currentDateTime = new Date().getTime();
        const timeDifference = sessionStartTime - currentDateTime;
        const hoursDifference = timeDifference / (1000 * 3600);
        return hoursDifference > 24;
    };

    const SessionWindow = ({
                               isCreditDeducted,
                           }: {
        isCreditDeducted: boolean;
    }) => (
        <Modal
            title={modalTitle}
            isOpen={sessionModalOpen}
            onClose={handleCloseSessionModal}
            className="w-[90vw] h-[90vh]"
        >
            <Coaching
                variant="modal"
                coachId={currentBookingCoach}
                meetingId={reschedulingSession}
                handleBookingSubmission={handleCloseSessionModal}
                isCreditDeducted={isCreditDeducted}
                orientation={currentReschedulingSession?.orientation !== null
                    ? currentReschedulingSession?.orientation
                    : undefined}
                isReschedule={!!reschedulingSession}
            />
        </Modal>
    );

    if (aiOnlyCoach) return <AiHome/>

    return (
        <div className="bg-linen_gradient flex flex-col gap-4 py-6 px-6 h-full">
            {progress ? (
                <>
                    <Header
                        progress={progress}
                        handleAction={handleAction}
                        latestSession={latestSession}
                        assessmentData={progress.current_state === EAssessmentType.Coaching ? undefined : {
                            key: progress.current_state,
                            quizData,
                        }}
                    />
                    <div className="flex flex-row gap-4">
                        <div className="flex flex-col basis-1/2 gap-4">
                            {config?.session_preparation_video && (
                                <FirstSessionPrep
                                    firstSessionComplete={
                                        progress.sessions.length
                                            ? !progress.sessions[0].upcoming
                                            : false
                                    }
                                />
                            )}
                            <div className="bg-white pt-4 rounded border-gray-50 border-[1px]">
                                <p className="font-bold font-dm_sans mx-4 mt-1 mb-2 text-sm">
                                    {t("my_coaching_journey")}
                                </p>
                                {!hideSelfReflection && (
                                    <SessionListItem
                                        title={t("self_reflection")}
                                        toolTip={t("tool_tip.self_reflection")}
                                        tag={t("assessment")}
                                        isComplete={progress.reflections.self_reflection}
                                        variant="quiz"
                                        handleAction={handleAction}
                                        assessmentData={{
                                            key: EAssessmentType.SelfReflection,
                                            quizData,
                                        }}
                                        displayAction={true}
                                        firstItem
                                    />
                                )}
                                {!hideFlashReflection && (
                                    <SessionListItem
                                        title={t("360_reflection")}
                                        toolTip={t("tool_tip.reflection_360")}
                                        tag={t("feedback")}
                                        isComplete={progress.reflections.reflection360}
                                        variant="quiz"
                                        handleAction={handleAction}
                                        assessmentData={{
                                            key: EAssessmentType.Reflection360,
                                            quizData,
                                        }}
                                        displayAction={true}
                                        firstItem={hideSelfReflection}
                                    />
                                )}
                                {previousSessions.length > 0 &&
                                    <PreviousSessionListItem
                                        title={t("previous_sessions")}
                                        isExpanded={showPreviousSessions}
                                        setIsExpanded={setShowPreviousSessions}
                                    />
                                }
                                {showPreviousSessions &&
                                    <div className="border-pink-100 border-y-[1px]">
                                        {previousSessions.map((session, index) => {
                                            const sessionNo = index + 1;
                                            const fomattedSessionNo =
                                                sessionNo < 10 ? `0${sessionNo}` : sessionNo;
                                            return (
                                                <SessionListItem
                                                    key={fomattedSessionNo}
                                                    title={`${t("session")} ${fomattedSessionNo}`}
                                                    tag={t("video_call")}
                                                    variant="video_call"
                                                    isComplete={!session.upcoming}
                                                    isCurrent={currentSession?.id === session.id}
                                                    session={session}
                                                    handleAction={handleAction}
                                                    cancelInProgress={
                                                        cancelInProgress &&
                                                        cancellingSession &&
                                                        cancellingSession.id === session.id
                                                    }
                                                    firstItem={
                                                        !index
                                                    }
                                                    lastItem={
                                                        previousSessions.length - 1 === index
                                                    }
                                                    hideCheckReportBtn={!(config?.report && session.report)}
                                                    isActiveCoach={session.coach_active}
                                                    isPrevious
                                                />
                                            );
                                        })}
                                    </div>
                                }
                                {progress.sessions.slice(0, expanded ? progress.sessions.length : 12).map((session, index) => {
                                    const sessionNo = index + 1;
                                    const fomattedSessionNo =
                                        sessionNo < 10 ? `0${sessionNo}` : sessionNo;
                                    return (
                                        <SessionListItem
                                            key={fomattedSessionNo}
                                            title={`${t("session")} ${fomattedSessionNo}`}
                                            tag={t("video_call")}
                                            variant="video_call"
                                            isComplete={!session.upcoming}
                                            isCurrent={currentSession?.id === session.id}
                                            session={session}
                                            handleAction={handleAction}
                                            cancelInProgress={
                                                cancelInProgress &&
                                                cancellingSession &&
                                                cancellingSession.id === session.id
                                            }
                                            firstItem={
                                                !index && hideSelfReflection && hideFlashReflection
                                            }
                                            lastItem={
                                                !pendingSessions.length &&
                                                index === progress.sessions.length - 1 &&
                                                hideImpactReflection
                                            }
                                            hideCheckReportBtn={!(config?.report && session.report)}
                                            isActiveCoach={session.coach_active}
                                        />
                                    );
                                })}

                                {pendingSessions.slice(0, visibleSessions).map((sessionNoVal, index) => {
                                    const fomattedSessionNo =
                                        sessionNoVal < 10 ? `0${sessionNoVal}` : sessionNoVal;

                                    return (
                                        <SessionListItem
                                            key={fomattedSessionNo}
                                            title={`${t("session")} ${fomattedSessionNo}`}
                                            tag={t("video_call")}
                                            variant="video_call"
                                            isNext={!index}
                                            handleAction={handleAction}
                                            latestSession={latestSession}
                                            isPending
                                            firstItem={
                                                !progress.sessions.length &&
                                                !index &&
                                                hideSelfReflection &&
                                                hideFlashReflection
                                            }
                                            lastItem={
                                                index === pendingSessions.length - 1 &&
                                                hideImpactReflection
                                            }
                                            hideCheckReportBtn={!config?.report}
                                        />
                                    );
                                })}

                                {!hideImpactReflection && (
                                    <SessionListItem
                                        title={t("impact_reflection")}
                                        tag={t("assessment")}
                                        variant="quiz"
                                        isComplete={progress.reflections.impact_reflection}
                                        currentProgressState={progress.current_state}
                                        handleAction={handleAction}
                                        assessmentData={{
                                            key: EAssessmentType.ImpactReflection,
                                            quizData,
                                        }}
                                        lastItem
                                    />
                                )}
                                {progress.sessions.length + pendingSessions.length > 12 &&
                                    <div className="flex justify-start justify-items-stretch gap-2 py-4 px-4">
                                        <button
                                            className="flex items-center gap-2 px-4 py-2 text-sm font-medium transition-all duration-200 rounded-lg
                                   bg-white border border-black text-black w-full justify-center"
                                            onClick={() => {
                                                if (expanded) {
                                                    setVisibleSessions(progress.sessions.length < 12 ? 12 - progress.sessions.length : 0)
                                                } else {
                                                    setVisibleSessions(progress?.allowed_sessions_count);
                                                }
                                                setExpanded(!expanded);
                                            }}
                                        >
                                            <span>{expanded ? t("see_less") : t("see_more")}</span>
                                            {expanded ? <ChevronUpIcon className="w-4 h-4 "/>
                                                : <ChevronDownIcon className="w-4 h-4 "/>}
                                        </button>
                                    </div>}
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 basis-1/2">
                            {highlightedSession && (
                                <SessionCard
                                    session={highlightedSession}
                                    handleAction={handleAction}
                                    cancelInProgress={
                                        cancelInProgress &&
                                        cancellingSession &&
                                        cancellingSession.id === highlightedSession.id
                                    }
                                    disableNextButton={progress.pending_sessions_count === 0}
                                />
                            )}
                            {progress.sessions.length > 0 && (
                                <>
                                    <Recommended articles={progress.articles.coach_articles}/>
                                    {config?.ai_generated_materials_for_meetings &&
                                        <AIRecommended articles={progress.articles.ai_articles}/>}
                                </>

                            )}

                            <Objectives
                                objectives={objectives}
                                onEditObjectives={() => setDisplayObjectives(true)}
                            />
                            <SessionsDetails progress={progress}/>
                            {/*  <div className="group fixed top-[600px] right-6 p-2 z-50 flex items-end justify-end w-24 h-24 cursor-pointer">*/}
                            {/*    <div className="text-white shadow-xl flex items-center justify-center p-3 rounded-full bg-red z-50 absoulte shadow-red-300">*/}
                            {/*      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" fill="currentColor" className="w-6 h-6">*/}
                            {/*        <path fillRule="evenodd"*/}
                            {/*              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"*/}
                            {/*              clipRule="evenodd"/>*/}
                            {/*      </svg>*/}
                            {/*      <p className=" sticky font-semibold text-xs group-hover:visible mx-1 my-1"> FAQ </p>*/}
                            {/*    </div>*/}
                            {/*    <div className="absolute rounded-full transition-all duration-[0.2s] ease-out scale-x-0 group-hover:scale-x-100 group-hover:-translate-y-16  flex  p-2 hover:p-3 bg-blue-300 hover:bg-blue-400  text-black w-[500px] max-w-xl">*/}
                            {/*      <FAQComponent/>*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                        </div>

                    </div>


                    {(currentReschedulingSession &&
                        isMoreThan24Hours(currentReschedulingSession)) ||
                    modalTitle === t("schedule_session") ? (
                        <SessionWindow isCreditDeducted={false}/>
                    ) : (
                        <Modal
                            title={modalTitle}
                            isOpen={sessionModalOpen}
                            onClose={handleCloseSessionModal}
                            className="min-w-fit"
                        >
                            <p className="mb-4">{t("do_you_confirm_alert")}</p>
                            <div className="flex justify-end gap-4">
                                <Button
                                    className="text-black border-2 border-black px-4 py-1"
                                    onClick={handleCloseSessionModal}
                                >
                                    {t("no")}
                                </Button>
                                <Button
                                    className="text-white bg-black px-4 py-1"
                                    onClick={handleconfirmationSessionModalOpen}
                                >
                                    {t("yes")}
                                </Button>
                                {confirmationSessionModalOpen && (
                                    <SessionWindow isCreditDeducted={true}/>
                                )}
                            </div>
                        </Modal>
                    )}
                    {previousSessionPopupVisible.visible && (
                        <PreviousSessionModal
                            session={joinedSession}
                            handleAction={handleAction}
                            onClose={() => {
                                setPreviousSessionPopupVisibility({
                                    visible: false,
                                    alreadyPrompted: false,
                                });
                                setJoinedSession(undefined);
                            }}
                        />
                    )}
                    <FrameModal ref={frameModalRef} onClose={handleCloseModal}/>
                    <Modal
                        title={t("cancel_confirmation")}
                        isOpen={confirmationModalOpen}
                        onClose={setConfirmationModalOpen}
                    >
                        <p className="mb-4">
                            {" "}
                            {cancellingSession && isMoreThan24Hours(cancellingSession)
                                ? t("do_you_confirm")
                                : t("do_you_confirm_alert")}
                        </p>
                        <div className="flex justify-end gap-4">
                            <Button
                                className="text-black border-2 border-black px-4 py-1"
                                onClick={() => {
                                    setConfirmationModalOpen(false);
                                }}
                            >
                                {t("no")}
                            </Button>
                            <Button
                                className="text-white bg-black px-4 py-1"
                                onClick={() => {
                                    cancellingSession &&
                                    handleCancelConfirm(
                                        cancellingSession,
                                        !isMoreThan24Hours(cancellingSession),
                                    );
                                }}
                            >
                                {t("yes")}
                            </Button>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={displayObjectives}
                        onClose={setDisplayObjectives}
                        className="w-[90vw] h-[80vh] bg-linen_gradient"
                    >
                        <ObjectiveModalContent setIsOpen={setDisplayObjectives} initialObjectives={objectives}/>
                    </Modal>
                </>
            ) : (
                <LoaderContainer/>
            )}
        </div>
    );
};

export default Home;
