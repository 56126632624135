import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/misc.json";
import Fr from "../../locale/fr/misc.json";
import {useTranslation} from "react-i18next";
import Modal from "../../components/Modal";
import ICoach from "../../../../models/ICoach";
import {
    deleteCoachFromProgram,
    getExcludeProgramCoaches,
    getProgramCoaches,
    updateProgramCoaches
} from "../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";
import CoachCard from "../Coaches/components/CoachCard";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import {IProgramData} from "../../../../constants/types";
import CoachListCard from "./components/CoachListCard";
import {SearchIcon} from "@heroicons/react/outline";
import {debounce} from "lodash";
import {toast} from "react-toastify";
import {useNavTitle} from "@hooks/useNavTitle";

const ManageCoaches = ({...props}) => {
    i18n.addResourceBundle("en", "misc", ENG);
    i18n.addResourceBundle("fr", "misc", Fr);
    const {t} = useTranslation("misc");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {user} = useAuth();
    const [coachesForAdding, setCoachesForAdding] = useState<ICoach[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCoaches, setSelectedCoaches] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [programs, setPrograms] = useState<IProgramData[]>([]);
    const [companyCoaches, setCompanyCoaches] = useState<ICoach[]>([]);
    const [companyPrograms, setCompanyPrograms] = useState<IProgramData[]>([]);
    const [selectedProgramId, setSelectedProgramId] = useState<number | null>(null);
    const [isLoadingStart, setIsLoadingStart] = useState(false);

    const handleOpen = (programId: number | null) => {
        setIsOpen(true);
        getCoachesForAdding(programId);
        setSelectedProgramId(programId);
    }

    const fetchPrograms = useCallback((isBlockedRefreshing?: boolean) => {
        if (!user) return;
        !isBlockedRefreshing && setIsLoadingStart(true);
        getProgramCoaches(user.companyId).then((response) => {
            setPrograms(response);
        }).catch((err) => {
            console.error(err);
        }).finally(() => setIsLoadingStart(false));
    }, [])

    useEffect(() => {
        fetchPrograms();
    }, []);

    const fetchCoaches = () => {
        const filterCompany = programs.filter((program) => program.program_id === undefined);
        const filterProgram = programs.filter((program) => program.program_id !== undefined);
        filterCompany.map((item) =>
            setCompanyCoaches(item.coaches)
        )
        setCompanyPrograms(filterProgram);
    }

    useEffect(() => {
        fetchCoaches();
    }, [programs]);

    const getCoachesForAdding = (program_id: number | null) => {
        setIsLoading(true);
        getExcludeProgramCoaches(program_id, user?.companyId)
            .then((response) => {
                setCoachesForAdding(response);
            }).catch((err) => {
            console.error(err);
        }).finally(() => setIsLoading(false))
    };

    const handleSubmit = async (programId: number | null) => {
        if (!user) return;
        if (selectedCoaches.length === 0) {
            setSelectedProgramId(null);
            setSelectedCoaches([]);
            setSearchText("");
            setIsOpen(false);
            return;
        }
        await updateProgramCoaches(user.companyId, selectedCoaches, programId)
            .catch((err) => {
                console.error(err)
            })
        setSelectedProgramId(null);
        setSelectedCoaches([]);
        setSearchText("");
        setIsOpen(false);
        fetchPrograms(true);
    }

    const handleDeleteCoach = (pool_id: number) => {
        deleteCoachFromProgram(pool_id)
            .then(() => {
                toast.success(t("manage_coaches.delete_success"));
                fetchPrograms(true);
            }).catch((err) => {
            toast.error(t("manage_coaches.delete_failed"));
            console.error(err);
        })
    }

    const handleSelect = ((coach: ICoach) => {
        setSelectedCoaches(prevSelected => {
            if (prevSelected.includes(coach.id)) {
                return prevSelected.filter(c => c !== coach.id);
            } else {
                return [...prevSelected, coach.id];
            }
        });
    });

    const handleClose = () => {
        setIsOpen(false);
        setSelectedCoaches([]);
        setSearchText("");
    }

    const filteredCoaches = useMemo(() => {
        const text = searchText.toLowerCase();
        const coaches = coachesForAdding;

        if (text.length < 2) return coaches;

        return coaches.filter((coach) => {
            return (
                `${coach.first_name.toLowerCase()} ${coach.last_name.toLowerCase()}`.includes(text)
            );
        });
    }, [searchText, coachesForAdding]);

    const debouncedSearch = useRef(
        debounce(async (criteria) => {
            setSearchText(criteria)
        }, 50)
    ).current;

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <div className="gap-5 px-10 py-5">
            <h3 className="font-bold font-dm_sans text-black py-2 mb-2">
                {t("manage_coaches.title")}
            </h3>
            {isLoadingStart ? (
                <div className="inset-0 fixed">
                    <LoaderContainer/>
                </div>
            ) : (
                <div className="grid grid-cols-4 gap-4">
                    <CoachListCard isCompanyCard coaches={companyCoaches} onClick={handleOpen}
                                   handleDeleteCoach={handleDeleteCoach}/>
                    {companyPrograms.map((item) =>
                        <CoachListCard programId={item.program_id} programName={item.program_name}
                                       coaches={item.coaches}
                                       onClick={handleOpen} handleDeleteCoach={handleDeleteCoach}/>
                    )}
                </div>
            )}
            <Modal
                className="w-[1200px] max-h-[590px]"
                title={
                    <div className="flex justify-between items-center gap-5">
                        <p className="font-bold text-lg font-dm_sans text-black">
                            {t("manage_coaches.select_coaches")}
                        </p>
                        <div className="bg-white w-fit rounded-lg">
                            <div
                                className="h-[36px] w-max flex flex-row items-center gap-2 px-2 pl-3 border-[1px] border-[#f2ebe3] rounded-[5px]">
                                <input
                                    className="w-60 font-lexend text-sm font-bold text-black placeholder:font-light placeholder:text-[#bfbfbf] focus:outline-none"
                                    placeholder={t('manage_coaches.search')}
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        debouncedSearch(e.target.value);
                                    }}
                                />
                                <SearchIcon className="h-[20px] text-black"/>
                            </div>
                        </div>
                        <div/>
                    </div>}
                isOpen={isOpen}
                onClose={handleClose}
            >
                {isLoading ? (
                    <LoaderContainer/>
                ) : (
                    <div className="flex flex-col justify-between">
                        <div className="h-[550px] overflow-y-auto">
                            <div
                                className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pr-2">
                                {filteredCoaches.length === 0 && searchText ? (
                                    <p className="text-sm font-dm_sans pb-5">{t("manage_coaches.no_found_text")}</p>
                                ) : (
                                    filteredCoaches.map((coach, index) => (
                                        <div key={index} className="relative">
                                            <div className="absolute top-2 right-2">
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 accent-red cursor-pointer"
                                                    onChange={() => {
                                                        handleSelect(coach)
                                                    }}
                                                    checked={selectedCoaches.includes(coach.id)}
                                                />
                                            </div>
                                            <CoachCard coach={coach}/>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        <button onClick={() => handleSubmit(selectedProgramId)}
                                className={`dashboard-button ${selectedCoaches.length > 0 ? "bg-red hover:bg-black" : "bg-black"} px-4 py-2 w-full mt-4`}>
                            {selectedCoaches.length > 0 ? t("manage_coaches.submit") : t("manage_coaches.close")}
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default ManageCoaches;
