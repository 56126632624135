import React, {useEffect, useState} from "react";

import moment from "moment";
import {useTranslation} from "react-i18next";

import {IProgressSchema} from "../../../../../models/IHome";

import sessions_remaining_icon from "@img/dashboard/home/icons/sessions_remaining.svg";
import sessions_expiration_icon from "@img/dashboard/home/icons/sessions_expiration.svg";
import {QuestionMarkCircleIcon} from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import InformationIcon from "../../../../../shared/components/InformationIcon";

type Props = {
    progress: IProgressSchema;
};

const SessionsDetails = ({progress}: Props) => {
    const {t} = useTranslation("home");

    const [sessionsRemaining, setSessionRemaining] = useState("");
    const [sessionsExpiration, setSessionsExpiration] = useState("");

    useEffect(() => {
        const totalSessions = progress.allowed_sessions_count;
        const remainingSessions = progress.pending_sessions_count;
        const fractionString = `${
            remainingSessions < 9 ? `0${remainingSessions}` : remainingSessions
        }/${totalSessions < 9 ? `0${totalSessions}` : totalSessions}`;
        setSessionRemaining(fractionString);
    }, [progress]);

    useEffect(() => {
        setSessionsExpiration(moment(progress.expiration).format("MM/DD/YYYY"));
    }, []);

    return (
        <div className="flex flex-row p-5 bg-white border-gray-50 border-[1px] rounded">
            <div className="flex flex-row gap-4 w-1/2">
                <img
                    src={sessions_remaining_icon}
                    alt="sessions_remaining"
                    className="w-10"
                />
                <div className="flex flex-col justify-center">
                    <p className="font-dm_sans text-black font-bold m-0 text-sm">
                        {sessionsRemaining}
                    </p>
                    <div className="flex flex-row items-center gap-2">
                        <p className="font-dm_sans text-gray-400 m-0 text-sm">
                            {t("sessions_details.sessions_remaining")}
                        </p>
                        <span data-for="session-remaining-tooltip" data-tip={"hover on me will keep the tooltip"}>
                            <InformationIcon className="ml-0.5 w-4 h-4 cursor-pointer"/>
                        </span>
                    </div>
                    <ReactTooltip
                        className={"w-60"}
                        delayHide={500}
                        id={"session-remaining-tooltip"}
                        place="top"
                        effect="solid"
                        backgroundColor="#FEB3CC"
                        textColor="black"
                    >
                        <span>{t("sessions_details.sessions_remaining_tooltip")}</span>
                    </ReactTooltip>
                </div>
            </div>
            <div className="flex flex-row gap-4 w-1/2">
                <img
                    src={sessions_expiration_icon}
                    alt="sessions_expiration"
                    className="h-10"
                />
                <div className="flex flex-col justify-center">
                    <p className="font-dm_sans text-black font-bold m-0 text-sm">
                        {sessionsExpiration}
                    </p>
                    <div className="flex flex-row items-center gap-2">
                        <p className="font-dm_sans text-gray-400 m-0 text-sm">
                            {t("sessions_details.sessions_expiration")}
                        </p>
                        <span data-for="session-expiration-id" data-tip={"hover on me will keep the tooltip"}>
                             <InformationIcon className="ml-0.5 w-4 h-4 cursor-pointer"/>
                        </span>
                    </div>
                    <ReactTooltip
                        className={"w-60"}
                        delayHide={500}
                        id={"session-expiration-id"}
                        place="top"
                        effect="solid"
                        backgroundColor="#FEB3CC"
                        textColor="black"
                    >

                        <span>{t("sessions_details.sessions_expiration_tooltip")} </span>
                    </ReactTooltip>
                </div>
            </div>
        </div>
    );
};

export default SessionsDetails;
