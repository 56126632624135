import {useState, useRef, useCallback} from 'react';

interface UseWebSocketProps {
    url: string;
    onMessage?: (data: any) => void;
    onOpen?: () => void;
    onClose?: () => void;
}

export const useWebSocket = ({url, onMessage, onOpen, onClose}: UseWebSocketProps) => {
    const isConnectedRef = useRef<boolean>(false);
    const [isPaused, setIsPaused] = useState(false);
    const socketRef = useRef<WebSocket | null>(null);
    const start = useCallback((): Promise<boolean> => {
        return new Promise((resolve) => {
            if (socketRef.current) return resolve(false);
            socketRef.current = new WebSocket(url);
            socketRef.current.onopen = () => {
                console.log('WebSocket opened');
                isConnectedRef.current = true;
                onOpen?.();
                resolve(true);
            };
            socketRef.current.onclose = () => {
                isConnectedRef.current = false;
                onClose?.();
                socketRef.current = null;
            };
            socketRef.current.onmessage = (event) => {
                // console.log('WebSocket message', event.data);
                onMessage?.(JSON.parse(event.data));
            };
            socketRef.current.onerror = () => {
                console.log('WebSocket error');
                resolve(false);
            };
        });
    }, [url, onMessage, onOpen, onClose]);
    const sendMessage = useCallback((data: Blob | string) => {
        if (isConnectedRef.current && socketRef.current && !isPaused) {
            socketRef.current.send(data);
        }
    }, [isPaused]);
    const pause = () => setIsPaused(true);
    const resume = () => setIsPaused(false);
    const close = () => {
        socketRef.current?.close();
        isConnectedRef.current = false;
    };
    return {isConnected: isConnectedRef.current, isPaused, sendMessage, start, pause, resume, close};
};