import React, {useEffect, useState} from 'react';
import {Dropdown, Tooltip, Whisper} from "rsuite";
import {CalendarIcon} from "@heroicons/react/outline";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {updateCoachBookingConfig} from "../../../../../services/api/coachApi";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/events.json";
import Fr from "../../../locale/fr/events.json";
import {useTranslation} from "react-i18next";
import Button from "../../../../../shared/components/Button";
import {TiTick} from "react-icons/ti";

interface Props {
    title: string;
    options: any[];
    onClose?: () => void;
}

const CalendarsDropdown: React.FC<Props> = ({title, options, onClose}) => {
    i18n.addResourceBundle("en", "events", ENG);
    i18n.addResourceBundle("fr", "events", Fr);
    const {t} = useTranslation("events");

    const [calendarOptions, setCalendarOptions] = useState(options);
    const [initialOptions, setInitialOptions] = useState(options);
    const [isClosing, setIsClosing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setCalendarOptions(options);
        setInitialOptions(options);
    }, [options]);

    const toggleConnected = (id: string) => {
        setCalendarOptions(prevOptions =>
            prevOptions.map(option =>
                option.id === id
                    ? {...option, connected: !option.connected}
                    : option
            )
        );
    };

    const handleClose = () => {
        if (isClosing) return;
        setIsClosing(true);

        const hasChanged = JSON.stringify(initialOptions) !== JSON.stringify(calendarOptions);
        if (hasChanged) {
            const skipCalendars = calendarOptions
                .filter(option => !option.connected)
                .map(option => option.id);
            updateCoachBookingConfig({skip_calendars: skipCalendars})
                .then((res) => {
                    setIsClosing(false);
                    setIsOpen(false);
                    onClose && onClose();
                })
                .catch((err) => {
                    console.error(err);
                })
        } else {
            setIsClosing(false);
            setIsOpen(false);
        }
    }
    const handleToggle = (isDropdownOpen?: boolean) => {
        setIsOpen(isDropdownOpen || false);
    };

    return <Dropdown
        onToggle={handleToggle}
        open={isOpen}
        title={
            <span className="hover:text-red-500 font-semibold text-sm mr-5">
            {title}
        </span>
        }
        icon={
            <Whisper
                placement="bottom"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <span className="text-sm text-white">{t("connected_calendars_tooltip")}</span>
                    </Tooltip>
                }
            >
                <CalendarIcon
                    style={{
                        width: '24px',
                        height: '24px',
                        marginRight: "10px",
                        color: "gray",
                    }}
                />
            </Whisper>
        }
        className="hover:text-red-500 focus:ring focus:ring-gray-50"
        onClose={handleClose}
    >
        {calendarOptions.length === 0 &&
            <p className="w-full max-w-[300px] min-w-[245px] px-3 py-2 flex items-center gap-2 text-gray-400">
                {t("no_calendars")}
            </p>}
        {calendarOptions.map((option: any) => (
            <div
                onClick={() => !option.primary && toggleConnected(option.id)}
                key={option.id}
                className={`w-full max-w-[300px] min-w-[245px] px-3 py-2 flex items-center gap-2 transition-all duration-150 ${
                    option.primary
                        ? "cursor-not-allowed"
                        : "bg-white hover:bg-gray-50 cursor-pointer"
                }`}
            >
                <div
                    className={`relative w-6 h-6 flex items-center justify-center ${
                        option.primary ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                >
                    {option.connected || option.primary ? (
                        <>
                            <CheckCircleIcon
                                className={`h-[20px] ${
                                    option.primary ? "text-gray-300" : "text-red-500"
                                }`}
                            />
                            {!option.primary && (
                                <span
                                    className="absolute inset-0 rounded-full bg-red opacity-0 hover:opacity-25 hover:animate-ping"/>
                            )}
                        </>
                    ) : (
                        <i
                            className="fa fa-circle-thin text-gray-400 hover:text-red-500"
                            aria-hidden="true"
                        />
                    )}
                </div>
                <div className="flex flex-col leading-tight">
                    <span
                        className={`text-sm font-medium ${
                            option.primary ? "text-gray-500" : "text-gray-800"
                        }`}
                    >
                        {option.name}
                    </span>
                    {option.primary && (
                        <span className="text-xxs text-gray-400 italic mt-0.5">
                            {t("primary_calendar")}
                        </span>
                    )}
                </div>
            </div>
        ))}
        {calendarOptions.length > 0 &&
        <div className="w-full ">
            <hr className="w-full m-0"/>
            <Button onClick={handleClose}
                    className="ml-auto mr-2 px-2 py-0.5 mt-1.5 bg-black hover:bg-red"
                    disabled={isClosing}
            >
                <TiTick className=" text-white"/>
            </Button>
        </div>}
    </Dropdown>
};

export default CalendarsDropdown;
