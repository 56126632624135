import {createContext, ReactNode, useContext, useEffect, useState,} from "react";

import storage from "../../services/storage/localStorage";

import {getAppConfig} from "../../services/api/clientApi";
import {ERole} from "../../constants/enums";
import MetaApi from "../../services/api/metaApi";

type IClientSettingsConfig = {
    quick_replies_enabled?: boolean;
    preferred_language_code?: string;
    voice_speed?: 'fast' | 'medium' | 'slow'
}

export type ILanguageSchema = {
    en?: string;
    fr?: string;
    de?: string;
}


export type IConfig = {
    self_reflection?: boolean;
    report?: boolean;
    resources?: boolean;
    third_party_login?: boolean;
    onboarding?: boolean;
    flash_reflection?: boolean;
    impact_reflection?: boolean;
    app_logo?: string;
    coach_recommendation?: boolean;
    coach_profile_labels?: boolean;
    ai_coach?: boolean;
    orientation_call_duration?: number;
    orientation_coaches?: Array<number>;
    session_duration?: number;
    session_duration_variations?: Array<number>;
    session_preparation_video?: boolean;
    client_preferences_screen?: boolean;
    ai_generated_materials?: boolean;
    ai_generated_materials_for_meetings?: boolean;
    tripartie_email_required?: boolean;
    flash_feedback_required?: boolean;
    ai_voice_activated?: boolean;
    ai_action_notification?: boolean;
    client_settings?: IClientSettingsConfig;
    default_language?: string;
    manager_access_enable?: boolean;
    onboarding_journey_screen_des?: ILanguageSchema;
    onboarding_method_screen_des?: ILanguageSchema;
    onboarding_functionality_screen_des?: ILanguageSchema;
    onboarding_self_reflection_screen_des?: ILanguageSchema;
    onboarding_peer_reflection_screen_des?: ILanguageSchema;
};

type ConfigContextType = {
    config: IConfig | null;
    fetchConfig: (user: any) => Promise<void>;
};
const initialConfig: IConfig = {
    self_reflection: true,
    flash_reflection: true,
    impact_reflection: true,
    report: true,
    resources: true,
    third_party_login: true,
    onboarding: true,
    coach_recommendation: true,
    coach_profile_labels: true,
    ai_coach: true,
    orientation_call_duration: 30,
    session_duration: 60,
    session_duration_variations: [60],
    session_preparation_video: true,
    ai_generated_materials: false,
    ai_generated_materials_for_meetings: false,
};

const ConfigContext = createContext<ConfigContextType>({
    config: initialConfig,
    fetchConfig: (user: any) => Promise.resolve(),
});
export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({children}: { children: ReactNode }) => {
    const [config, setConfig] = useState<IConfig | null>(storage.get("config") || initialConfig);


    const fetchConfig = async (user: any) => {

        console.log("loading config.....", user);
        if (user?.companyId != null) {
            try {
                let response
                if (user?.role == ERole.Coachee) {
                    response = await getAppConfig();

                } else {
                    response = await MetaApi.getAppConfig(user?.companyId);
                }
                setConfig(response);
                storage.set("config", response);
            } catch (error) {
                console.error("Error fetching config:", error);
            }
        }
    };

    return (
        <ConfigContext.Provider value={{config, fetchConfig}}>
            {children}
        </ConfigContext.Provider>
    );
};
