import React, {FC, useEffect, useState} from "react";
import {Filters} from "../../../../../constants/types";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/performance.json";
import Fr from "../../../locale/fr/performance.json";
import {useTranslation} from "react-i18next";
import {getMaxValueInInnerKey} from "../../../../../utils/helpers/object";
import {getPeerBehaviours, getPeerObjectives} from "../../../../../services/api/companyApi";
import {IAuthUser} from "../../../../../models/IAuth";
import TemplateChartSection from "../../../../../shared/components/TemplateChartSection";
import CustomBarChart from "../../../../../shared/components/BarChart/CustomBarChart";
import {PeerGrowthObjectiveIcon} from "../../../../../constants/data/chartData";
import {DUMMY_CHART_DATA} from "../../../../../constants/data/dummyChartData";

enum DataState {
    NO_DATA = 'NO_DATA',
    NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
    DATA = 'DATA'
}

interface PeerSectionProps {
    filters: Filters;
    isFiltered: boolean;
    user: null | IAuthUser;
    maxCategoryValue: number;
    SectionTitle: FC<{ title: string }>;
    getDataState: (isNoData: boolean, isFiltered: boolean) => DataState;
    getSectionGrid: (charts: any[]) => JSX.Element[];
}

const PeerSection: FC<PeerSectionProps> = ({
                                               filters,
                                               isFiltered,
                                               user,
                                               maxCategoryValue,
                                               SectionTitle,
                                               getDataState,
                                               getSectionGrid
                                           }) => {
    i18n.addResourceBundle("en", "performance", ENG);
    i18n.addResourceBundle("fr", "performance", Fr);
    const {t} = useTranslation("performance");

    const [peerObjectives, setPeerObjectives] = useState<Record<any, any>[]>([]);
    const [peerBehaviours, setPeerBehaviours] = useState<Record<any, any>[]>([]);

    const maxPeerObjectiveValue =
        peerObjectives.length > 0
            ? getMaxValueInInnerKey(peerObjectives, "count").count + 1
            : 10;

    useEffect(() => {
        getPeerObjectives(user?.companyId!, filters)
            .then((res) => {
                setPeerObjectives(res.peer_objectives);
            })
            .catch((e) => {
                console.log("api error");
            });
        getPeerBehaviours(user?.companyId!, filters)
            .then((res) => {
                setPeerBehaviours(res.peer_behaviours);
            })
            .catch((e) => {
                console.log("api error");
            });
    }, [filters]);

    const getRandomDummyData = () => {
        const shuffled = DUMMY_CHART_DATA.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 5);
    }

    const peerObjectivesDataState = getDataState(peerObjectives?.every(entry => entry.count === 0), isFiltered);
    const peerBehaviourDataState = getDataState(peerBehaviours?.every(entry => entry.count === 0), isFiltered);

    const PeerObjectives = () => (
        <TemplateChartSection
            title={t("text20")}
            toolTip={t("text20")}
            dataState={peerObjectivesDataState}
        >
            <CustomBarChart
                showIcon={true}
                showTagLine={true}
                range={[0, maxPeerObjectiveValue]}
                data={
                    peerObjectivesDataState === DataState.DATA ? (
                        peerObjectives?.map((objective) => {
                            const icon = (PeerGrowthObjectiveIcon as any)[objective.key]
                                ? (PeerGrowthObjectiveIcon as any)[objective.key].icon
                                : (PeerGrowthObjectiveIcon as any).default_icon.icon;
                            return {
                                label: objective.val,
                                value: objective.count,
                                icon: icon
                            };
                        })
                    ) : (
                        getRandomDummyData().map((dummyItem) => {
                            return {
                                label: dummyItem.label,
                                value: dummyItem.value,
                                icon: PeerGrowthObjectiveIcon.default_icon.icon
                            };
                        })
                    )
                }

            />
        </TemplateChartSection>
    );

    const PeerBehaviour = () => (
        <TemplateChartSection
            title={t("text21")}
            toolTip={t("text21")}
            dataState={peerBehaviourDataState}
        >
            <CustomBarChart
                key="peer_behavior"
                showIcon={false}
                showTagLine={true}
                verticalLabel={true}
                range={[0, maxCategoryValue]}
                data={
                    peerBehaviourDataState === DataState.DATA ? (
                        peerBehaviours?.map((item) => {
                            let obj = {
                                label: item.key,
                                value: item.count,
                                [t("text26")]: item.count,
                            };
                            return item.key == "None"
                                ? Object.assign(obj, {color: "rgba(254, 203, 196, 1)"})
                                : obj;
                        })
                    ) : (
                        getRandomDummyData().map((dummyItem) => {
                            return {
                                label: dummyItem.label,
                                value: dummyItem.value,
                                color: dummyItem.color
                            };
                        })
                    )
                }
                showXPercentage={true}
                showPercentageLabel={true}
                legendName={t("text26")}
            />
        </TemplateChartSection>
    );

    const allCharts = [
        <PeerObjectives/>,
        // <PeerBehaviour/>
    ];

    const rows: JSX.Element[] = getSectionGrid(allCharts);

    return (
        <div>
            {rows.length > 0 && (
                <div>
                    <SectionTitle title={t("text19")}/>
                    {rows}
                </div>
            )}
        </div>
    );
}

export default PeerSection;
