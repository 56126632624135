import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import InputFormik from "../../../shared/components/formik/InputFormik/InputFormik";
import SelectFormik from "../../../shared/components/formik/SelectFormik";
import {getLanguages} from "../../../services/api/metaApi";
import {IMeta} from "../../../models/IMeta";
import {useTranslation} from "react-i18next";
import {GlobeAltIcon, OfficeBuildingIcon} from "@heroicons/react/outline";
import InputFormikWithIcon from "../../../shared/components/formik/InputFormik/InputFormikWithIcon";
import TextareaFormik from "../../../shared/components/formik/TextareaFormik/TextareaFormik";
import SubmitFormik from "../../../shared/components/formik/SubmitFormik/SubmitFormik";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/company.json";
import FR from "../locale/fr/company.json";
import {
    aiCoachTypeOptions,
    subscriptionOptions,
    toolOptions,
    userPermissionOptions
} from "../../../constants/data/companyData";

interface CompanyProfileFormProps {
    initialValues: {
        name: string;
        tool: any;
        location?: string;
        credit_limit?: number;
        subscription_method: any;
        website?: string;
        logo_url?: string;
        user_permission: any;
        primary_language?: any;
        domain?: string;
        challenges?: string;
        teams_event_on_coachello: boolean;
        intro_session: boolean;
        head_count: string;
        seniority: string;
        other_info: string;
        group_name?: string,
        ai_coach_type?: any
    };
    onSubmit: (values: any) => void;
    isCreate?: boolean;
}

const CompanyProfileForm = ({initialValues, onSubmit, isCreate = false}: CompanyProfileFormProps) => {
    i18n.addResourceBundle("en", "company", ENG);
    i18n.addResourceBundle("fr", "company", FR);
    const {t} = useTranslation("company");
    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>()

    useEffect(() => {
        getLanguages()
            .then((res) => {
                setLanguagesOptions(res)
            })
            .catch((e) => {
                console.log("api error getting meta");
            })
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                name: Yup.string().required(t("required")),
                tool: Yup.mixed().required(t("required")),
                credit_limit: Yup.number()
                    .positive(t("must_be_a_positive_number"))
                    .typeError(t("numeric_required")),
                user_permission: Yup.mixed().required(t("required")),
                primary_language: Yup.mixed().required(t("required")),
                subscription_method: Yup.mixed().required(t("required")),
                intro_session: Yup.boolean(),
            })}
            onSubmit={(values, {setSubmitting, validateForm}) => {
                validateForm().then((errors) => {
                    if (Object.keys(errors).length === 0) {
                        onSubmit(values);
                    }
                    setSubmitting(false);
                });
            }}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {() => (
                <Form className="flex flex-col gap-6 px-5 py-4">
                    <div className="grid grid-cols-2 gap-3">
                        <InputFormik placeholder={t("name_placeholder")}
                                     label={t("name")} name={"name"}/>

                        <div className="flex flex-col gap-2 pt-[5px]">
                            <span className="font-medium text-base text-black">{t("tool")}</span>
                            <SelectFormik name="tool" isMulti={false}
                                          options={toolOptions}
                                          placeholder={t("tool_placeholder")}/>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                        <InputFormik placeholder={t("domain_placeholder")}
                                     label={t("domain")} name={"domain"}/>

                        <InputFormik placeholder={t("location_placeholder")}
                                     label={t("location")} name={"location"}/>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                        <InputFormik placeholder={t("credit_limit")}
                                     label={t("credit_limit")} name={"credit_limit"}/>

                        <div className="flex flex-col gap-2 pt-[5px]">
                            <span className="font-medium text-base text-black">{t("subscription_method")}</span>
                            <SelectFormik name="subscription_method" isMulti={false}
                                          options={subscriptionOptions}
                                          placeholder={t("subscription_method_placeholder")}/>
                        </div>
                    </div>

                    <InputFormikWithIcon placeholder={t("website_placeholder")}
                                         label={t("website")} name={"website"}
                                         HeroIcon={GlobeAltIcon}/>

                    <InputFormikWithIcon placeholder={t("logo_url_placeholder")}
                                         label={t("logo_url")} name={"logo_url"}
                                         HeroIcon={OfficeBuildingIcon}/>

                    <div className="grid grid-cols-2 gap-3">
                        <div className="flex flex-col gap-2 pt-[5px]">
                            <span className="font-medium text-base text-black">{t("user_permission")}</span>
                            <SelectFormik name="user_permission" isMulti={false}
                                          options={userPermissionOptions}
                                          placeholder={t("user_permission_placeholder")}/>
                        </div>

                        <div className="flex flex-col gap-2 pt-[5px]">
                            <span className="font-medium text-base text-black">{t("primary_language")}</span>
                            <SelectFormik name="primary_language" isMulti={false}
                                          labelKey="name" valueKey="id"
                                          options={languagesOptions} placeholder={t("primary_language_placeholder")}/>
                        </div>
                    </div>

                    <TextareaFormik readOnly={false} name="challenges" label={t("challenges")}/>

                    <div className="grid grid-cols-2 gap-3">
                        <InputFormik placeholder={t("head_count")}
                                     label={t("head_count")} name={"head_count"}/>

                        <InputFormik placeholder={t("seniority_placeholder")}
                                     label={t("seniority")} name={"seniority"}/>
                    </div>

                    <TextareaFormik readOnly={false} name="other_info" label={t("other_info")}/>
                    {/*Hide temporally */}
                    <div className="items-center gap-2 hidden">
                        <Field type="checkbox" name="teams_event_on_coachello"/>
                        <label htmlFor="teams_event_on_coachello" className="text-gray-700"
                               dangerouslySetInnerHTML={{__html: t("teams_event_on_coachello")}}/>
                    </div>

                    <div className="flex items-center gap-2">
                        <Field type="checkbox" name="intro_session"/>
                        <label htmlFor="intro_session" className="text-gray-700">
                            {t("allow_orientation_session")}
                        </label>
                    </div>

                    {isCreate &&
                        <div className="space-y-2 pt-3">
                            <p className="text-lg font-semibold text-gray-400">{t("default_group")}</p>
                            <hr className="w-full"/>

                            <div className="grid grid-cols-2 gap-3 pt-1">
                                <InputFormik placeholder={t("group_name_placeholder")}
                                             label={t("group_name")} name={"group_name"}/>

                                <div className="flex flex-col gap-2 pt-[5px]">
                                    <span className="font-medium text-base text-black">{t("ai_coach_type")}</span>
                                    <SelectFormik name="ai_coach_type" isMulti={false} options={aiCoachTypeOptions}
                                                  placeholder={t("ai_coach_type")} isClearable={false}/>
                                </div>
                            </div>
                        </div>
                    }

                    <SubmitFormik className="dashboard-button bg-black py-4 mt-2 justify-center"
                                  text={t("save")} onSubmittingText={t("saving")}/>
                </Form>
            )}
        </Formik>
    );
};

export default CompanyProfileForm;
