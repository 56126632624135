import React, {useEffect, useState} from 'react';
import TemplateChartSection from "../../../../../shared/components/TemplateChartSection";
import CustomBarChart from "../../../../../shared/components/BarChart/CustomBarChart";
import {
    DUMMY_ACTION_PLANS_DATA,
    DUMMY_LANGUAGE_DATA,
    DUMMY_RADAR_DATA,
    DUMMY_SESSION_USEFULNESS_DATA,
    DUMMY_SKILLS_DATA,
} from "../../../../../constants/data/dummyChartData";
import RingPieChart from "../../../../../shared/components/RingPieChart";
import {useTranslation} from "react-i18next";

import AdoptionImg from "../../../../../assets/img/dashboard/aiCoaching/adoption.png";
import ParticipantImg from "../../../../../assets/img/dashboard/aiCoaching/participant.png";
import StatCard from "../../../../../shared/components/StatCard";
import {AcceleratorChart} from "@components";
import {
    getAIAssessmentStat,
    getAICoachActionImplementation,
    getAICoachAdaptionRate,
    getAICoachAvgSessionsPerClient,
    getAICoachFeelingScores,
    getAICoachGoalOrigin,
    getAICoachLanguages,
    getAICoachSkillType,
    getAICoachUsefulness
} from "../../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";
import {
    Filters,
    IActionPlans,
    IAdaptionRate,
    IAICoachFeelingScores,
    IAssessmentStat,
    IAVgSessionsPerClient,
    ISessionGoals,
    ISessionLanguages,
    ISessionUsefulness,
    ISkillType,
    RadarDataItem
} from "../../../../../constants/types";
import {DataState} from "../../../../../constants/enums";
import CustomRadarChart from "../../../../../shared/components/CustomRadarChart";
import {useNavTitle} from "@hooks/useNavTitle";


const PerformanceAI = ({...props}) => {
    const {t} = useTranslation("ai_performance");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {user} = useAuth();
    const [pointScored, setPointScored] = useState<ISkillType>({
        soft_skill: 0,
        hard_skill: 0
    });
    const [actionPlans, setActionPlans] = useState<IActionPlans>({
        implemented: 0,
        not_implemented: 0
    });
    const [sessionUsefulness, setSessionUsefulness] = useState<ISessionUsefulness>({
        useful: 0,
        not_useful: 0
    });
    const [sessionLanguages, setSessionLanguages] = useState<ISessionLanguages>({
        languages: []
    });
    const [sessionGoals, setSessionGoals] = useState<ISessionGoals>({
        own_goal: 0,
        suggested_from_reports: 0
    });
    const [feelingScores, setFeelingScores] = useState<IAICoachFeelingScores>({
        avg_feeling_before: 0,
        avg_feeling_after: 0,
    });
    const [avgSessionsPerClient, setAvgSessionsPerClient] = useState<IAVgSessionsPerClient>({
        avg_sessions_per_client: 0
    });
    const [adaptionRate, setAdaptionRate] = useState<IAdaptionRate>({
        total_invites: 0,
        total_ai_coach_users: 0
    });
    const [assessmentStat, setAssessmentStat] = useState<IAssessmentStat>({})
    const [filters, setFilters] = useState<Filters>({});

    function convertToRadarData(data: IAssessmentStat): RadarDataItem[] {
        return Object.keys(data).map(key => ({
            label: key,
            value: data[key]
        }));
    }

    const allAssessmentValuesAreZero = (assessmentStat: Record<string, number>): boolean => {
        return Object.values(assessmentStat).every(value => value === 0);
    };

    useEffect(() => {
        getAICoachSkillType(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setPointScored(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachActionImplementation(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setActionPlans(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachUsefulness(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setSessionUsefulness(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachLanguages(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setSessionLanguages(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachGoalOrigin(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setSessionGoals(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachFeelingScores(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setFeelingScores(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachAvgSessionsPerClient(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setAvgSessionsPerClient(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachAdaptionRate(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setAdaptionRate(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAIAssessmentStat(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setAssessmentStat(res);
                }
            }).catch((e) => {
            console.error(e);
        })
    }, [filters]);

    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4">
                <div className="col-span-1">
                    <StatCard
                        icon={AdoptionImg}
                        value={adaptionRate.total_ai_coach_users}
                        maxValue={adaptionRate.total_invites}
                        label={t("adoption_rate.title")}
                        toolTip={t("adoption_rate.toolTip")}
                        dataState={adaptionRate.total_invites > 0 ? DataState.DATA : DataState.NO_DATA}
                    />
                </div>
                <div className="col-span-1">
                    <StatCard
                        icon={ParticipantImg}
                        value={avgSessionsPerClient.avg_sessions_per_client}
                        label={t("avg_use_participant.title")}
                        toolTip={t("avg_use_participant.toolTip")}
                        dataState={avgSessionsPerClient.avg_sessions_per_client > 0 ? DataState.DATA : DataState.NO_DATA}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4">
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("perceived_growth.title")}
                        toolTip={t("perceived_growth.toolTip")}
                        dataState={feelingScores.avg_feeling_before > 0 || feelingScores.avg_feeling_after > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <div className="relative">
                            <div className="flex gap-8 py-14 justify-between">
                                <div className="text-center w-full">
                                    <AcceleratorChart percentage={feelingScores.avg_feeling_before * 10}/>
                                    <div
                                        className="flex flex-row items-center justify-center gap-2 text-left px-12 -mt-10">
                                        <div className="w-2/3">
                                        <span className="text-base font-bold text-black text-left">
                                            {t("perceived_growth.before")}
                                        </span>
                                        </div>
                                        <h3 className="text-black text-2xl">{feelingScores.avg_feeling_before}
                                             <span className="font-normal text-lg -translate-y-[40%]">/10</span>
                                        </h3>
                                    </div>
                                </div>
                                <div className="text-center w-full">
                                    <AcceleratorChart
                                        percentage={feelingScores.avg_feeling_after * 10}
                                        isPositive={true}
                                    />
                                    <div
                                        className="flex flex-row items-center justify-center text-left gap-2 px-12 -mt-10">
                                        <div className="w-2/3">
                                            <span className="text-base font-bold text-black">
                                                {t("perceived_growth.after")}
                                            </span>
                                        </div>
                                        <h3 className="text-black text-2xl">{feelingScores.avg_feeling_after}
                                            <span className="font-normal text-lg -translate-y-[40%]">/10</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TemplateChartSection>
                </div>
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("ai_coach_reports.title")}
                        toolTip={t("ai_coach_reports.toolTip")}
                        dataState={allAssessmentValuesAreZero(assessmentStat) ? DataState.NO_DATA : DataState.DATA}
                    >
                        <CustomRadarChart
                            data={!allAssessmentValuesAreZero(assessmentStat) ? convertToRadarData(assessmentStat).map((item) => {
                                return {
                                    label: t(`ai_coach_reports.${item.label}`),
                                    value: item.value
                                }
                            }) : DUMMY_RADAR_DATA}
                            fillColor="rgb(254, 203, 196)"
                            radarName={t("ai_coach_reports.radar_name")}
                        />
                    </TemplateChartSection>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 py-4">
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("point_scored.title")}
                        toolTip={t("point_scored.toolTip")}
                        dataState={pointScored.soft_skill > 0 || pointScored.hard_skill > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <RingPieChart
                            customLegendStyle="style1"
                            data={pointScored.hard_skill > 0 || pointScored.soft_skill > 0 ? [
                                {
                                    "label": t("point_scored.hard_skill"),
                                    "value": pointScored.hard_skill,
                                    "color": "#B4E1C6",
                                },
                                {
                                    "label": t("point_scored.soft_skill"),
                                    "value": pointScored.soft_skill,
                                    "color": "rgb(172, 199, 232)"
                                },
                            ] : DUMMY_SKILLS_DATA.map((dummyItem) => {
                                    return {
                                        label: dummyItem.label,
                                        value: dummyItem.value,
                                        color: dummyItem.color,
                                    }
                                }
                            )}
                            showTitle={false}
                            paddingAngle={10}
                            innerRadius={55}
                            outerRadius={100}
                            showPercentageLabel={true}
                            layoutDirection="column"
                            hideValueInLegend
                        />
                    </TemplateChartSection>
                </div>
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("action_plans.title")}
                        toolTip={t("action_plans.toolTip")}
                        dataState={actionPlans.not_implemented > 0 || actionPlans.implemented > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <RingPieChart
                            customLegendStyle="style1"
                            data={actionPlans.not_implemented > 0 || actionPlans.implemented > 0 ? [
                                {
                                    "label": t("action_plans.not_implemented"),
                                    "value": actionPlans.not_implemented,
                                    "color": "#FFF3B6"
                                },
                                {
                                    "label": t("action_plans.implemented"),
                                    "value": actionPlans.implemented,
                                    "color": "#B4E1C6"
                                }
                            ] : DUMMY_ACTION_PLANS_DATA.map((dummyItem) => {
                                    return {
                                        label: dummyItem.label,
                                        value: dummyItem.value,
                                        color: dummyItem.color,
                                    }
                                }
                            )
                            }
                            showTitle={false}
                            paddingAngle={10}
                            innerRadius={55}
                            outerRadius={100}
                            showPercentageLabel={true}
                            layoutDirection="column"
                            hideValueInLegend
                        />
                    </TemplateChartSection>
                </div>
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("session_usefulness.title")}
                        toolTip={t("session_usefulness.toolTip")}
                        dataState={sessionUsefulness.useful > 0 || sessionUsefulness.not_useful > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <RingPieChart
                            customLegendStyle="style1"
                            data={sessionUsefulness.useful > 0 || sessionUsefulness.not_useful > 0 ? [
                                {
                                    "label": t("session_usefulness.useful"),
                                    "value": sessionUsefulness.useful,
                                    "color": "#B4E1C6"
                                },
                                {
                                    "label": t("session_usefulness.not_useful"),
                                    "value": sessionUsefulness.not_useful,
                                    "color": "#FECBC4"
                                }
                            ] : DUMMY_SESSION_USEFULNESS_DATA.map((item) => {
                                    return {
                                        label: item.label,
                                        value: item.value,
                                        color: item.color,
                                    }
                                }
                            )}
                            showTitle={false}
                            paddingAngle={10}
                            innerRadius={55}
                            outerRadius={100}
                            showPercentageLabel
                            layoutDirection="column"
                            hideValueInLegend
                        />
                    </TemplateChartSection>
                </div>
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("session_language.title")}
                        toolTip={t("session_language.toolTip")}
                        dataState={sessionLanguages.languages.length > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <CustomBarChart
                            key="session_language"
                            showIcon={false}
                            showTagLine={true}
                            showXPercentage
                            range={[0, 100]}
                            tooltipDataKey={t("session_language.percentage")}
                            tooltipStyle="style2"
                            data={sessionLanguages.languages.length > 0 ?
                                sessionLanguages.languages.map((dummyItem) => {
                                    return {
                                        label: dummyItem.lang,
                                        value: dummyItem.count,
                                        color: '#D6CAB3'
                                    };
                                }) : DUMMY_LANGUAGE_DATA.map((item) => {
                                    return {
                                        label: item.label,
                                        value: item.value,
                                        color: "#D6CAB3",
                                    }
                                })
                            }
                            isVertical={true}
                        />
                    </TemplateChartSection>
                </div>
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("session_goals.title")}
                        toolTip={t("session_goals.toolTip")}
                        dataState={sessionGoals.own_goal > 0 || sessionGoals.suggested_from_reports > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <CustomBarChart
                            key="session_goals"
                            showIcon={false}
                            showTagLine={true}
                            tooltipDataKey={t("session_goals.percentage")}
                            showXPercentage
                            tooltipStyle="style2"
                            data={[
                                {
                                    "label": t("session_goals.own_goal_label"),
                                    "value": sessionGoals.own_goal > 0 || sessionGoals.suggested_from_reports > 0 ? sessionGoals.own_goal : 2,
                                    "color": "rgb(172, 199, 232)",
                                    "tooltipLabel": t("session_goals.own_goal")
                                },
                                {
                                    "label": t("session_goals.chosen_label"),
                                    "value": sessionGoals.own_goal > 0 || sessionGoals.suggested_from_reports > 0 ? sessionGoals.suggested_from_reports : 8,
                                    "color": "#B4E1C6",
                                    "tooltipLabel": t("session_goals.suggested_from_reports")
                                }
                            ]}
                            isVertical={true}
                        />
                    </TemplateChartSection>
                </div>
            </div>
        </div>
    );
};

export default PerformanceAI;
