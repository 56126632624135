interface Option {
    key: string;
    value: string;
}

interface TabProps {
    options: Option[];
    selectedOption: string;
    setSelectedOption: (option: string) => void;
}

const TabComponent: React.FC<TabProps> = ({ options, selectedOption, setSelectedOption }) => {
    return (
        <div className="flex font-bold text-md items-center gap-3">
            <div className="inline-block border-gray-200 border-[1px] py-2 px-1 bg-white rounded-2xl whitespace-nowrap">
                {options.map((option) => (
                    <span
                        key={option.key}
                        className={`bg-white rounded-2xl py-1 px-3 select-none cursor-pointer font-normal
                            ${selectedOption === option.key ? 'bg-gray-100 text-black' : 'text-black'}`}
                        onClick={() => setSelectedOption(option.key)}
                    >
                        {option.value}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default TabComponent;