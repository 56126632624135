import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/companyInsights.json";
import Fr from "../../locale/fr/companyInsights.json";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useNavTitle} from "@hooks/useNavTitle";
import {useEffect, useState} from "react";
import CompanyCard from "./components/CompanyCard";
import {ICompany} from "../../../../models/ICompany";
import {getCompanyInsights} from "../../../../services/api/companyApi";
import {getCompanies} from "../../../../services/api/metaApi";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import {AiOutlinePlus} from "react-icons/ai";
import ReactModal from "react-modal";
import CompanyCreate from "../CreateCompany";
import CompanyUpdate from "../../UpdateCompany";

type FilterOption = {
    label: string;
    value: number;
}

const CompanyInsights = ({...props}) => {
    i18n.addResourceBundle("en", "companyInsights", ENG);
    i18n.addResourceBundle("fr", "companyInsights", Fr);
    const {t} = useTranslation("companyInsights");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const [allCompanyInsights, setAllCompanyInsights] = useState<ICompany[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState<FilterOption[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState<"create" | "update">("create");
    const [currentCompany, setCurrentCompany] = useState<ICompany | null>(null);
    const [selectedCompanies, setSelectedCompanies] = useState<FilterOption[]>([]);
    const [newlyAddedCompany, setNewlyAddedCompany] = useState<ICompany | null>(null);
    const [showActiveCompanies, setShowActiveCompanies] = useState(true);
    const [filteredCompanyInsights, setFilteredCompanyInsights] = useState<ICompany[]>([]);

    const openCreateModal = () => {
        setModalType("create");
        setIsModalOpen(true);
    };

    const openUpdateModal = (company: ICompany) => {
        setCurrentCompany(company);
        setModalType("update");
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentCompany(null);
    };

    const addOrUpdateCompany = (company: any) => {
        setAllCompanyInsights((prev) => {
            const existingIndex = prev.findIndex((c) => c.id === company.id);
            if (existingIndex !== -1) {
                const updatedCompanies = [...prev];
                updatedCompanies[existingIndex] = company;
                return updatedCompanies;
            } else {
                setNewlyAddedCompany(company)
                return [company, ...prev];
            }
        });
        closeModal();
    };

    useEffect(() => {
        const filtrationCompanies = selectedCompanies.map((company) => company.value);
        setIsLoading(true);
        getCompanyInsights(filtrationCompanies)
            .then((res) => {
                setAllCompanyInsights(res.data);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log("api error");
                setIsLoading(false);
            });
    }, [selectedCompanies]);

    useEffect(() => {
        // Company insights filtration by expiration of credit_data
        // const filteredCompanyInsights = allCompanyInsights.filter((company) => {
        //     const today = new Date();
        //     if (showActiveCompanies) {
        //         return company.credit_data?.some((credit) => {
        //             const expiresAt = new Date(credit.expires_at);
        //             return  expiresAt >= today;
        //         });
        //     } else {
        //         return company.credit_data?.every((credit) => {
        //             const expiresAt = new Date(credit.expires_at);
        //             return  expiresAt < today;
        //         })
        //     }
        //
        // });

        const filteredCompanyInsights = allCompanyInsights.filter((company) => {
            return showActiveCompanies ? !company.expired : company.expired;
        });
        setFilteredCompanyInsights(filteredCompanyInsights);
    }, [allCompanyInsights, showActiveCompanies]);

    useEffect(() => {
        getCompanies().then((res) => {
            setCompanies(
                res.map((obj: any) => {
                    return {
                        label: obj.name,
                        value: obj.id,
                    };
                })
            );
        }).catch((e) => {
            console.log("api error");
        });
    }, [])

    return (
        <div className="m-3 flex flex-col gap-2">
            <div className="flex flex-row justify-between items-center">
                <Select
                    isClearable
                    isSearchable={true}
                    className="w-[300px] z-20"
                    placeholder={t("filter.placeholder")}
                    noOptionsMessage={() => t("filter.no_option")}
                    value={selectedCompanies}
                    options={companies}
                    onChange={(value) => {
                        setSelectedCompanies(value as FilterOption[]);
                    }}
                    menuPlacement="bottom"
                    isMulti
                />
                <div className="flex gap-5 justify-between items-center">
                    <button
                        onClick={openCreateModal}
                        className="flex flex-row items-center justify-center bg-white text-green-600 rounded-full hover:bg-gray-200 transition duration-200 p-2">
                        <AiOutlinePlus className="w-4 h-4 mr-1"/>
                        {t("text.add_company")}
                    </button>
                    <div className="flex font-bold text-md items-center gap-3">
                        <div className="inline-block py-2 px-1 bg-white rounded-2xl whitespace-nowrap">
                          <span>
                            <input
                                onChange={(e) => setShowActiveCompanies(true)}
                                type="radio"
                                id="show-active-companies"
                                className="hidden peer"
                                checked={showActiveCompanies}
                            />
                            <label
                                htmlFor="show-active-companies"
                                className="bg-white rounded-2xl py-1 px-3 select-none cursor-pointer peer-checked:bg-green-100 peer-checked:text-green-800 font-normal"
                            >
                             {t("status.active")}
                            </label>
                          </span>
                            <span>
                            <input
                                onChange={(e) => setShowActiveCompanies(false)}
                                type="radio"
                                id="show-expired-companies"
                                className="hidden peer"
                                checked={!showActiveCompanies}
                            />
                            <label
                                htmlFor="show-expired-companies"
                                className="bg-white rounded-2xl py-1 px-3 select-none cursor-pointer peer-checked:bg-red-100 peer-checked:text-red-800 font-normal"
                            >
                             {t("status.expired")}
                            </label>
                          </span>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? (<div className="inset-0 fixed"><LoaderContainer/></div>):
            (<>
            <div>
                {filteredCompanyInsights.map((company) => (
                    <CompanyCard key={company.id} company={company} editCompany={openUpdateModal}
                                 newCompany={newlyAddedCompany} setNewCompany={setNewlyAddedCompany}/>
                ))}
            </div>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel={modalType === "create" ? t("text.create_company") : t("text.update_company")}
                className="bg-white p-6 rounded-lg w-[70%] max-h-[90vh] mx-auto hide-scrollabr relative flex flex-col"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
                <h2 className="text-xl font-semibold mb-4">
                    {modalType === "create"
                        ? t("text.create_company")
                        : `${t("text.update_company")} - ${currentCompany?.name || ''}`}
                </h2>

                <div className="flex-grow overflow-y-auto mb-4">
                    {modalType === "create" ? (
                        <CompanyCreate onSave={addOrUpdateCompany}/>
                    ) : (
                        currentCompany && <CompanyUpdate companyData={currentCompany!} onUpdate={addOrUpdateCompany}/>
                    )}
                </div>

                <button
                    onClick={closeModal}
                    className="absolute top-2 right-6 text-gray-400 hover:text-gray-600 text-2xl"
                    aria-label="Close Modal"
                >
                    &times;
                </button>
            </ReactModal>
            </>)}

        </div>
    )
}

export default CompanyInsights;