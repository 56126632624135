import React, {useEffect, useState} from "react";

import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import FieldLabel from "../components/FieldLabel";
import FieldValue from "../components/FieldValue";
import ReportCard from "../components/ReportCard";
import ReportRate from "../components/ReportRate";
import ReportSideCard from "../components/ReportSideCard";
import Section from "../components/Section";
import EmptyResults from "../components/EmptyResults";
import ChipsField from "../components/ChipsField";
import Divider from "../../../components/Divider";

import {IClientReport} from "../../../../../models/IReport";
import Coaching from "../../../../coaching/pages/Coaching";
import Modal from "../../../components/Modal";
import {EAssessmentType} from "../../../../../constants/enums";
import review from "@img/dashboard/home/icons/Review.svg";

type Props = {
    data: IClientReport[];
    openModal: (key: EAssessmentType, title: string, frameElementType?: "iframe" | "video", sessionId?: number, size?: number) => void;
};

const CoachingReportsSection = ({data, openModal}: Props) => {
    const {t} = useTranslation("reports");
    const navigate = useNavigate();
    const [currentReport, setCurrentReport] = useState<IClientReport>();
    const [sessionModalOpen, setSessionModalOpen] = useState<boolean>(false);
    const [reschedulingSession, setReschedulingSession] = useState<number>();

    const handleEmptyResultClick = () => {
        setSessionModalOpen(true);
    }

    const handleCloseSessionModal = () => {
        setSessionModalOpen(false);
        setTimeout(() => {
            setReschedulingSession(undefined);
        }, 1000);
    };

    useEffect(() => {
        if (data && data.length > 0) setCurrentReport(data[0]);
    }, [data]);

    return (
        <Section
            title={t("coaching_reports.title")}
            sidePanel={
                data.length
                    ? data.map((report, index) => (
                        <ReportSideCard
                            key={index}
                            coach={report.coach_name}
                            date={moment(report.meeting_date).format("MMM D, YYYY")}
                            time={moment(report.meeting_date).format("HH:MM")}
                            session={report.session_no.toString()}
                            coachImage={report.coach_profile}
                            selected={report === currentReport}
                            onClick={() => setCurrentReport(report)}
                        />
                    ))
                    : null
            }
        >
            <Modal
                title={t("coaching_reports.schedule_session")}
                isOpen={sessionModalOpen}
                onClose={handleCloseSessionModal}
                className="w-[90vw] h-[90vh]"
            >
                <Coaching
                    variant="modal"
                    meetingId={reschedulingSession}
                    handleBookingSubmission={handleCloseSessionModal}
                    isCreditDeducted
                />
            </Modal>
            {!data.length ? (
                <EmptyResults
                    title={t("coaching_reports.empty_results.title")}
                    buttonText={t("coaching_reports.empty_results.button_text")}
                    onButtonClick={handleEmptyResultClick}
                />
            ) : (
                <ReportCard
                    title={`${t("coaching_reports.session")} ${currentReport?.session_no}`}
                    {...(!currentReport?.review ? {
                        button:
                            <button
                                className="flex items-center bg-red py-1 px-2 rounded-md text-white transition-colors"
                                onClick={() =>
                                    openModal(
                                        EAssessmentType.Review,
                                        t("coaching_reports.leave_review"),
                                        "iframe",
                                        currentReport?.id,
                                        600
                                    )
                                }>
                                <img src={review} alt="Review"
                                     className="w-4 h-auto mr-2 object-cover pointer-events-none"/>
                                {t("coaching_reports.leave_review")}
                            </button>
                    } : {})}
                >
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.coaching_category.label")}
                            tooltip={t("coaching_reports.coaching_category.tooltip")}
                        />
                        <ChipsField data={currentReport?.option_answers.categories}/>
                    </div>
                    <div className="flex flex-row gap-1 items-center">
                        <FieldLabel
                            label={t("coaching_reports.grade_prior_coaching.label")}
                            tooltip={t("coaching_reports.grade_prior_coaching.tooltip")}
                        />
                        <ReportRate value={currentReport?.option_answers.grade_prior}/>
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.actions_applied_by_coachee.label")}
                            tooltip={t("coaching_reports.actions_applied_by_coachee.tooltip")}
                        />
                        <FieldValue value={currentReport?.summary_answers[1]}/>
                    </div>
                    <Divider/>
                    <div className="flex flex-row gap-1 items-center">
                        <FieldLabel
                            label={t("coaching_reports.grade_after_coaching.label")}
                            tooltip={t("coaching_reports.grade_after_coaching.tooltip")}
                        />
                        <ReportRate value={currentReport?.option_answers.grade_after}/>
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.feeling_after_session.label")}
                            tooltip={t("coaching_reports.feeling_after_session.tooltip")}
                        />
                        <FieldValue
                            value={
                                currentReport?.summary_answers[5] ||
                                currentReport?.option_answers.feeling_after.name
                            }
                        />
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.advanced_towards_goals.label")}
                            tooltip={t("coaching_reports.advanced_towards_goals.tooltip")}
                        />
                        <FieldValue
                            value={currentReport?.option_answers.towards_goal.name}
                        />
                    </div>
                    <div>
                        <FieldLabel
                            label={t(
                                "coaching_reports.applied_actions_from_last_session.label"
                            )}
                            tooltip={t(
                                "coaching_reports.applied_actions_from_last_session.tooltip"
                            )}
                        />
                        <FieldValue
                            value={currentReport?.option_answers.applied_action.name}
                        />
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.observable_behaviours.label")}
                            tooltip={t("coaching_reports.observable_behaviours.tooltip")}
                        />
                        <ChipsField data={currentReport?.option_answers.behaviors}/>
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.actions_agreed_upon.label")}
                            tooltip={t("coaching_reports.actions_agreed_upon.tooltip")}
                        />
                        <FieldValue value={currentReport?.summary_answers[3]}/>
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.goal_of_session.label")}
                            tooltip={t("coaching_reports.goal_of_session.tooltip")}
                        />
                        <FieldValue value={currentReport?.summary_answers[2]}/>
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.coaching_material.label")}
                            tooltip={t("coaching_reports.coaching_material.tooltip")}
                        />
                        {/* <a href="#" className="text-v2_purple-200">
            <FieldValue value="http://www.larkin.net/rerum-et-aut-sint" />
          </a> */}
                        <FieldValue value={currentReport?.summary_answers[4]}/>
                    </div>
                    <div>
                        <FieldLabel
                            label={t("coaching_reports.coaching_notes.label")}
                            tooltip={t("coaching_reports.coaching_notes.tooltip")}
                        />
                        <FieldValue value={currentReport?.note}/>
                    </div>
                </ReportCard>
            )}
        </Section>
    );
};

export default CoachingReportsSection;
