import {useRef, useState} from "react";

import {Loader} from "rsuite";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";

import Dropdown from "./Dropdown";
import ArrowRight from "./ArrowRight";
import Button from "../../../../../shared/components/Button";

import {ISessionSchema} from "../../../../../models/IHome";
import {
    EAssessmentType,
    EHomeDropdownAction,
} from "../../../../../constants/enums";
import {THomeAssessmentData} from "../../../../../constants/types";

import sessionIncompleteIcon from "../../../../../assets/img/dashboard/home/icons/session_incomplete.png";
import sessionIncompleteHoverIcon from "../../../../../assets/img/dashboard/home/icons/session_incomplete_hover.png";
import tickIcon from "../../../../../assets/img/dashboard/home/icons/tick.svg";
import impactReflectionIcon from "../../../../../assets/img/dashboard/home/icons/impact_reflection.png";
import InfoPopover from "../../../components/InfoPopover";

type Props = {
    title: string;
    tag: string;
    isComplete?: boolean;
    firstItem?: boolean;
    lastItem?: boolean;
    variant: "quiz" | "video_call";
    isCurrent?: boolean;
    isNext?: boolean;
    isPending?: boolean;
    currentProgressState?: EAssessmentType;
    session?: ISessionSchema;
    handleAction: (
        action: string | EHomeDropdownAction,
        session?: ISessionSchema,
        assessmentData?: THomeAssessmentData,
    ) => void;
    assessmentData?: THomeAssessmentData;
    cancelInProgress?: boolean;
    latestSession?: ISessionSchema;
    displayAction?: boolean | undefined;
    hideCheckReportBtn?: boolean;
    toolTip?: string;
    isActiveCoach?: boolean;
    isPrevious?: boolean;
};

const SessionListItem = ({
                             title,
                             tag,
                             isComplete,
                             firstItem,
                             lastItem,
                             variant,
                             isCurrent,
                             isNext,
                             isPending,
                             currentProgressState,
                             handleAction,
                             session,
                             assessmentData,
                             cancelInProgress,
                             latestSession,
                             displayAction,
                             hideCheckReportBtn,
                             toolTip,
                             isActiveCoach,
                             isPrevious
                         }: Props) => {
    const {t} = useTranslation("home");

    const [mouseIn, setMouseIn] = useState(false);

    const dropdownRef = useRef<React.ElementRef<typeof Dropdown>>(null);

    return (
        <div
            className={`group cursor-default pl-[3%] pr-[4%] flex flex-row items-center justify-between h-14 ${
                isPrevious ? "pl-[6%]" : isCurrent ? "bg-pink-light" : "bg-transparent"
            } ${
                isPrevious ?
                    "hover:bg-pink-100" :
                    isCurrent
                        ? "bg-pink-light"
                        : isComplete
                            ? "hover:bg-green-50"
                            : "hover:bg-warm-light"
            }`}
            onMouseEnter={() => setMouseIn(true)}
            onMouseLeave={() => {
                dropdownRef.current?.closeDropdown();
                setMouseIn(false);
            }}
        >
            <div className="flex flex-row gap-2 h-full items-center">
                <div className="flex flex-col items-center h-full">
                    <div
                        className={`flex-1 w-[1px] ${
                            firstItem ? null : isComplete ? "bg-green-50" : "bg-pink-light"
                        } ${
                            isPrevious ?
                                "hover:bg-pink-100" :
                                isCurrent
                                    ? "bg-pink-light"
                                    : isComplete
                                        ? "group-hover:bg-green-50"
                                        : "group-hover:bg-warm-light"
                        }`}
                    />
                    <div
                        className={`w-8 h-8 border-[1px] border-pink rounded-full flex justify-center items-center ${
                            lastItem && variant === "quiz" && !isComplete
                                ? "border-purple-150 bg-white"
                                : isComplete
                                    ? "bg-green-50 border-green-50 group-hover:bg-white group-hover:border-white"
                                    : "bg-white border-pink"
                        }`}
                    >
                        {isComplete ? (
                            <img
                                src={tickIcon}
                                className="h-3 w-3 object-contain rounded-full"
                                alt="session"
                            />
                        ) : (
                            <img
                                src={
                                    lastItem && variant === "quiz"
                                        ? impactReflectionIcon
                                        : !isCurrent && mouseIn
                                            ? sessionIncompleteHoverIcon
                                            : sessionIncompleteIcon
                                }
                                className="h-5 w-5 object-contain rounded-full"
                                alt="session"
                            />
                        )}
                    </div>
                    <div
                        className={`flex-1 w-[1px] ${
                            lastItem ? null : isComplete ? "bg-green-50" : "bg-pink-light"
                        } ${
                            isPrevious ?
                                "group-hover:bg-pink-100" :
                                isCurrent
                                    ? "bg-pink-light"
                                    : isComplete
                                        ? "group-hover:bg-green-50"
                                        : "group-hover:bg-warm-light"
                        }`}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <p
                        className={`font-bold font-dm_sans text-sm flex items-center ${
                            !isPending && mouseIn && variant === "video_call"
                                ? "hidden"
                                : "block"
                        }`}
                    >
                        {title}
                        {toolTip && <div className="ml-2 flex items-center w-4 h-4">
                            <InfoPopover content={toolTip} trigger="hover" placement="bottomStart"/>
                        </div>}
                    </p>
                </div>
                <span
                    className={`p-0.5 px-2 ${
                        mouseIn ? "bg-white text-xs font-bold " : "bg-pink-light text-xs "
                    } rounded-full ${
                        variant === "video_call" && !isPending && !isNext
                            ? "block"
                            : "hidden"
                    }`}
                >
          {moment(session?.datetime).format(
              mouseIn ? "ddd, Do MMM [at] hh:mm A" : "Do MMM",
          )}
        </span>
                {session?.orientation && (
                    <span
                        className={`p-0.5 px-2 ${
                            mouseIn ? "bg-white text-xs font-bold " : "bg-purple-150 text-xs "
                        } rounded-full `}
                    >
            Orientation
          </span>
                )}
                {isPending && (
                    <span
                        className={`font-dm_sans text-xxs p-0.5 px-2 rounded-full ${
                            variant === "quiz"
                                ? "bg-purple-50"
                                : isCurrent
                                    ? "bg-white"
                                    : "bg-pink-light"
                        }`}
                    >
            {tag}
          </span>
                )}
            </div>
            <div className="flex flex-row gap-5 items-center">
                {isCurrent && (
                    <Button
                        className="text-black flex gap-2 max-h-10 text-xs h-9 justify-center items-center"
                        onClick={() =>
                            handleAction(EHomeDropdownAction.JoinSession, session)
                        }
                    >
                        <span>{t("join_session")}</span>
                        <ArrowRight/>
                    </Button>
                )}
                {isNext && (
                    <Button
                        className="text-red flex gap-2 max-h-10 text-xs h-9 justify-center items-center"
                        onClick={() =>
                            handleAction(EHomeDropdownAction.ScheduleNext, latestSession)
                        }
                    >
                        <span>{t("session_list_item.schedule_now")}</span>
                        <ArrowRight color="#FD0054"/>
                    </Button>
                )}
                {(displayAction !== undefined ? displayAction : !isComplete) &&
                    variant === "quiz" &&
                    ((assessmentData &&
                            [
                                EAssessmentType.SelfReflection,
                                EAssessmentType.Reflection360,
                            ].includes(assessmentData.key as EAssessmentType)) ||
                        !lastItem ||
                        (lastItem &&
                            currentProgressState === EAssessmentType.ImpactReflection)) && (
                        <Button
                            className="text-black flex gap-2 max-h-10 text-xs h-9 justify-center items-center"
                            onClick={() =>
                                handleAction(
                                    EHomeDropdownAction.CompleteNow,
                                    undefined,
                                    assessmentData,
                                )
                            }
                        >
              <span>{isComplete && assessmentData ? (
                  (assessmentData.key === EAssessmentType.SelfReflection ||
                      assessmentData.key === EAssessmentType.ImpactReflection) ? t("session_list_item.retake_now") :
                      assessmentData.key === EAssessmentType.Reflection360 ? t("session_list_item.request_now") :
                          t("session_list_item.complete_now")
              ) : (assessmentData?.key === EAssessmentType.Reflection360) ? t("session_list_item.send_now") : t("session_list_item.complete_now")}</span>
                            <ArrowRight/>
                        </Button>
                    )}
                {cancelInProgress ? (
                    <Loader/>
                ) : (
                    <>
                        {mouseIn &&
                            variant === "video_call" &&
                            !isPending &&
                            !isCurrent && (
                                <>
                                    {hideCheckReportBtn && isComplete ? null : (
                                        <Button
                                            className="text-black flex gap-2 max-h-10 text-xs h-9 justify-center items-center"
                                            onClick={() =>
                                                handleAction(
                                                    isComplete
                                                        ? EHomeDropdownAction.CheckReport
                                                        : EHomeDropdownAction.Reshedule,
                                                    session,
                                                )
                                            }
                                        >
                      <span>
                        {isComplete ? t("check_report") : isActiveCoach ? t("reschedule") : null}
                      </span>
                                        </Button>
                                    )}

                                    <Button
                                        className="text-black flex gap-2 max-h-10 text-xs h-9 justify-center items-center"
                                        onClick={() =>
                                            handleAction(
                                                isComplete
                                                    ? EHomeDropdownAction.LeaveReview
                                                    : EHomeDropdownAction.Cancel,
                                                session,
                                            )
                                        }
                                    >
                                        <span>{isComplete ? t("leave_review") : t("cancel")}</span>
                                    </Button>
                                </>
                            )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SessionListItem;
