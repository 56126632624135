import {Widget} from '@typeform/embed-react'
import useAuth from "@hooks/useAuth";
import Loader from "../../../shared/components/Loader/Loader";
import {useCallback} from "react";

type Props = {
    onSubmit?: () => void;
};
export const ImpactAssessment = ({onSubmit}: Props) => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const {user} = useAuth()
    const formId = () => {
        if (user?.companyName == "CEA") {
            return 'mkLCDQSU'
        } else if (lng == 'fr') {
            return 't3L0xWRW';
        } else if (lng == 'de') {
            return 'r1xbuUkY'
        } else {
            return 'uiJhT2Vg'
        }
    }

    const handleFormSubmit = useCallback((event: any) => {
        onSubmit?.();
    }, []);

    if (!user) {
        return <Loader/>
    }
    return <Widget
        onSubmit={handleFormSubmit}
        hidden={{
            email: user?.email,
            name: user?.firstName,
            companyname: user?.companyName,
        }} id={formId()} style={{width: '100%', height: '100vh', flex: '1 1 auto'}}/>
}
