import React, {useState, useEffect, useRef, useCallback} from 'react';
import {VoiceSupportCommands, VoiceSupportInfo} from "../../../../../../constants/enums";
import {useAICoaching} from "../../utils/AICoachingContext";
import {useTranslation} from "react-i18next";
import GradientSpinner from "./GradientSpinner";
import {toast} from "react-toastify";
import {useWebSocket} from "@hooks/useWebsocket";
import {useAudioRecorder} from "@hooks/useAudioRecorder";
import {AnimatePresence, motion} from "framer-motion";
import ConnectingSpinner from "./ConnectingSpinner";
import ListeningLoader from "./ListeningLoader";
import {Tooltip} from "antd";
import useAuth from "@hooks/useAuth";
import {getVoiceDetails} from "../../../../../../utils/helpers/voiceLanguage";
import {useConfig} from "../../../../../../utils/providers/AppConfigProvider";

type VoiceChatProps = {
    onNewPrompt: (value: string, callback: () => void, voice?: boolean) => any;
}

type VoiceChatState = 'connecting' | 'listening' | 'paused' | 'thinking' | 'playing' | 'other';

const VoiceChat = ({onNewPrompt}: VoiceChatProps) => {
    const {
        voiceState,
        setVoiceState,
        customChatSection,
        setCustomChatSection,
        selectedThreadItem
    } = useAICoaching();
    const {t} = useTranslation("ai_coaching");
    const {user} = useAuth();
    const {config} = useConfig();
    const [voiceChatState, setVoiceChatState] = useState<VoiceChatState>('connecting');
    const historyRef = useRef<string>("");
    const promptRef = useRef<string>("");
    const [messageIndex, setMessageIndex] = useState(0);
    const isMountedRef = useRef<boolean>(true);
    const isConnectedRef = useRef<boolean>(false);
    const onMessageRef = useRef<any>();
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const onMessage = useCallback(async (event: any) => {
        const res = event;
        if (res.error) {
            toast.error(t("voice.could_not_process"));
            stopRecording();
            close();
            setVoiceState('not-active');
        } else if (res.info === VoiceSupportInfo.TRANSCRIBING) {
            if (res.is_final) {
                historyRef.current = historyRef.current + res.message;
                promptRef.current = historyRef.current;
            } else {
                promptRef.current = historyRef.current + res.message;
            }

        } else if (res.info === VoiceSupportInfo.SILENCE_TIMEOUT) {
            if (historyRef.current != "") {
                stopRecording();
                setVoiceChatState('thinking')
                const newPrompt = historyRef.current;
                historyRef.current = "";
                const promptResponse = await onNewPrompt(newPrompt, () => {
                }, true)
                if (isMountedRef.current) {
                    readText(promptResponse.reply, res.lng_code);
                }
            }

        } else if (res.info === VoiceSupportInfo.PAUSE_TIMEOUT){
            sendMessage(VoiceSupportCommands.STOP_VOICE)
            stopRecording();
            setVoiceChatState('paused')
        } else if (res.info === VoiceSupportInfo.DISCONNECT_TIMEOUT) {
            // onclose listenee would be automatically called
        } else if (res.info === VoiceSupportInfo.DISCONNECTED) {
            // onclose listenee would be automatically called
        }
    }, [voiceChatState, setVoiceChatState, selectedThreadItem]);

    const {isConnected, isPaused, sendMessage, start, pause, resume, close} = useWebSocket({
        url: `${process.env.REACT_APP_API_BASE_URL}api/clients/update/speech-to-text`,
        onMessage: (event: any) => onMessageRef.current?.(event),
        onOpen: () => {
            console.log("Websocket connection established");
        },
        onClose: () => {
            console.log("Websocket connection closed")
        }
    });

    const {isRecording, startRecording, stopRecording} = useAudioRecorder({
        onData: (byte) => {
            sendMessage(byte);
        }
    });

    onMessageRef.current = onMessage;

    const messages = [
        t("voice.listening"),
        t("voice.finish_to_send"),
    ];

    const connectAndRecord = async () => {
        promptRef.current = "";
        if (isConnectedRef.current) {
            await startVoiceRecording();
        } else {
            setVoiceChatState('connecting')

            const voice_lng = config?.client_settings?.preferred_language_code || "en-GB"

            const success = await start();
            if (success) {
                sendMessage(JSON.stringify({
                    'client_id': user?.id,
                    'handler': {
                        rate: 16000,
                        language: voice_lng,
                        encoding: 'webm',
                        highest_accuracy: true,
                    },
                    'timeout': {
                        websocket_duration_threshold: 60,
                        silence_duration_threshold: 1.3
                    }
                }))
                await startVoiceRecording();
            } else {
                toast.error(t("voice.lost_connection"));
                stopRecording();
                close();
                setVoiceState('not-active');
            }
        }
    };

    const readText = async (content: string, lng_code: string) => {
        const cleanedContent = content.replace(/[\*\*]/g, "")

        const voice_lng = getVoiceDetails(lng_code, "FEMALE"); // can use {config - voice gender}

        try {
            const response = await fetch(
                `https://texttospeech.googleapis.com/v1/text:synthesize?key=${process.env.REACT_APP_GOOGLE_API_CLIENT_KEY_VOICE}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        input: { text: cleanedContent },
                        voice: {
                            languageCode: voice_lng.languageCode,
                            ssmlGender: "FEMALE",
                            name: voice_lng.voiceName,
                        },
                        audioConfig: {
                            audioEncoding: "MP3",
                            // speakingRate: 1.1,
                            // volumeGainDb: 0.5,
                        },
                    }),
                }
            );

            const data = await response.json();

            promptRef.current = "";

            if (data.audioContent) {
                const audioUrl = `data:audio/mp3;base64,${data.audioContent}`;
                const audio = new Audio(audioUrl);
                audioRef.current = audio;

                const extractPhrases = (content: any, isRTL?: boolean) => {
                    const phrases = content.match(/[^.!?]+[.!?]*|[\s]+/g) || [];

                    return isRTL ? phrases.reverse() : phrases;
                };

                const transcriptChunks = extractPhrases(cleanedContent, voice_lng.isRTL);

                promptRef.current = transcriptChunks[0];

                setVoiceChatState('playing');

                audio.play();

                audio.ontimeupdate = () => {
                    const totalDuration = audio.duration;
                    let cumulativeLength = 0;
                    const totalTextLength = transcriptChunks.reduce((acc: any, chunk: any) => acc + chunk.length, 0);

                    for (let i = 0; i < transcriptChunks.length; i++) {
                        const chunkProportion = transcriptChunks[i].length / totalTextLength;
                        cumulativeLength += chunkProportion * totalDuration;

                        if (audio.currentTime <= cumulativeLength) {
                            promptRef.current = transcriptChunks[i];
                            break;
                        }
                    }
                };

                audio.onended = () => {
                    audioRef.current = null;
                    promptRef.current = "";
                    connectAndRecord();
                };
            } else {
                console.error('Failed to fetch audio from Google TTS:', data);
                toast.error(t("voice.could_not_process"));
                stopRecording();
                close();
                setVoiceState('not-active');
            }
        } catch (error) {
            console.error('Error during Google TTS API call:', error);
            toast.error(t("voice.could_not_process"));
            stopRecording();
            close();
            setVoiceState('not-active');
        }
    };

    const pauseListening = () => {
        stopRecording();
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }

        isMountedRef.current = false;
        setVoiceChatState('paused');
    };

    const resumeListening = async () => {
        isMountedRef.current = true;
        await connectAndRecord()
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [messages]);

    useEffect(() => {
        isConnectedRef.current = isConnected;
    }, [isConnected]);

    useEffect(() => {
        const handleVoice = async () => {
            if (voiceState === 'active') {
                setCustomChatSection(undefined);
                await connectAndRecord();
                isMountedRef.current = true;
            } else if (voiceState === 'switching') {
                stopRecording();
                close();
                isMountedRef.current = false;
                setVoiceChatState('other');
            }
        };

        handleVoice();

        return () => {
            isMountedRef.current = false;
            stopRecording();
            close();
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, [voiceState]);

    const startVoiceRecording = async () => {
        sendMessage(VoiceSupportCommands.START_VOICE)
        await startRecording();
        setVoiceChatState('listening')
        promptRef.current = "";
    }

    const transitionVariants = {
        initial: {opacity: 0, scale: 0.95},
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "easeInOut",
                duration: 0.35,
            }
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            transition: {
                type: "easeInOut",
                duration: 0.3,
            }
        },
    };

    const renderUI = () => {
        switch (voiceChatState) {
            case 'connecting':
                return (
                    <motion.div
                        key="connecting"
                        variants={transitionVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="w-full flex flex-col items-center justify-center"
                    >
                        <ConnectingSpinner/>
                        <p className="text-center text-lg mt-6 mb-2">{t("voice.connecting")}</p>
                        <p className="text-center text-sm text-gray-500">{t("voice.wait")}</p>
                    </motion.div>
                );
            case 'listening':
                return (
                    <motion.div
                        key="listening"
                        variants={transitionVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="w-full flex flex-col items-center justify-center"
                        // onClick={pauseListening}
                    >
                        <GradientSpinner color1='#FFA5C3' color2='#FD0054' color3='#D9D9D9'/>
                        <p className="text-center text-lg mt-6 mb-3">{messages[messageIndex]}</p>
                        <div>
                            <ListeningLoader/>
                        </div>
                    </motion.div>
                );
            case 'paused':
                return (
                    <motion.div
                        key="paused"
                        variants={transitionVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="w-full flex flex-col items-center justify-center"
                        onClick={resumeListening}
                    >
                        <div className="w-40 h-40 mb-6 rounded-full bg-[#D9D9D9]"></div>
                        <p className="text-center text-lg mb-2">{t("voice.paused")}</p>
                        <p className="text-center text-sm text-gray-500">{t("voice.resume")}</p>
                    </motion.div>
                );
            case 'thinking':
                return (
                    <motion.div
                        key="thinking"
                        variants={transitionVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="w-full flex flex-col items-center justify-center"
                    >
                        <GradientSpinner color1='#2ECC71' color2='#007A33' color3='#D9D9D9'/>
                        <p className="text-center text-lg mt-6 mb-2">{t("voice.thinking")}</p>
                        <div>
                            <ListeningLoader color2='#007A33'/>
                        </div>
                    </motion.div>
                );
            case 'playing':
                return (
                    <motion.div
                        key="playing"
                        variants={transitionVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="w-full flex flex-col items-center justify-center"
                        onClick={async () => {
                            if (audioRef.current) {
                                audioRef.current.pause();
                                audioRef.current.currentTime = 0;
                            }
                            await connectAndRecord();
                        }}
                    >
                        <GradientSpinner color1='#878787' color2='#000000' color3='#D9D9D9'/>
                        <p className="text-center text-lg mt-6 mb-2">{t("voice.interrupt")}</p>
                        <div>
                            <ListeningLoader color2='#000000'/>
                        </div>
                    </motion.div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="w-full h-full flex flex-col items-center justify-center relative">
            <AnimatePresence mode="wait">
                {renderUI()}
            </AnimatePresence>

            <div className="w-full text-center px-4">
                {promptRef.current && (
                    <motion.div
                        className="mt-4 p-4 w-full text-lg text-gray-700 overflow-auto max-h-40 italic"
                        key={promptRef.current}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.5, ease: "easeOut"}}
                    >
                        {promptRef.current}
                    </motion.div>
                )}
            </div>

            {customChatSection && <div className="w-full transition-all min-h-[8rem] ">
                <AnimatePresence>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.3}}
                        className="w-full p-2">
                        {customChatSection}
                    </motion.div>
                </AnimatePresence>
            </div>}

            <div className="fixed bottom-2 flex items-center justify-center space-x-3 mb-4">
                <Tooltip title={voiceChatState === "paused" ? t("voice.resume") : t("voice.pause")}>
                    <button
                        className="flex items-center justify-center w-8 h-8 rounded-full border border-gray text-gray-400 transition hover:bg-yellow-100"
                        onClick={async () => {
                            if (voiceChatState === "paused") {
                                await resumeListening();
                            } else {
                                pauseListening();
                            }
                        }}
                    >
                        {voiceChatState === "paused" ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="w-5 h-5">
                                <path d="M8 5v14l11-7L8 5z"/>
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="w-5 h-5">
                                <path d="M6 5h4v14H6zM14 5h4v14h-4z"/>
                            </svg>
                        )}
                    </button>
                </Tooltip>
                <Tooltip title={t("voice.exit")}>
                    <button
                        className="flex items-center justify-center w-8 h-8 rounded-full border border-gray text-red-500 transition hover:bg-red-100"
                        onClick={() => {
                            setVoiceState("not-active")
                        }}
                    >
                        &times;
                    </button>
                </Tooltip>
            </div>
        </div>
    );
};

export default VoiceChat;
