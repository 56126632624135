import { FC, InputHTMLAttributes } from 'react';


import { FieldHookConfig, useField } from 'formik';

import { setClasses } from "../../../../utils/helpers/css";
import style from "./InputFormik.module.css";

interface Props {
    label: string;
    HeroIcon: any;
    requiredasterisk?: boolean;
}

const InputFormikWithIcon: FC<Props & (FieldHookConfig<string>)> = ({label, requiredasterisk, HeroIcon, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)

    const [field, meta] = useField(props);
    
    return (

        <div className={setClasses(style.wrapper, (meta.touched && meta.error) && style.error) + " relative"}>
            <label className={`${style.label}`} htmlFor={props.id || props.name}>{label}</label>
            <div className="relative w-full">
                <input
                    className={style.input + " w-full pl-10"} {...field} value={field.value ?? ""}
                    {...props as InputHTMLAttributes<HTMLInputElement>}/>
                <button className="absolute left-0 top-0 p-2">
                    <HeroIcon className="text-gray-600 h-6 w-6"/>
                </button>
                {requiredasterisk && (
                    <div className={style["requiredAsterisk"]}>*</div>
                )}
            </div>

            {meta.touched && meta.error ? (
                <span className={style.errorLabel}>{meta.error}</span>
            ) : null}

        </div>

    );

};

export default InputFormikWithIcon;
