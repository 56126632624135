import {TrashIcon} from "@heroicons/react/outline";
import {PencilAltIcon} from "@heroicons/react/solid";
import {Typography} from "antd";
import "../../CalanderSomeCss/Calander.css";

interface Props {
    eventInfo: any;
    onClick: (id: number, personalUnavailabilityEvent?: boolean) => void;
    onReschedule?: (meetingId: number) => void;
}

function EventContent({eventInfo, onClick, onReschedule}: Props) {
    const titleColor = eventInfo.event._def.title === "Other Events" ? "black" : eventInfo.event._def.extendedProps.type === "personal_unavailability" ? "green" : "#fd0054";
    const personalUnavailabilityEvent = eventInfo.event._def.extendedProps.type === "personal_unavailability";
    const bookOnBehalfEvent = eventInfo.event._def.extendedProps.bookedByCoach;
    const editableEvent = eventInfo.event._def.title !== "Other Events" && !personalUnavailabilityEvent;

    const handleReschedule = () => {
        onReschedule && onReschedule(eventInfo.event._def.publicId as number)
    }

    const handleDeleteEvent = () => {
        if (personalUnavailabilityEvent) {
            onClick(eventInfo.event._def.publicId.split('_')[0] as number, personalUnavailabilityEvent)
        } else if (bookOnBehalfEvent) {
            onClick(eventInfo.event._def.publicId as number)
        } else {
            return
        }
    }

    return (
        <div className="overflow-hidden">
            <div className="max-w-sm px-1 overflow-hidden">
                <div>
                    {!(eventInfo.event._def.title === "Other Events") &&
                        <div className="flex items-center justify-between">
                            <Typography className="text-[0.7rem] font-medium" style={{color: titleColor}}>
                                {eventInfo.event._def.title}
                            </Typography>
                            <div className="flex items-center justify-between gap-1">
                                {editableEvent &&
                                    <PencilAltIcon className="h-3.5 w-3.5 cursor-pointer flex-shrink-0"
                                                   style={{color: titleColor}}
                                                   onClick={handleReschedule}/>
                                }
                                {(personalUnavailabilityEvent || bookOnBehalfEvent) &&
                                    <TrashIcon className="h-3.5 w-3.5 cursor-pointer flex-shrink-0"
                                               style={{color: titleColor}}
                                               onClick={handleDeleteEvent}
                                    />
                                }
                            </div>
                        </div>}
                </div>
                {!(eventInfo.event._def.title === "Other Events") &&
                    <div className="items-center" style={{color: titleColor}}>
                        <i className="fa fa-calendar-plus-o" aria-hidden="true"></i>
                        <span className="px-2 text-[0.7rem]">{eventInfo.timeText}</span>
                    </div>}
            </div>
        </div>
    );
}

export default EventContent;