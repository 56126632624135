import {ISidebarGroup} from "../../models/ISidebarMenu";
import {
    AcademicCapIcon,
    AdjustmentsIcon,
    BookOpenIcon,
    CalendarIcon,
    ChartBarIcon,
    ChartPieIcon,
    ChartSquareBarIcon,
    ChatAltIcon,
    DocumentTextIcon as DocumentTextIconOutlined,
    HomeIcon,
    IdentificationIcon,
    KeyIcon,
    OfficeBuildingIcon,
    ShieldCheckIcon,
    UserCircleIcon,
    UserIcon,
    UsersIcon,
    CogIcon
} from "@heroicons/react/outline";
import {
    AcademicCapIcon as AcademicCapIconSolid,
    AdjustmentsIcon as AdjustmentsIconSolid,
    BookOpenIcon as BookOpenIconSolid,
    BriefcaseIcon,
    CalendarIcon as CalendarIconSolid,
    ChartBarIcon as ChartBarIconSolid,
    ChartPieIcon as ChartPieIconSolid,
    ChartSquareBarIcon as ChartSquareBarIconSolid,
    ChatAltIcon as ChatAltIconSolid,
    ChipIcon,
    DocumentTextIcon,
    HomeIcon as HomeIconSolid,
    IdentificationIcon as IdentificationIconSolid,
    KeyIcon as KeyIconSolid,
    OfficeBuildingIcon as OfficeBuildingIconSolid,
    ShieldCheckIcon as ShieldCheckIconSolid,
    UserCircleIcon as UserCircleIconSolid,
    UserIcon as UserIconSolid,
    UsersIcon as UsersIconSolid,
    CogIcon as CogIconSolid,
} from "@heroicons/react/solid";

import {ERole} from "../enums";
import AICoachIcon from "@icons/ai-coach.png";
import AICoachSelectedIcon from "@icons/ai-coach-selected.png";

export const SidebarData: ISidebarGroup[] = [
    {
        icon: <DocumentTextIcon/>,
        path: "reports",
        title: "text8",
        allowedRoles: [ERole.Coach],
    },
    {
        icon: <BriefcaseIcon/>,
        path: "session-view",
        title: "text9",
        allowedRoles: [ERole.Coach],
    },
    {
        icon: <UserCircleIcon/>,
        icon_selected: <UserCircleIconSolid/>,
        path: "profile",
        title: "text10",
        allowedRoles: [ERole.Coach],
    },
    {
        icon: <HomeIcon/>,
        icon_selected: <HomeIconSolid/>,
        path: "home",
        title: "text12",
        allowedRoles: [ERole.Coachee],
    },
    {
        icon: <BookOpenIcon/>,
        icon_selected: <BookOpenIconSolid/>,
        path: "coach/booking",
        title: "text15",
        allowedRoles: [ERole.Coachee],
    },
    {
        icon: <DocumentTextIconOutlined/>,
        icon_selected: <DocumentTextIcon/>,
        path: "resources",
        title: "text13",
        allowedRoles: [ERole.Coachee],
    },
    {
        icon: <ChartBarIcon/>,
        icon_selected: <ChartBarIconSolid/>,
        path: "reports/view",
        title: "text14",
        allowedRoles: [ERole.Coachee],
    },
    {
        icon: <ChatAltIcon/>,
        icon_selected: <ChatAltIconSolid/>,
        path: "ai/coaching",
        title: "text19",
        allowedRoles: [ERole.Coachee],
    },
    {
        icon: <CalendarIcon/>,
        icon_selected: <CalendarIconSolid/>,
        path: "event-schedule",
        title: "text22",
        allowedRoles: [ERole.Coach],
    },
    {
        title: "text24",
        icon: <UserIcon/>,
        icon_selected: <UserIconSolid/>,
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin, ERole.Manager],
        menu: [
            {
                title: "text1",
                icon: <HomeIcon/>,
                icon_selected: <HomeIconSolid/>,
                path: "coaching",
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
                // subMenu: [
                //     {
                //         icon: <FaIcons.FaDeezer/>,
                //         title: 'Tenants',
                //         path: "Tenants"
                //     }, {
                //         icon: <FaIcons.FaGripfire/>,
                //         title: 'Landlords',
                //         path: "Landlords",
                //     }, {
                //         icon: <FaIcons.FaMagento/>,
                //         title: 'Ponols',
                //         path: "Ponols",
                //     },
                // ]
            },
            {
                icon: <ChartPieIcon/>,
                icon_selected: <ChartPieIconSolid/>,
                path: "usage",
                title: "text2",
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin, ERole.Manager],
                disable: false,
            },
            {
                icon: <ChartSquareBarIcon/>,
                icon_selected: <ChartSquareBarIconSolid/>,
                path: "performance",
                title: "text3",
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
                disable: false,
            },
            {
                icon: <AcademicCapIcon/>,
                icon_selected: <AcademicCapIconSolid/>,
                path: "coaches",
                title: "text4",
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
                disable: false,
            },
        ]
    },
    {
        title: "text23",
        icon: <img src={AICoachIcon} alt="ai-coach-icon"/>,
        icon_selected: <img src={AICoachSelectedIcon} alt="ai-coach-icon"/>,
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin, ERole.Manager],
        menu: [
            {
                icon: <HomeIcon/>,
                icon_selected: <HomeIconSolid/>,
                path: "ai/summary",
                title: "text1",
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
                disable: false,
            },
            {
                icon: <ChartPieIcon/>,
                icon_selected: <ChartPieIconSolid/>,
                path: "ai/usage",
                title: "text2",
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin, ERole.Manager],
                disable: false,
            },
            {
                icon: <ChartSquareBarIcon/>,
                icon_selected: <ChartSquareBarIconSolid/>,
                path: "ai/performance",
                title: "text3",
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
                disable: false,
            },
        ]
    },
    {
        title: "text28",
        icon: <ShieldCheckIcon/>,
        icon_selected: <ShieldCheckIconSolid/>,
        allowedRoles: [ERole.SuperAdmin],
        menu: [
            {
                icon: <OfficeBuildingIcon/>,
                icon_selected: <OfficeBuildingIconSolid/>,
                path: "companies",
                title: "text25",
                allowedRoles: [ERole.SuperAdmin],
                disable: false,
            },
            {
                icon: <IdentificationIcon/>,
                icon_selected: <IdentificationIconSolid/>,
                path: "manage-coaches",
                title: "text20",
                allowedRoles: [ERole.SuperAdmin],
                disable: false,
            },
            {
                icon: <UsersIcon/>,
                icon_selected: <UsersIconSolid/>,
                path: "coach-insights",
                title: "text21",
                allowedRoles: [ERole.SuperAdmin],
                disable: false,
            },
            {
                icon: <UserCircleIcon/>,
                icon_selected: <UserCircleIconSolid/>,
                path: "create-coach",
                title: "text26",
                allowedRoles: [ERole.SuperAdmin],
                disable: false,
            },
            {
                icon: <CogIcon/>,
                icon_selected: <CogIconSolid/>,
                path: "configuration",
                title: "text29",
                allowedRoles: [ERole.SuperAdmin],
                disable: false,
            },
        ]
    },
    {
        icon: <ChipIcon/>,
        icon_selected: <ChipIcon/>,
        path: "insights",
        title: "text17",
        allowedRoles: [ERole.SuperAdmin],
    },
    {
        icon: <AdjustmentsIcon/>,
        icon_selected: <AdjustmentsIconSolid/>,
        path: "groups",
        title: "text16",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
    },

    {
        icon: <BriefcaseIcon/>,
        path: "integration",
        title: "text7",
        allowedRoles: [ERole.ItAdmin],
    },
    {
        icon: <KeyIcon/>,
        icon_selected: <KeyIconSolid/>,
        path: "tools",
        title: "text11",
        allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin],
    },
];
