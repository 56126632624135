import React, {useEffect, useState} from "react";

import Scrollbars from "react-custom-scrollbars";

import chevron from "@img/dashboard/reports/icons/chevron-up.svg";

type Props = {
    children?: React.ReactNode;
    title?: string;
    sidePanel?: React.ReactNode;
    buttonTitle?: string;
    onClick?: () => void;
};

const Section = ({children, title, sidePanel, buttonTitle, onClick}: Props) => {
    const [childrenRef, setChildrenRef] = useState<HTMLDivElement | null>(null);
    const [height, setHeight] = useState(0);

    const [expanded, setExpanded] = useState(true);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const updateSize = () => {
        setHeight(childrenRef?.clientHeight || 0);
    };

    useEffect(() => {
        setHeight(childrenRef?.clientHeight || 0);

        window.addEventListener("resize", updateSize);

        return () => window.removeEventListener("resize", updateSize);
    }, [childrenRef, children]);


    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                setHeight(entry.contentRect.height);
            }
        });

        if (childrenRef) {
            observer.observe(childrenRef);
        }

        return () => {
            observer.disconnect();
        };
    }, [childrenRef]);

    return (
        <div className="w-full py-4">
            <div className="flex flex-row flex-1 justify-between items-center h-12">

                <div className="font-serif font-bold md:text-base">{title}</div>

                <div className="flex items-center">
                    {buttonTitle && onClick && (
                        <button
                            onClick={onClick}
                            className="mr-2 md:px-2 md:py-1.5 p-1 bg-red text-white md:text-sm text-xs rounded hover:bg-red-400"
                        >
                            {buttonTitle}
                        </button>
                    )}
                    <img
                        src={chevron}
                        alt="chevron"
                        className={`transition-transform ${expanded ? "" : "rotate-180"} cursor-pointer`}
                        onClick={handleExpand}
                    />

                </div>
            </div>
            <div className="w-full">
                <div
                    className={`transition-all origin-top ${
                        expanded ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"
                    }`}
                    style={{height: expanded ? height : 0}}
                >
                    <div ref={(e) => setChildrenRef(e)} className="flex flex-row gap-4">
                        {sidePanel && (
                            <div className="lg:w-6/12 xl:w-5/12">
                                <div className="sticky top-0 max-h-screen">
                                    <Scrollbars
                                        style={{
                                            minHeight:
                                                window.innerHeight < height
                                                    ? window.innerHeight
                                                    : height,
                                        }}
                                    >
                                        <div className="flex flex-col gap-2 w-full pr-5">
                                            {sidePanel}
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        )}
                        <div className="w-full">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section;
