import React from "react";

import Card from "../../../components/Card";

type Props = {
    title?: string;
    children?: React.ReactNode;
    button?: React.ReactNode;
};

const ReportCard = ({children, title, button}: Props) => {
    return (
        <Card noPadding>
            <div className="flex flex-row justify-between items-center p-3 border-b">
                <div className="font-serif text-lg font-bold">{title}</div>
                {button}
            </div>
            <div className="flex flex-col p-4 gap-6">{children}</div>
        </Card>
    );
};

export default ReportCard;
