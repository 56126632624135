import {twMerge} from "tailwind-merge";

import {IChatItem, useAICoaching} from "../../utils/AICoachingContext";
import BOTIcon from "../../../../../../assets/img/aiCoaching/ai_coachello.svg";
import BOTBlackIcon from "../../../../../../assets/img/aiCoaching/coachello-black.svg";
import defaultProfileImg from "@img/icons/default-profile.png";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {PiWaveformBold} from "react-icons/pi";
import {HiSpeakerWave} from "react-icons/hi2";
import {useEffect, useState} from "react";
import {PauseIcon} from "@heroicons/react/solid";
import {useConfig} from "../../../../../../utils/providers/AppConfigProvider";
import {franc} from "franc";

const ChatItem = ({
                      id,
                      content,
                      role,
                      header,
                      footer,
                      showProfile,
                      profile,
                      voice,
                  }: IChatItem) => {
    const {state, currentlyPlayingId, setCurrentlyPlayingId} = useAICoaching();
    const {config} = useConfig();
    const [audioUrl, setAudioUrl] = useState<string | null>(null);

    const isPlaying = currentlyPlayingId === id;

    useEffect(() => {
        if (!isPlaying) {
            setAudioUrl(null);
        }
    }, [isPlaying]);

    const readText = async () => {
        if (isPlaying) {
            setCurrentlyPlayingId(null);
        } else {
            try {
                const cleanedContent = content.replace(/[\*\*]/g, "")

                const detectedLang = franc(cleanedContent);
                const languageCode = detectedLang === "fra" ? "fr-FR" : "en-GB";

                // const ssmlContent = `
                //     <speak>
                //         <prosody rate="1.3" pitch="-0.8st">
                //             ${cleanedContent}
                //         </prosody>
                //     </speak>
                // `;

                const response = await fetch(
                    `https://texttospeech.googleapis.com/v1/text:synthesize?key=${process.env.REACT_APP_GOOGLE_API_CLIENT_KEY_VOICE}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            // input: { ssml: ssmlContent },
                            input: { text: cleanedContent },
                            voice: {
                                languageCode: languageCode,
                                ssmlGender: "FEMALE",
                                name: detectedLang === "fra" ? "fr-FR-Wavenet-C" : "en-GB-Wavenet-F",
                            },
                            audioConfig: {
                                audioEncoding: "MP3",
                                // speakingRate: 1.1,
                                // pitch: -0.5,
                                // volumeGainDb: 0.5,
                            },
                        }),
                    }
                );

                if (!response.ok) {
                    console.error("Failed to fetch speech");
                    return;
                }

                const data = await response.json();
                const audioContent = data.audioContent;
                const audioBlob = new Blob([new Uint8Array(atob(audioContent).split("").map((c) => c.charCodeAt(0)))], {type: "audio/mp3"});
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioUrl(audioUrl);

                setCurrentlyPlayingId(id);
            } catch (error) {
                console.error("Speech synthesis error:", error);
                setCurrentlyPlayingId(null);
            }
        }
    };

    return (
        <div
            className={twMerge(
                role === "me" ? "flex-row-reverse ml-auto" : "flex-row mr-auto",
                "flex gap-4 items-center"
            )}
        >
            <div
                className={twMerge(role === "assistant" && "border",
                    "rounded-full h-max  ",
                    showProfile ? "opacity-100" : " opacity-0"
                )}
            >
                <img
                    src={role === 'assistant' ? (state == 'practice' ? BOTBlackIcon : BOTIcon) :
                        (profile || defaultProfileImg)}
                    alt=""
                    className=" rounded-full object-cover pointer-events-none h-[41px] min-w-[41px]"
                />
            </div>
            <div
                className={twMerge(
                    "relative px-3 rounded-2xl flex justify-center items-center ",
                    role === "me" ? "bg-[#FCF4EA] max-w-[70%] min-w-[8%]" : `border-[1px] ${state == 'practice' ? 'border-practice' : 'border-[#D6CAB3]'} bg-white max-w-[70%] min-w-[8%] `,
                )}>
                <div className={twMerge(" relative flex", "")}>
                    {/*{isLoading?<Loader isFullscreen={false} spinnerColor="red"/>:content}*/}
                    <Markdown className="markdown" remarkPlugins={[remarkGfm]}>{content}</Markdown>
                    {/*duration: {getDuration(index)}*/}
                </div>
            </div>
            {/*{role !== "me" && config?.ai_voice_activated && (*/}
            {/*    <div*/}
            {/*        onClick={readText}*/}
            {/*        className="cursor-pointer flex-shrink-0"*/}
            {/*    >*/}
            {/*        <div className="relative border border-gray-300 rounded-full hover:bg-gray-100 p-1">*/}
            {/*            {isPlaying ? (<PauseIcon className="h-3 w-3 text-gray-500 hover:text-gray-600"/>) :*/}
            {/*                (<HiSpeakerWave className="h-3 w-3 text-gray-500 hover:text-gray-600"/>)}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{voice && role === "me" && config?.ai_voice_activated && (*/}
            {/*    <div*/}
            {/*        // onClick={readText}*/}
            {/*        // className="cursor-pointer flex-shrink-0"*/}
            {/*    >*/}
            {/*        <div className="relative border border-gray-300 rounded-full p-1">*/}
            {/*            <PiWaveformBold className="h-3 w-3 text-gray-500"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{audioUrl && isPlaying && (*/}
            {/*    <audio*/}
            {/*        autoPlay*/}
            {/*        src={audioUrl}*/}
            {/*        onEnded={() => setCurrentlyPlayingId(null)}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    );
};

export default ChatItem;
