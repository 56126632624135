import React, {useEffect, useState} from 'react';
import ChatBox from "./components/ChatBox/ChatBox";
import Overview from "./Overview";
import {AICoachingProvider, IOverviewItem, IThreadItem, useAICoaching} from "./utils/AICoachingContext";
import AiModelPicker from "./components/AIModelPicker";
import Summary from "./components/Summary";
import {twMerge} from "tailwind-merge";

import {AnimatePresence, motion} from "framer-motion";
import {AIModel} from "./enums";
import {processChatPrompt, updateSchedule} from "./actions";
import {useSearchParams} from "react-router-dom";
import Spinner from "./components/ChatBox/Spinner";
import ChatSettings from "./components/ChatSettings";
import SettingsImg from "../../../../assets/img/aiCoaching/chatSettings.png"

const AICoaching = () => {

    const {
        state,
        selectedThreadItem,
        setSelectedThreadItem,
        setMessages,
        overviewItems,
        overviewJourneyItems,
        setState,
        setIsLoading,
        createNewThread,
        addMessage,
        voiceState
    } = useAICoaching();

    const [searchParams, setSearchParams] = useSearchParams()
    const [contextDecided, setContextDecided] = useState(false)
    const [showOverview, setShowOverview] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const findThreadItemById = (id: number, mappedJourneyOverview: IOverviewItem[]): IThreadItem | undefined => {
        for (const journey of mappedJourneyOverview) {
            if (journey.id == id) {
                return journey;
            }
            if (journey.subSessions)
                for (const subSession of journey.subSessions) {
                    if (subSession && subSession.id == id) {
                        return subSession;
                    }
                }
        }
        return undefined;
    };

    const clearContext = () => {
        setSearchParams(undefined)
        setContextDecided(true)
    }

    const toggleSettingsMenu = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };

    useEffect(() => {
        // debugger
        if (!searchParams.toString()) {
            setContextDecided(true)
            return
        }

        const aiModel: AIModel | null = searchParams.get('aiModel') as AIModel
        const jump = searchParams.get('jump')
        const threadId = searchParams.get('threadId')

        if (aiModel && jump && threadId) {
            if ((overviewJourneyItems && overviewJourneyItems?.length > 0) && (aiModel == AIModel.journey || aiModel == AIModel.session)) {
                const item = findThreadItemById(Number(threadId), overviewJourneyItems)
                console.log(item);
                setState('thread')
                setMessages([])
                setSelectedThreadItem(item)
                clearContext()
            } else if (overviewItems && overviewItems?.length > 0 && aiModel == AIModel.flash) {
                const item = findThreadItemById(Number(threadId), overviewItems)
                console.log(item);
                setState('thread')
                setSelectedThreadItem(item)
                setMessages([])
                clearContext()
            }
        }

    }, [overviewItems, overviewJourneyItems, searchParams]);


    useEffect(() => {
        if (!searchParams.toString()) {
            setContextDecided(true)
            return

        }

        const aiModel: AIModel | null = searchParams.get('aiModel') as AIModel
        const newThread = searchParams.get('new')
        const scheduleId = searchParams.get('scheduleId')
        const journeyId = searchParams.get('journeyId')

        //navigated through deep link
        if (aiModel && newThread && scheduleId) {
            updateSchedule(Number(scheduleId), true);
            setState('thread')
            clearContext()
            // setCustomChatSection(undefined)
            setIsLoading(true);
            createNewThread(AIModel.session, Number(journeyId)).then((newThread => {
                processChatPrompt(newThread.id, '').then((promptResponse) => {
                    addMessage({
                        id: Math.random().toString(),
                        content: promptResponse.reply,
                        role: "assistant",
                    });
                })

            })).finally(() => setIsLoading(false))

        } else if (aiModel && newThread) {
            if (aiModel == AIModel.journey) {
                setState('journey')
                clearContext()
            } else if (aiModel == AIModel.flash) {
                setState('flash')
                clearContext()
            }

        }
    }, [searchParams]);


    return (
        <div className="flex p-5 gap-1 bg-white h-[calc(100vh-64px)] relative">
            <div className={twMerge("md:w-56 md:static absolute z-10 h-full shrink-0 md:my-0 -my-5 flex md:border-r")}>
                <div className={twMerge(showOverview ? 'block' : 'hidden', "h-full transition-all md:block")}>
                    <Overview handleOverviewSelect={() => setShowOverview(false)}/>
                </div>
                <button
                    className={twMerge(showOverview ? `rotate-180` : `rotate-0 animate-bounce-x`, `transition-all md:hidden py-2 my-auto border rounded-md bg-white z-10 hover:bg-red -ml-2 `)}
                    onClick={() => setShowOverview(prevState => !prevState)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5}
                         stroke="currentColor" className="w-5 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                    </svg>

                </button>
            </div>
            <div id="chat" className="flex-auto h-full relative ">
                {!contextDecided ? <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        className="absolute inset-0 flex flex-col w-full items-center justify-center h-full ">
                        <Spinner size="42" className=""/>

                    </motion.div>
                    :
                    (state === "init" ? <AiModelPicker/>
                        :
                        (<>
                                <AnimatePresence>
                                    {state == "summary" && selectedThreadItem && <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}}
                                        className={twMerge(`absolute h-full w-full z-30 bg-white`)}>
                                        <Summary
                                            threadID={selectedThreadItem?.id}/>
                                    </motion.div>
                                    }</AnimatePresence>
                                <ChatBox/>
                            </>

                        ))}


            </div>
            <div className={`w-7 relative flex items-center ${voiceState != "not-active" ? 'hidden' : 'block'}`}>
                <button
                    onClick={toggleSettingsMenu}
                    className="absolute -top-2 -right-2 rounded-full bg-white hover:bg-gray-200 w-5 h-5 flex items-center justify-center"
                >
                    <img src={SettingsImg} className="h-auto w-4"/>
                </button>
            </div>
            <ChatSettings isOpen={isSettingsOpen} onClose={toggleSettingsMenu}/>
        </div>
    );
};

interface AIOnlyCoachingProps {
    isJourneyVisible: boolean;
}

export const AIOnlyCoaching: React.FC<AIOnlyCoachingProps> = ({isJourneyVisible}) => <AICoachingProvider
    isJourneyVisible={isJourneyVisible}><AICoaching/></AICoachingProvider>;
