import React from "react";
import Spinner from "../../../shared/components/Spinner/Spinner";

interface Props {
    headerText?: string;
    subText?: string;
    position?: string;
}

const LoadingScreen: React.FC<Props> = ({headerText, subText, position}) => {

    return <div
        className={`z-10 fixed ${position ? position : "inset-0"} w-full backdrop-blur-md flex flex-col items-center justify-end text-center p-7 pb-80 gap-2`}>
        <Spinner size={"40px"}/>
        {headerText && <h4 className="md:text-sm text-xs">{headerText}</h4>}
        {subText && <p className="md:text-sm text-xs">{subText}</p>}
    </div>
}

export default LoadingScreen;