import {IMeta} from "../../../models/IMeta";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import i18n from "../../../utils/lib/i18n";
import WelcomeENG from "../locale/eng/welcome.json";
import WelcomeFR from "../locale/fr/welcome.json";
import {getLanguages} from "../../../services/api/metaApi";
import ReactSelect from "react-select";
import ToggleButton from "../../../shared/components/ToggleButton";
import Button from "../../../shared/components/Button";
import {setClientPreference} from "../../../services/api/clientApi";
import {useConfig} from "../../../utils/providers/AppConfigProvider";
import useAuth from "@hooks/useAuth";

interface Props {
    onSetSelectedLanguage?: (lng: IMeta | undefined) => void;
    onSetConsentAssessmentData?: (consent: boolean) => void;
    onSetConsentFlashFeedback?: (consent: boolean) => void;
    onSuccess?: () => void;
    hideButton?: boolean;
    aiCoach?: boolean;
}

interface PreferencesState {
    selectedLanguage: IMeta | undefined;
    consentAssessmentData: boolean;
    consentFlashFeedback: boolean;
}

const Preference = (props: Props) => {
    i18n.addResourceBundle("en", "welcome", WelcomeENG);
    i18n.addResourceBundle("fr", "welcome", WelcomeFR);
    const {t} = useTranslation("welcome");
    const {fetchConfig} = useConfig();
    const {user} = useAuth();


    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>()

    const [preferences, setPreferences] = useState<PreferencesState>({
        selectedLanguage: undefined,
        consentAssessmentData: false,
        consentFlashFeedback: false
    });

    useEffect(() => {
        getLanguages().then((lng) => setLanguagesOptions(lng))
    }, []);

    useEffect(() => {
        props.onSetSelectedLanguage && props.onSetSelectedLanguage(preferences.selectedLanguage);
        props.onSetConsentAssessmentData && props.onSetConsentAssessmentData(preferences.consentAssessmentData);
        props.onSetConsentFlashFeedback && props.onSetConsentFlashFeedback(preferences.consentFlashFeedback);
    }, [preferences]);

    const handleSelectedLanguage = (item: IMeta) => {
        props.onSetSelectedLanguage && props.onSetSelectedLanguage(item);
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            selectedLanguage: item
        }));
    }

    const handleConsentAssessmentData = (consent: boolean) => {
        props.onSetConsentAssessmentData && props.onSetConsentAssessmentData(consent);
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            consentAssessmentData: consent
        }));
    };

    const handleConsentFlashFeedback = (consent: boolean) => {
        props.onSetConsentFlashFeedback && props.onSetConsentFlashFeedback(consent);
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            consentFlashFeedback: consent
        }));
    };

    const setClientPreferences = () => {
        const payload = {
            preferred_language: preferences.selectedLanguage,
            client_preference: [
                {
                    preference_key: "assessment_data_consent",
                    preference_value: preferences.consentAssessmentData
                },
                {
                    preference_key: "flash_feedback_consent",
                    preference_value: preferences.consentFlashFeedback
                }
            ]
        };
        setClientPreference(payload)
            .then(() => {
                fetchConfig(user);
                props.onSuccess && props.onSuccess();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <div className="mt-5 h-full flex flex-col items-center justify-between">
            <div className="w-full max-w-lg">
                <div className="mb-3 text-center">
                    <h5 className="font-medium">{t("step_4_1.preferred_language")}</h5>
                </div>
                <div className="mb-4 flex justify-center">
                    <ReactSelect
                        getOptionLabel={(option) => option.name}
                        // @ts-ignore
                        getOptionValue={(option) => option.id}
                        onChange={(item) => handleSelectedLanguage(item as IMeta)}
                        isMulti={false}
                        name="language"
                        options={languagesOptions}
                        placeholder={t("step_4_1.language")}
                        className="w-full max-w-xs"
                    />
                </div>

                {/*TODO: After adding more preferences can change this logic*/}
                {!props.aiCoach &&
                    <>
                        <hr className="w-full mb-4"/>

                        <div className="flex justify-between items-center mb-6">
                            <label className="font-medium">{t("step_4_1.consent_assessment_data")}</label>
                            <div>
                                <ToggleButton
                                    enabled={preferences.consentAssessmentData}
                                    setEnabled={handleConsentAssessmentData}
                                />
                            </div>
                        </div>

                        <div className="flex justify-between items-center mb-6">
                            <label className="font-medium">{t("step_4_1.consent_flash_feedback")}</label>
                            <div>
                                <ToggleButton
                                    enabled={preferences.consentFlashFeedback}
                                    setEnabled={handleConsentFlashFeedback}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>

            <div className="flex justify-center mt-4 mb-3">
                {!props.hideButton && (
                    <Button
                        onClick={setClientPreferences}
                        disabled={!preferences.selectedLanguage}
                        className="bg-red text-white font-bold rounded px-5 py-2.5"
                    >
                        {t("step_4_1.action_button")}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default Preference;