import {IAIActionNotificationState, NotificationItems} from "../../AICoaching/types";
import {useTranslation} from "react-i18next";
import ENG from "../locale/en/ai_home.json"
import Fr from "../locale/fr/ai_home.json"
import i18n from "../../../../../utils/lib/i18n";
import React from "react";
import NotificationItem from "./NotificationItem";
// import {DUMMY_BOOKING_DATA} from "../../../../../constants/data/dummyChartData";

interface Props {
    notificationSummary: NotificationItems[];
    handleUpdate: (id: number, data: IAIActionNotificationState) => void;
}

const NotificationView: React.FC<Props> = ({notificationSummary, handleUpdate}) => {
    const {t} = useTranslation("ai_home");
    i18n.addResourceBundle("en", "ai_home", ENG);
    i18n.addResourceBundle("fr", "ai_home", Fr);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-6 w-full px-6">
            <div className="flex flex-col rounded w-full bg-white p-6 gap-4">
                <p className="font-bold text-base font-dm_sans text-black">
                    {t("notification_view.title")}
                </p>
                {notificationSummary.length > 0 ? (
                    notificationSummary.map((item, key) =>
                        <NotificationItem
                            key={key}
                            title={item.title}
                            actions={item.actions}
                            frequency={item.frequency}
                            goalDeadline={item.goal_deadline}
                            notification_id={item.notification_id}
                            completed={item.completed}
                            handleClick={handleUpdate}
                        />
                    )
                ) : (
                    <p className="text-sm font-dm_sans text-black">
                        {t("notification_view.no_notification_text")}
                    </p>
                )}
            </div>
            {/*TODO: Add graph and its data*/}
            {/*<div className="flex flex-col rounded w-full bg-white p-6 gap-4 h-max">*/}
            {/*    <p className="font-bold text-base font-dm_sans text-black">*/}
            {/*        {t("notification_view.title_2")}*/}
            {/*    </p>*/}
            {/*    <AreaChartWithPoints data={DUMMY_BOOKING_DATA.map((dummyItem, index) => {*/}
            {/*        let date = new Date(dummyItem.label);*/}
            {/*        let label = "";*/}
            {/*        if (!isNaN(date.getMonth())) {*/}
            {/*            label = date.toDateString().split(" ").slice(1).join(" ");*/}
            {/*        }*/}
            {/*        return {label: label, value: dummyItem.count};*/}
            {/*    })}/>*/}

            {/*</div>*/}
        </div>
    );
};

export default NotificationView;
