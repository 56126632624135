import React from "react";
import {useAICoaching} from "../../utils/AICoachingContext";
import {useTranslation} from "react-i18next";

const AIChatTitle: React.FC = () => {
    const {selectedThreadItem, state} = useAICoaching();
    const {t} = useTranslation("ai_coaching");
    let title = ""

    if (state == 'practice') {
        title = t("practice_title")
    } else if (selectedThreadItem?.aiModel == 'journey') {
        title = t("journey_title")
    } else if (selectedThreadItem?.aiModel == 'flash') {
        title = t("flash_title")
    } else {
        title = t("session_title")
    }
    return (
        <div
            className={`absolute top-2 left-0 w-full p-1 bg-white text-center ${state == 'practice' ? 'text-practice' : ''} rounded-t-lg z-20`}>
            <h5 className=" font-semibold">
                {selectedThreadItem && selectedThreadItem?.title ? title + selectedThreadItem.title : ""}
            </h5>
        </div>
    );
};

export default AIChatTitle;
