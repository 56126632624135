import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import Modal from "./Modal";
import {getClientObjectives,} from "../../../services/api/teams/GuideActions";
import ObjectiveModalContent from "./ObjectiveModalContent";
import useAuth from "@hooks/useAuth";
import {AICoachType} from "../pages/AICoaching/enum";

const ObjectiveModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuth();
  const isAICoach = user?.aiCoachType === AICoachType.ai_only;

  useEffect(() => {
    getClientObjectives()
      .then((objectives) => {
        if (objectives) setIsOpen(!objectives.length && !isAICoach);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClose = () => {
    toast.error("Please select and submit 3 objectives");
  };

  return (
    <Modal
      title="Objectives"
      isOpen={isOpen}
      closeHandler={handleClose}
      className="w-[90vw] h-[80vh] bg-linen_gradient"
    >
      <ObjectiveModalContent setIsOpen={setIsOpen}/>
    </Modal>
  );
};

export default ObjectiveModal;
