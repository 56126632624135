import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import {
  getActivePeriod,
  getGroups,
  getLabels,
  getPrograms,
} from "../../../services/api/companyApi";
import { IMeta } from "../../../models/IMeta";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/performance.json";
import Fr from "../locale/fr/performance.json";
import { useTranslation } from "react-i18next";
import useAuth from "@hooks/useAuth";
import MyDateRange from "../../../shared/components/MyDateRange";
import { CalendarSelectionType } from "../../../models";
import { Filters, FilterOption } from "../../../constants/types";
import { RefreshIcon, XIcon } from "@heroicons/react/outline";
import { Colors } from "../../../constants/enums";
import moment from "moment/moment";
import axios from "axios";
import {DocumentDownloadIcon} from "@heroicons/react/solid";
import {toast} from "react-toastify";

interface props {
  setFilters: Dispatch<SetStateAction<Filters>>;
  groups?: FilterOption[];
  setisFiltered?: Dispatch<SetStateAction<boolean>>;
  toPDF: () => void
}
const FilterBar: React.FC<props> = (props) => {
  const { user } = useAuth();
  i18n.addResourceBundle("en", "performance", ENG);
  i18n.addResourceBundle("fr", "performance", Fr);
  const { t } = useTranslation("performance");
  const lng = i18n.language;

  const [selectedGroups, setSelectedGroups] = useState<FilterOption[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<FilterOption[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<FilterOption[]>([]);
  const [programData, setProgramMetaData] = useState<IMeta[]>([]);
  const [programs, setPrograms] = useState<FilterOption[]>([]);
  const [groups, setGroups] = useState<FilterOption[]>([]);
  const [labels, setLabels] = useState<FilterOption[]>([]);
  const initialCalState = [
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ];
  const setIsFiltered = (isFiltered: boolean) => {
    if (props.setisFiltered) {
      props.setisFiltered(isFiltered);
    }
  };
  const [calState, setCalState] =
    useState<CalendarSelectionType[]>(initialCalState);

  useEffect(() => {
    if (!user) return;
    getPrograms(
      user.companyId,
      selectedGroups.map((option) => option.value as number)
    ).then((data: IMeta[]) => {
      setProgramMetaData(data);
    });
  }, [selectedGroups]);

  useEffect(() => {
    setPrograms(() => {
      return programData.map((programMeta) => {
        return { label: ((lng == "fr") ? programMeta.name_fr : programMeta.name), value: programMeta.id! };
      });
    })
  }, [lng, programData]);

  useEffect(() => {
    if (!user) return;
    getGroups(
      user.companyId,
      selectedPrograms.map((option) => option.value as number)
    ).then((data: IMeta[]) => {
      setGroups(() => {
        return data.map((groupMeta) => {
          return { label: groupMeta.name, value: groupMeta.id! };
        });
      });
    });
  }, [selectedPrograms]);

  useEffect(() => {
    if (!user) return;
    getLabels(user.companyId).then((data: IMeta[]) => {
      setLabels(() => {
        return data.map((labelMeta) => {
          return { label: labelMeta.name, value: labelMeta.name! };
        });
      });
    });
  }, []);

  useEffect(() => {
    const filters: Filters = {
      program_ids: selectedPrograms.map((option) => option.value as number),
      group_ids: selectedGroups.map((option) => option.value as number),
      labels: selectedLabels.map((option) => option.value as number),
      startDate: calState[0].startDate!,
      endDate: calState[0].endDate!,
    };
    props.setFilters(filters);
  }, [selectedPrograms, selectedGroups, selectedLabels, calState]);

  const resetFilters = () => {
    setSelectedPrograms([]);
    setSelectedGroups([]);
    setSelectedLabels([]);
    setIsFiltered(false);
    setCalState(initialCalState);
  };

  const selectStyle: StylesConfig = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: Colors.warm,
      "&:hover": {
        borderColor: state.isFocused ? Colors.warmDark : Colors.warmDark,
      },
      borderWidth: 2,
      boxShadow: "",
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      color: "black",
    }),
  };

  return (
    <div className="bg-white rounded border-warm-dark border-[1px] p-2 px-4 mb-5 mt-2 flex flex-col md:flex-row flex-grow justify-between items-center gap-5">
      <Select
        styles={selectStyle}
        isClearable
        isSearchable={false}
        className="w-1/3 z-30"
        placeholder={t("text28")}
        noOptionsMessage={() => t("text30")}
        value={selectedPrograms}
        options={programs}
        onChange={(value) => {
          setSelectedPrograms(value as FilterOption[]);
          if (value.length === 0 && selectedGroups.length === 0 && selectedLabels.length === 0) {
            setIsFiltered(false);
          } else {
            setIsFiltered(true);
          }
        }
      }
        menuPlacement="bottom"
        isMulti
      />
      <Select
        styles={selectStyle}
        isClearable
        isSearchable={false}
        className="w-1/3 z-30"
        placeholder={t("text29")}
        noOptionsMessage={() => t("text30")}
        value={selectedGroups}
        options={groups}
        onChange={(value) => {
          setSelectedGroups(value as FilterOption[]);
          if (value.length === 0 && selectedPrograms.length === 0 && selectedLabels.length === 0) {
            setIsFiltered(false);
          } else {
            setIsFiltered(true);
          }
        }}
        menuPlacement="bottom"
        isMulti
      />
      <Select
        styles={selectStyle}
        isClearable
        isSearchable={false}
        className="w-1/3 z-30"
        placeholder={t("text36")}
        noOptionsMessage={() => t("text30")}
        value={selectedLabels}
        options={labels}
        onChange={(value) => {
          setSelectedLabels(value as FilterOption[]);
          if (value.length === 0 && selectedGroups.length === 0 && selectedPrograms.length === 0) {
            setIsFiltered(false);
          } else {
            setIsFiltered(true);
          }
        }}
        menuPlacement="bottom"
        isMulti
      />
      <div className="w-1/3">
        <MyDateRange
          state={calState}
          setState={setCalState}
          style="text-normal text-black cursor-pointer hover:border-warm-dark border-t-1 border-warm-dark"
        />
      </div>
      <div
          className="rounded flex gap-2 items-center justify-center">
        <button
            title={t("text1_3")}
            onClick={() => resetFilters()}
            className="w-10 h-10 hover:shadow-lg bg-white rounded-md p-2 border-2 border-black"
        >
          <RefreshIcon className="text-black w-5 h-5"/>
        </button>
        <button onClick={() => {
          toast.info(t("download_message"))
          props.toPDF()
        }}
                className="hover:shadow-lg bg-white rounded-md p-2 border-2 border-red text-red flex gap-2 items-center">
          <DocumentDownloadIcon className="text-red w-5 h-5"/>
        </button>
      </div>
    </div>
  );
};

export default FilterBar;
