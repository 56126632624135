import React from "react";
import viewReportIcon from "../../../../../assets/img/dashboard/home/icons/view_report.png";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";

type Props = {
    title: string;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const PreviousSessionListItem: React.FC<Props> = ({
                                                      title,
                                                      isExpanded,
                                                      setIsExpanded
                                                  }) => {

    return (
        <div
            className="group cursor-pointer pl-[3%] pr-[4%] flex flex-row items-center justify-between h-14 bg-transparent hover:bg-warm-light"
            onClick={() => setIsExpanded(!isExpanded)}
        >
            <div className="flex flex-row gap-2 h-full items-center">
                <div className="flex flex-col items-center h-full">
                    <div className="flex-1 w-[1px] bg-pink-light group-hover:bg-warm-light"/>
                    <div
                        className="w-8 h-8 border-[1px] bg-white border-pink rounded-full flex justify-center items-center">
                        <img
                            src={viewReportIcon}
                            className="h-5 w-5 object-contain rounded-full"
                            alt="session"
                        />
                    </div>
                    <div className="flex-1 w-[1px] bg-pink-light group-hover:bg-warm-light"/>
                </div>
                <div className="flex items-center gap-2">
                    <p className="font-bold font-dm_sans text-sm items-center block">
                        {title}
                    </p>
                </div>
            </div>
            <div className="flex flex-row gap-5 items-center">
                {isExpanded ? <ChevronUpIcon className="w-5 h-5 text-black shrink-0"/>
                    : <ChevronDownIcon className="w-5 h-5 text-black shrink-0"/>}
            </div>
        </div>
    );
};

export default PreviousSessionListItem;
