import React, {useEffect, useState} from "react";

import moment from "moment";
import {useTranslation} from "react-i18next";

import ProfileCard from "./ProfileCard";
import Button from "../../../../../shared/components/Button";
import ChatGuide from "../../../../../shared/components/ChatGuide";
import HT from "../../../../dashboard/pages/OnboardingRegular/components/HighlightedText";
import VerticalExpander from "../../../../dashboard/pages/OnboardingRegular/components/VerticalExpander";

import ICoach from "../../../../../models/ICoach";
import {ITimeSlot} from "../../../../../models/IBooking";
import {EConfirmBookingFormField} from "../../../../../constants/enums";
import useAuth from "@hooks/useAuth";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";

type Props = {
    selectedCoach: ICoach | null;
    handleSetConfirmBookingForm: (key: string, value: string) => void;
    confirmBookingForm: {
        [key: string]: { value: string; error: boolean; errorText: string };
    };
    timeSlot: ITimeSlot | null;
    handleConfirmBooking?: () => Promise<void>;
    externalVisit: boolean;
    isOnboarding?: boolean;
    tripartiteEmail?: { value: string; error: boolean; errorText: string }
    setTripartiteEmail?: (tripartiteEmail: string | undefined) => void;
};

const ConfirmBooking: React.FC<Props> = ({
                                             selectedCoach,
                                             handleSetConfirmBookingForm,
                                             confirmBookingForm,
                                             timeSlot,
                                             handleConfirmBooking = () => new Promise<void>(() => {
                                             }),
                                             externalVisit,
                                             isOnboarding,
                                             tripartiteEmail,
                                             setTripartiteEmail,
                                         }) => {
    const {t} = useTranslation("booking");
    moment.locale(localStorage.getItem("I18N_LANGUAGE") || "en");
    const {user} = useAuth();
    const {config} = useConfig();

    const [showContent, setShowContent] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setShowContent(!isOnboarding);
    }, []);

    const startTime = moment(
        new Date(`${timeSlot?.time.date}T${timeSlot?.time.start}`).toUTCString()
    );

    return (
        <>
            {isOnboarding ? (
                <ChatGuide
                    coachSubText={t("confirm_booking.coach_sub_text")}
                    title={<HT>{t("confirm_booking.title")}</HT>}
                    description={t("confirm_booking.description")}
                    descriptionClassName="font-normal"
                    onShowContent={() => setShowContent(true)}
                />
            ) : null}
            <VerticalExpander expanded={showContent}>
                <div
                    className="w-full flex flex-row justify-start lg:justify-between lg:items-stretch lg:flex-row gap-4 mt-4 mb-8">
                    <div className="md:w-5/12 lg:w-4/12 xl:w-3/12">
                        <ProfileCard
                            name={
                                selectedCoach
                                    ? `${selectedCoach.first_name} ${selectedCoach.last_name}`
                                    : "-"
                            }
                            imageUrl={selectedCoach ? selectedCoach.image_url : ""}
                            website={selectedCoach ? selectedCoach.website : ""}
                            date={
                                timeSlot
                                    ? moment(timeSlot.time.date).format("dddd, DD MMMM")
                                    : "-"
                            }
                            timeSlot={timeSlot}
                            time={startTime.format("HH:mm")}
                            isOrientation={selectedCoach?.orientation}
                        />
                    </div>
                    <div className="w-full h-full relative">
                        <div
                            className="w-full h-full bg-white divide-gray-200 rounded-lg shadow py-14 px-6 flex flex-col justify-between">
                            <div className="flex flex-col gap-6">
                                {/*<div className="font-dm_sans font-bold text-black">*/}
                                {/*    {t("confirm_booking.fill_details")}*/}
                                {/*</div>*/}
                                <div>
                                    {!isOnboarding && (
                                        <div className="flex flex-row gap-4 mb-4">
                                            <div className="flex flex-col mx-0 gap-1">
                                                <input
                                                    className="text-sm border border-black rounded-md p-2 font-dm_sans placeholder:text-gray-300"
                                                    placeholder={t("confirm_booking.first_name")}
                                                    value={confirmBookingForm.firstName.value}
                                                    disabled={!externalVisit}
                                                    onChange={(e) =>
                                                        handleSetConfirmBookingForm(
                                                            EConfirmBookingFormField.firstName,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <div
                                                    className={`text-red-700 text-xs ${
                                                        confirmBookingForm.firstName.error
                                                            ? "opacity-80"
                                                            : "opacity-0"
                                                    }`}
                                                >
                                                    {confirmBookingForm.firstName.errorText}
                                                </div>
                                            </div>
                                            <div className="flex flex-col mx-0 gap-1">
                                                <input
                                                    className="text-sm border border-black rounded-md p-2 font-dm_sans placeholder:text-gray-300"
                                                    placeholder={t("confirm_booking.last_name")}
                                                    value={confirmBookingForm.lastName.value}
                                                    disabled={!externalVisit}
                                                    onChange={(e) =>
                                                        handleSetConfirmBookingForm(
                                                            EConfirmBookingFormField.lastName,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <div
                                                    className={`text-red-700 text-xs ${
                                                        confirmBookingForm.lastName.error
                                                            ? "opacity-80"
                                                            : "opacity-0"
                                                    }`}
                                                >
                                                    {confirmBookingForm.lastName.errorText}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="font-dm_sans font-bold text-black text-sm mb-3">
                                        {t("confirm_booking.notes_for_coach")}
                                    </div>
                                    <div className="flex flex-col mx-0 gap-1">
                    <textarea
                        rows={4}
                        value={confirmBookingForm.noteForCoach.value}
                        onChange={(e) =>
                            handleSetConfirmBookingForm(
                                EConfirmBookingFormField.noteForCoach,
                                e.target.value
                            )
                        }
                        className="border border-black rounded-md p-2 font-dm_sans placeholder:text-gray-300 placeholder:text-sm"
                                            placeholder={user?.companyName === "Enedis" ? t("confirm_booking.notes_for_coach_placeholder_enedis") : t("confirm_booking.notes_for_coach_placeholder")}
                    />
                                        <div className="mb-4" />

                                        {/*<div*/}
                                        {/*    className={`text-red-700 text-xs mb-4 ${*/}
                                        {/*        confirmBookingForm.noteForCoach.error*/}
                                        {/*            ? "opacity-80"*/}
                                        {/*            : "opacity-0"*/}
                                        {/*    }`}*/}
                                        {/*>*/}
                                        {/*    {confirmBookingForm.noteForCoach.errorText}*/}
                                        {/*</div>*/}
                                    </div>
                                    {setTripartiteEmail && (
                                        <>
                                            <div className="font-dm_sans font-bold text-black text-sm mb-3">
                                                {(config?.tripartie_email_required) ? t("confirm_booking.invite_your_manager") : t("confirm_booking.invite_your_manager_optional")}
                                            </div>
                                            <div className="flex flex-col mx-0 my-1 gap-1">
                                                <input
                                                    value={tripartiteEmail?.value}
                                                    onChange={(e) => setTripartiteEmail(e.target.value)}

                                                    className="border border-black rounded-md p-2 font-dm_sans placeholder:text-gray-300 placeholder:text-sm"
                                                    placeholder="email@example.com"
                                                />
                                                <span className="text-xs h-[16px] text-red-500 font-lexend font-normal">
                                                    {tripartiteEmail?.error && tripartiteEmail.errorText}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-row mt-2 p-2 bg-pink-100 border border-pink-300 rounded-md text-xs text-gray-700 max-w-max">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                <p className="text-left ml-0.5 text-xs">
                                    <span className="font-normal">{t("confirm_booking.cancellation_policy")}:</span> {t("confirm_booking.cancellation_message")}
                                </p>
                            </div>
                            {!isOnboarding && (
                                <div className="flex justify-end items-center">
                                    <Button
                                        disabled={isLoading}
                                        className="mt-5 rounded-md w-32 h-10 text-white border-2 bg-black text-xs font-bold"
                                        onClick={async () => {
                                            setIsLoading(true);
                                            await handleConfirmBooking();
                                            setIsLoading(false);
                                        }}
                                        loading={isLoading}
                                    >
                                        {t("confirm_booking.confirm")}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </VerticalExpander>
        </>
    );
};

export default ConfirmBooking;
