import React, {useState} from 'react';
import InformationIcon from "../../../../../shared/components/InformationIcon";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import {colourStyles} from "./SettingCard";
import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import SettingENG from "../../../locale/eng/setting.json";
import SettingFR from "../../../locale/fr/setting.json";
import {CheckIcon, XIcon} from "@heroicons/react/outline";
import {PencilAltIcon} from "@heroicons/react/solid";
import ConfirmationModal from "../../../../../shared/components/ConfirmationModal/ConfirmationModal";
import {IConfigSchema} from "../../../../../constants/types";
import {AiOutlineSetting} from "react-icons/ai";
import RadioSettingInput from "./RadioSettingInput";

interface Props {
    settingName: string;
    value: string | boolean;
    tooltip?: string;
    options: any[];
    isLoading?: boolean;
    handleUpdate?: (config: IConfigSchema) => void;
    readOnly?: boolean;
}

const CompanyConfigCard: React.FC<Props> = ({
                                                settingName,
                                                value,
                                                tooltip,
                                                options,
                                                isLoading,
                                                handleUpdate,
                                                readOnly
                                            }) => {
    i18n.addResourceBundle("en", "setting", SettingENG);
    i18n.addResourceBundle("fr", "setting", SettingFR);
    const {t} = useTranslation("setting");
    const [selectedValue, setSelectedValue] = useState<any[]>([
        {
            label: value === true ? t("yes") : value === false ? t("no") : value,
            value: value
        }]);
    const [isEditing, setIsEditing] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const [configValue, setConfigValue] = useState<boolean | null>(typeof value === "boolean" ? value : null);

    const handleChange = () => {
        setIsEditing(!isEditing);
    }

    const handleUpdateConfig = () => {
        const updatedValue = Object.values(selectedValue).map((item) => {
            return item
        });
        setIsEditing(false);
        handleUpdate && handleUpdate({key: settingName, value: updatedValue[1] as string | boolean});
    }

    const handleBoolSettingValues = (value: boolean) => {
        setConfigValue(value);
        setIsOpenConfirmModal(true);
    };

    const handleUpdateBoolConfig = () => {
        if (configValue !== null && handleUpdate) {
            handleUpdate({key: settingName, value: configValue});
        }
    }

    return <div className="bg-white rounded-lg border border-gray-100 mb-2">
        <div className="grid grid-cols-2 gap-4 justify-center items-center bg-white p-3">
            <div className="flex items-center gap-1">
                <div className="flex items-center justify-center shrink-0">
                    <AiOutlineSetting className=" w-4 h-4 "/>
                </div>
                <p className=" text-gray-600 font-medium">{t(`${settingName}`)}</p>
                <div className="flex flex-row items-center">
                    <InformationIcon
                        data-for={settingName}
                        data-tip={"configuration description"}
                        className="h-4 w-4"
                    />
                    {tooltip && (
                        <ReactTooltip
                            className="w-96 bg-black backdrop-opacity-100 whitespace-pre-line"
                            delayHide={500}
                            id={settingName}
                            place="top"
                            effect="solid"
                            html={true}
                        >
                            {tooltip}
                        </ReactTooltip>
                    )}
                </div>
            </div>
            <div className="flex gap-2">
                <div className="w-5/12">
                    {typeof value === "boolean" ? (
                            <RadioSettingInput
                                handleChange={handleBoolSettingValues}
                                trueChecked={configValue === true}
                                falseChecked={configValue === false}
                                settingKey={settingName}
                                setIsEditing={setIsEditing}
                                readOnly={readOnly}
                            />
                        ) :
                        <Select
                            closeMenuOnSelect={isEditing}
                            isSearchable={isEditing}
                            onChange={isEditing ? (value) => {
                                setSelectedValue(value as any[]);
                            } : undefined}
                            {...!isEditing && {menuIsOpen: false}}
                            options={options.map((option) => ({
                                label: option === true ? t("yes") : option === false ? t("no") : option,
                                value: option
                            }))}
                            styles={colourStyles}
                            placeholder={"Select"}
                            value={selectedValue}
                            className="border border-gray-200 w-full rounded focus:outline-none text-base bg-white text-gray-600"
                        />}
                </div>
                {typeof value !== "boolean" && !readOnly &&
                    <div className="flex justify-start items-center col-span-3">
                        {isEditing ? (
                            <>
                                <CheckIcon
                                    className="cursor-pointer text-gray-300 font-bold px-1 h-5 shrink-0 hover:text-red"
                                    onClick={() => {
                                        const updatedValue = Object.values(selectedValue).map((item) => {
                                            return item
                                        });
                                        if (updatedValue[1] !== undefined) {
                                            setIsOpenConfirmModal(true);
                                        } else {
                                            handleChange();
                                        }
                                    }}
                                />
                                <XIcon
                                    className="cursor-pointer text-gray-300 font-bold px-1 h-5 shrink-0 hover:text-red"
                                    onClick={handleChange}
                                />
                            </>
                        ) : (
                            <PencilAltIcon
                                className="cursor-pointer text-gray-300 font-bold px-1 h-[18px] shrink-0 hover:text-red"
                                onClick={handleChange}
                            />
                        )}
                    </div>}
            </div>
        </div>
        <ConfirmationModal
            isOpen={isOpenConfirmModal}
            handleConfirm={() => {
                if (typeof value === "boolean") {
                    handleUpdateBoolConfig();
                } else {
                    handleUpdateConfig();
                }
                setIsOpenConfirmModal(false);
            }}
            handleClose={() => {
                setIsOpenConfirmModal(false);
                setConfigValue(typeof value === "boolean" ? value : null);
            }}
            title={t("confirm_modal.title")}
            textMessage={t("confirm_modal.text_message")}
            isLoading={isLoading}
            prevButtonText={t("delete_modal.cancel")}
            nextButtonText={t("confirm_modal.confirm")}
            loadingButtonText={t("confirm_modal.updating")}
        />
    </div>
}

export default CompanyConfigCard;