import moment from "moment";
import { ACTION } from "../../Constants/utilities";

export const settingsController = (arrayToBeSanitized: Array<any>) => {

    if (arrayToBeSanitized && arrayToBeSanitized.length > 0) {
        let tempryArray: any = [];
        arrayToBeSanitized = arrayToBeSanitized.map(obj => {
            if (obj?.isTrue) {
                tempryArray.push(obj);
            }
        })
        return tempryArray;
    }
    else {
        return [];
    }
}

export const isValidController = (params: any, customSetting: any, t : any) => {
    const filteredArrayOfChecked = settingsController(customSetting?.selectedTimeSlots);

    if (filteredArrayOfChecked && filteredArrayOfChecked.length > 0) {
        const dateForFuture = customSetting.daysOfFuture || 100000;
        const startTimeParams = moment(params?.start);

        if (dateForFuture) {
            if (isItaValideDate(customSetting, startTimeParams)) {
                let selectedDay = startTimeParams.format('ddd');
                let isItValid: any;

                filteredArrayOfChecked.forEach((object: any) => {
                    if (t(`day_names_short.${object.id}`).toUpperCase() == selectedDay.toUpperCase().replace(/\.$/, '')) {
                        isItValid = isItaValidTime(params, object);
                        return true;
                    } else return;
                })
                return isItValid ? isItValid : false;
            } else return false

        } else return true

    } else return true;
}

const isItaValideDate = (customSetting: any, startTimeParams: any) => {
    const dateForFuture = customSetting.daysOfFuture || 10000;
    let afterFollowingDays = moment(customSetting.dateOfSubmitted).add(dateForFuture - 1, 'days');
    let theDateIsBetween = startTimeParams.isBetween(moment(customSetting.dateOfSubmitted), moment(afterFollowingDays));

    return theDateIsBetween ? theDateIsBetween : false;
}

const isItaValidTime = (inCommingParams: any, settingsParams: any) => {
    let startTimeInOrdinary: any = moment(moment(inCommingParams?.start).format('HH:mm'), "HH:mm")
    let endTimeInOrdinary: any = moment(moment(inCommingParams?.end).format('HH:mm'), "HH.mm");
    let settingsStart = moment(settingsParams.start, 'HH:mm');
    let settingsEnd = moment(settingsParams.end, 'HH:mm')
    return startTimeInOrdinary.isBetween(settingsStart, settingsEnd, undefined, '[)')
        && endTimeInOrdinary.isBetween(settingsStart, settingsEnd, undefined, '(]');
}

export const checkIsHaveBeforeAfter = (settingsParams: any, endTime: any) => {
    const startTimeParams = moment(endTime);
    const defaulReturn = { isTrue: false };

    if (!settingsParams) return defaulReturn;

    if (isItaValideDate(settingsParams, startTimeParams)) {

        if (settingsParams?.beforeTime?.isTrue) {
            return calculateTheTime(endTime, settingsParams?.beforeTime?.time, ACTION.BEFORE_THE_TIME);

        } else if (settingsParams?.afterTime?.isTrue) {
            return calculateTheTime(endTime, settingsParams?.afterTime?.time, ACTION.AFTER_THE_TIME);

        } else return defaulReturn;

    } else return defaulReturn;
}

const calculateTheTime = (endTime: any, beforeOfAfterTime: any, id: string) => {
    let breakEnd = moment(endTime).add(beforeOfAfterTime, 'minutes');

    return {
        start: endTime,
        end: breakEnd,
        content: {
            id: id,
            isTrue: true,
        }
    }
}