import {useEffect, useState} from "react";
import {getAssessmentScore} from "../../../../../services/api/teams/AssessmentActions";
import useAuth from "@hooks/useAuth";
import AssessmentCard from "../../Reports/components/AssessmentCard";
import assessment_career from "@img/dashboard/reports/icons/assessment_career.png";
import assessment_work from "@img/dashboard/reports/icons/assessment_work.png";
import assessment_job from "@img/dashboard/reports/icons/assessment_job.png";
import assessment_leadership from "@img/dashboard/reports/icons/assessment_leadership.png";
import assessment_communication from "@img/dashboard/reports/icons/assessment_communication.png";
import assessment_quality from "@img/dashboard/reports/icons/assessment_quality.png";
import assessment_self from "@img/dashboard/reports/icons/assessment_self.png";
import assessment_stress from "@img/dashboard/reports/icons/assessment_stress.png";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/reports.json";
import FR from "../../../locale/fr/reports.json";
import {useTranslation} from "react-i18next";
import IAssessmentScore from "../../../../../models/IAssessmentScore";
import {Loader} from "@components";

const AssessmentResult = () => {
    i18n.addResourceBundle("en", "reports", ENG);
    i18n.addResourceBundle("fr", "reports", FR);
    const {t} = useTranslation("reports");

    const [assessmentScore, setAssessmentScore] = useState<IAssessmentScore>();
    const [isLoading, setIsLoading] = useState(false);
    const {user} = useAuth();
    const client_id = user?.id;

    useEffect(() => {
        const fetchAssessmentScore = async () => {
            const res = await getAssessmentScore(+client_id!);
            setAssessmentScore(res);
        };

        setIsLoading(true);
        fetchAssessmentScore();
        setIsLoading(false);
    }, [])

    return (
        <>
            {isLoading ? (
                <Loader/>
            ) : (
                <div className="w-full grid grid-cols-4 gap-2">
                    <AssessmentCard
                        title={t("assessment_results.career_development.title")}
                        image={assessment_career}
                        progress={assessmentScore?.career}
                        tooltip={t("assessment_results.career_development.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.work_environment.title")}
                        image={assessment_work}
                        progress={assessmentScore?.intellect}
                        tooltip={t("assessment_results.work_environment.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.job_performance.title")}
                        image={assessment_job}
                        progress={assessmentScore?.organisation}
                        tooltip={t("assessment_results.job_performance.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.leadership.title")}
                        image={assessment_leadership}
                        progress={assessmentScore?.leadership}
                        tooltip={t("assessment_results.leadership.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.communication.title")}
                        image={assessment_communication}
                        progress={assessmentScore?.communication}
                        tooltip={t("assessment_results.communication.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.quality_of_life.title")}
                        image={assessment_quality}
                        progress={assessmentScore?.worklife}
                        tooltip={t("assessment_results.quality_of_life.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.self_awareness.title")}
                        image={assessment_self}
                        progress={assessmentScore?.assertivity}
                        tooltip={t("assessment_results.self_awareness.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.stress_and_anxiety.title")}
                        image={assessment_stress}
                        progress={assessmentScore?.self_esteem}
                        tooltip={t("assessment_results.stress_and_anxiety.tooltip")}
                    />
                </div>
            )}
        </>
    )
}

export default AssessmentResult;