import {useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useLocation} from "react-router-dom";
import ISidebarMenu, {ISidebarGroup} from "../../models/ISidebarMenu";
import LanguageSelector from "../../shared/components/LanguageSelector/LanguageSelector";
import {SidebarData} from "../../constants/data/SidebarData";
import useAuth from "@hooks/useAuth";
import {useConfig} from "../../utils/providers/AppConfigProvider";
import {AICoachType} from "../../modules/dashboard/pages/AICoaching/enum";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";
import {ERole} from "../../constants/enums";

const SidebarItem = ({
                         item,
                         isOpen,
                     }: {
    item: ISidebarMenu;
    isOpen: boolean;
}) => {
    const {t} = useTranslation("layout");

    return (
        <NavLink
            className={({isActive}) => {
                return (
                    (isActive
                        ? "font-semibold text-red bg-[#FD00541A] focus:text-red focus:font-bold focus:no-underline"
                        : "focus:no-underline") +
                    " flex gap-2 items-center my-1 px-1 py-2 rounded hover:font-semibold w-full justify-start" +
                    " hover:text-red hover:font-bold hover:no-underline"
                );
            }}
            to={item.path ? item.path : ""}
        >
            {({isActive}) => (
                <>
                    <i className="ml-0.5 w-6 h-6">
                        {" "}
                        {isActive
                            ? item.icon_selected
                                ? item.icon_selected
                                : item.icon
                            : item.icon}
                    </i>
                    <span
                        className={`md:block hidden opacity-0 translate-x-28 md:translate-x-0 md:overflow-auto overflow-hidden transition-opacity 
                    ${
                            isOpen
                                ? "duration-200 ease-in delay-100 md:opacity-100 translate-x-0 max-w-screen"
                                : "duration-200 ease-out md:opacity-0 -translate-x-2 max-w-0"
                        }`}
                    >
            {t(`sidebar.${item.title}`)}{" "}
          </span>
                </>
            )}
        </NavLink>
    );
};

const SidebarHeaderItem = ({
                               item,
                               isOpen,
                               isActive,
                               isExpanded
                           }: {
    item: ISidebarMenu;
    isOpen: boolean;
    isActive?: boolean;
    isExpanded?: boolean;
}) => {
    const {t} = useTranslation("layout");
    const [isHovered, setIsHovered] = useState(false);
    const iconToDisplay = isActive || isHovered ? (item.icon_selected ? item.icon_selected : item.icon) : item.icon;

    return (
        <div className={`flex justify-between items-center my-1 px-1 py-2 rounded w-full hover:text-red hover:font-bold
            ${isActive ? "font-semibold text-red bg-[#FD00541A] focus:text-red focus:font-bold focus:no-underline"
            : "focus:no-underline"} hover:no-underline cursor-pointer`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex gap-2 items-center">
                <i className="ml-0.5 w-6 h-6">
                    {iconToDisplay}
                </i>
                <span className={`md:block hidden opacity-0 translate-x-28 md:translate-x-0 md:overflow-auto overflow-hidden transition-opacity
                ${isOpen ? "duration-200 ease-in delay-100 md:opacity-100 translate-x-0 max-w-screen"
                    : "duration-200 ease-out md:opacity-0 -translate-x-2 max-w-0"}
                `}>
                {t(`sidebar.${item.title}`)}{" "}
            </span>
            </div>
            {isExpanded ? <ChevronUpIcon className="h-4 w-4"/> :
                <ChevronDownIcon className="h-4 w-4"/>}

        </div>
    );
};

const Sidebar = ({
                     isOpen,
                 }: {
    isOpen: boolean;
    onClickSideMenu: React.MouseEventHandler;
}) => {
    const {config} = useConfig();
    const {user} = useAuth();
    const aiOnlyCoach = user?.aiCoachType && user.aiCoachType === AICoachType.ai_only;
    const location = useLocation();
    const path = location.pathname;
    const pathParams = path.split('/').filter(Boolean);
    const itemPath = pathParams.slice(1).join('/');
    const findMenuItemTitleByPath = (itemPath: string, sidebarData: ISidebarGroup[]): string => {
        for (const group of sidebarData) {
            if (group.menu) {
                for (const item of group.menu) {
                    if (item.path === itemPath) {
                        return group.title;
                    }
                }
            }
        }
        return "text24";
    };
    const [openGroups, setOpenGroups] = useState<string[]>(
        [findMenuItemTitleByPath(itemPath, SidebarData)]);

    const toggleGroup = (title: string) => {
        setOpenGroups(prevOpenGroups => {
            if (prevOpenGroups.includes(title)) {
                return prevOpenGroups.filter(group => group !== title);
            } else {
                return [...prevOpenGroups, title];
            }
        });
    };

    // const filterMenuItems = (menu: ISidebarMenu[]) => {
    //     return menu.reduce((menuItems, currVal) => {
    //         if (!config?.report && currVal.path === "reports/view") return menuItems;
    //         if (aiOnlyCoach && currVal.path === "coach/booking") return menuItems;
    //         if (!aiOnlyCoach && currVal.path === "ai/coaching") return menuItems;
    //         if (!config?.resources && currVal.path === "resources") return menuItems;
    //
    //         return [...menuItems, currVal];
    //     }, [] as ISidebarMenu[]);
    // };

    const renderMenuItems = (menu: ISidebarMenu[]) => {
        return menu.map((item, index) => {
            if (!(item.allowedRoles && user?.role && item.allowedRoles.indexOf(user.role) === -1)) {
                return <SidebarItem key={index} item={item} isOpen={isOpen}/>;
            }
            return null
        });
    };

    return (
        <div
            className={`aside fixed h-full w-12 py-14 m-0 flex flex-col bg-white justify-between z-10 
        shrink-0 item-center transform ease-in-out w-min-max border-warm-dark border-[1px]  overflow-y-auto
        ${isOpen ? "duration-700 md:w-52" : "duration-700 md:w-20"}`}
        >
            <div>
                <div className="mt-5 flex flex-col items-start scale-100">
                    {SidebarData.reduce((menuItems, currVal) => {
                        if (!config?.report && currVal.path === "reports/view") {
                            return menuItems;
                        }
                        if (aiOnlyCoach && currVal.path === "coach/booking") {
                            return menuItems
                        }
                        if (!aiOnlyCoach && currVal.path === "ai/coaching") {
                            return menuItems
                        }
                        if (!config?.resources && currVal.path === "resources") {
                            return menuItems;
                        }
                        return [...menuItems, currVal];
                    }, [] as ISidebarGroup[])
                        .map((item) => {
                        const filteredMenu = item.menu ? item.menu : [];
                        const isAllowed = (!(item.allowedRoles && user?.role && item.allowedRoles.indexOf(user.role) === -1));
                        if (!isAllowed) return null;
                        return (
                            <div className="w-full px-2" key={item.title}>
                                {user?.role === ERole.SuperAdmin || user?.role === ERole.HrAdmin  || user?.role === ERole.GroupAdmin || user?.role === ERole.Manager ? (
                                    <>
                                        {item.menu ? (
                                            <>
                                                <div onClick={() => toggleGroup(item.title)}>
                                                    <SidebarHeaderItem
                                                        item={item}
                                                        isActive={item.menu?.some(itemMenu => itemPath === itemMenu.path)}
                                                        isExpanded={openGroups.includes(item.title)}
                                                        isOpen={isOpen}
                                                    />
                                                </div>
                                                {openGroups.includes(item.title) && (
                                                    <div className="pl-6">
                                                        {renderMenuItems(filteredMenu)}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            !(item.allowedRoles && user?.role && item.allowedRoles.indexOf(user.role) === -1)
                                                ? <SidebarItem item={item} isOpen={isOpen}/> : null

                                        )}
                                    </>
                                ) : (
                                    <>
                                        {item.menu ? renderMenuItems(filteredMenu)
                                            : !(item.allowedRoles && user?.role && item.allowedRoles.indexOf(user.role) === -1) ?
                                                <SidebarItem item={item} isOpen={isOpen}/> : null}
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="mb-10">
                {/*<SidebarItem item={{*/}
                {/*    title:  t('text6') ,*/}
                {/*    icon: <UsageIcon/>,*/}
                {/*    path: "settings",*/}
                {/*}}/>*/}

                <div
                    className={`flex gap-2 items-center my-3 pl-0 md:pl-6 md:px-1 ${
                        !isOpen && "md:w-16"
                    }`}
                >
                    <LanguageSelector/>
                </div>
            </div>
            {/*<div className="hidden flex-row w-10 h-20 md:block lg:block xl:block overflow-hidden text-white*/}
            {/*absolute right-0 -mr-5 top-1/2 transform -translate-y-1/2 rounded-full*/}
            {/*border-warm-dark border-0 border-r-[1px]"*/}
            {/*     onClick={onClickSideMenu} >*/}
            {/*    <div className="h-full w-full flex flex-row">*/}
            {/*        <div className="h-full w-1/2 bg-gradient-to-r from-transparent via-transparent to-white"/>*/}
            {/*        <div className="h-full w-1/2 flex items-center justify-end p-0 bg-white self-end">*/}
            {/*        {*/}
            {/*            isOpen ?  <ChevronLeftIcon className="text-warm"/> :  <ChevronRightIcon className="text-warm"/>*/}
            {/*        }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default Sidebar;
