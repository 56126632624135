import Spinner from "../Spinner/Spinner";

interface Props {
    spinnerSize?: string;
}

const Loader = ({spinnerSize} : Props) => {
  return (
    <div className="flex justify-center w-full text-center">
      <div className="flex flex-col justify-center items-center">
        <Spinner size={spinnerSize ? spinnerSize : "48px"} />
        <p className="">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;
