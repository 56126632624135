import { useState, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/lib/i18n.js";
import { CustomeSettingsReducer, EventReducer } from "./Constants/ConstantsComponents";
import ENG from "../locale/eng/events.json";
import Fr from "../locale/fr/groups.json";
import React from "react";
import ReactiveCalander from "./MainComponents/Calander";
import { customSettingDummy, eventsDummy } from "./Constants/mainStore";
import { getCoachBookingConfig } from "../../../services/api/coachApi";
import { ACTION } from "./Constants/utilities";
import { EventInput } from "@fullcalendar/core";

const EventScheduleContainer = () => {

  i18n.addResourceBundle("en", "events", ENG);
  i18n.addResourceBundle("fr", "events", Fr);
  const { t } = useTranslation('events')
  const [events, eventDispatch] = useReducer(EventReducer, eventsDummy)
  const [customSettingState, settingDispatch] = useReducer(CustomeSettingsReducer, customSettingDummy)
  const [isLoading, setIsLoading] = useState(false)
  // const [events, setEvents] = useState<{ 
  //   coachello_events: EventInput[], 
  //   other_busy_events: EventInput[]
  // }>({ coachello_events: [], other_busy_events: [] });

  const mapResponseToInitValues = (response: any) => {
    return {
      titleForBlocked: "",
      daysOfFuture: response?.available_day_count || "",
      indentifyInToTheFuture: response == null ? true : response.infinitely_available,
      timeZone: response?.config_timezone || "",
      blockTransparentEvents: response?.block_transparent_events || true,
      selectedTimeSlots: [
        {
          id: "monday",
          title: t("day_names_short.monday"),
          isTrue: (response == null || response.monday) ? true : false,
          start: response == null ? "06:00" : (response.monday ? response.monday.split('-')[0] : ""),
          end: response == null ? "23:00" : (response.monday ? response.monday.split('-')[1] : ""),
        },
        {
          id: "tuesday",
          title: t("day_names_short.tuesday"),
          isTrue: (response == null || response.tuesday) ? true : false,
          start: response == null ? "06:00" : (response.tuesday ? response.tuesday.split('-')[0] : ""),
          end: response == null ? "23:00" : (response.tuesday ? response.tuesday.split('-')[1] : ""),
        },
        {
          id: "wednesday",
          title: t("day_names_short.wednesday"),
          isTrue: (response == null || response.wednesday) ? true : false,
          start: response == null ? "06:00" : (response.wednesday ? response.wednesday.split('-')[0] : ""),
          end: response == null ? "23:00" : (response.wednesday ? response.wednesday.split('-')[1] : ""),
        },
        {
          id: "thursday",
          title: t("day_names_short.thursday"),
          isTrue: (response == null || response.thursday) ? true : false,
          start: response == null ? "06:00" : (response.thursday ? response.thursday.split('-')[0] : ""),
          end: response == null ? "23:00" : (response.thursday ? response.thursday.split('-')[1] : ""),
        },
        {
          id: "friday",
          title: t("day_names_short.friday"),
          isTrue: (response == null || response.friday) ? true : false,
          start: response == null ? "06:00" : (response.friday ? response.friday.split('-')[0] : ""),
          end: response == null ? "23:00" : (response.friday ? response.friday.split('-')[1] : ""),
        },
        {
          id: "saturday",
          title: t("day_names_short.saturday"),
          isTrue: (response == null || response.saturday) ? true : false,
          start: response == null ? "06:00" : (response.saturday ? response.saturday.split('-')[0] : ""),
          end: response == null ? "23:00" : (response.saturday ? response.saturday.split('-')[1] : ""),
        },
        {
          id: "sunday",
          title: t("day_names_short.sunday"),
          isTrue: (response == null || response.sunday) ? true : false,
          start: response == null ? "06:00" : (response.sunday ? response.sunday.split('-')[0] : ""),
          end: response == null ? "23:00" : (response.sunday ? response.sunday.split('-')[1] : ""),
        }
      ],
      afterTime: {
        isTrue: (response == null || response.post_event_buffer) ? true : false,
        time: response?.post_event_buffer || "10"
      },
      beforeTime: {
        isTrue: (response == null || response.pre_event_buffer) ? true : false,
        time: response?.pre_event_buffer || "10"
      },
    };
  };

  const fetchBookingConfig = () => {
    getCoachBookingConfig()
      .then((res) => {
        const mappedResponse = mapResponseToInitValues(res);
        settingDispatch({ type: ACTION.ADD_NEW_SETTINGS, payLoad: mappedResponse })
      })
  }

useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchBookingConfig();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return React.createElement(ReactiveCalander, {
    events: events,
    dispatch: eventDispatch,
    customSetting: customSettingState,
    settingDispatch: settingDispatch
  });
};

export default EventScheduleContainer;
