import { FC } from "react";
import { DashboardLayout } from "../../../layouts";
import Coaches from "../pages/Coaches";
import Coaching from "../pages/Coaching";
import Performance from "../pages/Performance";
import Usage from "../pages/Usage";
import SessionView from "../pages/SessionView";
import Profile from "../pages/Profile";
import Integration from "../pages/Integration";
import Tools from "../pages/Tools";
import CreateReport from "../pages/CreateReport";
import CreateReportOverview from "../pages/CreateReportOverview";
import Home from "../pages/Home";
import Resources from "../pages/Resources";
import Reports from "../pages/Reports";
import CoachBooking from "../pages/CoachBooking";
import Welcome from "../pages/Welcome";
import Onboarding from "../pages/Onboarding";
import Groups from "../pages/Groups";
import CoachInsights from "../pages/CoachInsights";
import withObjectiveModal from "../hoc/withObjectiveModal";

import { IRoute } from "../../../models/IRoute";
import { ERole, ERouteType } from "../../../constants/enums";
import HRInsights from "../pages/Insights/index";
import GroupCreate from "../pages/Group/Group.Create";
import GroupEdit from "../pages/Group/Group.Edit";
import { AIOnlyCoaching } from "../pages/AIOnlyCoaching";
import AiHome from "../pages/AiHome";
import Success from "../pages/Success";
import ManageCoaches from "../pages/ManageCoaches";
import EventScheduleContainer from "../EventSchedule/EventScheduleContainer";
import SummaryAI from "../pages/Admin/AICoaching/SummaryAI";
import PerformanceAI from "../pages/Admin/AICoaching/PerformanceAI";
import UsageAI from "../pages/Admin/AICoaching/UsageAI";
import CompanyInsights from "../pages/CompanyInsights";
import CreateCoach from "../pages/CreateCoach";
import UpdateCoach from "../pages/UpdateCoach";
import Configuration from "../pages/Configuration";

const DashboardRoutes: IRoute[] = [
    {
        path: "/dashboard",
        layout: DashboardLayout,
        routeType: ERouteType.public,
        routes: [

            {
                path: "coaching",
                component: Coaching,
                routeType: ERouteType.private,
                props: {navTitle: "text1"},
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
            },
            {
                path: "usage",
                component: Usage,
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin, ERole.Manager],
                routeType: ERouteType.private,
                props: {navTitle: "text2"},
            },
            {
                path: "insights",
                component: HRInsights,
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
                routeType: ERouteType.private,
                props: {navTitle: "text18"},
            },
            {
                path: "performance",
                component: Performance,
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
                routeType: ERouteType.private,
                props: {navTitle: "text3"},
            },
            {
                path: "coaches",
                component: Coaches,
                routeType: ERouteType.private,
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
                props: {navTitle: "text4"},
            },
            {
                path: "integration",
                component: Integration,
                routeType: ERouteType.private,
                allowedRoles: [ERole.ItAdmin, ERole.HrAdmin, ERole.GroupAdmin],
                props: {navTitle: "text7"},
            },
            {
                path: "integration/success",
                component: Success,
                routeType: ERouteType.private,
                allowedRoles: [ERole.ItAdmin, ERole.HrAdmin, ERole.GroupAdmin],
                props: {navTitle: "text7"},
            },
            {
                path: "tools",
                component: Tools,
                routeType: ERouteType.private,
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
                props: {navTitle: "text11"},
            },
            {
                path: "session-view",
                component: SessionView,
                allowedRoles: [ERole.Coach],
                routeType: ERouteType.private,
                props: {navTitle: "text9"},
            },
            {
                path: "profile",
                component: Profile,
                allowedRoles: [ERole.Coach],
                routeType: ERouteType.private,
                props: {navTitle: "text10"},
            },
            {
                path: "reports",
                component: CreateReportOverview,
                allowedRoles: [ERole.Coach],
                routeType: ERouteType.private,
                props: {navTitle: "text2"},
            },
            {
                path: "reports/create/:sessionId",
                component: CreateReport,
                allowedRoles: [ERole.Coach],
                routeType: ERouteType.private,
                props: {navTitle: "text2"},
            },
            {
                path: "home",
                component: Home,
                routeType: ERouteType.private,
                props: {navTitle: "text12"},
                allowedRoles: [ERole.Coachee],
            },
            {
                path: "resources",
                component: Resources,
                routeType: ERouteType.private,
                props: {navTitle: "text13"},
                allowedRoles: [ERole.Coachee],
            },
            {
                path: "reports/view",
                component: Reports,
                routeType: ERouteType.private,
                props: {navTitle: "text14"},
                allowedRoles: [ERole.Coachee],
            },
            {
                path: "coach/booking",
                component: CoachBooking,
                routeType: ERouteType.private,
                props: {navTitle: "text15"},
                allowedRoles: [ERole.Coachee],
            },
            {
                path: "welcome",
                component: Welcome,
                routeType: ERouteType.private,
                allowedRoles: [ERole.Coachee],
            },
            {
                path: "onboarding",
                component: Onboarding,
                routeType: ERouteType.private,
                allowedRoles: [ERole.Coachee],
            },

            {
                path: "groups",
                component: Groups,
                routeType: ERouteType.private,
                allowedRoles: [ERole.HrAdmin, ERole.SuperAdmin, ERole.GroupAdmin],
                props: {navTitle: "text16"},
            },
            {
                path: "groups/create",
                component: GroupCreate,
                routeType: ERouteType.private,
                props: {navTitle: "text1"},
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin],
            },
            {
                path: "groups/:groupId/edit",
                component: GroupEdit,
                routeType: ERouteType.private,
                props: {navTitle: "text1"},
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
            },
            {
                path: "manage-coaches",
                component: ManageCoaches,
                routeType: ERouteType.private,
                props: {navTitle: "text20"},
                allowedRoles: [ERole.SuperAdmin],
            },
            {
                path: "coach-insights",
                component: CoachInsights,
                routeType: ERouteType.private,
                allowedRoles: [ERole.SuperAdmin],
                props: {navTitle: "text21"},
            },
            {
              path: "event-schedule",
              component: EventScheduleContainer,
              allowedRoles: [ERole.Coach],
              routeType: ERouteType.private,
              props: { navTitle: "text22" },
            },
            {
                path: "companies",
                component: CompanyInsights,
                allowedRoles: [ERole.SuperAdmin],
                routeType: ERouteType.private,
                props: { navTitle: "text25" },
            },
            {
                path: "create-coach",
                component: CreateCoach,
                allowedRoles: [ERole.SuperAdmin],
                routeType: ERouteType.private,
                props: {navTitle: "text26"},
            },
            {
                path: "update-coach/:coachId",
                component: UpdateCoach,
                allowedRoles: [ERole.SuperAdmin],
                routeType: ERouteType.private,
                props: {navTitle: "text27"},
            },
            {
                path: "configuration",
                component: Configuration,
                allowedRoles: [ERole.SuperAdmin],
                routeType: ERouteType.private,
                props: {navTitle: "text29"},
            },
            {
                path: "ai/home",
                component: AiHome,
                routeType: ERouteType.private,
                props: {navTitle: "text12"},
                allowedRoles: [ERole.Coachee],
            },
            {
                path: "ai/coaching",
                component: AIOnlyCoaching,
                routeType: ERouteType.private,
                props: {navTitle: "text19"},
                allowedRoles: [ERole.Coachee],
            },
            {
                path: "ai/summary",
                component: SummaryAI,
                routeType: ERouteType.private,
                props: {navTitle: "text1"},
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
            },
            {
                path: "ai/performance",
                component: PerformanceAI,
                routeType: ERouteType.private,
                props: {navTitle: "text3"},
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin],
            },
            {
                path: "ai/usage",
                component: UsageAI,
                routeType: ERouteType.private,
                props: {navTitle: "text2"},
                allowedRoles: [ERole.SuperAdmin, ERole.HrAdmin, ERole.GroupAdmin, ERole.Manager],
            }

        ].map((route) => {
            if (
                ["home", "resources", "reports/view", "coach/booking", "ai/coaching"].includes(
                    route.path
                )
            )
                return {
                    ...route,
                    component: withObjectiveModal(route.component as FC),
                };
            return route;
        }),
    },
];

export default DashboardRoutes;
