import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
import {FaList} from "react-icons/fa";
import SessionDetailsModal from "./SessionDetailsModal";
import useAuth from "@hooks/useAuth";

interface Props {
    companyId?: number;
    coacheeId?: number;
    coachId?: number;
    groupId: number | null;
}

const DetailsIconCell = (props: Props) => {
    const {t} = useTranslation('commons')
    const {user} = useAuth();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    return (
        <div className="flex items-center gap-1.5">
            <button data-tip data-for="detailsTip" onClick={openModal} className="focus:outline-none">
                <FaList className="text-red w-4 h-4 transition duration-200"/>
            </button>
            <ReactTooltip id="detailsTip" place="top" effect="solid">
                {t("more_detail.view_session_details")}
            </ReactTooltip>

            {modalIsOpen && (
                <SessionDetailsModal
                    {...props}
                    companyId={props.companyId ? props.companyId : user?.companyId!}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                />
            )}
        </div>
    )
}

export default DetailsIconCell;