import { CalendarIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { twMerge } from "tailwind-merge";
import { IGroupBasic } from "../../../../../models/IGroup";
import {IConstant, IMeta} from "../../../../../models/IMeta";
import { getPrograms } from "../../../../../services/api/companyApi";
import MetaApi from "../../../../../services/api/metaApi";
import InputFormik from "../../../../../shared/components/formik/InputFormik/InputFormik";
import style from "../../../../../shared/components/formik/InputFormik/InputFormik.module.css";
import SelectFormik from "../../../../../shared/components/formik/SelectFormik";
import InformationIcon from "../../../../../shared/components/InformationIcon";
import useAuth from "../../../../../utils/hooks/useAuth";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import ColorPickerField from "../../GroupCreate.old/components/ColorPickerField";
import ExpirationDatePickerField from "../../GroupCreate.old/components/ExpirationDatePickerField";
import ProgramPickerField from "../../GroupCreate.old/components/ProgramPickerField";
import {CreateGroupFormFields} from "../../GroupCreate.old/validation/groupFormSchema";
import {ERole, RenewalCycleFrequency} from "../../../../../constants/enums";

interface IGroupDetailsFormProps {
    groupDetails: IGroupBasic;
    editMode?: boolean;
    handleLaunch?: () => void;
    isLaunching?: boolean;
}

const GroupDetailsForm: React.FC<IGroupDetailsFormProps> = ({
                                                                groupDetails,
                                                                editMode,
                                                                handleLaunch,
                                                                isLaunching
                                                            }) => {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups:create_group");
    const {user} = useAuth();
    const {setFieldValue, values, errors, touched} = useFormikContext<IGroupBasic>();

    const [programMetaData, setProgramMetaData] = useState<IMeta[]>([]);
    const [programOptions, setProgramOptions] = useState<IMeta[]>([]);
    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>();
    const [isAIOnlyCoachingSelected, setIsAIOnlyCoachingSelected] = useState(false);
    const [isRenewalFrequencySelected, setIsRenewalFrequencySelected] = useState(false);

    const renewalFreqOptions: IConstant[] = [
        {
            value: RenewalCycleFrequency.yearly,
            label: t(`create_group.renewal_freqs.${RenewalCycleFrequency.yearly}`)
        },
        {
            value: RenewalCycleFrequency.bi_annually,
            label: t(`create_group.renewal_freqs.${RenewalCycleFrequency.bi_annually}`)
        },
        {
            value: RenewalCycleFrequency.tri_annually,
            label: t(`create_group.renewal_freqs.${RenewalCycleFrequency.tri_annually}`)
        }
    ]

    const localLanguage = i18n.language;

    useEffect(() => {
        handleGetMeta();
    }, []);

    const handleGetMeta = () => {
        const lng = MetaApi.getLanguages()
        const programs = getPrograms(user!.companyId)

        Promise.all([lng, programs]).then(([lng, programs]) => {
                setLanguagesOptions(lng)
                setProgramMetaData(programs);
            }
        ).catch((e) => {
            console.log("api error getting meta");
        })


    };

    useEffect(() => {
        const filteredPrograms = programMetaData.filter((programMeta) => (localLanguage === "fr") ?
            programMeta.name_fr !== null : programMeta.name !== null);
        setProgramOptions(() => {
            return filteredPrograms.map((programMeta) => {
                return {name: ((localLanguage == "fr") ? programMeta.name_fr : programMeta.name), id: programMeta.id!};
            });
        })
    }, [programMetaData, localLanguage])

    const handleCreateProgramOption = (value: string) => {
        if (value.trim() === "") return;
        setProgramOptions((currVal) => {
            const filteredVal = currVal.filter((programMeta) => programMeta.name !== null);
            const alreadyExists = filteredVal.some(
                (programMeta) => programMeta.name.toLowerCase() === value.toLowerCase()
            );
            if (alreadyExists) return filteredVal;
            return [
                ...filteredVal,
                {
                    name: value,
                    name_fr: value
                },
            ];
        });
    };

    useEffect(() => {
        const aiCoachType = values.ai_coach_type?.value;
        if (aiCoachType === "ai_only") {
            setIsAIOnlyCoachingSelected(true);
        } else {
            setIsAIOnlyCoachingSelected(false);
        }
    }, [values]);

    useEffect(() => {
        const isFrequencyValid = !!values.renewal_cycle_frequency;
        setIsRenewalFrequencySelected(isFrequencyValid);
        if (!isFrequencyValid) {
            setFieldValue("cycle_startdate", null);
        }
    }, [values.renewal_cycle_frequency, setFieldValue]);

    useEffect(() => {
        if (groupDetails.renewal_cycle_frequency) {
            const defaultRenewalFreq = {value: groupDetails.renewal_cycle_frequency, label:  t(`create_group.renewal_freqs.${groupDetails.renewal_cycle_frequency}`)}
            setFieldValue("renewal_cycle_frequency", defaultRenewalFreq)
        }
    }, [groupDetails, editMode]);


    return (
        <div className="flex flex-col gap-3 mx-8 py-4 ">
            <div className="flex items-center gap-4">
                <p className="font-lexend font-bold text-black text-base">
                    {t("create_group.text1")}
                </p>
                {groupDetails.name &&
                    <div className="flex justify-start">
                        <span
                            className={`${groupDetails.active ? "bg-[#DDF6EF] text-[#1FC191]" : "bg-[#FFF5F8] text-[#FE7BA6]"}  
                            px-4 py-0.5  rounded-full text-center font-normal text-xs capitalize`}>
                        {groupDetails.active ? t("create_group.text58") : t("create_group.text59")}
                        </span>
                    </div>}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 border-b mx-5">
                {!['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) &&
                    <div className="col-span-1">
                        <div className="flex flex-col flex-1 pt-[5px]">
                          <span className={style.label + " pb-1"}>
                            {t("create_group.text8_2")}
                        </span>
                            {!editMode ? (
                                <p className="font-lexend font-light text-black text-sm pb-6">
                                    {t(`ai_coach_type.${groupDetails.ai_coach_type?.value}`)}
                                </p>
                            ) : (
                                <div className="h-[64px] min-h-[64px] -mt-1">
                                    <SelectFormik isMulti={false} name="ai_coach_type" labelKey="label" valueKey="value"
                                                  options={[
                                                      {
                                                          label: t("ai_coach_type.ai_augmented"),
                                                          value: "ai_augmented",
                                                      },
                                                      {
                                                          label: t("ai_coach_type.ai_only"),
                                                          value: "ai_only"
                                                      },
                                                      {
                                                          label: t("ai_coach_type.human_only"),
                                                          value: "human_only"
                                                      }
                                                  ]}
                                        // placeholder={t("create_group.text8_1")}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }
                <div className="col-span-1">
                    {!groupDetails.active && <div className="flex flex-col flex-1 pt-[5px] pb-6">
                        <span className={style.label}>{t("create_group.text57")}
                        </span>
                        {!editMode ? (
                            <div className="flex items-center gap-4">
                                <p className="font-lexend font-light text-black text-sm">
                                    {groupDetails.launch_date ?? "-"}
                                </p>
                                {!groupDetails.active &&
                                    <button
                                        disabled={isLaunching}
                                        className="bg-red hover:bg-black text-white rounded-full px-4 py-1"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            handleLaunch && handleLaunch();
                                        }}>
                                        {isLaunching ? t("create_group.text61") : t("create_group.text60")}
                                    </button>}
                            </div>
                        ) : (
                            <div className="flex items-center gap-4">
                                {!groupDetails.active ?
                                    <div className="flex items-center w-full gap-2">
                                        <ExpirationDatePickerField
                                            placeholder={t(
                                                CreateGroupFormFields.launch_date.placeholder ?? ""
                                            )}
                                            value={values.launch_date}
                                            onChange={(date: string) => {
                                                setFieldValue("launch_date", date);
                                            }}
                                        />
                                        {groupDetails.name &&
                                            <button
                                                disabled={isLaunching}
                                                className="bg-red hover:bg-black text-white rounded-full px-4 py-1.5"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    handleLaunch && handleLaunch();
                                                }}>
                                                {isLaunching ? t("create_group.text61") : t("create_group.text60")}
                                            </button>}
                                    </div> :
                                    <div className="flex gap-2 w-full">
                                        <div className="flex flex-col justify-center w-full">
                                            <div
                                                className="bg-[#ECECEB] cursor-no-drop w-full h-[40px] flex flex-row items-center justify-between gap-2 px-2 pl-3 border-[2px] border-[#D9D9D9] rounded-[5px]">
                                                <p
                                                    className={`font-lexend ${
                                                        values.launch_date ? "text-black font-normal" : "text-[#bfbfbf] font-light"
                                                    }  text-sm`}
                                                >
                                                    {values.launch_date}
                                                </p>
                                                <CalendarIcon className="h-[20px] text-[#B1B1AF]"/>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div>}
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 px-5">
                <div className="col-span-1">
                    {!editMode ? (
                        <div className="flex flex-col flex-1 pt-[5px]">
                          <span className={style.label}>
                            {t(CreateGroupFormFields.name.label)}
                          </span>
                            <p className="font-lexend font-light text-black text-sm">
                                {groupDetails.name}
                            </p>
                        </div>
                    ) : (
                        <InputFormik
                            placeholder={t(CreateGroupFormFields.name.placeholder ?? "")}
                            label={t(CreateGroupFormFields.name.label)}
                            name={"name"}
                        />
                    )}
                </div>
                <div className="col-span-1">
                    {!editMode ? (
                        <div className="flex flex-col flex-1 pt-[5px] pb-2">
                                    <span className={style.label}>
                                      {t(CreateGroupFormFields.description.label)}
                                    </span>
                            <p className="font-lexend font-light text-black text-sm">
                                {groupDetails.description == '' ? "-" : groupDetails.description}
                            </p>
                        </div>
                    ) : (
                        <InputFormik
                            placeholder={t(CreateGroupFormFields.description.placeholder ?? "")}
                            label={t(CreateGroupFormFields.description.label)}
                            name={"description"}
                        />
                    )}
                </div>
                <div className="col-span-1">
                    <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label}>
                          {t(CreateGroupFormFields.color_code.label)}
                        </span>
                        {!editMode ? (
                            <div className="flex items-center">
                                <div
                                    className="h-[20px] w-[20px] rounded-full mr-1"
                                    style={{backgroundColor: values.color_code ?? ""}}
                                />
                                <p className="font-lexend font-normal text-black text-sm">
                                    {values.color_code == '' ? "-" : values.color_code}
                                </p>
                            </div>
                        ) : (
                            <ColorPickerField
                                color={values.color_code ?? ""}
                                onChange={(value) => {
                                    setFieldValue("color_code", value);
                                }}
                            />
                        )}
                    </div>
                </div>
                {!['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) &&
                    <div className="col-span-1">
                        <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label}>
                          {t(CreateGroupFormFields.group_expiration.label)}
                        </span>
                            {!editMode ? (
                                <p className="font-lexend font-light text-black text-sm">
                                    {groupDetails.group_expiration ?? "-"}
                                </p>
                            ) : (
                                <ExpirationDatePickerField
                                    placeholder={t(
                                        CreateGroupFormFields.group_expiration.placeholder ?? ""
                                    )}
                                    value={values.group_expiration}
                                    onChange={(date: string) => {
                                        setFieldValue("group_expiration", date);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                }
                {!['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) &&
                    <div className="col-span-1">
                        <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label + " pb-1"}>
                            {t("create_group.text8_1")}
                        </span>
                            {!editMode ? (

                                <p className="font-lexend font-light text-black text-sm">
                                    {groupDetails.language?.name ?? "-"}
                                </p>

                            ) : (
                                <div className="-mt-1">
                                    <SelectFormik isMulti={false} name="language" labelKey="name" valueKey="id"
                                                  options={languagesOptions}
                                        // placeholder={t("create_group.text8_1")}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }
                <div className="col-span-1">
                    <div className="flex flex-col flex-1 pt-[5px]">
                        <span className={style.label}>{t("create_group.text38")}</span>
                        {!editMode ? (
                            <p className="font-lexend font-light text-black text-sm">
                                {localLanguage == 'fr' ? groupDetails.program?.name_fr : (groupDetails.program?.name ?? "-")}
                            </p>
                        ) : (
                            <div className="h-[66px] min-h-[66px]">
                                <ProgramPickerField
                                    value={values.program}
                                    onChange={(program: IMeta | IMeta[] | null) => {
                                        setFieldValue("program", program);
                                    }}
                                    programOptions={programOptions}
                                    onCreateProgramOption={handleCreateProgramOption}
                                    error={
                                        typeof errors.program === "string" ? "Invalid value" : ""
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!isAIOnlyCoachingSelected && (
                    <div className="col-span-1">
                        <div className="flex-col flex-1">
                                <span className={twMerge(style.label, 'flex gap-2 items-center')}>
                            {t(CreateGroupFormFields.credits.label)} <InformationIcon
                                    data-for={'credits'}
                                    data-tip={"hover on me will keep the tooltip"}
                                    className={"w-4 h-4"}/>
                            <ReactTooltip
                                className={"w-60"}
                                delayHide={500}
                                id={'credits'}
                                place="top"
                                effect="solid"
                            >
                              <span>{t("create_group.text3_1")} </span>
                            </ReactTooltip>
                          </span>
                            {!editMode ? (
                                <div className="flex flex-col flex-1 pt-[5px]">
                                    <p className="font-lexend font-light text-black text-sm">
                                        {Number(groupDetails.credits) > 0 ? groupDetails.credits : "-"}
                                    </p>
                                </div>
                            ) : (
                                <InputFormik
                                    placeholder={t(CreateGroupFormFields.credits.placeholder ?? "")}
                                    // label={t(CreateGroupFormFields.credits.label)}
                                    name={"credits"}
                                    type="number"
                                />
                            )}
                        </div>
                    </div>
                )}
                {!isAIOnlyCoachingSelected && (
                    <div className="col-span-1">
                        {!editMode ? (
                            <div className="flex flex-col flex-1 pt-[5px]">
                            <span className={style.label}>
                              {t(CreateGroupFormFields.credit_limit_per_user.label)}
                            </span>
                                <p className="font-lexend font-light text-black text-sm">
                                    {Number(groupDetails.credit_limit_per_user) > 0
                                        ? groupDetails.credit_limit_per_user
                                        : "-"}
                                </p>
                            </div>
                        ) : (
                            <InputFormik
                                placeholder={t(
                                    CreateGroupFormFields.credit_limit_per_user.placeholder ?? ""
                                )}
                                label={t(CreateGroupFormFields.credit_limit_per_user.label)}
                                name={"credit_limit_per_user"}
                                type="number"
                            />
                        )}
                    </div>
                )}
                {user?.role == ERole.SuperAdmin && <>
                    <div className="col-span-1">
                        <div className="flex flex-col flex-1 pt-[5px]">
                            <span
                                className={style.label}>{t(CreateGroupFormFields.renewal_cycle_frequency.label)}</span>
                            {!editMode ? (
                                <p className="font-lexend font-light text-black text-sm">
                                    {groupDetails.renewal_cycle_frequency ? t(`create_group.renewal_freqs.${groupDetails.renewal_cycle_frequency}`) : "-"}
                                </p>
                            ) : (
                                <div className="h-[66px] min-h-[66px]">
                                    <SelectFormik
                                        isMulti={false}
                                        placeholder={t("create_group.text69")}
                                        name="renewal_cycle_frequency"
                                        labelKey="label"
                                        valueKey="value"
                                        options={renewalFreqOptions}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {isRenewalFrequencySelected &&
                        <div className="col-span-1">
                            <div className="flex flex-col flex-1 pt-[5px]">
                                <span className={style.label}>{t(CreateGroupFormFields.cycle_startdate.label)}</span>
                                {!editMode ? (
                                    <p className="font-lexend font-light text-black text-sm">
                                        {groupDetails.cycle_startdate ?? "-"}
                                    </p>
                                ) : (
                                    <>
                                        <ExpirationDatePickerField
                                            placeholder={t(
                                                CreateGroupFormFields.cycle_startdate.placeholder ?? ""
                                            )}
                                            value={values.cycle_startdate}
                                            onChange={(date: string) => {
                                                setFieldValue("cycle_startdate", date);
                                            }}
                                        />
                                        <span className="text-red h-[18px]">
                                    {touched.cycle_startdate && errors.cycle_startdate ? errors.cycle_startdate : ""}
                                  </span>
                                    </>
                                )}
                            </div>
                        </div>
                    }
                </>}
            </div>
        </div>
    );
};

export default GroupDetailsForm;
