import { userTimeZone } from "../../utils/helpers/time";
import axios from "axios";
import {GET, POST, PUT, DELETE} from "../../utils/lib/axios";
import { COACH } from "../../constants/endpoints/coach";
import ICoachProfile from "../../models/ICoachProfile";
import moment from "moment";

export const getCoach = (coachID: number, orientation?: boolean) => {
  return GET(COACH.get.getCoach, {coach_id: coachID}, {orientation: orientation}).then((response) => {
    return response.data.data;
  });
};
export const getCoaches = (companyId: string) => {
  return axios.get<any>("api/coach?company=" + companyId).then((response) => {
    return response.data.data;
  });
};

export const getAvailableDates = (
  coachID: number,
  start: string,
  end: string
) => {
  return axios
    .get(
      `api/coach/${coachID}/calendar?start_date=${start}&end_date=${end}&time_zone=${userTimeZone()}`
    )
    .then((response) => {
      return response.data;
    });
};

export const createBooking = (data: Object) => {
  return axios.post("/api/coach/calendar/event", data).then((response) => {
    return response.data;
  });
};

export const getCoachSessions = (
  coachID: number,
  date: Date,
  all: boolean = false,
  upcoming: boolean = false
) => {
  return GET(COACH.get.coachSessions, undefined, {
    date: moment(date).format("YYYY-MM-DD"),
    timezone: userTimeZone(),
    all,
    upcoming,
  }).then((response) => {
    return response.data;
  });
};

export const getCoachProfile = (coachID: number) => {
  return GET(COACH.get.coachProfile, undefined, {coach_id: coachID}).then((response) => {
    return response.data;
  });
};

export const updateCoachProfile = (profile: ICoachProfile, coachID: number) => {
  return PUT(COACH.put.coachProfile, profile, undefined, {coach_id: coachID}).then((response) => {
    return response.data;
  });
};

export const getPendingReports = (coachID: number) => {
  return GET(COACH.get.pendingReports, undefined, {
    timezone: userTimeZone(),
  }).then((response) => {
    return response.data;
  });
};

export const getSessionsOverview = (coachID: number, sessionID: number) => {
  return GET(COACH.get.sessionsOverview, undefined, {
    session_id: sessionID,
    timezone: userTimeZone(),
  }).then((response) => {
    return response.data;
  });
};

export const getReport = (coachID: number, sessionID: number) => {
  return GET(COACH.get.report, { session_id: sessionID }).then((response) => {
    return response.data;
  });
};

export const createReport = (coachID: number, sessionID: number, data: any) => {
  return POST(COACH.post.report, data, { session_id: sessionID }).then(
    (response) => {
      return response.data;
    }
  );
};

export const getOccupiedEvents = (
  coachId: string | null,
  start_date: string,
  end_date: string
) => {
  return GET(
    COACH.get.getOccupiedEvents,
    { coachId },
    { start_date, end_date, time_zone: userTimeZone() }
  ).then((response) => {
    return response.data;
  });
};

export const getAllCoaches = (companies?: Array<number>) => {
  return GET(COACH.get.getAllCoaches, undefined, {
    companies: companies
  }).then((response) => {
    return response.data;
  });
}

export const getCoachBookingConfig = () => {
  return GET(COACH.get.getCoachBookingConfig, undefined).then((response) => {
    return response.data;
  });
};

export const updateCoachBookingConfig = (data: any) => {
  return PUT(COACH.put.coachBookingConfig, data).then((response) => {
    return response.data;
  });
};

export const getCoachEvents = (start_date: string, end_date: string) => {
  return GET(COACH.get.getCoachEvents, undefined, {
    start_date,
    end_date,
  }).then((response) => {
    return response.data;
  });
};

export const getCoachClients = () => {
  return GET(COACH.get.getClients).then((response) => {
    return response.data;
  });
}

export const updatePersonalUnavailableEvents = (data: any) => {
  return PUT(COACH.put.personalUnavailableEvents, data).then((response) => {
    return response.data;
  });
};

export const updateOnbehalfBooking = (data: any) => {
  return POST(COACH.post.onbehalfBooking, data).then((response) => {
    return response.data;
  });
};

export const deletePersonalUnavailability = (id: number) => {
  return DELETE(COACH.delete.deletePersonalUnavailability, undefined, {id})
      .then((response) => {
          return response.data;
  });
};

export const deleteBookOnBehalf = (meeting_id: number) => {
  return DELETE(COACH.delete.deleteBookOnBehalf, undefined, {meeting_id})
  .then((response) => {
    return response.data;
  })
};

export const getClientEvent = (coach_id: number, meeting_id: number) => {
  return GET(COACH.get.getClientEvent, {coach_id}, {meeting_id}).then((response) => {
    return response.data;
  });
};

export const createCoachProfile = (profile: any) => {
  return POST(COACH.post.coachProfile, profile).then((response) => {
    return response.data;
  })
}