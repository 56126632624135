import {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import Select from "react-select";

import CoachCard from "./components/CoachCard";

import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/coaches.json";
import Fr from "../../locale/fr/coaches.json";
import {useNavTitle} from "@hooks/useNavTitle";
import {getCoaches, getPrograms} from "../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";

import ICoach from "../../../../models/ICoach";
import {IMeta} from "../../../../models/IMeta";
import SwitchToggle from "./components/SwitchToggle";
import {BsFunnel} from "react-icons/bs";
import LoaderContainer from "../../../../shared/components/LoaderContainer";

type FilterOption = {
    label: string;
    value: number;
};

const Coaches = ({...props}) => {
    i18n.addResourceBundle("en", "coaches", ENG);
    i18n.addResourceBundle("fr", "coaches", Fr);
    const {t} = useTranslation("coaches");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const [coaches, setCoaches] = useState<ICoach[]>([]);
    const [sortedCoaches, setSortedCoaches] = useState<ICoach[]>([]);
    const {user} = useAuth();

    const [programs, setPrograms] = useState<FilterOption[]>([]);
    const [selectedPrograms, setSelectedPrograms] = useState<FilterOption[]>([]);
    const [sortByCoachee, setSortByCoachee] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!user) return;
        getPrograms(user.companyId).then((data: IMeta[]) => {
            setPrograms(() => {
                return data.map((programMeta) => {
                    return {label: programMeta.name, value: programMeta.id!};
                });
            });
        });
    }, []);

    useEffect(() => {
        const sorted = coaches.slice().sort((a: any, b: any) => {
            return sortByCoachee ? b.coachee - a.coachee : b.bookings - a.bookings;
        });
        setSortedCoaches(sorted);
    }, [sortByCoachee, coaches]);

    useEffect(() => {
        const filtrationPrograms = selectedPrograms.map((program) => program.value);

        getAllCoaches(filtrationPrograms);
    }, [selectedPrograms]);

    const getAllCoaches = (filtrationPrograms: Array<number>) => {
        setIsLoading(true);
        if (!user) return;
        getCoaches(user.companyId, undefined, filtrationPrograms)
            .then((response) => {
                const sortedCoaches = response.sort((a: any, b: any) => b.coachee - a.coachee);
                setCoaches(sortedCoaches);
            })
            .catch((err) => {
                console.error(err.response);
            }).finally(() => setIsLoading(false));
    };

    return (
        <div className="m-5 flex flex-col gap-4">
            <div className="flex flex-col md:flex-row flex-grow justify-between items-center gap-5">
                <Select
                    isClearable
                    isSearchable={false}
                    className="w-[300px] z-20"
                    placeholder={t("text9")}
                    noOptionsMessage={() => t("text10")}
                    value={selectedPrograms}
                    options={programs}
                    onChange={(value) => {
                        setSelectedPrograms(value as FilterOption[]);
                    }}
                    menuPlacement="bottom"
                    isMulti
                />
                <div className="flex-grow flex items-center justify-end gap-2">
                    <BsFunnel className="text-black w-5 h-5"/>
                    <SwitchToggle option1={t("coachees")} option2={t("bookings")}
                                  switchToOption1={sortByCoachee} setSwitchToOption1={setSortByCoachee}/>
                </div>
            </div>
            {isLoading ? (
                <div className="inset-0 fixed">
                    <LoaderContainer/>
                </div>
            ) : (
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                {sortedCoaches.map((coach, index) => (
                    <CoachCard key={index} coach={coach} sortByCoachee={sortByCoachee}/>
                ))}
            </div>
            )}
        </div>
    );
};

export default Coaches;
