import React, {FC, useMemo} from "react";
import CreatableSelect from "react-select/creatable";
import {IMeta} from "src/models/IMeta";

import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";

type ProgramPickerProps = {
    value?: IMeta | IMeta[] | null;
    error?: string;
    onChange: (date: IMeta | IMeta[] | null) => void;
    programOptions: IMeta[];
    onCreateProgramOption: (value: string) => void;
    isMultiSelection?: boolean;
};

const ProgramPickerField: FC<ProgramPickerProps> = ({
                                                        error,
                                                        value,
                                                        onChange,
                                                        programOptions,
                                                        onCreateProgramOption,
                                                        isMultiSelection = false
                                                    }) => {
    const { t } = useTranslation("groups:coachees");
    i18n.addResourceBundle("en", "groups:coachees", ENG);
    i18n.addResourceBundle("fr", "groups:coachees", Fr);
    const lng = i18n.language;

    const handleChange = (value: string | null) => {
        if (!value) return onChange(null);
        const labelOption =
            programOptions.find((programMeta) => programMeta.name === value) ?? null;
        if (labelOption) return onChange(labelOption);
        onCreateProgramOption(value);
        onChange({
            name: value,
            name_fr: value
        });
    };

    const handleMultiChange = (selectedValues: { label: string; value: string }[] | null) => {
        if (!selectedValues) return onChange(null);
        const selectedLabels: IMeta[] = selectedValues.map((v) => {
            const labelOption = programOptions.find((programMeta) => programMeta.name === v.value);
            return labelOption ? labelOption : { name: v.value }; // Ensure it returns a valid IMeta type
        });

        return onChange(selectedLabels);
    };

    const options = programOptions.map((programMeta) => ({
        label: programMeta.name,
        value: programMeta.name,
    }));

    const selectorValue = useMemo(() => {
        if (Array.isArray(value)) {
            return value.map((v) => ({
                label: v.name,
                value: v.name,
            }));
        } else if (value) {
            return {
                label: value.name,
                value: value.name,
            };
        }
        return null;
    }, [value,lng]);

    return (
        <div className="flex-1 flex flex-col gap-2">
            <div className="flex flex-col justify-center w-full">
                <CreatableSelect
                    isMulti={isMultiSelection}
                    closeMenuOnSelect={!isMultiSelection}
                    isClearable={!isMultiSelection}
                    placeholder="Select..."
                    noOptionsMessage={() => t("coachees.text7")}
                    onCreateOption={handleChange}
                    onChange={(value) => {
                        if (isMultiSelection) {
                            handleMultiChange(value as { label: string; value: string }[] | null);
                        } else {
                            const singleValue = (value as { label: string; value: string } | null)?.value || null;
                            handleChange(singleValue);
                        }
                    }}
                    value={selectorValue}
                    getOptionLabel={(option) => option["label"]}
                    getOptionValue={(option) => option["value"]}
                    options={options}
                    formatCreateLabel = {(inputValue) => `${t("coachees.text13")} "${inputValue}"`}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            height: "40px",
                            minHeight: "40px",
                            width: "100%",
                            border: "2px solid #E5E7EB",
                            fontSize: "14px",
                            fontWeight: "normal",
                        }),
                    }}
                />
                {error && (
                    <span className="absolute bottom-0 text-xs h-[16px] text-red-500 font-lexend font-normal">
            {error}
          </span>
                )}
            </div>
        </div>
    );
};

export default ProgramPickerField;
