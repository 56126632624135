import {FC, useMemo, useState} from "react";
import {
    ColumnDef,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import {PencilIcon, TrashIcon} from "@heroicons/react/solid";
import {twMerge} from "tailwind-merge";
import {useTranslation} from "react-i18next";

import {TGroupTableRow} from "../../../../../constants/types";
import LoaderContainer from "../../../../../shared/components/LoaderContainer";

type GroupTableProps = {
    onEditGroup: (groupId: number) => void;
    onDeleteGroup: (groupId: number) => void;
    tableData: TGroupTableRow[];
    isLoading?: boolean;
};

const GroupTable: FC<GroupTableProps> = ({
                                             onEditGroup,
                                             onDeleteGroup,
                                             tableData,
                                             isLoading
                                         }) => {
    const {t} = useTranslation("tables");
    const [hoveringRow, setHoveringRow] = useState<number | undefined>();

    const columnHelper = createColumnHelper<TGroupTableRow>();
    const columns: ColumnDef<TGroupTableRow, any>[] = useMemo(
        () => [
            columnHelper.accessor("groupName", {
                header: "text1",
                cell: (data) => {
                    const value = data.getValue();
                    return <GroupName logo={value.logo} name={value.name}/>;
                },
            }),
            {
                header: "text10",
                accessorKey: "aiCoachAccess",
                cell: (data) => {
                    const value = data.getValue();
                    return (
                        <div className="relative w-[130px] overflow-hidden text-ellipsis whitespace-nowrap group">
                            <div className="truncate group-hover:w-auto group-hover:whitespace-normal group-hover:overflow-visible transition-all duration-300">
                                {value}
                            </div>
                        </div>
                    );
                },
            },
            {
                header: "text2",
                accessorKey: "members",
            },
            {
                header: "text3",
                accessorKey: "credits",
                cell: ({ row }) => {
                    const creditUsage = row.original.creditUsage ?? 0;
                    const credits = row.original.credits ? row.original.credits : '-';
                    return `${creditUsage} / ${credits}`;
                },
            },
            {
                header: "text4",
                accessorKey: "creditLimitPerUser",
            },
            // {
            //     header: "text5",
            //     accessorKey: "medium",
            // },
            {
                header: "text6",
                accessorKey: "program",
                cell: (data) => {
                    const value = data.getValue();
                    return (
                        <div className="max-w-[100px] break-words whitespace-normal">
                            {value}
                        </div>
                    );
                },
            },
            {
                header: "text7",
                accessorKey: "groupExpiration",
            },
            columnHelper.accessor("colorCode", {
                header: "text8",
                cell: (data) => {
                    const value = data.getValue();
                    return (
                        <div
                            className="h-[30px] w-[30px] rounded-full"
                            style={{backgroundColor: value}}
                        />
                    );
                },
            }),
            {
                header: "text9",
                accessorKey: "createdAt",
            },

        ],
        [tableData],
    );

    const tableInstance = useReactTable({
        getCoreRowModel: getCoreRowModel(),
        columns,
        data: tableData,
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <table>
            <thead className="cursor-default bg-[#fafafa] h-[44px]">
            {tableInstance.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((column, cellIndex) => {
                        const className = !cellIndex
                            ? "pl-6"
                            : cellIndex === headerGroup.headers.length - 1
                                ? "pr-6"
                                : "";
                        const header = flexRender(
                            column.column.columnDef.header,
                            column.getContext(),
                        );

                        return (
                            <th
                                key={column.id}
                                colSpan={column.colSpan}
                                className={twMerge(
                                    "font-lexend font-normal text-black text-sm",
                                    className,
                                )}
                            >
                                <div
                                    className="flex flex-row items-center gap-2 hover:cursor-pointer"
                                    onClick={column.column.getToggleSortingHandler()}
                                >
                                <p className="font-lexend font-normal text-black text-sm text-start">
                                    {header === null ? "" : t(`groups.${header}`)}
                                </p>
                                    <span
                                        className={
                                            !column.column.getIsSorted() ? "opacity-0" : ""
                                        }
                                    >
                                    {{desc: " ▼", asc: " ▲"}[
                                        column.column.getIsSorted() as string
                                        ] ?? null}
                                  </span>
                                </div>
                            </th>
                        );
                    })}
                </tr>
            ))}
            </thead>
            <tbody>
            {isLoading ?
                <div className="z-10 fixed inset-x-0 flex justify-center items-center py-8">
                    <LoaderContainer spinnerSize={"40px"}/>
                </div>
                : tableInstance.getRowModel().rows.map((row, i) => {
                return (
                    <tr
                        key={row.id}
                        className="cursor-pointer h-[44px] hover:bg-[#fafafa] border-b-[1px] border-[#f2ebe3] relative"
                        onMouseEnter={() => setHoveringRow(i)}
                        onMouseLeave={() => setHoveringRow(undefined)}
                        onClick={() => onEditGroup(row.original.id)}
                    >
                        {row.getVisibleCells().map((cell, cellIndex) => {
                            const className = !cellIndex
                                ? "pl-6"
                                : cellIndex === row.getAllCells().length - 1
                                    ? "pr-6"
                                    : "";
                            return (
                                <td
                                    key={cell.id}
                                    className={twMerge(
                                        "font-lexend font-normal text-black text-sm",
                                        className,
                                    )}
                                >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                </td>
                            );
                        })}

                            {/* Render the Actions component only when the row is hovered */}
                            {hoveringRow === i && (
                                <div className="absolute right-5 top-1/2 transform -translate-y-1/2 flex gap-2">
                                    <Actions
                                        onEdit={() => onEditGroup(row.original.id)}
                                        onDelete={() => onDeleteGroup(row.original.id)}
                                    />
                                </div>
                            )}
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

type GroupNameProps = {
    logo: string;
    name: string;
};

const GroupName: React.FC<GroupNameProps> = ({logo, name}) => {
    return (
        <div className="flex flex-row gap-2 items-center">
            {/* <div className="h-8 w-8 rounded-full">
        <img alt="logo" src={logo} className="object-contain" />
      </div> */}
            <p className="font-lexend font-normal text-black text-sm">{name}</p>
        </div>
    );
};

type ActionsProps = {
    onEdit: () => void;
    onDelete: () => void;
};

const Actions: React.FC<ActionsProps> = ({onEdit, onDelete}) => {
    return (
        <div className="flex flex-row justify-end gap-1 bg-[#eeeeee] rounded-lg px-1 py-0.5">
            <PencilIcon
                className="cursor-pointer text-red h-[20px]"
                onClick={onEdit}
            />
            <TrashIcon
                className="cursor-pointer text-red h-[20px]"
                onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                }}
            />
        </div>
    );
};

export default GroupTable;
