import {AxiosError} from "axios";
import {Form, Formik, FormikHelpers, FormikValues} from "formik";
import moment from "moment/moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {IGroupBasic} from "../../../../models/IGroup";
import {createGroup, getCreditStats} from "../../../../services/api/companyApi";
import SubmitFormik from "../../../../shared/components/formik/SubmitFormik/SubmitFormik";
import useAuth from "../../../../utils/hooks/useAuth";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/groups.json";
import Fr from "../../locale/fr/groups.json";
import GroupDetailsForm from "./components/GroupDetailsForm";
import {IConstant} from "../../../../models/IMeta";

export default function GroupCreate() {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups:create_group");
    const navigate = useNavigate();
    const {user} = useAuth();
    const [maxCredit, setMaxCredit] = useState<number | null>(null);

    const defaultLanguage = () => {
        if (['Enedis-Pilot', 'Enedis'].includes(user?.companyName!)) {
            return {id: 2, name: 'French'}
        } else {
            return {id: 1, name: 'English'}
        }
    }
    const defaultAiCoachType = {
        value: "ai_augmented",
        label: t("ai_coach_type.ai_augmented")
    };

    const initialFormValues: IGroupBasic = {
        name: "",
        credits: null,
        credit_limit_per_user: null,
        program: null,
        color_code: "",
        group_expiration: "",
        description: "",
        language: defaultLanguage(),
        ai_coach_type: defaultAiCoachType,
        renewal_cycle_frequency: null,
        cycle_startdate: "",
    };

    useEffect(() => {
        getCreditStats(user?.companyId!)
            .then((res) => {
                setMaxCredit((res.total_credits - res.group_assigned_credits) > 0 ? (res.total_credits - res.group_assigned_credits) : 2);
            })
            .catch((e) => {
                console.log("api error");
            });
    }, []);

    const _handleSubmit = async (
        values: FormikValues,
        actions: FormikHelpers<any>
    ) => {

        let updatedValues = values;
        const isFutureLaunchDate = new Date(values.launch_date) > new Date();
        if (isFutureLaunchDate) {
            updatedValues = {...values, active: false}
        }
        if (values.launch_date === "") {
            updatedValues.launch_date = moment().format('YYYY-MM-DD HH:mm:ss');
        }
        const cycleFreq = values.renewal_cycle_frequency as unknown as IConstant;
        if (cycleFreq) {
            updatedValues = {...updatedValues, renewal_cycle_frequency: cycleFreq.value};
        }
        try {
            const {id} = await createGroup(user!.companyId, {group: updatedValues});
            toast.success(t("create_group.text23"));
            navigate(`/dashboard/groups/${id}/edit`);
        } catch (e) {
            const err = e as AxiosError<{ message: string }>;
            const message = err.response?.data?.message ?? "Something went wrong";
            toast.error(message);
        }
    };

    const groupDetailsValidationSchema = (maxCredit: number | null) => Yup.object({
            name: Yup.string()
                .trim()
                .required(t("create_group.text46")),
            credits: Yup.lazy((value) => {
                return Yup.number().max(maxCredit || 1000, `Cannot be more than ${maxCredit} credits`).min(1, 'Credits must be more than 1')
                    .nullable().typeError(t("create_group.text47"))
            }),
            credit_limit_per_user: Yup.lazy((value) => {
                return Yup.number().nullable()
                    .typeError(t("create_group.text47"))
            }),
            program: Yup.object()
                .shape({
                    id: Yup.number().nullable(),
                    name: Yup.string().nullable(),
                    name_fr: Yup.string().nullable(),
                })
                .nullable()
                .default(null),
            group_expiration: Yup.string()
                .nullable()
                .default(null),
            color_code: Yup.string()
                .trim()
                .nullable(),
            description: Yup.string().nullable()
                .trim(),
            logo: Yup.mixed()
                .nullable()
                .default(null),
            language: Yup.object()
                .shape({
                    id: Yup.number().nullable(),
                    name: Yup.string().nullable(),
                    name_fr: Yup.string().nullable(),
                })
                .nullable()
                .default(null),
            ai_coach_type: Yup.object()
                .shape({
                    label: Yup.string().nullable(),
                    value: Yup.string().nullable(),
                })
                .nullable()
                .default(null),
            launch_date: Yup.string()
                .nullable()
                .default(null),
            renewal_cycle_frequency: Yup.object()
                .shape({
                    label: Yup.string().required(),
                    value: Yup.string().required()
                })
                .nullable()
                .default(null),
            cycle_startdate: Yup.lazy((value, context) => {
                const { renewal_cycle_frequency } = context.parent;
                if (renewal_cycle_frequency != null) {
                    return Yup.string()
                        .required(t("create_group.text68"))
                        .nullable();
                } else {
                    return Yup.string().nullable().notRequired();
                }
            }),
        }
    )
    return (
        <div className="bg-linen_gradient flex flex-col gap-4 p-6 h-full">
            <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={groupDetailsValidationSchema(maxCredit)}
                onSubmit={_handleSubmit}
            >
                <Form className="flex flex-col bg-white rounded gap-4 border-gray-50 border-[1px] pb-4">
                    <div
                        className="flex flex-row justify-center items-center w-full p-4 py-5 border-b-[2px] border-[#FD0054]">
                        <p className="font-bold text-black text-lg">
                            {t("create_group.text0")}
                        </p>
                    </div>
                    <GroupDetailsForm groupDetails={initialFormValues} editMode/>
                    <SubmitFormik
                        className="dashboard-button bg-black py-3 justify-center mx-10"
                        text={t("create_group.text41")}
                        onSubmittingText={t("create_group.text39")}
                    />
                </Form>
            </Formik>
        </div>
    );
}
