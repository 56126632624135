import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import DashboardProgress from "./DashboardProgress";
import Button from "../../../../../shared/components/Button";
import ArrowRight from "./ArrowRight";

import { EHomeDropdownAction, EAssessmentType } from "../../../../../constants/enums";
import { IProgressSchema, ISessionSchema } from "../../../../../models/IHome";
import storage from "../../../../../services/storage/localStorage";

import homeHeaderDummyDp from "../../../../../assets/img/icons/user.png";
import {THomeAssessmentData} from "../../../../../constants/types";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";

type Props = {
  progress: IProgressSchema;
  handleAction: (
    action: string | EHomeDropdownAction,
    session?: ISessionSchema,
    assessmentData?: THomeAssessmentData
  ) => void;
  latestSession?: ISessionSchema;
  assessmentData?: THomeAssessmentData
};

const Header: React.FC<Props> = ({ progress, handleAction, latestSession, assessmentData }) => {
  const { t } = useTranslation("home");
  const user = storage.get("user");
  const {config} = useConfig();

  const hasUpcomingSession = useMemo(
    () => progress.sessions.some((session) => session.upcoming),
    [progress]
  );

  const isReflectionState =
      (config?.self_reflection && progress.current_state === EAssessmentType.SelfReflection) ||
      (config?.flash_reflection && progress.current_state === EAssessmentType.Reflection360) ||
      (config?.impact_reflection && progress.current_state === EAssessmentType.ImpactReflection);

  const handleActionClick = () => {
    if (isReflectionState) {
      handleAction(EHomeDropdownAction.CompleteNow, undefined, assessmentData)
    } else {
      handleAction(EHomeDropdownAction.ScheduleNext, latestSession)
    }
  }

  return (
    <div className="flex flex-row bg-white rounded p-4 gap-4 border-gray-50 border-[1px]">
      <div className="flex flex-col w-full justify-center gap-6">
        <div className="flex flex-row items-center gap-2">
          <img
            src={user.image_url ? user.image_url : homeHeaderDummyDp}
            className="w-10 h-10 rounded-full object-contain"
            alt="profile"
          />
          <p className="font-bold font-dm_sans">
            {t("header.welcome")}, {user.firstName} {user.lastName}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold text-xs font-lexend">
            {t("header.program_completion")}
          </p>
          <DashboardProgress progress={progress} />
        </div>
      </div>
      <div className="flex flex-col justify-end w-max">
        <div className="relative flex justify-center items-center h-[43px] w-full">
          {!hasUpcomingSession && (
            <div className="z-10 absolute flex justify-center items-center w-[60px] h-[60px] right-[-30px] top-[-30px]">
              <div className="relative w-full h-full flex justify-center items-center">
                <div className="absolute z-30 flex justify-center items-center bg-[#f75c75] w-[30px] h-[30px] rounded-full opacity-90">
                  <div className="absoulte z-20 w-[15px] h-[15px] bg-[#ff9ab8] rounded-full flex justify-center items-center">
                    <div className="absolute z-40 rounded-full animate-ripple" />
                  </div>
                </div>
              </div>
            </div>
          )}
          <Button
            className="w-max h-[43px] flex items-center justify-center gap-2 text-xs px-5 py-2.5 
            border-2 border-red bg-red text-white font-bold"
            onClick={handleActionClick}
          >
            {isReflectionState ? t("header.complete") + " " + t(progress.current_state) : t("header.schedule_next_session")}
            <ArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
