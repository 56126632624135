import { GET, PUT } from "../../utils/lib/axios";

import { CLIENT } from "../../constants/endpoints/client";
import { userTimeZone } from "../../utils/helpers/time";

import {IProgressSchema, ISessionSchema} from "src/models/IHome";
import {IConfig} from "../../utils/providers/AppConfigProvider";

export const setSessionLimit = (clientId: number, groupId: number | null ,sessionLimit: number) => {
  return PUT(
    CLIENT.put.setSessionLimit,
    { session_limit: sessionLimit },
    { client_id: clientId },
    { group_id: groupId }
  ).then((response) => {
    return response.data;
  });
};

export const getCurrentProgress = async (): Promise<IProgressSchema> => {
  return GET(CLIENT.get.getCurrentProgress, undefined, {
    timezone: userTimeZone(),
  }).then((response) => {
    return response.data;
  });
};

export const getAppConfig = async (): Promise<IConfig> => {
  return GET(CLIENT.get.getAppConfig).then((response) => {
      return response.data;
  });
};

export const setClientPreference = async (data: Object) => {
  return PUT(CLIENT.put.setClientPreferences, data)
  .then((response) => {
    return response.data;
  });
}

export const getPreviousGroupSessions = async (): Promise<[ISessionSchema]> => {
  return GET(CLIENT.get.getPreviousGroupSessions, undefined, {
    timezone: userTimeZone(),
  }).then((response) => {
    return response.data;
  });
}

export const setImpactReflectionStatus = async (client_id: number, status: boolean) => {
  return PUT(CLIENT.put.setImpactReflectionStatus, {}, undefined, {client_id, status})
      .then((response) => {
        return response.data;
      });
}