import React from 'react';
import {AiOutlineClose} from "react-icons/ai";
import Button from "../Button";
import ReactModal from "react-modal";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    textMessage: string;
    handleConfirm: () => void;
    isLoading?: boolean
    prevButtonText: string;
    nextButtonText: string;
    loadingButtonText: string
}

const ConfirmationModal: React.FC<Props> = ({
                                                isOpen,
                                                handleClose,
                                                title,
                                                textMessage,
                                                handleConfirm,
                                                isLoading,
                                                prevButtonText,
                                                nextButtonText,
                                                loadingButtonText
                                            }) => {
    return <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        contentLabel="Enrolled User details"
        ariaHideApp={false}
        closeTimeoutMS={200}
        className="bg-white rounded-lg shadow-lg p-4 w-2/4 max-w-lg mx-auto transition-transform transform-gpu ease-out duration-200"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
        <>
            <div className="flex justify-between items-center mb-2 py-1">
                <div className="flex items-center gap-2">
                    <h2 className="text-sm font-semibold">{title}</h2>
                </div>
                <button onClick={handleClose}
                        className="text-gray-500 hover:text-gray-800 focus:outline-none">
                    <AiOutlineClose size={18}/>
                </button>
            </div>
            <p className="pb-6 text-sm">{textMessage}</p>
            <div className="relative flex flex-row justify-between w-full items-center gap-4">
                <Button
                    className="w-full flex justify-center text-black border-2 border-black text-xs font-bold h-7 px-4 hover:shadow-lg"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClose();
                    }}
                >
                    {prevButtonText}
                </Button>
                <Button
                    className="w-full flex justify-center text-white border-2 border-red bg-red text-xs font-bold h-7 px-4 hover:shadow-xl"
                    onClick={handleConfirm}
                    disabled={isLoading}
                >
                    {isLoading ? loadingButtonText : nextButtonText}
                </Button>
            </div>
        </>
    </ReactModal>
}

export default ConfirmationModal;