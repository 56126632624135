import { RefObject } from "react";

const HighlightedText = ({
  children,
  className,
  onClick,
  elRef,
  underline,
}: {
  elRef?: RefObject<HTMLSpanElement>;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  underline?: boolean;
}) => {
  return (
    <span
      ref={elRef}
      className={`font-dm_sans font-bold text-xs text-black inline ${onClick ? 'cursor-pointer' : ''} ${underline ? "underline" : ""} ${className}`}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default HighlightedText;
