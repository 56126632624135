import React, {useEffect, useState} from 'react';
import TemplateChartSection from "../../../../../shared/components/TemplateChartSection";
import CustomBarChart from "../../../../../shared/components/BarChart/CustomBarChart";
import {
    DUMMY_AI_COACH_USERS,
    DUMMY_COACHING_STATUS_DATA,
    DUMMY_LABEL_DATA,
    DUMMY_RATING_DATA,
    DUMMY_TOPIC_DATA
} from "../../../../../constants/data/dummyChartData";
import RingPieChart from "../../../../../shared/components/RingPieChart";
import ChartEmbeddedTable from "../../../../../shared/components/ChartEmbeddedTable";
import {useTranslation} from "react-i18next";
import {
    Filters,
    IAICoachRating,
    IAICoachUsers,
    IAISessionFrequency,
    IChartData,
    IChartEmbeddedTableData,
    ICoachingLabel,
    ICoachingStats,
    ICoachingTopics,
} from "../../../../../constants/types";
import {
    getAICoachLabels,
    getAICoachRating,
    getAICoachSessionFrequency,
    getAICoachTopics,
    getAICoachTypeStats,
    getAICoachUserStats
} from "../../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";
import {DataState, FrequencyType} from "../../../../../constants/enums";
import CustomRatingChart from "../../../../../shared/components/BarChart/CustomRatingChart";
import {useNavTitle} from "@hooks/useNavTitle";

const FREQUENCY_DATA_COUNT = 12;

const SummaryAI = ({...props}) => {
    const {t} = useTranslation("ai_usage");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {user} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTopics, setIsLoadingTopics] = useState(false);
    const [currentView, setCurrentView] = useState<FrequencyType>(FrequencyType.weekly);
    const [frequencyPagination, setFrequencyPagination] = useState(1);
    const [sessionFrequency, setSessionFrequency] = useState<IAISessionFrequency>({
        company_id: 0,
        frequencies: []
    });
    const [coachingStats, setCoachingStats] = useState<ICoachingStats>({
        flash_sessions: 0,
        leadership_path_sessions: 0,
        practice_sessions: 0,
        flash_percentage: 0,
        leadership_percentage: 0,
        practice_percentage: 0,
        total_sessions: 0
    });
    const [coachingTopics, setCoachingTopics] = useState<ICoachingTopics>({
        topics: []
    });
    const [coachAIUsers, setCoachAIUsers] = useState<IAICoachUsers>({
        company_id: 0,
        session_counts: [],
        summed_percentages: {}
    });
    const [coachAIRating, setCoachAIRating] = useState<IAICoachRating>({
        company_id: 0,
        counts_by_rating: [],
    });
    const [coachingLabels, setCoachingLabels] = useState<ICoachingLabel[]>([]);
    const [filters, setFilters] = useState<Filters>({});

    const ratingSessionCount = coachAIRating.counts_by_rating.reduce((sum, item) => sum + item.session_count, 0);

    const coachAIUserTableData = coachAIUsers.session_counts.length > 0
        ? coachAIUsers.session_counts : DUMMY_AI_COACH_USERS.session_counts;
    const coachAIUserChartData = Object.keys(coachAIUsers.summed_percentages).length !== 0
        ? coachAIUsers.summed_percentages : DUMMY_AI_COACH_USERS.summed_percentages;

    const transformedCoachTableData: IChartEmbeddedTableData[] = coachAIUserTableData.map((item) => ({
        firstColumn: item.session_count,
        secondColumn: item.user_count,
        thirdColumn: item.percentage,
    }));

    const transformedCoachChartData: IChartData[] = Object.entries(coachAIUserChartData).map(([label, percentage]) => ({
        label: t(`ai_coach_users.${label}`),
        percentage,
        color: '#B4E1C6'
    }));

    const allFreqeuncyCountsAreZero = (frequencies: { label: string; count: number }[]): boolean => {
        return frequencies.every(frequency => frequency.count === 0);
    };

    const expectedRatings = [1, 2, 3, 4, 5];

    const filledRatings = expectedRatings.map((rating) => {
        const existingRating = coachAIRating.counts_by_rating.find((item) => item.rating === rating);
        return {
            rating: rating,
            session_count: existingRating ? existingRating.session_count : 0
        };
    });

    const handleFrequency = (updatedPagination: number, frequencyType: FrequencyType) => {
        setIsLoading(true);
        getAICoachSessionFrequency(user?.companyId!, updatedPagination, frequencyType, FREQUENCY_DATA_COUNT)
            .then((res) => {
                if (res) {
                    setSessionFrequency(res);
                }
            }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setTimeout(() => setIsLoading(false), 500);
        });

    };

    useEffect(() => {
        handleFrequency(1, currentView);
        setIsLoadingTopics(true);
        getAICoachTypeStats(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setCoachingStats(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachTopics(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setCoachingTopics(res);
                }
            }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setIsLoadingTopics(false);
        });
        getAICoachUserStats(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setCoachAIUsers(res);
                }
            }).catch((e) => {
            console.error(e);
        });
        getAICoachRating(user?.companyId!, filters)
            .then((res) => {
                setCoachAIRating(res);
            }).catch((e) => {
            console.error(e);
        });
        getAICoachLabels(user?.companyId!, filters)
            .then((res) => {
                if (res) {
                    setCoachingLabels(res)
                }
            }).catch((e) => {
            console.error(e);
        });
    }, []);

    useEffect(() => {
        handleFrequency(1, currentView);
    }, [currentView]);

    return (
        <div className="container mx-auto">
            <div className="row-span-1 py-4">
                <TemplateChartSection
                    title={t("sessions_frequency.title")}
                    toolTip={currentView === FrequencyType.weekly ? t("sessions_frequency.toolTipForWeeks") : t("sessions_frequency.toolTipForMonths")}
                    isLoading={isLoading}
                    dataState={(allFreqeuncyCountsAreZero(sessionFrequency.frequencies) && frequencyPagination === 1) ? DataState.NO_DATA
                        : allFreqeuncyCountsAreZero(sessionFrequency.frequencies) ? DataState.NO_DATA_WITH_FILTERS : DataState.DATA}
                    titleBarChildren={
                        <div className="flex items-center justify-center px-5 gap-5">
                            <div className="inline-flex rounded-md shadow-sm" role="group">
                                <button onClick={() => setCurrentView(FrequencyType.weekly)}
                                        className={`btn w-20 h-8 sm:h-auto flex items-center justify-center sm:w-24 font-bold px-3 
                                    py-1 text-sm hover:border-red-500 hover:text-red-500 focus:border-red-500 
                                    focus:text-red-500 border-y-2 border-l-2 rounded-l-md ${currentView === FrequencyType.weekly
                                            ? "text-red-500 border-red-500" : "text-black border-black"}`}>
                                    {t("sessions_frequency.week")}
                                </button>
                                <button onClick={() => setCurrentView(FrequencyType.monthly)}
                                        className={`btn w-20 h-8 sm:h-auto flex items-center justify-center sm:w-24 font-bold px-3 
                                    py-1 text-sm hover:border-red-500 hover:text-red-500 focus:border-red-500 
                                    focus:text-red-500 border-2 rounded-r-md ${currentView === FrequencyType.monthly
                                            ? "text-red-500 border-red-500" : "text-black border-black"}`}>
                                    {t("sessions_frequency.month")}
                                </button>
                            </div>
                            <div className="flex gap-2">
                                <button onClick={() => {
                                    setFrequencyPagination((prev) => {
                                        const newPagination = prev + 1;
                                        handleFrequency(newPagination, currentView);
                                        return newPagination;
                                    })
                                }}
                                        disabled={isLoading}
                                        className="btn h-8 w-8 border-2 flex items-center justify-center font-bold
                                    text-black text-xl sm:text-2xl rounded-md border-black hover:border-red-500
                                    hover:text-red-500"><i className="fa fa-angle-left "/>
                                </button>
                                <button onClick={() => {
                                    setFrequencyPagination((prev) => {
                                        const newPagination = Math.max(prev - 1, 1);
                                        handleFrequency(newPagination, currentView);
                                        return newPagination;
                                    })
                                }}
                                        disabled={isLoading || frequencyPagination === 1}  // Disable for future dates
                                        className={`btn h-8 w-8 border-2 flex items-center justify-center font-bold text-xl 
                                    sm:text-2xl rounded-md ${frequencyPagination !== 1 ? "hover:border-red-500 " +
                                            "hover:text-red-500 text-black border-black " : "text-gray-200 border-gray-200"}`}>
                                    <i className="fa fa-angle-right "/>
                                </button>
                            </div>
                        </div>}
                >
                    <CustomBarChart
                        key="sessions_frequency"
                        isVertical={true}
                        verticalLabel
                        data={
                            sessionFrequency.frequencies.slice()
                                .reverse().map((dataItem) => {
                                return {
                                    label: dataItem.label,
                                    value: dataItem.count,
                                    color: "#B4E1C6"
                                }
                            })
                        }
                        yAxisTitle={t("sessions_frequency.y_title")}
                        tooltipDataKey={t("sessions_frequency.sessions")}
                    />
                </TemplateChartSection>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("coaching_status.title")}
                        toolTip={t("coaching_status.toolTip")}
                        dataState={coachingStats.total_sessions > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <RingPieChart
                            customLegendStyle="style2"
                            data={coachingStats.total_sessions > 0 ?
                                [
                                    {
                                        "label": t("coaching_status.flash_sessions"),
                                        "value": coachingStats.flash_sessions,
                                        "color": "rgb(172, 199, 232)"

                                    },
                                    {
                                        "label": t("coaching_status.leadership_path_sessions"),
                                        "value": coachingStats.leadership_path_sessions,
                                        "color": "#B4E1C6"
                                    },
                                    {
                                        "label": t("coaching_status.practice_sessions"),
                                        "value": coachingStats.practice_sessions,
                                        "color": "#FECBC4"
                                    }
                                ] : DUMMY_COACHING_STATUS_DATA.map((dummyItem) => {
                                        return {
                                            label: dummyItem.label,
                                            value: dummyItem.value,
                                            color: dummyItem.color
                                        }
                                    }
                                )}
                            showTitle={false}
                            paddingAngle={10}
                            innerRadius={55}
                            outerRadius={100}
                            // style2label={t("coaching_status.all_sessions")}
                            showPercentageLabel={true}
                            hideValueInLegend
                        />
                    </TemplateChartSection>
                </div>
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("topics_of_discussion.title")}
                        toolTip={t("topics_of_discussion.toolTip")}
                        dataState={coachingTopics.topics.length > 0 ? DataState.DATA : DataState.NO_DATA}
                        isLoading={isLoadingTopics}
                    >
                        <CustomBarChart
                            key="topics_of_discussion"
                            showIcon={false}
                            showTagLine={true}
                            range={[0, 100]}
                            showXPercentage
                            tooltipStyle="style2"
                            tooltipDataKey={t("topics_of_discussion.percentage")}
                            data={
                                coachingTopics.topics.length > 0 ? coachingTopics.topics.map((dataItem) => {
                                    return {
                                        label: dataItem.topic,
                                        value: dataItem.count,
                                        color: "rgb(172, 199, 232)"
                                    };
                                }) : DUMMY_TOPIC_DATA.map((dummyItem) => {
                                        return {
                                            label: dummyItem.label,
                                            value: dummyItem.value,
                                            color: "rgb(172, 199, 232)"
                                        }
                                    }
                                )
                            }
                        />
                    </TemplateChartSection>
                </div>
            </div>
            <div className="row-span-1 py-4">
                <TemplateChartSection
                    title={t("ai_coach_users.title")}
                    toolTip={t("ai_coach_users.toolTip")}
                    dataState={coachAIUsers.session_counts.length > 0 ? DataState.DATA : DataState.NO_DATA}
                >
                    <ChartEmbeddedTable
                        tableData={transformedCoachTableData}
                        chartData={transformedCoachChartData}
                        chartTitle={t("ai_coach_users.chart_title")}
                        labelTitle={t("ai_coach_users.label")}
                        countTitle={t("ai_coach_users.count")}
                    />
                </TemplateChartSection>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
                {ratingSessionCount >= 10 &&
                    <div className="col-span-1">
                        <TemplateChartSection
                            title={t("average_grade.title")}
                            toolTip={t("average_grade.toolTip")}
                            dataState={ratingSessionCount > 10 ? DataState.DATA : DataState.NO_DATA}
                        >
                            <CustomRatingChart
                                key="average_grade"
                                showTagLine={true}
                                tooltipDataKey={t("average_grade.session_count")}
                                data={
                                    ratingSessionCount > 10 ?
                                        (filledRatings.sort((a, b) => b.rating - a.rating).map((item) => {
                                            return {
                                                label: item.rating as unknown as string,
                                                value: item.session_count,
                                                color: '#B4E1C6',
                                                tooltipLabel: `Rating ${item.session_count as unknown as string}`
                                            };
                                        })) : (
                                            DUMMY_RATING_DATA.sort((a, b) => b.rating - a.rating).map((dummyItem) => {
                                                return {
                                                    label: dummyItem.rating as unknown as string,
                                                    value: dummyItem.session_count,
                                                    color: '#B4E1C6',
                                                    tooltipLabel: `Rating ${dummyItem.rating as unknown as string}`
                                                };
                                            })
                                        )
                                }
                            />
                        </TemplateChartSection>
                    </div>
                }
                <div className="col-span-1">
                    <TemplateChartSection
                        title={t("labels_used.title")}
                        toolTip={t("labels_used.toolTip")}
                        dataState={coachingLabels.length > 0 ? DataState.DATA : DataState.NO_DATA}
                    >
                        <CustomBarChart
                            key="labels_used"
                            showIcon={false}
                            showTagLine={true}
                            showXPercentage
                            range={[0, 100]}
                            tooltipDataKey={t("labels_used.percentage")}
                            tooltipStyle="style2"
                            data={coachingLabels.length > 0 ?
                                coachingLabels.map((item) => {
                                    return {
                                        label: item.label,
                                        value: item.count,
                                        color: "#D6CAB3"
                                    }
                                })
                                :
                                DUMMY_LABEL_DATA.map((dummyItem) => {
                                        return {
                                            label: dummyItem.label,
                                            value: dummyItem.value,
                                            color: "#D6CAB3"
                                        }
                                    }
                                )
                            }
                        />
                    </TemplateChartSection>
                </div>
                {user?.companyId === 170 &&
                    <div className="col-span-1">
                        <TemplateChartSection
                            title={t("job_level.title")}
                            toolTip={t("job_level.toolTip")}
                            dataState={DataState.DATA}
                        >
                            <RingPieChart
                                customLegendStyle="style1"
                                data={[
                                    {label: t("job_level.non_managerial"), value: 54.54, color: "#B4E1C6"},
                                    {label: t("job_level.senior_manager"), value: 27.27, color: "rgb(172, 199, 232)"},
                                    {label: t("job_level.manager"), value: 18.18, color: "#FFF3B6"},
                                ]}
                                showTitle={false}
                                paddingAngle={10}
                                innerRadius={55}
                                outerRadius={100}
                                showPercentageLabel={true}
                                layoutDirection="column"
                            />
                        </TemplateChartSection>
                    </div>
                }
            </div>
        </div>
    );
};

export default SummaryAI;
