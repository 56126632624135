import {TimePicker, Typography} from "antd";
import {Divider} from "rsuite";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import "../../CalanderSomeCss/Calander.css";
import {CheckCircleIcon} from "@heroicons/react/solid";
import dayjs from "dayjs";
import moment from "moment";
import {
    ACTION,
    BeforeAfterObjectState,
    CalanderModalContainerProps,
    currentComponentId
} from "../../Constants/utilities";
import {initValues} from "../../Constants/mainStore";
import {formatDate, formatMinit} from "../../Constants/ConstantsComponents";
import SubmitFormik from "../../../../../shared/components/formik/SubmitFormik/SubmitFormik";
import CustomInputFormik from "../../../../../shared/components/formik/InputFormik/CustomInputFormik";
import {useTranslation} from "react-i18next";
import {updateCoachBookingConfig} from "../../../../../services/api/coachApi";
import {userTimeZone} from "../../../../../utils/helpers/time";
import InformationIcon from "../../../../../shared/components/InformationIcon";
import ReactTooltip from "react-tooltip";

const Settings: React.FC<CalanderModalContainerProps> = ({
                                                             setIsOpen,
                                                             settingDispatch,
                                                             customSetting,
                                                             updateCalendar
                                                         }) => {
    const {t} = useTranslation("events");
    const [titleForBlocked, setTitleForBlocked] = useState<Array<any>>(initValues.weeklyDays);
    const [beforeTime, setBeforeTime] = useState<BeforeAfterObjectState>(initValues.beforeTime)
    const [afterTime, setAfterTime] = useState<BeforeAfterObjectState>(initValues.afterTime)
    const [currentKey, setCurrentKey] = useState<any>(currentComponentId);
    const [isIndefinitely, setIsIndefinitely] = useState(customSetting.indentifyInToTheFuture);
    const [isBlockTransparentEvents, setIsBlockTransparentEvents] = useState(customSetting.blockTransparentEvents);
    const timezone = userTimeZone();

    useEffect(() => {
        if (Object.keys(customSetting).length > 0) {
            setTitleForBlocked(customSetting.selectedTimeSlots);
            setBeforeTime(customSetting.beforeTime);
            setAfterTime(customSetting.afterTime);
            setIsBlockTransparentEvents(customSetting.blockTransparentEvents);
            initValues.timeZone = customSetting.timeZone;
            initValues.indentifyInToTheFuture = customSetting.indentifyInToTheFuture;
            initValues.daysOfFuture = customSetting.daysOfFuture;
        } else {
            cleanUp();
        }
    }, [customSetting])

    const mapInitValuesToBackendSchema = (values: any) => {
        const mapDay = (day: any) => {
            if (day.isTrue) {
                return `${day.start}-${day.end}`;
            }
            return null;
        };

        return {
            available_day_count: values.daysOfFuture,
            infinitely_available: values.indentifyInToTheFuture,
            config_timezone: timezone,
            monday: mapDay(values.selectedTimeSlots.find((day: any) => day.id === "monday")),
            tuesday: mapDay(values.selectedTimeSlots.find((day: any) => day.id === "tuesday")),
            wednesday: mapDay(values.selectedTimeSlots.find((day: any) => day.id === "wednesday")),
            thursday: mapDay(values.selectedTimeSlots.find((day: any) => day.id === "thursday")),
            friday: mapDay(values.selectedTimeSlots.find((day: any) => day.id === "friday")),
            saturday: mapDay(values.selectedTimeSlots.find((day: any) => day.id === "saturday")),
            sunday: mapDay(values.selectedTimeSlots.find((day: any) => day.id === "sunday")),
            pre_event_buffer: values.beforeTime.isTrue ? parseInt(values.beforeTime.time) : null,
            post_event_buffer: values.afterTime.isTrue ? parseInt(values.afterTime.time) : null,
            date_of_submitted: values.dateOfSubmitted,
            block_transparent_events: values.blockTransparentEvents
        };
    }

    const addCoachBookingConfig = async (data: any) => {
        try {
            const mappedData = mapInitValuesToBackendSchema(data);
            updateCoachBookingConfig(mappedData);
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    const ArrayValuesHandler = (id: string, changedKey: string, changedValue: any, mainObj: any) => {
        let itemIndex = 0;
        const tempArray = titleForBlocked.filter((obj, index) => {
            if (obj?.id === id) {
                itemIndex = index;
            }
            return obj?.id !== id
        });
        const temObj = {
            ...mainObj,
            [changedKey]: changedValue
        }
        if (changedKey === 'isTrue' && changedValue === true) {
            temObj.start = "06:00";
            temObj.end = "23:00";
        }
        if (changedKey === 'isTrue' && changedValue === false) {
            temObj.start = "";
            temObj.end = "";
        }
        tempArray.splice(itemIndex, 0, temObj);
        setTitleForBlocked(tempArray)
    }

    const handleBeforeAfter = (type: string, key: string, changedValue: any, exisitingObj: object) => {
        const temObj: any = {
            ...exisitingObj,
            [key]: changedValue,
        }

        if (type === 'after') {
            setAfterTime(temObj);
        } else if (type === 'before') {
            setBeforeTime(temObj)
        }
    }

    const handleSubmit = (values: any, action: any) => {
        const getReadyObject = {
            dateOfSubmitted: moment().format(),
            selectedTimeSlots: titleForBlocked,
            beforeTime: beforeTime,
            afterTime: afterTime,
            daysOfFuture: isIndefinitely ? null : values?.daysOfFuture,
            indentifyInToTheFuture: isIndefinitely,
            timeZone: timezone,
            blockTransparentEvents: isBlockTransparentEvents
        }

        settingDispatch({type: ACTION.ADD_NEW_SETTINGS, payLoad: {...getReadyObject}});
        action.setSubmitting(false);
        setIsOpen(false);
        setCurrentKey("");
        addCoachBookingConfig(getReadyObject);
        if (updateCalendar) {
            updateCalendar();
        }
    }

    const cleanUp = () => {
        setTitleForBlocked(initValues.weeklyDays);
        setBeforeTime(initValues.afterTime);
        setAfterTime(initValues.beforeTime);
        setIsBlockTransparentEvents(initValues.blockTransparentEvents);
        initValues.timeZone = "";
        initValues.indentifyInToTheFuture = true;
        initValues.daysOfFuture = "";
    }

    return (
        <div className="w-full">
            <div className=" flex justify-center">
                <h4>{t("calendar_settings.label")}</h4>
            </div>
            <Divider/>
            <Formik
                initialValues={initValues}
                validationSchema={!isIndefinitely ? Yup.object({
                    daysOfFuture: Yup.number().required(t("required")),
                }) : undefined}
                onSubmit={handleSubmit}
            >
                <Form>
                    <div className="w-full px-5">
                        <div>
                            <Typography className="font-bold">{t("calendar_settings.range")}</Typography>
                            <Typography className="pb-2">
                                {t("calendar_settings.range_description")}
                            </Typography>
                            <div>
                                <div className="items-center flex justify-between gap-4">
                                    <div>
                                        <div
                                            className="inline-block py-3 px-1.5 bg-white rounded-full whitespace-nowrap">
                  <span>
                    <input
                        onChange={(e) => setIsIndefinitely(false)}
                        type="radio"
                        id="noshow"
                        className="hidden peer"
                        checked={!isIndefinitely}
                    />
                    <label
                        htmlFor="noshow"
                        className="bg-white rounded-2xl py-1.5 px-3 select-none cursor-pointer peer-checked:bg-black peer-checked:text-white font-normal"
                    >
                      {t("calendar_settings.date_range")}
                    </label>
                  </span>
                                            <span>
                    <input
                        onChange={(e) => setIsIndefinitely(true)}
                        type="radio"
                        id="attended"
                        className="hidden peer"
                        checked={isIndefinitely}
                    />
                    <label
                        htmlFor="attended"
                        className="bg-white rounded-2xl py-1.5 px-3 select-none cursor-pointer peer-checked:bg-black peer-checked:text-white font-normal"
                    >
                      {t("calendar_settings.indefinitely")}
                    </label>
                  </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        {!isIndefinitely &&
                                            <div className="-mt-1">
                                                <CustomInputFormik
                                                    label=""
                                                    placeholder="Enter a number"
                                                    name="daysOfFuture"
                                                    className="p-3"
                                                    suffixTitle={t("calendar_settings.input_suffix")}
                                                />
                                            </div>}
                                        {isIndefinitely &&
                                            <div className="-mt-1">
                                                <CustomInputFormik
                                                    disabled={true}
                                                    value={t("calendar_settings.indefinitely")}
                                                    label=""
                                                    name="indentifyInToTheFuture"
                                                    className="p-3"
                                                    suffixTitle={t("calendar_settings.input_suffix")}
                                                />
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                        <div>
                            <Typography className="font-bold">{t("calendar_settings.set_weekly_hours")}</Typography>
                            <div className="flex items-center space-x-2 pt-2">
                                <Typography>{t("calendar_settings.time_zone")} : {timezone}</Typography>
                                <div className="relative group flex items-center">
                                    <InformationIcon/>
                                    <div
                                        className="realtive hidden group-hover:block bg-black text-white text-xs rounded-lg px-3 py-1 ">
                                        {t("calendar_settings.time_zone_info")}
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* CLICKABLE LOOP */}
                                <div className="mt-5 mb-5">
                                    {titleForBlocked.map((obj, key) => {
                                        return (
                                            <div key={obj.id}>
                                                <div className="grid grid-cols-5 py-3 pr-3" key={`${obj.id}${key}`}>
                                                    <div>
                                                        <div className="flex flex-row items-center pr-5">
                                                            <div className="px-2">
                                                                {/* IF TICKED */}
                                                                {/* OR */}
                                                                {/* IF NON TICKED */}
                                                                {obj?.isTrue ?
                                                                    <div className="relative" onClick={() => {
                                                                        ArrayValuesHandler(obj?.id, 'isTrue', false, obj);
                                                                    }}>
                                                                        <CheckCircleIcon
                                                                            className="text-red h-[17px] cursor-pointer"/>
                                                                        <span className="absolute inset-0 rounded-full bg-red opacity-0
                                      cursor-pointer hover:opacity-100 hover:animate-ping"/>
                                                                    </div> :
                                                                    <i className="fa fa-circle-thin cursor-pointer hover:text-red"
                                                                       aria-hidden="true" onClick={() => {
                                                                        ArrayValuesHandler(obj?.id, 'isTrue', true, obj);
                                                                    }}></i>
                                                                }
                                                            </div>
                                                            <div className="px-1">
                                                                <Typography
                                                                    className="font-bold">{obj.title}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {obj?.isTrue ?
                                                        <>
                                                            <div>
                                                                <TimePicker
                                                                    allowClear={false}
                                                                    className="w-2/3"
                                                                    format={"HH:mm"}
                                                                    suffixIcon={false}
                                                                    value={formatDate(obj?.start)}
                                                                    onChange={(time: any) => {
                                                                        if (!time) return;
                                                                        const timeSlot = moment(time["$d"]).format("HH:mm")
                                                                        ArrayValuesHandler(obj?.id, 'start', timeSlot, obj);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="flex justify-center columns-2">
                                                                <Typography className="font-bold">-</Typography>
                                                            </div>
                                                            <div>
                                                                <TimePicker
                                                                    allowClear={false}
                                                                    className="w-2/3"
                                                                    format={"HH:mm"}
                                                                    value={formatDate(obj.end)}
                                                                    suffixIcon={false}
                                                                    onChange={(time: any) => {
                                                                        if (!time) return;
                                                                        const timeSlot = moment(time["$d"]).format("HH:mm")
                                                                        ArrayValuesHandler(obj?.id, 'end', timeSlot, obj);
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="px-1">
                                                            <Typography
                                                                className="font-bold">{t("calendar_settings.unavailable")}</Typography>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="pb-4">
                            <Typography className="font-bold">
                                {t("calendar_settings.time_add_title")}
                            </Typography>
                            <div className="w-2/3">
                                <div className="grid grid-cols-2 gap-3 columns-auto py-3 pr-3 align-middle">
                                    <div>
                                        <div className="flex flex-row items-center pr-5 text-center">
                                            <div className="px-2">
                                                {/* IF TICKED */}
                                                {/* OR */}
                                                {/* IF NON TICKED */}
                                                {beforeTime.isTrue ?
                                                    <div className="relative" onClick={() => {
                                                        handleBeforeAfter('before', 'isTrue', false, beforeTime)
                                                    }}>
                                                        <CheckCircleIcon className="text-red h-[17px] cursor-pointer"/>
                                                        <span className="absolute inset-0 rounded-full bg-red opacity-0
                                        cursor-pointer hover:opacity-100 hover:animate-ping"/>
                                                    </div>
                                                    :
                                                    <i className="fa fa-circle-thin cursor-pointer hover:text-red"
                                                       aria-hidden="true" onClick={() => {
                                                        handleBeforeAfter('before', 'isTrue', true, beforeTime)
                                                    }}></i>
                                                }

                                            </div>
                                            <div className="flex flex-row">
                                                <Typography
                                                    className="font-bold">{t("calendar_settings.before_time")}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-3 flex flex-row items-center">
                                        <TimePicker
                                            allowClear={false}
                                            className="w-2/3"
                                            defaultValue={dayjs(`${moment().hours()}:${moment().minutes()}`, "mm")}
                                            format={"mm"}
                                            suffixIcon={false}
                                            value={formatMinit(beforeTime?.time)}
                                            onChange={(time: any) => {
                                                if (!time) return;
                                                const timeSlot = moment(time["$d"]).format("mm")
                                                handleBeforeAfter('before', 'time', timeSlot, beforeTime)
                                            }}
                                        />
                                        <span className="px-3">min</span>
                                    </div>
                                    <div>
                                        <div className="flex flex-row items-center pr-5 text-center">
                                            <div className="px-2 ">
                                                {/* IF TICKED */}
                                                {/* OR */}
                                                {/* IF NON TICKED */}
                                                {afterTime.isTrue ?
                                                    <div className="relative" onClick={() => {
                                                        handleBeforeAfter('after', 'isTrue', false, afterTime)
                                                    }}>
                                                        <CheckCircleIcon className="text-red h-[17px] cursor-pointer"/>
                                                        <span className="absolute inset-0 rounded-full bg-red opacity-0
                                      cursor-pointer hover:opacity-100 hover:animate-ping"/>
                                                    </div>
                                                    :
                                                    <i className="fa fa-circle-thin hover:text-red cursor-pointer"
                                                       aria-hidden="true"
                                                       onClick={() => {
                                                           handleBeforeAfter('after', 'isTrue', true, afterTime)
                                                       }}></i>
                                                }
                                            </div>
                                            <div className="px-1">
                                                <Typography
                                                    className="font-bold">{t("calendar_settings.after_time")}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-3 flex flex-row items-center">
                                        <TimePicker
                                            allowClear={false}
                                            className="w-2/3"
                                            defaultValue={dayjs(`${moment().hours()}:${moment().minutes()}`, "mm")}
                                            format={"mm"}
                                            value={formatMinit(afterTime?.time)}
                                            suffixIcon={false}
                                            onChange={(time: any) => {
                                                if (!time) return;
                                                const timeSlot = moment(time["$d"]).format("mm")
                                                handleBeforeAfter('after', 'time', timeSlot, afterTime)
                                            }}
                                        />
                                        <span className="px-3">min</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pb-4  mb-3">
                            <div className="flex items-center space-x-4">
                                <Typography className="font-bold">
                                    <div className="flex gap-1 items-center">
                                        {t("calendar_settings.transparent_events")}
                                        <InformationIcon
                                            data-for={'transparent_events'}
                                            data-tip={"hover on me will keep the tooltip"}
                                            className={"w-4 h-4"}/>
                                        <ReactTooltip
                                            className="w-1/2"
                                            delayHide={500}
                                            id={'transparent_events'}
                                            place="right"
                                            effect="solid"
                                        >
                                            <span> {t("calendar_settings.transparent_events_info")} </span>
                                        </ReactTooltip>
                                    </div>
                                </Typography>

                                <div className="flex gap-4">
                                    <div className="inline-flex items-center">
                                        <label className="relative flex items-center cursor-pointer"
                                               htmlFor="block_transparent_events_yes">
                                            <input
                                                type="radio"
                                                name="blockTransparentEvents"
                                                checked={isBlockTransparentEvents === true}
                                                onChange={() => setIsBlockTransparentEvents(true)}
                                                className="peer h-4 w-4 cursor-pointer appearance-none rounded-full border border-red checked:border-red transition-all"
                                                id="block_transparent_events_yes"
                                            />
                                            <span
                                                className="absolute bg-red w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
                                        </label>
                                        <label className="ml-2 text-slate-600 cursor-pointer text-sm"
                                               htmlFor="block_transparent_events_yes">{t("calendar_settings.yes")}</label>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <label className="relative flex items-center cursor-pointer"
                                               htmlFor="block_transparent_events_no">
                                            <input
                                                type="radio"
                                                name="blockTransparentEvents"
                                                checked={isBlockTransparentEvents === false}
                                                onChange={() => setIsBlockTransparentEvents(false)}
                                                className="peer h-4 w-4 cursor-pointer appearance-none rounded-full border border-red checked:border-red transition-all"
                                                id="block_transparent_events_no"
                                            />
                                            <span
                                                className="absolute bg-red w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
                                        </label>
                                        <label className="ml-2 text-slate-600 cursor-pointer text-sm"
                                               htmlFor="block_transparent_events_no">{t("calendar_settings.no")}</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                            <SubmitFormik
                                className="dashboard-button w-full bg-black py-3 justify-center"
                                text={t("applyBtn")}
                                onSubmittingText="Updating..."
                            />
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export default Settings;
