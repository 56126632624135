import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import img_action_plan from "./../../../../../assets/img/aiCoaching/Individual-coaching.png";

import {useAICoaching} from "../utils/AICoachingContext";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";
import {ReferenceItem, SummaryData} from "../../AICoaching/types";
import {getRecommendations, getSummary} from "../actions";
import Loader from "../../AICoaching/components/Loader";
import {NoDataWrapperArticles} from "../../Resources/AIResources/AIResources";
import InfoCard from "../../../../../shared/components/InfoCard/InfoCard";
import {CgClose} from "react-icons/cg";

type SummaryProps = {
    threadID?: number
    summaryData?: SummaryData
    // viewConversation
}

function Summary({
                     threadID,
                     summaryData
                 }: SummaryProps) {

    const {t} = useTranslation("ai_coaching");
    const {config} = useConfig();
    const {state, setState, selectedThreadItem} = useAICoaching();

    let getSummaryAbortController = new AbortController();
    let getReferenceMaterialsAbortController = new AbortController();

    const isAIGeneratedMaterial = config?.ai_generated_materials;
    // const isAIGeneratedMaterial = true;

    const [isSummaryLoading, setIsSummaryLoading] = useState(false)
    const [isAIMaterialsLoading, setIsAIMaterialsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [summary, setSummary] = useState<string | undefined>(undefined);
    const [actionPlanSteps, setActionPlanSteps] = useState<string[]>()
    const [refMaterialsArray, setRefMaterialsArray] = useState<ReferenceItem[]>([]);

    useEffect(() => {
        resetAll();
        if (!threadID) return
        setIsSummaryLoading(true)
        if (summaryData) {
            setSummary(summaryData.summary);
            setActionPlanSteps(summaryData.actions);
            setIsSummaryLoading(false)
            return;
        } else {
            getSummary(threadID, getSummaryAbortController)
                .then((data) => {
                    if (data) {
                        setSummary(data.summary);
                        setActionPlanSteps(data.actions);
                    }
                })
                .finally(() => {
                    setIsSummaryLoading(false)
                    fetchReferenceMaterials(threadID);
                });
        }
    }, [threadID]);


    const fetchReferenceMaterials = async (id: number) => {
        setIsError(false);
        setIsAIMaterialsLoading(true)
        try {
            if (config?.ai_generated_materials === false) return;
            const materials = await getRecommendations(id, getReferenceMaterialsAbortController);
            if (materials) {
                const selectedMaterials = [];
                if (materials.articles.length > 0) selectedMaterials.push({
                    ...materials.articles[0],
                    media_type: 'article'
                });
                if (materials.youtubes.length > 0) selectedMaterials.push({
                    ...materials.youtubes[0],
                    media_type: 'video'
                });
                if (materials.podcasts.length > 0) selectedMaterials.push({
                    ...materials.podcasts[0],
                    media_type: 'podcast'
                });
                setRefMaterialsArray(selectedMaterials);
            }
        } catch (error) {
            console.error("Failed to fetch reference materials:", error);
            setIsError(true);
        } finally {
            setIsAIMaterialsLoading(false)
            console.log(isError)
        }
    };

    const abortNetworkCalls = () => {
        getSummaryAbortController.abort();
        getReferenceMaterialsAbortController.abort()
        getSummaryAbortController = new AbortController();
        getReferenceMaterialsAbortController = new AbortController()
    };

    const resetAll = () => {
        abortNetworkCalls();
        setIsSummaryLoading(false);
        setIsAIMaterialsLoading(false);
        setSummary(undefined);
        setActionPlanSteps([])
        setRefMaterialsArray([])
    };

    return (
        <div
            className="flex flex-col rounded w-full h-full relative gap-4 p-5 bg-white text-black text-sm overflow-auto">
            <div className="flex flex-row items-center justify-between">
                <p className="font-bold text-base">{selectedThreadItem && selectedThreadItem?.title ? selectedThreadItem.title : t("summary.title")}</p>
                <button className="rounded p-1 border hover:bg-red ml-auto relative flex items-center justify-center"
                        onClick={() => setState('thread')}><CgClose
                    className="w-5 h-5 relative"/>
                </button>
            </div>
            <hr className="bg-[#F2EBE3] m-0"/>
            {
                isSummaryLoading &&
                <div className="flex justify-center items-center">
                    <Loader isFullscreen={false} spinnerColor="red"/>
                </div>
            }
            {!summary && <p>{t("summary.not_available")}</p>}
            {summary && (
                <div className="w-full text-left font-normal rounded-xl">
                    <p>{summary}</p>
                </div>
            )}
            {actionPlanSteps &&
                actionPlanSteps.length !== undefined &&
                actionPlanSteps.length > 0 && (
                    <div className="flex flex-row items-center justify-start gap-5 bg-[#FCF4EA] rounded-lg p-6">
                        <img
                            src={img_action_plan}
                            className="h-32 w-auto"
                            alt="action plan"
                        />
                        <div>
                            <p className="font-bold text-sm py-3">{t("summary.action_plan")}</p>
                            <ul className="px-4 list-disc text-left">
                                {actionPlanSteps.map((step) => (
                                    <li>{step}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}

            {isAIGeneratedMaterial &&
                <div className="bg-[#FCF4EA] rounded-lg p-6">
                    <p className="font-bold text-sm pb-3">{t("summary.references")}</p>
                    <div className="flex w-full overflow-x-auto pb-2">
                        {!(refMaterialsArray?.length > 0) && !isAIMaterialsLoading &&
                            <div className="flex justify-center items-center w-full">
                                <NoDataWrapperArticles/>
                            </div>}
                        {
                            !isError && isAIMaterialsLoading &&
                            <div className="flex justify-center items-center">
                                <Loader isFullscreen={false} spinnerColor="red"/>
                            </div>
                        }
                        <div className="flex gap-4 justify-start">
                            {
                                refMaterialsArray.map((item, index) => (
                                    <div className="w-[30vh]">
                                        <InfoCard
                                            title={item.title}
                                            imageUrl={item.thumbnail}
                                            linkUrl={item.url}
                                            type={item.media_type}
                                            className={{font: "text-xs font-bold", img: "h-[15vh] object-cover w-full"}}
                                            contentId={item.id}
                                            placeTagAbove={true}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Summary
