import React from 'react';
import {AiOutlineClose} from "react-icons/ai";
import ReactModal from "react-modal";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import {useTranslation} from "react-i18next";
import {ValidationErrorSchema} from "../../../../../constants/types";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    validationErrors: ValidationErrorSchema[];
}

const ValidationErrorModal: React.FC<Props> = ({isOpen, onClose, validationErrors}) => {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups:create_group");

    return <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Validation Errors"
        ariaHideApp={false}
        closeTimeoutMS={200}
        className="bg-warm rounded-lg shadow-lg p-4 max-w-2xl mx-auto transition-transform transform-gpu ease-out duration-200"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
        <>
            <div className="flex justify-between items-center mb-2">
                <div className="flex items-center gap-2">
                    <h2 className="text-sm text-red font-semibold">{t("create_group.text65")} !</h2>
                </div>
                <button onClick={onClose}
                        className="text-gray-500 hover:text-gray-800 focus:outline-none">
                    <AiOutlineClose size={18}/>
                </button>
            </div>
            <p className="pb-4">{t("create_group.text63")}</p>
            <div className="overflow-y-auto" style={{maxHeight: '60vh'}}>
                <table
                    className="min-w-full bg-white border border-gray-200 text-xs text-gray-700 text-center">
                    <thead className="bg-warm-light">
                    <tr>
                        <th className="py-2 px-4 border-b">{t("create_group.page")}</th>
                        <th className="py-2 px-4 border-b">{t("create_group.field")}</th>
                        <th className="py-2 px-4 border-b">{t("create_group.error")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {validationErrors.map((error, index) => (
                        <tr key={index} className="hover:bg-gray-50 transition duration-150">
                            <td className="py-2 px-4 border-b">{Math.ceil(error.row / 10)}</td>
                            <td className="py-2 px-4 border-b">{`${Object.keys(error.error).map((key) => t(`create_group.${key}`)).join(", ")}`}</td>
                            <td className="py-2 px-4 border-b">{Object.values(error.error).join(", ")}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <p className="py-4">{t("create_group.text64")}</p>
        </>
    </ReactModal>
}

export default ValidationErrorModal;