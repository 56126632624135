import React, {useState} from "react";
import {Typography} from "antd";
import {Form, Formik} from "formik";
import TimeSlotsFrequency from "../Components/TimeSlotFrequency";
import {useTranslation} from "react-i18next";
import SubmitFormik from "../../../../../shared/components/formik/SubmitFormik/SubmitFormik";
import moment from "moment";

const RescheduleEvent = (params: any) => {
    const {t} = useTranslation("events");
    const [isChecked, setIsChecked] = useState(false);
    const isRescheduling = params.rescheduleEventDetails.rescheduleEvent;
    const userEventDetails = params.rescheduleEventDetails.rescheduleDetails;

    const handleChecked = () => {
        setIsChecked(!isChecked);
    }

    return (
        <>
            <div className="w-full">
                <div className="p-5 flex items-center gap-2">
                    <input
                        type="checkbox"
                        className="w-4 h-4 accent-red cursor-pointer"
                        onChange={handleChecked}
                        checked={isChecked}
                    />
                    <Typography className="py-1">
                        {t("reschedule_event.consent_message")}
                    </Typography>
                </div>
                <Formik
                    initialValues={
                        {
                            date: moment(params.date),
                            startTime: moment(params.startTime),
                            endTime: moment(params.endTime),
                        }
                    }
                    onSubmit={(values, action) => {
                        const adjustedDate = values.date
                        const adjustedStartTime = values.startTime
                        const adjustedEndTime = values.endTime
                        params._handleSubmit({
                            ...params.rescheduleEventDetails.rescheduleDetails,
                            isRescheduling,
                            date: adjustedDate,
                            startTime: adjustedStartTime,
                            endTime: adjustedEndTime,
                            action,
                        })
                    }}
                >
                    {({setFieldValue}) => (
                        <Form className="px-5">
                            <div className="w-full flex flex-col gap-6 pb-4">
                                <div>
                                    <Typography className="font-bold">
                                        {t("book_behalf.client")}
                                    </Typography>
                                    <div>
                                        <Typography className="py-1">
                                            {`${userEventDetails.client_first_name} ${userEventDetails.client_last_name}`}
                                        </Typography>
                                    </div>
                                </div>
                                <div>
                                    <Typography className="font-bold">
                                        {t("book_behalf.subject_remarks")}
                                    </Typography>
                                    <Typography className="py-1">
                                        {userEventDetails.coach_note}
                                    </Typography>
                                </div>
                                <TimeSlotsFrequency
                                    title={t("book_behalf.blocked_frequency")}
                                    params={params}
                                    setFieldValue={setFieldValue}
                                    bookOnBehalf
                                    isDisabled={!isChecked}
                                />
                                <div>
                                    <SubmitFormik
                                        className={`${isChecked ? "dashboard-button" : "rounded-md"} w-full bg-black py-3 justify-center`}
                                        text={t("applyBtn")}
                                        onSubmittingText="Updating..."
                                        disabled={!isChecked}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default RescheduleEvent;
