import React, {useEffect, useState} from 'react';
import {PencilAltIcon} from "@heroicons/react/solid";
import {CheckIcon, ChevronDownIcon, ChevronUpIcon, TrashIcon, UserGroupIcon, XIcon} from "@heroicons/react/outline";
import Select, {StylesConfig} from "react-select";
import {ICompanySettings, ISettingSchema, NoSettingGroup} from "../../../../../constants/types";
import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import SettingENG from "../../../locale/eng/setting.json";
import SettingFR from "../../../locale/fr/setting.json";
import ConfirmationModal from "../../../../../shared/components/ConfirmationModal/ConfirmationModal";
import InformationIcon from "../../../../../shared/components/InformationIcon";
import ReactTooltip from "react-tooltip";
import SettingOptionInput from "./SettingOptionInput";
import {AiOutlineSetting} from "react-icons/ai";
import RadioSettingInput from "./RadioSettingInput";

export const colourStyles: StylesConfig<any, true> = {
    control: (styles) => ({
        ...styles, backgroundColor: 'white', minHeight: '30px',
        height: 'auto', fontSize: 14
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: '0 8px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
    }),
    input: (styles) => ({
        ...styles,
        margin: '0',
        padding: '0',
        height: '30px',
    }),
    singleValue: (styles) => ({
        ...styles,
        fontSize: 15,
        fontWeight: 400,
        color: "#4B5563"
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? 'rgba(231,93,93,0.84)'
                    : isFocused
                        ? 'rgba(231,93,93,0.35)'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'white'
                    : 'black'
            ,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? 'rgba(203,61,61,0.84)'
                        : 'rgba(196,67,67,0.84)'
                    : undefined,
            },
            fontSize: 15,
            fontWeight: 400,
        };
    },
    multiValue: (styles, {data}) => {
        return {
            ...styles,
            backgroundColor: 'rgba(255, 86, 48, 0.1)',
        };
    },
    multiValueLabel: (styles, {data}) => ({
        ...styles,
        color: 'rgb(0,0,0)',
    }),
    multiValueRemove: (styles, {data}) => ({
        ...styles,
        color: 'rgb(255, 86, 48)',
        ':hover': {
            backgroundColor: 'rgba(231,93,93,0.84)',
            color: 'white',
        },
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '4px',
        svg: {
            width: '16px',
            height: '16px',
        },
    }),
    clearIndicator: (styles) => ({
        ...styles,
        padding: '4px',
        svg: {
            width: '16px',
            height: '16px',
        },
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        height: '30px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 10
    }),
};

export const selectOptionSettings = [
    "orientation_call_duration",
    "session_duration",
    "default_language"]

export const multiSelectOptionSettings = [
    "session_duration_variations",
    "skipped_timebounded_slugs",
    "skipped_ai_timebounded_slugs"]

export const creatableSelectOptionSettings = [
    "session_duration_variations"
]

interface Props {
    companySetting: ICompanySettings;
    handleUpdateCompanySettings: (settings: ISettingSchema) => void;
    handleUpdateGroupSettings: (groupId: number, settings: ISettingSchema) => void;
    handleDeleteGroupSetting: (groupId: number, settingId: number) => void;
    isDeleting?: boolean;
    isUpdating?: boolean;
    toggleSetting: (id: number) => void;
    isExpanded?: boolean;
    readOnly?: boolean;
}

const SettingCard: React.FC<Props> = ({
                                          companySetting,
                                          handleUpdateCompanySettings,
                                          handleUpdateGroupSettings,
                                          handleDeleteGroupSetting,
                                          isDeleting,
                                          isUpdating,
                                          toggleSetting,
                                          isExpanded,
                                          readOnly
                                      }) => {
    const {t} = useTranslation("setting");
    i18n.addResourceBundle("en", "setting", SettingENG);
    i18n.addResourceBundle("fr", "setting", SettingFR);
    const lng = localStorage.getItem("I18N_LANGUAGE") || 'en';
    const [isEditing, setIsEditing] = useState(false);
    const [editingGroupId, setEditingGroupId] = useState<number | null>(null);
    const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<NoSettingGroup[]>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
    const [updatedCompanySettingValue, setUpdatedCompanySettingValue] = useState(companySetting.value);
    const [newGroupSetting, setNewGroupSetting] = useState("");
    const [updatedGroupSettingValue, setUpdatedGroupSettingValue] = useState("");
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const [deletingGroupId, setDeletingGroupId] = useState<number | null>(null);
    const [setting, setSetting] = useState<ICompanySettings>(companySetting);

    const handleDefaultCompanyValue = () => {
        let parsedValue = [];
        try {
            if (companySetting.value && companySetting.value.startsWith("[") && companySetting.value.endsWith("]")) {
                parsedValue = JSON.parse(companySetting.value);
            } else {
                parsedValue = [companySetting.value];
            }
        } catch (e) {
            parsedValue = [companySetting.value];
        }

        if (multiSelectOptionSettings.includes(companySetting.key)) {
            return parsedValue.map((val: string) => ({label: val, value: val}));
        } else {
            return [
                {
                    label: companySetting.type === "bool" ? companySetting.value === "1" ? t("yes") : t("no") : companySetting.value,
                    value: companySetting.value
                }
            ];
        }
    };

    const handleDefaultGroupValue = () => {
        const initialValues: Record<number, any[]> = {};
        companySetting.groups.forEach((group) => {
            let parsedValue = [];
            try {
                if (group.value && group.value.startsWith("[") && group.value.endsWith("]")) {
                    parsedValue = JSON.parse(group.value);
                } else {
                    parsedValue = [group.value];
                }
            } catch (e) {
                parsedValue = [group.value];
            }

            initialValues[group.group_id] = multiSelectOptionSettings.includes(companySetting.key)
                ? parsedValue.map((val: string) => ({label: val, value: val}))
                : [{
                    label: companySetting.type === "bool" ? group.value === "1" ? t("yes") : t("no") : group.value,
                    value: group.value,
                },
                ];
        });
        return initialValues;
    };

    const [selectedValue, setSelectedValue] = useState<any[]>(handleDefaultCompanyValue());
    const [selectedGroupValue, setSelectedGroupValue] = useState<Record<number, any[]>>(handleDefaultGroupValue());

    const SettingTitle = lng === "en" ? companySetting.title_en : companySetting.title_fr;

    const handleChangeCompanyValue = () => {
        setIsEditing(!isEditing);
        setEditingGroupId(null);
    }

    const handleEditGroupValue = (groupId: number) => {
        if (editingGroupId === groupId) {
            setEditingGroupId(null);
        } else {
            setEditingGroupId(groupId);
        }
    };

    const handleUpdateGroupSetting = (groupId: number) => {
        let settingValue = updatedGroupSettingValue
        if (Object.keys(selectedGroupValue).length !== 0) {
            const updatedValue = Object.values(selectedGroupValue[groupId]).map((item) => {
                return item
            });
            settingValue = getSettingValue(updatedGroupSettingValue, updatedValue);
        }

        const updatedGroups = companySetting.groups.map((group) =>
            group.group_id === groupId ? { ...group, value: settingValue } : group
        );
        setSetting((prev) => ({ ...prev, groups: updatedGroups }));

        handleUpdateGroupSettings(groupId, {setting_id: companySetting.id, value: settingValue});
        setUpdatedGroupSettingValue("");
        setEditingGroupId(null);
    }

    const handleAddNewGroup = () => {
        setIsAddingNewGroup(!isAddingNewGroup);
        setSelectedGroup([]);
    }

    const getSettingValue = (updatedSettingValue: string, updatedValue: any[]) => {
        let settingValue = updatedSettingValue;
        if (updatedValue[1] !== undefined) {
            settingValue = updatedValue[1]
        }
        if (multiSelectOptionSettings.includes(companySetting.key)) {
            settingValue = JSON.stringify(updatedValue.map((item) => item.value));
        }
        return settingValue;
    }

    const handleClickSetting = () => {
        const updatedValue = Object.values(selectedValue).map((item) => {
            return item
        });
        setIsEditing(!isEditing);
        const settingValue = getSettingValue(updatedCompanySettingValue, updatedValue);
        handleUpdateCompanySettings({setting_id: companySetting.id, value: settingValue});
    }

    const handleClickGroupSetting = () => {
        const groupId = Object.values(selectedGroup)[0] as unknown as number;
        const groupName = Object.values(selectedGroup)[1] as unknown as string;
        let settingValue = newGroupSetting
        if (companySetting.type === "bool") {
            settingValue = newGroupSetting
        } else if (Object.keys(selectedGroupValue).length !== 0) {
            const updatedValue = Object.values(selectedGroupValue[groupId]).map((item) => {
                return item
            });
            settingValue = getSettingValue(newGroupSetting, updatedValue);
        }

        const updatedGroups = companySetting.groups;
        updatedGroups.push({ group_id: groupId, group_name: groupName, value: settingValue });
        setSetting((prev) => ({ ...prev, groups: updatedGroups }));

        handleUpdateGroupSettings(groupId, {setting_id: companySetting.id, value: settingValue});
        setIsAddingNewGroup(!isAddingNewGroup);
        setSelectedGroup([]);
        setNewGroupSetting("");
    }

    useEffect(() => {
        const newGroup = Object.values(selectedGroup).map((item) => {
            return item;
        });
        setSelectedGroupId(newGroup[0] as unknown as number);
    }, [selectedGroup]);

    const handleBoolSettingValues = (value: boolean, groupId?: number | null) => {
        if (isAddingNewGroup) {
            setIsOpenConfirmModal(true);
            setNewGroupSetting(value ? "1" : "0");
        } else if (groupId !== undefined && groupId !== null) {
            setUpdatedGroupSettingValue(value ? "1" : "0");
            setIsOpenConfirmModal(true);
        } else {
            setUpdatedCompanySettingValue(value ? "1" : "0");
            setIsOpenConfirmModal(true);
        }
    };

    const handleDeleteSetting = () => {
        if (deletingGroupId) {
            const updatedGroups = companySetting.groups.filter(
                (group) => group.group_id !== deletingGroupId
            );
            setSetting((prev) => ({ ...prev, groups: updatedGroups }));
            handleDeleteGroupSetting(deletingGroupId, companySetting.id);
        }
        setIsOpenDeleteModal(false);
    }

    const handleClose = () => {
        setIsOpenConfirmModal(false);
        setUpdatedCompanySettingValue(companySetting.value);
        setUpdatedGroupSettingValue("");
        setSetting(companySetting);
        setEditingGroupId(null);
    }

    return <div className="bg-white rounded-lg border border-gray-100 mb-2">
        <div className="px-3 py-3 flex items-center gap-3 justify-between cursor-pointer"
             onClick={() => toggleSetting(companySetting.id)}>
            <div className="flex items-center gap-1">
                <div
                    className="flex items-center justify-center shrink-0"><AiOutlineSetting className=" w-4 h-4 "/>
                </div>
                <p className=" text-gray-700 font-medium">
                    {SettingTitle || t(`${companySetting.key}`)}
                </p>
                <div className="flex flex-row items-center">
                    <InformationIcon
                        data-for={companySetting.key}
                        data-tip={"setting description"}
                        className="h-4 w-4"
                    />
                    {(lng === "en" && companySetting.description_en) || (lng === "fr" && companySetting.description_fr) ? (
                        <ReactTooltip
                            className="w-96 z-50 bg-black backdrop-opacity-100 whitespace-pre-line"
                            delayHide={500}
                            id={companySetting.key}
                            place="top"
                            effect="solid"
                            html={true}
                        >
                            {lng === "en" ? companySetting.description_en : companySetting.description_fr}
                        </ReactTooltip>
                    ) : null}
                </div>
            </div>
            {isExpanded ? <ChevronUpIcon className="cursor-pointer text-gray-300 px-3 font-bold h-5 shrink-0"/> :
                <ChevronDownIcon className="cursor-pointer text-gray-300 px-3 font-bold h-5 shrink-0"/>}
        </div>
        {isExpanded &&
            <div className="border-t border-t-gray-200">
                <div className="space-y-6">
                    <div className="px-3">
                        <div className="grid grid-cols-2 gap-4 justify-center items-center bg-white py-3 border-b">
                            <p className="text-sm text-gray-600 font-normal">{t("company_default_value")}</p>
                            <div className="flex gap-2">
                                <div className="w-5/12">
                                    {companySetting.type === "bool" ?
                                        <RadioSettingInput
                                            handleChange={handleBoolSettingValues}
                                            trueChecked={updatedCompanySettingValue === "1"}
                                            falseChecked={updatedCompanySettingValue === "0"}
                                            setIsEditing={setIsEditing}
                                            settingKey={companySetting.key}
                                            readOnly={readOnly}
                                        />
                                        :
                                        <SettingOptionInput
                                            isEditing={isEditing}
                                            companySetting={companySetting}
                                            setSelectedValue={setSelectedValue}
                                            selectedValue={selectedValue}
                                            setSettingValue={setUpdatedCompanySettingValue}
                                            value={companySetting.value}
                                        />}
                                </div>
                                {companySetting.type !== "bool" && !readOnly &&
                                    <div className="flex justify-start items-center col-span-3">
                                        {isEditing ? (
                                            <>
                                                <CheckIcon
                                                    className="cursor-pointer text-gray-300 font-bold px-1 h-5 shrink-0 hover:text-red"
                                                    onClick={() => {
                                                        const updatedValue = Object.values(selectedValue).map((item) => {
                                                            return item
                                                        });
                                                        if (updatedValue[1] !== undefined || updatedValue.length > 0) {
                                                            setIsOpenConfirmModal(true);
                                                        } else if (updatedCompanySettingValue !== companySetting.value) {
                                                            setIsOpenConfirmModal(true);
                                                        } else {
                                                            handleChangeCompanyValue();
                                                        }
                                                    }}
                                                />
                                                <XIcon
                                                    className="cursor-pointer text-gray-300 font-bold px-1 h-5 shrink-0 hover:text-red"
                                                    onClick={() => {
                                                        handleChangeCompanyValue();
                                                        setUpdatedCompanySettingValue(companySetting.value);
                                                        setSelectedValue(handleDefaultCompanyValue())
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            !companySetting.read_only &&
                                            <PencilAltIcon
                                                className="cursor-pointer text-gray-300 font-bold px-1 h-4 shrink-0 hover:text-red"
                                                onClick={handleChangeCompanyValue}
                                            />
                                        )}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-2 gap-4 justify-center items-center bg-white p-3">
                        <div className="flex items-center gap-1 ">
                            <UserGroupIcon className="text-gray-300 font-bold pr-1 h-4 shrink-0"/>
                            <p className=" font-bold text-gray-300">{t("group")}</p>
                        </div>
                        <div className="flex gap-2">
                            <div className="">
                                <button onClick={handleAddNewGroup}
                                        disabled={isAddingNewGroup || readOnly}
                                        className={`py-1.5 w-full ${isAddingNewGroup ? "bg-gray-300" : "bg-black hover:bg-red"}  rounded flex items-center justify-center gap-2`}>
                                    <p className="text-sm text-white font-normal px-5 decoration-2">{companySetting.groups.length > 0 ? `+ ${t("text1")}` : `+ ${t("text2")}`}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        {setting.groups.map((group, key) => (
                            <div className="space-y-6" key={key}>
                                <div className="px-3">
                                    <div
                                        className="grid grid-cols-2 gap-4 justify-center items-center bg-white py-3 border-b">
                                        <div className="flex items-center gap-3">
                                            {/*<div*/}
                                            {/*    className="flex items-center justify-center w-6 h-6 rounded-full bg-warm text-gray-600 font-medium shrink-0">{key + 1}</div>*/}
                                            <p className="text-sm text-gray-600 font-normal ml-4">{group.group_name}</p>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="w-5/12">
                                                {companySetting.type === "bool" ?
                                                    <RadioSettingInput
                                                        handleChange={handleBoolSettingValues}
                                                        trueChecked={editingGroupId === group.group_id ? updatedGroupSettingValue === "1" : group.value === "1"}
                                                        falseChecked={editingGroupId === group.group_id ? updatedGroupSettingValue === "0" : group.value === "0"}
                                                        setEditingGroupId={setEditingGroupId}
                                                        id={group.group_id}
                                                        settingKey={companySetting.key}
                                                        readOnly={readOnly}
                                                    /> :
                                                    <SettingOptionInput
                                                        isEditing={editingGroupId === group.group_id}
                                                        companySetting={companySetting}
                                                        setSelectedValue={(value) =>
                                                            setSelectedGroupValue((prev) => ({
                                                                ...prev,
                                                                [group.group_id]: value as any[],
                                                            }))
                                                        }
                                                        selectedValue={selectedGroupValue[group.group_id] || []}
                                                        setSettingValue={setUpdatedGroupSettingValue}
                                                        value={group.value}
                                                    />}
                                            </div>
                                            {!readOnly &&
                                                <div className="flex justify-start items-center col-span-3">
                                                    {editingGroupId === group.group_id && companySetting.type !== "bool" ?
                                                        <>
                                                            <CheckIcon
                                                                className="cursor-pointer text-gray-300 font-bold px-1 h-5 hover:text-red shrink-0"
                                                                onClick={() => {
                                                                    if (Object.keys(selectedGroupValue).length !== 0) {
                                                                        const updatedValue = Object.values(selectedGroupValue[group.group_id]).map((item) => {
                                                                            return item
                                                                        });
                                                                        if (updatedValue[1] !== undefined || updatedValue.length > 0) {
                                                                            setIsOpenConfirmModal(true);
                                                                        }
                                                                    } else if (updatedGroupSettingValue) {
                                                                        setIsOpenConfirmModal(true);
                                                                    } else {
                                                                        handleEditGroupValue(group.group_id);
                                                                    }
                                                                }}
                                                            />
                                                            <XIcon
                                                                className="cursor-pointer text-gray-300 font-bold px-1 h-5 shrink-0 hover:text-red"
                                                                onClick={() => {
                                                                    handleEditGroupValue(group.group_id);
                                                                    setUpdatedGroupSettingValue("");
                                                                    setSelectedGroupValue(handleDefaultGroupValue());
                                                                }}
                                                            />
                                                        </> :
                                                        <>
                                                            {companySetting.type !== "bool" &&
                                                                <PencilAltIcon
                                                                    className="cursor-pointer text-gray-300 font-bold px-1 h-[18px] hover:text-red shrink-0"
                                                                    onClick={() => {
                                                                        setIsEditing(false);
                                                                        handleEditGroupValue(group.group_id);
                                                                    }}
                                                                />}
                                                            <TrashIcon
                                                                className="cursor-pointer text-gray-300 font-bold px-1 h-[18px] hover:text-red shrink-0"
                                                                onClick={() => {
                                                                    setDeletingGroupId(group.group_id);
                                                                    setIsOpenDeleteModal(true)
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {isAddingNewGroup && (
                            <div className="grid grid-cols-2 pt-2 gap-4 justify-center items-center bg-white px-3 pb-3">
                                <Select
                                    closeMenuOnSelect={true}
                                    isSearchable
                                    onChange={(value) => {
                                        setSelectedGroup(value as NoSettingGroup[]);
                                    }}
                                    options={companySetting.no_setting_groups}
                                    getOptionLabel={(option) => option.group_name}
                                    getOptionValue={(option) => option.group_id}
                                    styles={colourStyles}
                                    placeholder={t("select_placeholder")}
                                    value={selectedGroup}
                                    className="border border-gray-200 w-full rounded focus:outline-none text-base bg-white text-gray-600"
                                />
                                <div className="flex gap-2">
                                    <div className="w-5/12">
                                        {companySetting.type === "bool" ? <RadioSettingInput
                                                handleChange={handleBoolSettingValues}
                                                trueChecked={newGroupSetting !== "" ? newGroupSetting === "1" : false}
                                                falseChecked={newGroupSetting !== "" ? newGroupSetting === "0" : false}
                                                id={selectedGroupId ? selectedGroupId : undefined}
                                                settingKey={companySetting.key}
                                                readOnly={readOnly}
                                            />
                                            :
                                            <SettingOptionInput
                                                isEditing={true}
                                                companySetting={companySetting}
                                                setSelectedValue={(value) => {
                                                    if (selectedGroupId) {
                                                        return setSelectedGroupValue((prev) => ({
                                                            ...prev,
                                                            [selectedGroupId]: value as any[],
                                                        }))
                                                    }
                                                }}
                                                selectedValue={selectedGroupId ? selectedGroupValue[selectedGroupId] : []}
                                                setSettingValue={setNewGroupSetting}
                                            />}
                                    </div>
                                    {companySetting.type !== "bool" &&
                                        <div className="flex justify-start items-center col-span-3">
                                            <CheckIcon
                                                className="cursor-pointer text-gray-300 font-bold px-1 h-5 shrink-0 hover:text-red"
                                                onClick={() => setIsOpenConfirmModal(true)}
                                            />
                                            <XIcon
                                                className="cursor-pointer text-gray-300 font-bold px-1 h-5 shrink-0 hover:text-red"
                                                onClick={handleAddNewGroup}
                                            />
                                        </div>}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>}
        <ConfirmationModal
            isOpen={isOpenDeleteModal}
            handleConfirm={handleDeleteSetting}
            handleClose={() => {
                setIsOpenDeleteModal(false);
                setDeletingGroupId(null);
            }}
            title={t("delete_modal.title")}
            textMessage={t("delete_modal.text_message")}
            isLoading={isDeleting}
            prevButtonText={t("delete_modal.cancel")}
            nextButtonText={t("delete_modal.delete")}
            loadingButtonText={t("delete_modal.deleting")}
        />
        <ConfirmationModal
            isOpen={isOpenConfirmModal}
            handleConfirm={() => {
                if (isEditing) {
                    handleClickSetting();
                }
                if (editingGroupId) {
                    editingGroupId && handleUpdateGroupSetting(editingGroupId);
                } else if (isAddingNewGroup) {
                    handleClickGroupSetting();
                }
                setIsOpenConfirmModal(false);
            }}
            handleClose={handleClose}
            title={t("confirm_modal.title")}
            textMessage={t("confirm_modal.text_message")}
            isLoading={isUpdating}
            prevButtonText={t("delete_modal.cancel")}
            nextButtonText={t("confirm_modal.confirm")}
            loadingButtonText={t("confirm_modal.updating")}
        />
    </div>
}

export default SettingCard;
