import React from 'react';
import {AiFillStar, AiOutlineStar} from "react-icons/ai";

interface RatingProps {
    value: number;
    max: number;
    onChange: (value: number) => void;
    texts: string[];
}

const Rating: React.FC<RatingProps> = ({ value, max, onChange, texts }) => {
    const handleClick = (newValue: number) => {
        onChange(newValue);
    };

    const getText = () => {
        if (value > max*0.8) return texts[4];
        else if (value > max*0.6) return texts[3];
        else if (value > max*0.4) return texts[2];
        else if (value > max*0.2) return texts[1];
        if (value === 0) return "";
        return texts[0];
    };

    return (
        <div className="flex items-center space-x-2">
            <div className="flex space-x-0.5">
                {Array.from({ length: max }, (_, i) => i + 1).map((item) => (
                    <div
                        key={item}
                        onClick={() => handleClick(item)}
                        className="cursor-pointer hover:scale-110 transition-transform duration-200 ease-in-out"
                    >
                        {item <= value ? (
                            <AiFillStar className="text-black w-5 h-5" />
                        ) : (
                            <AiOutlineStar className="text-gray-400 w-5 h-5" />
                        )}
                    </div>
                ))}
            </div>
            <span className="text-gray-700 font-medium text-xs">
                {getText()}
            </span>
        </div>
    );
};

export default Rating;
