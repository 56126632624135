import {ISessionReport} from "../../../../../models/IReport";
import React, {useEffect, useState} from "react";
import moment from "moment";
import ReportSideCard from "../components/ReportSideCard";
import Section from "../components/Section";
import {useTranslation} from "react-i18next";
import ReportCard from "../components/ReportCard";
import SummaryReportCard from "../components/SummaryReportCard";
import journeyIcon from "../../../../../assets/img/aiCoaching/stairs.png";
import flashIcon from "../../../../../assets/img/aiCoaching/ad_hoc_session.svg";
import sessionIcon from "../../../../../assets/img/aiCoaching/puzzle_blocks.png"

type Props = {
    data: ISessionReport[];
};

const SessionReportsSection = ({data}: Props) => {
    const {t} = useTranslation("reports");
    const [currentSession, setCurrentSession] = useState<ISessionReport>();

    useEffect(() => {
        if (data && data.length > 0) setCurrentSession(data[0]);
    }, []);


    return (
        <Section
            title={t("session_reports.title")}
            sidePanel={data.length ? data.map((session, index) => (
                <ReportSideCard
                    key={index}
                    coach={session.ai_model.toUpperCase()}
                    date={moment(session.datetime).format("MMM D, YYYY")}
                    time={moment(session.datetime).format("HH:MM")}
                    session={session.title}
                    coachImage={session.ai_model === 'journey' ? journeyIcon : session.ai_model === 'flash' ? flashIcon : sessionIcon}
                    selected={session === currentSession}
                    onClick={() => setCurrentSession(session)}
                />
            )) : null
            }
        >
            <ReportCard title={currentSession?.title} children={<SummaryReportCard threadID={currentSession?.id}/>}/>
        </Section>
    )
}

export default SessionReportsSection;