import {ICompany, ICreditData} from "../../../../../models/ICompany";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactModal from "react-modal";

interface Props {
    companyCredits: ICreditData[];
    company: ICompany;
    addCredit: (companyId: number, credit: any) => void;
}

const CompanyCreditsTab = ({companyCredits, company, addCredit}: Props) => {
    const {t} = useTranslation("companyInsights");
    const [creditData, setCreditData] = useState<ICreditData[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newCredit, setNewCredit] = useState({
        amount: 0,
        expiryDate: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        expiryDate: "",
    });

    useEffect(() => {
        setCreditData(companyCredits);
    }, [companyCredits]);

    const handleAddCreditClick = () => {
        setShowModal(true);
    }

    const handleModalClose = () => {
        setNewCredit({
            amount: 0,
            expiryDate: "",
        });
        setFormErrors({
            amount: "",
            expiryDate: "",
        });
        setShowModal(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setNewCredit({
            ...newCredit,
            [name]: name === "amount" ? Number(value) : new Date(value),
        });

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));
    };

    const validateForm = () => {
        const errors = {
            amount: validateField("amount", newCredit.amount),
            expiryDate: validateField("expiryDate", newCredit.expiryDate),
        };

        setFormErrors(errors);

        return !errors.amount && !errors.expiryDate;
    };

    const validateField = (name: string, value: any) => {
        if (name === "amount") {
            return value <= 0 ? t("credits.amount_required") : "";
        }
        if (name === "expiryDate") {
            const expiryDate = new Date(value);
            if (!value) {
                return t("credits.expiry_date_required");
            }
            return moment(expiryDate).isSameOrBefore(moment(), "day")
                ? t("credits.expiry_date_invalid")
                : "";
        }
        return "";
    };

    const handleAddCredit = () => {
        if (validateForm()) {
            setIsAdding(true);
            const creditAdded = {
                credits: newCredit.amount,
                creditExpiration: moment(newCredit.expiryDate).format("YYYY-MM-DDTHH:mm:ss")
            }
            addCredit(company.id, creditAdded);
            setIsAdding(false);
            handleModalClose();
        }
    };

    return (
        <>
            <div className="overflow-auto">
                <table className="w-full border md:text-sm text-xs text-center [&>*>tr>th]:font-normal">
                    <thead className="border-b-2 bg-[#F9F9F9FF]">
                    <tr>
                        {/*<th className="px-4 py-3 tracking-wider">{t("credits.program")}</th>*/}
                        <th className="px-4 py-3 tracking-wider">{t("credits.added")}</th>
                        <th className="px-4 py-3 tracking-wider">{t("credits.usage")}</th>
                        <th className="px-4 py-3 tracking-wider">{t("credits.created_at")}</th>
                        <th className="px-4 py-3 tracking-wider">{t("credits.expires_at")}</th>
                        <th className="px-4 py-3 tracking-wider">{t("credits.status")}</th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {creditData && creditData.map((item, index) => {
                        const isExpired = new Date(item.expires_at) < new Date();
                        return (
                            <tr key={index}>
                                {/*<td className="px-4 py-3">{item.program_name}</td>*/}
                                <td className="px-4 py-3">
                                    <span
                                        className={item.credits_added > 0 ? 'text-green-600' : 'text-red-600'}>
                                        {item.credits_added > 0 ? `+ ${item.credits_added}` : item.credits_added}
                                    </span>
                                </td>
                                <td className="px-4 py-3">{item.usage || 0}</td>
                                <td className="px-4 py-3">{moment(item.created_date).format("ll")}</td>
                                <td className="px-4 py-3">{moment(item.expires_at).format("ll")}</td>
                                <td className="px-4 py-3">
                                    <span className={`inline-flex px-2 py-1 text-xs font-medium rounded-full ${
                                        isExpired
                                            ? 'bg-red-100 text-red-800'
                                            : 'bg-green-100 text-green-800'
                                    }`}>
                                        {isExpired ? t("status.expired") : t("status.active")}
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                    {!creditData.length &&
                        <tr className="text-center">
                            <td colSpan={5} className="font-normal p-3">
                                {t("credits.no_data")}
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
                <div className="flex md:justify-end mt-2">
                    <button
                        onClick={handleAddCreditClick}
                        className="text-white bg-black font-semibold text-xs py-1 px-2 rounded border border-black"
                    >
                        {t("credits.add_credit")}
                    </button>
                </div>
            </div>
            <ReactModal
                isOpen={showModal}
                onRequestClose={handleModalClose}
                contentLabel={t("credits.add_credit")}
                ariaHideApp={false}
                className="bg-white p-6 rounded-md shadow-lg w-96"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                <h2 className="text-base font-semibold mb-4">{`${t("credits.add_credit")} - ${company?.name || ''}`}</h2>

                <div className="mb-2">
                    <input
                        type="number"
                        name="amount"
                        placeholder={t("credits.amount")}
                        value={newCredit.amount || ""}
                        onChange={handleInputChange}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.amount && <p className="text-red-500 text-xs">{formErrors.amount}</p>}
                </div>

                <div className="mb-2">
                    <input
                        type="date"
                        name="expiryDate"
                        value={newCredit.expiryDate ? moment(newCredit.expiryDate).format("YYYY-MM-DD") : ""}
                        onChange={handleInputChange}
                        min={moment().add(1, "days").format("YYYY-MM-DD")}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.expiryDate && <p className="text-red-500 text-xs">{formErrors.expiryDate}</p>}
                </div>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={handleModalClose}
                        disabled={isAdding}
                        className="px-4 py-2 bg-gray-200 rounded-md"
                    >
                        {t("button.cancel")}
                    </button>
                    <button
                        onClick={handleAddCredit}
                        disabled={isAdding}
                        className="px-4 py-2 bg-black text-white rounded-md"
                    >
                        {isAdding ? t("button.adding") : t("button.add")}
                    </button>
                </div>
            </ReactModal>
        </>
    )
}

export default CompanyCreditsTab;