import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import CoachesWrapper from "./CoachesWrapper";
import Skeletons from "./Skeletons";

import ICoach from "../../../../../../models/ICoach";

type HighlightedCoachesProps = {
  previouslyBookedCoach?: ICoach;
  orientationCoaches: ICoach[];
  recommendedCoaches: ICoach[];
  handleSelectCoach: (coach: ICoach, orientation?: boolean) => void;
  selectedCoach: ICoach | null;
  playCoachVideo: (url: string, title: string) => void;
  showAllCoaches: () => void;
};

const HighlightedCoaches = ({
  previouslyBookedCoach,
  orientationCoaches,
  recommendedCoaches,
  handleSelectCoach,
  selectedCoach,
  playCoachVideo,
  showAllCoaches,
}: HighlightedCoachesProps) => {
  const { t } = useTranslation("booking");

  const previouslyBookedCoachCount = previouslyBookedCoach ? 1 : 0;

  const skeletons = useMemo(() => {
    const skeletonCount =
      4 -
      previouslyBookedCoachCount -
      orientationCoaches.length -
      recommendedCoaches.length;
    const skeletonArr = [];
    for (let i = skeletonCount; i > 0; i--) {
      skeletonArr.push(i);
    }
    return skeletonArr;
  }, [previouslyBookedCoachCount, orientationCoaches, recommendedCoaches]);

  const coachesWrapperProps = {
    handleSelectCoach,
    selectedCoach,
    playCoachVideo,
  };

  return (
    <div className="grid w-full grid-cols-4 gap-y-5 gap-x-5 mt-5 pb-8 h-full">
      {previouslyBookedCoach && (
        <CoachesWrapper
          title={t("coaches.previously_booked_coach")}
          coaches={[previouslyBookedCoach]}
          {...coachesWrapperProps}
        />
      )}

      {orientationCoaches.length ? (
        <CoachesWrapper
            title="Our coaching manager"
          coaches={orientationCoaches}
          titleColor="text-[#26934a]"
          wrapperColor="bg-[#7ebd9478]"
          {...coachesWrapperProps}
        />
      ) : null}

      {recommendedCoaches.length ? (
        <CoachesWrapper
          title={t("coaches.based_on_your_assessment")}
          coaches={recommendedCoaches}
          {...coachesWrapperProps}
        />
      ) : null}

      <Skeletons skeletons={skeletons} showAllCoaches={showAllCoaches} />
    </div>
  );
};

export default HighlightedCoaches;
