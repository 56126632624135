import React, { FC, InputHTMLAttributes } from 'react';
import { Formik, Form, useField, FieldHookConfig, FieldInputProps, useFormikContext } from 'formik';
import style from "./InputFormik.module.css";
import { setClasses } from "../../../../utils/helpers/css";
import { Typography } from 'antd';

interface Props {
    label: string;
    suffixTitle: string;
}

const CustomInputFormik: FC<Props & (FieldHookConfig<string>)> = ({ label, suffixTitle, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)

    const [field, meta] = useField(props);

    return (

        <div className={setClasses(style.wrapper, (meta.touched && meta.error) && style.error) + ' flex-1'}>
            <label className={style.label} htmlFor={props.id || props.name}>{label}</label>
            <div className="flex flex-row items-center">
                <div className="grid grid-cols-3">
                    <div className="">
                        <input
                        className={style.input + " pl-2"} {...field}
                        value={field.value ?? ""}
                        {...props as InputHTMLAttributes<HTMLInputElement>}
                        style={{
                            border: "1px solid #B3B3B3",
                            borderRight: 0,
                            borderBottomLeftRadius: "4px",
                            borderTopLeftRadius: "4px",
                            outline: "none"}}
                        />
                    </div>
                    <div className="col-span-2 bg-[#F3EBE2] py-2 w-full rounded-r border-y border-r border-[#B3B3B3]">
                        <Typography className="font-bold px-2 ">{suffixTitle}</Typography>
                    </div>
                </div>

            </div>

            {meta.touched && meta.error ? (
                <span className={style.errorLabel}>{meta.error}</span>
            ) : null}
        </div>
    );
};

export default CustomInputFormik;