import React from 'react';
import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import SettingENG from "../../../locale/eng/setting.json";
import SettingFR from "../../../locale/fr/setting.json";

interface Props {
    trueChecked: boolean;
    falseChecked: boolean;
    handleChange: (value: boolean, groupId?: number) => void;
    id?: number;
    setEditingGroupId?: React.Dispatch<React.SetStateAction<number | null>>;
    setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
    settingKey: string;
    readOnly?: boolean;
}

const RadioSettingInput: React.FC<Props> = ({
                                                trueChecked,
                                                falseChecked,
                                                handleChange,
                                                id,
                                                setEditingGroupId,
                                                setIsEditing,
                                                settingKey,
                                                readOnly
                                            }) => {
    const {t} = useTranslation("setting");
    i18n.addResourceBundle("en", "setting", SettingENG);
    i18n.addResourceBundle("fr", "setting", SettingFR);

    const inputId = id ? id.toString() : "company-setting";

    return <div className="flex font-bold text-md items-center gap-3">
        <div
            className="inline-block py-1 px-0.5 bg-white border rounded-2xl whitespace-nowrap">
            <span>
                <input
                    disabled={readOnly}
                    onChange={(e) => {
                        if (id && setEditingGroupId) {
                            setEditingGroupId(id)
                        } else if (setIsEditing) {
                            setIsEditing(true)
                        }
                        handleChange(true, id);
                    }}
                    type="radio"
                    id={`${settingKey}-${inputId}-true`}
                    className="hidden peer"
                    checked={trueChecked}
                />
                <label
                    htmlFor={`${settingKey}-${inputId}-true`}
                    className="bg-white rounded-2xl py-0.5 px-5 select-none cursor-pointer peer-checked:bg-green-100 peer-checked:text-green-800 font-normal"
                >
                    {t("yes")}
                </label>
            </span>
            <span>
                <input
                    disabled={readOnly}
                    onChange={(e) => {
                        if (id && setEditingGroupId) {
                            setEditingGroupId(id)
                        } else if (setIsEditing) {
                            setIsEditing(true)
                        }
                        handleChange(false, id)
                    }}
                    type="radio"
                    id={`${settingKey}-${inputId}-false`}
                    className="hidden peer"
                    checked={falseChecked}
                />
                <label
                    htmlFor={`${settingKey}-${inputId}-false`}
                    className="bg-white rounded-2xl py-0.5 px-5 select-none cursor-pointer peer-checked:bg-red-100 peer-checked:text-red-800 font-normal"
                >
                    {t("no")}
                </label>
            </span>
        </div>
    </div>
}

export default RadioSettingInput;