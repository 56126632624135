import i18n from "i18next";

import {initReactI18next} from "react-i18next";

import layoutENG from "../../locales/eng/layout.json";
import layoutFr from "../../locales/fr/layout.json";

import tablesENG from "../../locales/eng/tables.json";
import tablesFr from "../../locales/fr/tables.json";

import commonsENG from "../../locales/eng/commons.json";
import commonsFr from "../../locales/fr/commons.json";

import generalENG from "../../locales/eng/general.json";
import generalFr from "../../locales/fr/general.json";

import bookingENG from "../../locales/eng/booking.json";
import bookingFr from "../../locales/fr/booking.json";

import aiBookingENG from "../../locales/eng/ai_booking.json";
import aiBookingFr from "../../locales/fr/ai_booking.json";

import aiOnlyBookingENG from "../../modules/dashboard/pages/AIOnlyCoaching/locale/en/ai_coaching.json";
import aiOnlyBookingFr from "../../modules/dashboard/pages/AIOnlyCoaching/locale/fr/ai_coaching.json";

import aiUsageENG from "../../modules/dashboard/locale/eng/aiUsage.json";
import aiUsageFR from "../../modules/dashboard/locale/fr/aiUsage.json";

import aiPerformanceENG from "../../modules/dashboard/locale/eng/aiPerformance.json";
import aiPerformanceFR from "../../modules/dashboard/locale/fr/aiPerformance.json";

// resources: {
//     en: {
//         namespace1: {
//             key: 'hello from namespace 1'
//         },
//         namespace2: {
//             key: 'hello from namespace 2'
//         }
//     },
//     de: {
//         namespace1: {
//             key: 'hallo von namespace 1'
//         },
//         namespace2: {
//             key: 'hallo von namespace 2'
//         }
//     }
// }

// the translations
const resources = {
    en: {
        layout: layoutENG,
        tables: tablesENG,
        commons: commonsENG,
        general: generalENG,
        booking: bookingENG,
        ai_booking: aiBookingENG,
        ai_coaching: aiOnlyBookingENG,
        ai_usage: aiUsageENG,
        ai_performance: aiPerformanceENG,
    },
    fr: {
        layout: layoutFr,
        tables: tablesFr,
        commons: commonsFr,
        general: generalFr,
        booking: bookingFr,
        ai_booking: aiBookingFr,
        ai_coaching: aiOnlyBookingFr,
        ai_usage: aiUsageFR,
        ai_performance: aiPerformanceFR,
    },
};

i18n
    // .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("I18N_LANGUAGE") || "en",
        fallbackLng: "en", // use en if detected lng is not available
        debug: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
