import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import HT from "./HighlightedText";
import VerticalExpander from "./VerticalExpander";
import ChatGuide from "../../../../../shared/components/ChatGuide";

import introStep1Image1 from "@img/dashboard/welcome/img/introSteps1.1.png";
import introStep1Image2 from "@img/dashboard/welcome/img/introSteps1.2.png";
import introStep1ImageFr1 from "@img/dashboard/welcome/img/introStepsFr1.1.png";
import introStep1ImageFr2 from "@img/dashboard/welcome/img/introStepsFr1.2.png";
import introStep1ImageFr3 from "@img/dashboard/welcome/img/introStepsFr1.3.png";

interface Props {
  description?: string;
}

const Step1 = ({description}: Props) => {
  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
  const { t } = useTranslation("welcome");
  const [showContent, setShowContent] = useState(false);
  const [showingImageNo, setShowingImageNo] = useState(1);

  useEffect(() => {
    if (showContent) {
      setTimeout(() => {
        setShowingImageNo(2);
        if (lng === "fr") {
          setTimeout(() => {
            setShowingImageNo(3);
          }, 1000);
        }
      }, 1000);
    }
  }, [showContent]);

  return (
    <>
      <ChatGuide
        title={<HT>{t("step_1.title")}</HT>}
        description={description || t("step_1.description")}
        descriptionClassName="font-normal"
        onShowContent={() => setShowContent(true)}
        onboarding
      />
      <div className="w-full flex flex-row justify-center items-center flex-1">
        {lng === "fr" ? (
          <>
            <VerticalExpander expanded={showContent && showingImageNo <= 3}>
              <div className="w-[350px] 2xl:w-[450px] my-10">
                <img src={introStep1ImageFr1} alt="step 1" />
              </div>
            </VerticalExpander>
            <VerticalExpander expanded={showContent && showingImageNo > 1}>
              <div className="w-[100px] 2xl:w-[200px] my-10">
                <img src={introStep1ImageFr2} alt="step 1" />
              </div>
            </VerticalExpander>
            <VerticalExpander expanded={showContent && showingImageNo > 2}>
              <div className="w-[350px] 2xl:w-[450px] my-10">
                <img src={introStep1ImageFr3} alt="step 1" />
              </div>
            </VerticalExpander>
          </>
        ) : (
          <>
            <VerticalExpander expanded={showContent && showingImageNo <= 2}>
              <div className="w-[250px] 2xl:w-[350px] my-10">
                <img src={introStep1Image1} alt="step 1" />
              </div>
            </VerticalExpander>
            <VerticalExpander expanded={showContent && showingImageNo > 1}>
              <div className="w-[250px] 2xl:w-[350px] my-10">
                <img src={introStep1Image2} alt="step 1" />
              </div>
            </VerticalExpander>
          </>
        )}
      </div>
    </>
  );
};

export default Step1;
