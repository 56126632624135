import {DELETE, GET, POST, PUT} from "../../utils/lib/axios";
import {AiBooking} from "../../constants/endpoints/aiBooking";
import {AxiosRequestConfig} from "axios";
import {IContentState} from "../../constants/types"
import {IAIActionNotificationState} from "../../modules/dashboard/pages/AICoaching/types";

interface RetryConfig extends AxiosRequestConfig {
    retry?: number;
    retryDelay?: number;
}

const retryConfig: RetryConfig = {
    retry: 3,
    retryDelay: 3000,
};

export const postAIPrompt = async (
    id: number,
    prompt: string,
    controller: AbortController
) => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";

    return POST(AiBooking.post.postAIPrompt, {prompt, lng}, {id}, undefined, {
        ...retryConfig,
        signal: controller.signal
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to post prompt:", err);
        });
};

export const postNewAIPrompt = (controller: AbortController) => {
    return POST(AiBooking.post.postNewAIPrompt,
        {},
        undefined,
        undefined,
        {signal: controller.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to post new prompt:", err);
        });
};
export const postReview = async (chatId: number, rate: number, comment?: string): Promise<any> => {
    return POST(AiBooking.post.postFeedback, {rate: rate, comment: comment}, {chatId})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to post rating:", err);
        });
};

export const createSummary = async (id: number, controller: AbortController): Promise<any> => {
    return POST(AiBooking.post.createSummary, {}, {id}, undefined, {signal: controller.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to create summary:", err);
        });
};

export const getSuggestions = async (id: number, controller: AbortController): Promise<any> => {
    return POST(AiBooking.post.getSuggestions, {}, {id}, undefined, {...retryConfig, signal: controller.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get ai chat suggestions:", err);
        });
};

export const deleteChat = async (id: number): Promise<any> => {
    return DELETE(AiBooking.delete.deleteSession, {}, {id})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to delete ai chat:", err);
        });
};


export const getAIHistory = (id: number) => {
    return GET(AiBooking.get.getAIHistory, {id}).then((response) => {
        return response.data;
    })
        .catch((err) => {
            console.log("Failed to get ai history:", err);
        });
};

export const getAIOverview = () => {
    return GET(AiBooking.get.getAIOverview).then((response) => {
        return response.data;
    });
};

export const getSummary = async (id: number, controller?: AbortController): Promise<any> => {
    return GET(AiBooking.get.getSummary, {id}, undefined, {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get session summary:", err);
        });
};

export const getRecommendations = async (ai_coach_id: number,controller?:AbortController): Promise<any> => {
    return GET(AiBooking.get.getRecommendations, {ai_coach_id}, undefined, {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
};

export const getAllRecommendations = async (client_id:number,controller?:AbortController): Promise<any> => {
    return GET(AiBooking.get.getAllRecommendations, {client_id}, undefined, {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get all recommendations:", err);
        });
};

export const updateContentState = async (content_id:number, data:IContentState, controller?:AbortController): Promise<any> => {
    return PUT(AiBooking.put.updateContentState,  data, {content_id}, {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to update the current state of the article:", err);
        });
};

export const getAllNotificationsSummary = async (client_id:number,controller?:AbortController): Promise<any> => {
    return GET(AiBooking.get.getAllNotificationsSummary, undefined, {client_id}, {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get all actions notifications:", err);
        });
};

export const updateStateAIActionNotification = async (id:number, data: IAIActionNotificationState, controller?:AbortController): Promise<any> => {
    return PUT(AiBooking.put.updateStateAIActionNotification, data, {id}, {signal: controller?.signal})
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log("Failed to get all actions notifications:", err);
        });
};
