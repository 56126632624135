import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import HT from "./HighlightedText";
import ChatGuide from '../../../../../shared/components/ChatGuide';
import { IMeta } from '../../../../../models/IMeta';
import Preference from "../../../components/Preference";
import user_preference from "../../../../../assets/img/aiCoaching/view_report.png";
import InfoPopover from "../../../components/InfoPopover";

interface Props {
    onSetSelectedLanguage: (lng: IMeta | undefined) => void;
    onSetConsentAssessmentData: (consent: boolean) => void;
    onSetConsentFlashFeedback: (consent: boolean) => void;
}

const PreferenceSection = (props: Props) => {
    const { t } = useTranslation("welcome");
    const [showContent, setShowContent] = useState(false);


    return (
        <div>
            <ChatGuide
                title={<HT>{t("step_4_1.title")}</HT>}
                description={t("step_4_1.description")}
                descriptionClassName="font-medium"
                onShowContent={() => setShowContent(true)}

            />
            {showContent &&
                <div className="w-full flex flex-col p-6 rounded-md my-8 bg-white">
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center gap-3">
                            <img
                                src={user_preference}
                                className="w-5"
                                alt="user-preference"
                            />
                            <HT className="text-xl font-bold font-lexend">
                                {t("step_4_1.title")}
                            </HT>
                            <span
                                className={`px-5 py-2 rounded-full text-xs text-black bg-[#E3F8EE]`}
                            >
                                {t("step_4_1.preference")}
                            </span>
                        </div>
                        <InfoPopover content={t("step_4_1.info")} />
                    </div>
                    <div className="h-full">
                        <Preference
                            onSetSelectedLanguage={props.onSetSelectedLanguage}
                            onSetConsentAssessmentData={props.onSetConsentAssessmentData}
                            onSetConsentFlashFeedback={props.onSetConsentFlashFeedback}
                            hideButton={true}
                        />
                    </div>

                </div>
            }
        </div>
    );
};

export default PreferenceSection;

