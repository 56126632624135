import React, {ReactNode} from 'react';
import InformationIcon from "./InformationIcon";
import ReactTooltip from "react-tooltip";
import {randomString} from "../../utils/helpers/str";
import BoxIcon from "./BoxIcon";
import NoDataIcon from "@icons/noDataIcon.png";
import {useTranslation} from "react-i18next";

interface StatCardProps {
    icon: string | ReactNode;
    value: number | string;
    maxValue?: number;
    label: string;
    toolTip?: string;
    dataState?: DataState;
}

enum DataState {
    NO_DATA = 'NO_DATA',
    NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
    DATA = 'DATA'
}

const StatCard: React.FC<StatCardProps> = ({icon, value, label, maxValue, toolTip, dataState = DataState.DATA}) => {
    const tooltipId = randomString();

    const {t} = useTranslation('commons');

    const noDataText1 = dataState === DataState.NO_DATA
        ? t('no_data.text1')
        : dataState === DataState.NO_DATA_WITH_FILTERS
            ? t('no_data_card.text3')
            : "";

    const noDataText2 = dataState === DataState.NO_DATA
        ? t('no_data_card.text2')
        : dataState === DataState.NO_DATA_WITH_FILTERS
            ? t('no_data_card.text2')
            : "";

    return (
        <div className="flex items-center justify-between h-full px-6 py-4 bg-white rounded-lg w-full">
            <div className="relative w-5/6">
                {dataState && (dataState === DataState.NO_DATA_WITH_FILTERS || dataState === DataState.NO_DATA) && (
                    <div
                        className="z-10 absolute inset-0 flex items-center justify-center text-center gap-2 overflow-hidden">
                        <img src={NoDataIcon} width={40} height={40} alt="No Data Icon"/>
                        <div className="flex flex-col">
                            <h4 className="md:text-sm text-xs">{noDataText1}</h4>
                            <p className="md:text-sm text-xs">{noDataText2}</p>
                        </div>
                    </div>
                )}
                <div style={{
                    filter: dataState === DataState.DATA ? 'none' : 'blur(12px)'
                }}>
                    <div className="flex items-center space-x-4 text-black">
                        {typeof icon === "string" ? <BoxIcon img={icon}/> : (
                            <div className="p-2 bg-orange-100 rounded-lg">
                                {icon}
                            </div>)}
                        <div>
                            <div className="flex gap-1 items-end">
                                <h2 className="font-lexend font-bold">{value}</h2>
                                {maxValue && <span className="font-normal text-lg -translate-y-[40%]">/{maxValue}</span>}
                            </div>
                            <div className="text-sm text-gray-600">
                                {label}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                toolTip &&
                <div className="flex items-center space-x-4">
                    <div className="w-px h-10 bg-gray-300"/>
                    <InformationIcon
                        data-for={tooltipId}
                        data-tip={"hover on me will keep the tooltip"}
                        className="text-black"
                    />
                </div>
            }

            <ReactTooltip
                className={"w-60"}
                delayHide={500}
                id={tooltipId}
                place="top"
                effect="solid"
            >
                <span>{toolTip}</span>
            </ReactTooltip>
        </div>
    )
}

export default StatCard;