import React, {useCallback, useEffect, useRef, useState} from "react";

import {twMerge} from "tailwind-merge";
import {v4 as uuidv4} from "uuid";

import ProgressBar from "../ProgressBar";
import Chat from "./Chat";
import ChatInput from "./ChatInput";

import {AIVoiceCoachingState, IChatItem, useAICoaching} from "../../utils/AICoachingContext";
import {getChatHistory, getSuggestions, processChatPrompt,} from "../../actions";

import {AIModel} from "../../enums";
import Suggestions from "../Suggestions";
import ChatEndActions from "./ChatEndActions";
import {ArrowCircleDownIcon, ArrowCircleUpIcon} from "@heroicons/react/solid";
import {AnimatePresence, motion} from "framer-motion";
import Spinner from "./Spinner";
import {useTranslation} from "react-i18next";
import {AI_FLASH_TOTAL_STEPS, AI_JOURNEY_TOTAL_STEPS, AI_SESSION_TOTAL_STEPS} from "../../../../../../constants";
import EdgePanel from "./EdgePanel";
import Loading from "./Loading";
import {useConfig} from "../../../../../../utils/providers/AppConfigProvider";
import VoiceChat from "../VoiceChat/VoiceChat";


const ChatBox = () => {
    const {
        messages,
        setMessages,
        addMessage,
        removeMessage,
        lastChangedIndex,
        isLoading,
        setIsLoading,
        setChatPrompt,
        state,
        selectedThreadItem,
        setSelectedThreadItem,
        setCustomChatSection,
        createNewThread,
        isStartState,
        setIsStartState,
        voiceState,
        setVoiceState
    } = useAICoaching();

    const {t} = useTranslation("ai_coaching");
    const {config} = useConfig();

    const selectedThreadIdRef = useRef<number | undefined>(selectedThreadItem?.id);
    const voicesStateRef = useRef<AIVoiceCoachingState>("not-active");

    useEffect(() => {
        selectedThreadIdRef.current = selectedThreadItem?.id;
    }, [selectedThreadItem])

    const spinPMessages = [
        t("spin_message.setting_up_practice_session"),
        t("spin_message.practice_session_ready"),
        t("spin_message.finalizing_practice_session")
    ]

    const [currentChatStatus, setCurrentChatStatus] = useState({lastStep: false, step: 0});
    const [isHistoryLoading, setIsHistoryLoading] = useState(false)
    const [practiceSessions, setPracticeSessions] = useState<any[]>([]);
    const [createPracticeMode, setCreatePracticeMode] = useState(false);
    const [isPLoading, setIsPLoading] = useState(false);
    const [selectedPracticeSessionId, setSelectedPracticeSessionId] = useState(0);

    useEffect(() => {
        // if (selectedThreadItem && selectedThreadItem.isNew) setMessages([])
        if (state != "practice") {
            setPracticeSessions([]);
            setSelectedPracticeSessionId(0);
        }
        if (selectedThreadItem && !selectedThreadItem.isNew) {
            fetchHistory(selectedThreadItem?.id);
        }
    }, [selectedThreadItem?.id]);

    useEffect(() => {
        voicesStateRef.current = voiceState;
        if (selectedThreadItem && voiceState === "summary") {
            fetchHistory(selectedThreadItem?.id);
            setVoiceState("not-active")
        }
    }, [voiceState]);


    const fetchHistory = async (id: number) => {
        setIsHistoryLoading(true)
        setMessages([])
        try {
            // Get this id from the selected overview item

            const historyData = await getChatHistory(id);
            const history: IChatItem[] = historyData.history
                .map(
                    (chatItem: any) => {
                        if (chatItem.content.trim() != "") {
                            return ({
                                id: uuidv4(),
                                content: chatItem.content.trim(),
                                role: chatItem.role === "user" ? "me" : "assistant",
                                voice: chatItem.voice
                            } as IChatItem)
                        } else {
                            return ({
                                id: uuidv4(),
                                content: () => {
                                },
                                role: "system",
                            } as IChatItem)
                        }

                    }
                )
                .reverse();

            if (historyData && historyData.history.at(-1))
                setCurrentChatStatus({lastStep: historyData.last_step, step: historyData.history.at(-1).step})

            if (historyData && historyData.practice_sessions.length > 0) {
                const formattedPracticeSessions = historyData.practice_sessions
                    .filter((session: any) => session.name !== null)
                    .map((session: any) => ({
                        id: session.id,
                        practiceId: session.practice_id,
                        title: session.name,
                        subTitle: session.objective
                    }));
                setPracticeSessions(formattedPracticeSessions);
            } else {
                setPracticeSessions([]);
            }

            if (historyData) {
                setCreatePracticeMode(historyData.enable_practice)
            }

            if (selectedThreadItem?.prevThread) {
                history.push({
                    id: uuidv4(),
                    content: () => <button
                        onClick={() => setSelectedThreadItem(selectedThreadItem?.prevThread!)}
                        className="flex gap-2 items-center px-2 py-1.5 mx-auto bg-black text-white rounded-md hover:text-gray-300">See
                        prev
                        session <ArrowCircleUpIcon className="w-4 h-4"/></button>,
                    role: "system",
                } as IChatItem)
            }

            if (selectedThreadItem?.nextThread) {
                history.unshift({
                    id: uuidv4(),
                    content: () => <button
                        onClick={() => setSelectedThreadItem(selectedThreadItem?.nextThread!)}
                        className="flex gap-2 items-center px-2 py-1.5 mx-auto bg-black text-white rounded-md hover:text-gray-300">See
                        next
                        session <ArrowCircleDownIcon className="w-4 h-4"/></button>,
                    role: "system",
                } as IChatItem)
            }
            if (historyData.last_step && selectedThreadItem)
                setCustomChatSection(<ChatEndActions
                    schedule={historyData.schedule}
                    aiModel={selectedThreadItem.aiModel}
                    journeyId={selectedThreadItem.aiModel == 'journey' ? selectedThreadItem.id : selectedThreadItem.journeyId}
                    threadID={selectedThreadItem.id}
                    summaryAvailable={historyData.summary_created}/>)

            if (!historyData.last_step && selectedThreadItem && config?.client_settings?.quick_replies_enabled) {
                const suggestions = await processSuggestions(id)

                if (suggestions?.suggestions && voicesStateRef.current !== "active") {
                    setCustomChatSection(<Suggestions onSelectSuggestion={(suggestion) => setChatPrompt(suggestion)}
                                                      suggestions={suggestions.suggestions} collapsed={false}
                                                      isPractice={state == 'practice'}/>)
                }
            }
            setMessages(history);
        } catch (e) {
            console.log(e);
        } finally {
            setIsHistoryLoading(false);
        }
    };

    const handlePrompt = useCallback(async (prompt: string, callback: () => void, voice?: boolean) => {
        setCustomChatSection(undefined)
        try {
            const chatItem = {
                id: uuidv4(),
                content: prompt,
                role: "me",
                voice: voice
            } as IChatItem;

            setIsLoading(true);
            addMessage(chatItem);
            callback();
            // debugger
            // Get this id from the selected overview item. Else null
            let id = selectedThreadItem?.id;
            if (!id) {
                if (state == 'flash') {
                    // Dynamically call createSession/createJourney with dynamic ChatType
                    const newChat = await createNewThread(AIModel.flash);
                    id = newChat.id as number;
                } else {
                    throw new Error(`cannot create new thread `);
                }

            }

            const promptResponse = await processPrompt(id, prompt, voice);
            setCurrentChatStatus({lastStep: promptResponse.last_step, step: promptResponse.step})
            // setSelectedThreadItem(prevItem => prevItem && prevItem.isNew ? ({
            //     ...prevItem,
            //     isNew: false
            // }) : prevItem);

            if (selectedThreadIdRef.current === id) {
                addMessage({
                    id: Math.random().toString(),
                    content: promptResponse.reply,
                    role: "assistant",
                });
            }
            setIsLoading(false);
            setIsStartState(false);
            if (promptResponse.last_step && selectedThreadItem)
                setCustomChatSection(<ChatEndActions aiModel={selectedThreadItem.aiModel}
                                                     journeyId={selectedThreadItem.aiModel == 'journey' ? selectedThreadItem.id : selectedThreadItem.journeyId}
                                                     threadID={selectedThreadItem.id}
                                                     summaryAvailable={false}
                />)
            if (selectedThreadIdRef.current === id) {
                if (!promptResponse.last_step) {
                    const suggestions = await processSuggestions(id)

                    if (!isLoading && suggestions?.suggestions) {
                        setCreatePracticeMode(suggestions?.practice_enable)
                        if (voicesStateRef.current !== "active" && config?.client_settings?.quick_replies_enabled) {
                            setCustomChatSection(<Suggestions
                                onSelectSuggestion={(suggestion) => setChatPrompt(suggestion)}
                                suggestions={suggestions.suggestions} collapsed={false}
                                isPractice={state == 'practice'}/>)
                        }
                    }
                }
            }
            return promptResponse;
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
            setIsStartState(false);
        }
    }, [setSelectedThreadItem, selectedThreadItem, setCustomChatSection, voiceState, setIsLoading, setIsStartState, addMessage, setVoiceState]);

    const processSuggestions = async (id: number) => {
        return getSuggestions(id).then((data) => {
            return {
                suggestions: data?.suggestions?.map((item: string) => {
                    return {
                        title: item,
                    };
                }),
                practice_enable: data?.enable_practice_session
            };
        });
    }
    const processPrompt = async (id: number, prompt: string, voice?: boolean) => {
        return await processChatPrompt(id, prompt, voice);
    };

    const maxStep = () => {
        if (selectedThreadItem?.aiModel == AIModel.journey) {
            return AI_JOURNEY_TOTAL_STEPS
        } else if (selectedThreadItem?.aiModel == AIModel.session) {
            return AI_SESSION_TOTAL_STEPS
        } else {
            return AI_FLASH_TOTAL_STEPS
        }
    }
    return (
        <div className="h-full flex flex-col relative overflow-x-hidden ">
            <ProgressBar maxSteps={maxStep()} currentStep={currentChatStatus.step}
                         isPractice={state == 'practice'}/>
            {/*<button onClick={() => createNewThread(AIModel.session, 485)}>btn</button>*/}
            {/*<button onClick={() => setCustomChatSection(<Questions onSelectQuestion={()=>{}}/>)}>btn</button>*/}
            {/*<button onClick={() => setCustomChatSection(undefined)}>btn</button>*/}
            <div className="h-full flex-auto flex flex-col px-3 pt-9">
                {voiceState !== "not-active" ?
                    <VoiceChat onNewPrompt={(prompt, callback, voice) => handlePrompt(prompt, callback, voice)}/>
                    :
                    <div
                        className={twMerge(
                            "flex flex-col-reverse", //If you need to start the chat by bottom
                            "overflow-y-scroll h-full scrollbar-hide "
                        )}
                    >
                        <AnimatePresence>
                            {isHistoryLoading || isPLoading ? <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                className="flex flex-col w-full items-center justify-center h-full ">
                                {isPLoading ? <Loading messages={spinPMessages} intervalTime={2000}
                                                       spinner_size="32" center={true}/> :
                                    <Spinner size="42" className=""/>}

                            </motion.div> : <Chat/>}
                        </AnimatePresence>
                    </div>}
                {/*Input section*/}
                <div className={twMerge("py-3 duration-300 transition-opacity")}>
                    <div className={`w-full flex ${voiceState === "not-active" ? 'mb-2' : 'mb-8'}`}>
                        {(practiceSessions.length > 0 || createPracticeMode) &&
                            <EdgePanel
                                createPracticeMode={createPracticeMode}
                                setPracticeSessions={setPracticeSessions}
                                items={practiceSessions}
                                setIsPLoading={setIsPLoading}
                                selectedId={selectedPracticeSessionId}
                                setSelectedId={setSelectedPracticeSessionId}
                            />}
                    </div>
                    {voiceState === "not-active" &&
                        <ChatInput
                            isLoading={isLoading}
                            onNewPrompt={(prompt, callback, voice) => handlePrompt(prompt, callback, voice)}
                            placeholder={t("chatbox.text7")}
                        />}
                </div>
            </div>
        </div>
    )
        ;
};

export default ChatBox;
