import { useEffect, useState } from "react";

import moment from "moment";

import ProfileCard from "./../../../../../coaching/pages/Coaching/components/ProfileCard";
import MonthView from "./../../../../../coaching/pages/Coaching/components/MonthView/MonthView";
import EventView from "./../../../../../coaching/pages/Coaching/components/MonthView/EventView";

import { getOccupiedEvents } from "../../../../../../services/api/coachApi";
import { useConfig } from "../../../../../../utils/providers/AppConfigProvider";

import ICoach from "../../../../../../models/ICoach";
import {
  IOccupiedEvents,
  ITime,
  ITimeSlot,
} from "../../../../../../models/IBooking";
import {mergeEventWithUnavailableSlots} from "../../../../../../utils/helpers/mergeSlots";

type Props = {
  coach: ICoach | null;
  timeSlot: ITimeSlot | null;
  setTimeSlot: React.Dispatch<React.SetStateAction<ITimeSlot | null>>;
  occupiedEvents: IOccupiedEvents;
  setOccupiedEvents: (occupiedEvents: IOccupiedEvents) => void;
  handleNext?: () => void;
  isOnboarding?: boolean;
  onGetOccupiedEventsFail: () => void;
};

const ChooseTimeSlot: React.FC<Props> = ({
  coach,
  timeSlot,
  setTimeSlot,
  occupiedEvents,
  setOccupiedEvents,
  handleNext,
  isOnboarding,
  onGetOccupiedEventsFail,
}) => {
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  // const [coach, setCoach] = useState<ICoach | null>(null);
  const { config } = useConfig();

  // start and end of day
  const [dayRange, setDayRange] = useState({
    start: "07",
    end: "21",
  });
  // picking slot size (minutes)
  const [slotSize, setSlotSize] = useState<number>(60);
  // number of minutes a single tile on the event picker represents
  const [slotSeparationSize, setSlotSeparationSize] = useState(15);
  // determines whether the weekends should be disabled or not
  const [weekendsPickable, setWeekendsPickable] = useState(false);

  // useEffect(() => {
  //   setCoach(selectedCoach);
  // }, [selectedCoach]);

  const loadOccupiedDates = (coachId: number, toDate: string) => {
    setIsLoadingEvents(true);
    handleTimeSlotChange("time", null);
    const start = moment().add(1, "days");
    const end = moment(toDate).add(2, "month").endOf("month");

    const dateDiff = Math.abs(moment(start).diff(end, "days"));
    const weekends: { [key: string]: string[] } = {};

    if (!weekendsPickable) {
      //Loops through every day between 'start' and 'end'
      for (let i = 0; i <= dateDiff; i++) {
        const date = moment(start).add(i, "days");
        //Disable weekends
        if (date.day() === 0 || date.day() === 6) {
          weekends[date.format("YYYY-MM-DD")] = [
            `${dayRange.start}:00-${dayRange.end}:00`,
          ];
        }
      }
    }

    const startStr = start.format("YYYY-MM-DD");
    const endStr = end.format("YYYY-MM-DD");

    const endOfNotAvailable = moment().add(24, "hours").format("HH:mm");
    const notAvailableSlots: {[key: string]: string[]} = {};
    notAvailableSlots[startStr] = [`${dayRange.start}:00-${endOfNotAvailable}`];

    getOccupiedEvents(String(coachId), startStr, endStr)
      .then((response) => {
        if (response.error && response.data) {
          onGetOccupiedEventsFail();
          return
        }
        setOccupiedEvents({ ...mergeEventWithUnavailableSlots(response.data.events, notAvailableSlots), ...weekends});
        handleTimeSlotChange("coachTimezone", response.data.coach_time_zone);
      })
      .catch(() => {
        onGetOccupiedEventsFail();
      })
      .finally(() => {
        setIsLoadingEvents(false);
      });
  };

  const handleTimeSlotChange = (
    field: string,
    value: string | ITime | null,
  ) => {
    setTimeSlot((currVal) => {
      const updatedTimeSlot = { ...currVal, [field]: value };
      return updatedTimeSlot as ITimeSlot;
    });
  };

  useEffect(() => {
    if (!coach) return;
    let duration = config?.session_duration;

    if (coach.orientation) {
      duration = config?.orientation_call_duration || 15;
    }

    if (duration) {
      setSlotSize(Number(duration));
    }
    loadOccupiedDates(coach.id, moment().toString());
  }, [coach]);

  const coachName = coach ? `${coach.first_name} ${coach.last_name}` : "-";
  const coachImageUrl = coach ? coach.image_url : "";
  return (
    <div className="w-full flex flex-col items-stretch md:flex-wrap md:flex-row my-4 gap-4">
      <div className="md:w-5/12 lg:w-4/12 xl:w-3/12">
        <ProfileCard
          name={coachName}
          imageUrl={coachImageUrl}
          isOrientation={coach?.orientation}
        />
      </div>
      <div className="md:flex-1 lg:w-5/12">
        <MonthView
          onChange={handleTimeSlotChange}
          selectedCoach={coach}
          loadOccupiedDates={loadOccupiedDates}
          timeSlot={timeSlot}
          isLoading={isLoadingEvents}
          occupiedEvents={occupiedEvents}
        />
      </div>
      <div className="md:w-full lg:w-4/12">
        <EventView
          timeSlot={timeSlot}
          occupiedEvents={occupiedEvents}
          onChange={handleTimeSlotChange}
          handleNext={handleNext}
          isOnboarding={isOnboarding}
          slotSize={slotSize}
          dayRange={dayRange}
          slotSeparationSize={slotSeparationSize}
          isLoading={isLoadingEvents}
        />
      </div>
    </div>
  );
};

export default ChooseTimeSlot;
