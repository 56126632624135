import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import CoachCard from "./CoachCard";
import HighlightedCoaches from "./HighlightedCoaches/HighlightedCoaches";
import LoaderContainer from "../../../../../shared/components/LoaderContainer";
import ChatGuide from "../../../../../shared/components/ChatGuide";
import InfoPopover from "../../../../dashboard/components/InfoPopover";
import HT from "../../../../dashboard/pages/OnboardingRegular/components/HighlightedText";

import { getCoach } from "../../../../../services/api/coachApi";
import ICoach from "../../../../../models/ICoach";
import OrientationCoachCard from "./OrientationCoachCard";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";

type Props = {
  coaches: ICoach[];
  selectedCoach: ICoach | null;
  handleSelectCoach: (coach: ICoach, orientation?: boolean) => void;
  playCoachVideo: (url: string, title: string) => void;
  onMount?: () => void;
  isOnboarding?: boolean;
};

const Coaches = ({
  coaches,
  selectedCoach,
  handleSelectCoach,
  playCoachVideo,
  onMount,
  isOnboarding,
}: Props) => {
  const { t } = useTranslation("booking");

  const {config} = useConfig();

  const [isAllCoaches, setIsAllCoaches] = useState(true);

  const [orientationCoaches, setOrientationCoaches] = useState<ICoach[]>([]);

  const [recommendedCoaches, setRecommendedCoaches] = useState<ICoach[]>([]);

  const [previouslyBookedCoach, setPreviouslyBookedCoach] = useState<ICoach>();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onMount?.();
  }, []);

  useEffect(() => {
    const foundReservedCoach = coaches.find((coach) => coach.reserved);
    const filteredOrientationCoaches = coaches.filter(
      (coach) => coach.orientation,
    );
    const filteredRecommendedCoaches = coaches.filter(
      (coach) => coach.recommended,
    );
    if (coaches.length) {
      if (
        filteredOrientationCoaches.length ||
        filteredRecommendedCoaches.length ||
        foundReservedCoach
      ) {
        setPreviouslyBookedCoach(foundReservedCoach);
        setOrientationCoaches(filteredOrientationCoaches);
        setRecommendedCoaches(filteredRecommendedCoaches);
        setIsAllCoaches(false);
      } else {
        setIsAllCoaches(true);
      }
      setIsLoading(false);
    }
  }, [coaches]);

  const handleOrientationSession = () => {
    const coachId = config?.orientation_coaches?.[0] || 11;
    getCoach(coachId, true).then((coach) => handleSelectCoach(coach, true));
  };

  const highlightedTextEventHandlers = [
    undefined,
    undefined,
    undefined,
    handleOrientationSession,
  ];

  const regEx = /(\*[^*]+\*|[^*]+)/g;
  const parts = t("coaches.description").split(regEx);

  const getStepOneDescription = () => {
    let currentHighlightTextNo = 1;
    return parts.map((part, index) => {
      if (part.includes("*")) {
        const clickHandler =
          highlightedTextEventHandlers[currentHighlightTextNo - 1];
        currentHighlightTextNo += 1;
        return (
          <HT
            key={index}
            className={`${clickHandler !== undefined ? "cursor-pointer" : ""}`}
            onClick={clickHandler}
          >
            {part.split("*")[1].split("*")[0]}
          </HT>
        );
      }
      return part;
    });
  };
  const step_1_desc = useMemo(() => getStepOneDescription(), []);

  return (
    <>
      {isOnboarding ? (
        <ChatGuide
          title={
            <div className="flex flex-row justify-between items-center gap-4">
              <HT>{t("coaches.title")}</HT>
              <InfoPopover content={step_1_desc} />
            </div>
          }
          descriptionClassName="font-normal"
        />
      ) : null}
      {isLoading ? (
        <LoaderContainer />
      ) : !isAllCoaches ? (
        <HighlightedCoaches
          previouslyBookedCoach={previouslyBookedCoach}
          orientationCoaches={orientationCoaches}
          recommendedCoaches={recommendedCoaches}
          handleSelectCoach={handleSelectCoach}
          selectedCoach={selectedCoach}
          playCoachVideo={playCoachVideo}
          showAllCoaches={() => setIsAllCoaches(true)}
        />
      ) : (
        <div className="grid grid-cols-4 w-full gap-x-5 gap-y-5 mt-5 pb-8">
          {coaches.map((coach) =>
            coach.orientation ? (
              <OrientationCoachCard
                key={coach.id}
                coach={coach}
                onSelect={() => handleSelectCoach(coach, true)}
                isSelected={selectedCoach?.id === coach.id}
                playCoachVideo={playCoachVideo}
              />
            ) : (
              <CoachCard
                key={coach.id}
                coach={coach}
                onSelect={() => handleSelectCoach(coach)}
                isSelected={selectedCoach?.id === coach.id}
                playCoachVideo={playCoachVideo}
              />
            ),
          )}
        </div>
      )}
    </>
  );
};

export default Coaches;
