export interface ReactiveCalanderProps {
  events: [];
  customSetting: [];
  dispatch: (action: any) => void;
  settingDispatch: (action: any) => void;

}

export interface BeforeAfterObjectState {
  isTrue: boolean;
  time: string;
}

export interface CalanderModalContainerProps {
  eventArgs: any;
  dispatch: (action: any) => void;
  settingDispatch: (action: any) => void;
  customSetting:any;
  setIsOpen: (action: any) => void;
  eventAdd: () => void;
  updateCalendar?: () => void;
  setIsBooking?: (action: any) => void;
  rescheduleEvent?: boolean;
  rescheduleDetails?: any;
}

export const headerToolBarUtil = {
  left: "title prev next dayGridMonth,timeGridWeek,timeGridDay",
  //   center: ,
  right: "settings",
};

export const headerBtnText = {
  dayGridMonth: "Month",
  timeGridWeek: "Week",
  timeGridDay: "Day",
};

export const ACTION = {
  ADD_EVENTS: "addEvents",
  ADD_NEW_SETTINGS: "addNewSettings",
  NEXT: "next",
  PREV: "prev",
  DAY_GRID_MONTH: "dayGridMonth",
  TIME_GRID_WEEK: "timeGridWeek",
  TIME_GRID_DAY: "timeGridDay",
  BEFORE_THE_TIME: "before",
  AFTER_THE_TIME: "after",
  WEEK_VIEW: "week",
  MONTH_VIEW: "month",
};

export const COMPONENTS_KEY = {
  personalUnavailability: "PERSONAL_UNAVAILABILITY",
  bookOnBehalf: "BOOK_ON_BEHALF",
  modalContainer: "MODAL_CONTAINER",
  settingsModal: "SETTINGS_MODAL",
};

export const currentComponentId = COMPONENTS_KEY.modalContainer;