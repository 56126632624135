import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Unauthorized: React.FC = () => {
    const {t} = useTranslation("commons");
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/");
    };

    return (
        <div className="flex flex-col gap-3 py-20 px-6 justify-center items-center bg-white min-h-screen">
            <h2 className="font-bold text-3xl text-center text-black font-dm_sans pb-2">
                {t("unauthorized.title")}
            </h2>
            <p className="text-base py-2 pb-6 font-dm_sans text-gray-500 text-center max-w-4xl">
                {t("unauthorized.sub_text")}
            </p>
            <button
                className="hover:bg-black dashboard-button bg-red rounded-md p-2 px-5 text-white flex gap-2 items-center transition duration-200"
                onClick={handleGoHome}>
                {t("unauthorized.button_text")}
            </button>
        </div>
    )
};

export default Unauthorized;
