import { useState } from "react";

import { useTranslation } from "react-i18next";

import HT from "./HighlightedText";
import VerticalExpander from "./VerticalExpander";
import ChatGuide from "../../../../../shared/components/ChatGuide";
import InfoPopover from "../../../components/InfoPopover";
import { AssessmentFr } from "../../../../teams/pages/AssessmentFr";
import AssessmentV2 from "../../../../teams/AssessmentV2";

import selfReflectionQuiz from "@img/dashboard/welcome/icon/map.svg";

type Props = {
  onSubmitAssessment: () => void;
  description?: string;
};

const AssessmentStep = ({ onSubmitAssessment,  description}: Props) => {
  const { t } = useTranslation("welcome");
  const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
  const [showContent, setShowContent] = useState(false);

  return (
    <div className="flex flex-col flex-1">
      <ChatGuide
        title={<HT>{t("step_4.title")}</HT>}
        description={description || t("step_4.description")}
        descriptionClassName="font-medium"
        onShowContent={() => setShowContent(true)}
        onboarding
      />
      <VerticalExpander expanded={showContent}>
        <div className="min-h-[750px] w-full flex flex-col p-6 rounded-md my-8 bg-white">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-3">
              <img
                src={selfReflectionQuiz}
                className="w-8"
                alt="Self-reflection quiz"
              />
              <HT className="text-xl font-bold font-lexend">
                {t("step_4.reflection_quiz")}
              </HT>
              <span
                className={`px-5 py-2 rounded-full text-xs text-black bg-[#E3F8EE]`}
              >
                {t("step_4.quiz")}
              </span>
            </div>
            <InfoPopover content={t("step_4.info")} />
          </div>
          <div className="h-full">
            {lng === "fr" ? (
              <AssessmentFr style={{ height: "100%" }} />
            ) : (
              <AssessmentV2
                onSubmit={onSubmitAssessment}
                style={{ height: "100%" }}
              />
            )}
          </div>
        </div>
      </VerticalExpander>
    </div>
  );
};

export default AssessmentStep;
