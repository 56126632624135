import React, {useImperativeHandle, useState} from "react";

import Modal from "../../modules/dashboard/components/Modal";
import {AssessmentFr} from "../../modules/teams/pages/AssessmentFr";
import {Assessment} from "../../modules/teams/pages/Assessment";
import Feedback360Emails from "../../modules/teams/pages/Feedback360Emails";

import {quizData} from "../../constants";
import {EAssessmentType} from "../../constants/enums";
import {ImpactAssessment} from "../../modules/dashboard/components/ImpactAssessment";
import ReviewModal from "../../modules/teams/pages/Review";
import {AICoachType} from "../../modules/dashboard/pages/AICoaching/enum";
import useAuth from "@hooks/useAuth";
import AssessmentAI from "../../modules/teams/AssessmentAI";
import {twMerge} from "tailwind-merge";
import {setImpactReflectionStatus} from "../../services/api/clientApi";

const FrameModal: React.ForwardRefRenderFunction<{
    open: (
        key: EAssessmentType | string,
        title: string,
        frameElementType?: "iframe" | "video",
        sessionId?: number,
        size?: number
    ) => void;
}, { onClose?: (e?: any) => any; }> = (_, ref) => {
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const {user} = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState<{
        child: React.ReactNode | string;
        title: string;
        frameElementType: string;
        size?: number;
    }>();
    const aiOnlyCoach = user?.aiCoachType && user.aiCoachType === AICoachType.ai_only;

    const handleClose = (result?: any) => {
        if (result) {
            _.onClose && _.onClose(result);
        } else {
            _.onClose && _.onClose();
        }
        setIsOpen(false);
        setTimeout(() => {
            setContent(undefined);
        }, 300);
    }

    const handleSubmitImpactAssessment = () => {
        if (user){
            setImpactReflectionStatus(user.id, true)
                .then(() => {})
        }
    }

    useImperativeHandle(ref, () => ({
        open: (key, title, frameElementType = "video", sessionId, size) => {
            let assessmentElement;
            switch (key) {
                case quizData.self_reflection.key:
                    if (aiOnlyCoach) {
                        assessmentElement = <AssessmentAI/>
                    } else {
                        assessmentElement = lng === "fr" ? <AssessmentFr/> : <Assessment/>;
                    }
                    break;
                case quizData.reflection360.key:
                    assessmentElement = <Feedback360Emails onNotificationSuccess={handleClose}/>;
                    break;
                case quizData.impact_reflection.key:
                    assessmentElement = <ImpactAssessment onSubmit={handleSubmitImpactAssessment}/>;
                    break;
                case quizData.review.key:
                    assessmentElement = <ReviewModal onClose={handleClose} meetingId={sessionId}/>
                    break;
            }

            setContent({
                child: key.includes("http") ? key : assessmentElement,
                title,
                frameElementType,
                size
            });
            setIsOpen(true);
        },
    }));

    return (
        <Modal
            title={content ? content.title : ""}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <div className={twMerge("min-h-[30vh] max-h-[70vh] overflow-y-scroll scrollbar-hide")}
                 style={{"width": content?.size ? 'w-auto' : '60vw'}}>
                {content && (
                    <>
                        {typeof content.child === "string" ? (
                            <>
                                {content.frameElementType === "video" ? (
                                    <video
                                        className="h-[60vh] w-full"
                                        src={content.child}
                                        title={content.title}
                                        autoPlay
                                        controls
                                    />
                                ) : (
                                    <iframe
                                        className="h-[60vh] w-full"
                                        src={content.child}
                                        title={content.title}
                                    />
                                )}
                            </>
                        ) : (
                            content.child
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default React.forwardRef(FrameModal);
