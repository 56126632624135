export const CLIENT = {
  put: {
    setSessionLimit: {
      private: true,
      path: "api/clients/{client_id}/session-limit",
    },
    setClientPreferences: {
      private: true,
      path: "api/clients/me/preference",
    },
    setImpactReflectionStatus: {
      private: true,
      path: "api/clients/me/impact-reflection",
    }
  },
  get: {
    getCurrentProgress: {
      private: true,
      path: "api/clients/me/progress",
    },
    getAppConfig: {
      private: true,
      path: "api/clients/me/app-config",
    },
    getPreviousGroupSessions: {
      private: true,
      path: "api/clients/me/previous-group-meetings",
    }
  },
};
