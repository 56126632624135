import React, {FC, useEffect, useState} from "react";
import {Filters} from "../../../../../constants/types";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/performance.json";
import Fr from "../../../locale/fr/performance.json";
import {useTranslation} from "react-i18next";
import {getMaxValueInInnerKey} from "../../../../../utils/helpers/object";
import {getBehaviors, getCustomFields} from "../../../../../services/api/companyApi";
import {IAuthUser} from "../../../../../models/IAuth";
import TemplateChartSection from "../../../../../shared/components/TemplateChartSection";
import CustomBarChart from "../../../../../shared/components/BarChart/CustomBarChart";
import {DUMMY_CHART_DATA} from "../../../../../constants/data/dummyChartData";

enum DataState {
    NO_DATA = 'NO_DATA',
    NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
    DATA = 'DATA'
}

interface OrganizationSectionProps {
    filters: Filters;
    isFiltered: boolean;
    user: null | IAuthUser;
    SectionTitle: FC<{ title: string }>;
    getDataState: (isNoData: boolean, isFiltered: boolean) => DataState;
    getSectionGrid: (charts: any[]) => JSX.Element[];
}

const OraganizationSection:FC<OrganizationSectionProps> = ({filters, isFiltered, user, SectionTitle, getSectionGrid, getDataState}) => {
    i18n.addResourceBundle("en", "performance", ENG);
    i18n.addResourceBundle("fr", "performance", Fr);
    const {t} = useTranslation("performance");
    const lng = i18n.language;

    const [behaviors, setBehaviors] = useState<Record<any, any>[]>([]);
    const [customFields, setCustomFields] = useState<Record<any, any>[]>([]);

    const maxBehaviorValue =
        behaviors.length > 0
            ? getMaxValueInInnerKey(behaviors, "count").count + 1
            : 10;
    const maxCustomFieldsValue =
        customFields?.length > 0
            ? getMaxValueInInnerKey(customFields, "count").count + 1
            : 10;

    useEffect(() => {
        getBehaviors(user?.companyId!, filters)
            .then((res) => {
                setBehaviors(res.behaviors);
            })
            .catch((e) => {
                console.log("api error");
            });
        getCustomFields(user?.companyId!, filters)
            .then((res) => {
                setCustomFields(res);
            })
            .catch((e) => {
                console.log("api error");
            });
    }, [filters]);

    const getRandomDummyData = () => {
        const shuffled = DUMMY_CHART_DATA.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 5);
    }

    const behaviorsDataState = getDataState(behaviors?.every(entry => entry.count === 0), isFiltered);

    const Behaviors = () => (
        <TemplateChartSection
            title={t("text18")}
            toolTip={['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) ? t("text18_1") : undefined}
            dataState={behaviorsDataState}
        >
            <CustomBarChart
                showIcon={false}
                range={[0, maxBehaviorValue]}
                data={
                    behaviorsDataState === DataState.DATA ? (
                        behaviors?.map((behavior) => {
                            return {
                                label: behavior[lng],
                                value: behavior.count,
                                color: behavior.color
                            };
                        })
                    ) : (
                        getRandomDummyData().map((dummyItem) => {
                            return {
                                label: dummyItem.label,
                                value: dummyItem.value,
                                color: dummyItem.color
                            };
                        })
                    )
                }
                legendName={t("barchart3.text7")}
                showXPercentage={true}
                showPercentageLabel={true}
                tooltipStyle="style2"
            />
        </TemplateChartSection>
    );

    const CustomFieldCharts = customFields?.map(
        (field: Record<string, any>) => {
            const fieldDataState = getDataState(!(field.options.length > 0), isFiltered);
                return (
                    <TemplateChartSection
                        title={field[`label${lng == "en" ? "" : "_" + lng}`]}
                        dataState={fieldDataState}
                    >
                        <CustomBarChart
                            showIcon={false}
                            range={[0, maxCustomFieldsValue]}
                            data={
                                fieldDataState === DataState.DATA ? (
                                    field.options?.map((option: any) => {
                                        return {label: option[lng], value: option.count};
                                    })
                                ) : (
                                    getRandomDummyData().map((dummyItem) => {
                                        return {label: dummyItem.label, value: dummyItem.value}
                                    })
                                )
                            }
                            legendName={t("barchart3.text7")}
                        />
                    </TemplateChartSection>
                );
        }
    );

    const allCharts = [
        ['Enedis-Pilot', 'Enedis', "DemoCompanySlack"].includes(user?.companyName!) && <Behaviors/>,
        ...(customFields ? CustomFieldCharts : []),
    ];

    const rows: JSX.Element[] = getSectionGrid(allCharts);

    return (
        <div>
            {rows.length > 0 && (
                <div>
                    {['Enedis-Pilot', 'Enedis'].includes(user?.companyName!) ? (
                        <SectionTitle title={t("text25_1")}/>
                    ) : (
                        <SectionTitle title={t("text25")}/>
                    )}
                    {rows}
                </div>
            )}
        </div>
    );
}

export default OraganizationSection;
