import React from "react";

interface LoaderProps {
    color1?: string;
    color2?: string;
    color3?: string;
    donut?: boolean;
}

const ListeningLoader = ({color1 = '#D9D9D9', color2 = '#FD0054'}: LoaderProps) => {
    const numberOfDots = 6;
    const delayIncrement = 0.3;

    return (
        <>
            <style>
                {`
                    @keyframes colorPulse {
                        0%, 100% { background-color: ${color1}; }
                        50% { background-color: ${color2}; }
                    }
                `}
            </style>
            <div className="flex items-center justify-center space-x-1.5">
                {Array.from({length: numberOfDots}).map((_, index) => (
                    <div
                        key={index}
                        className="w-3 h-3 rounded-full"
                        style={{
                            backgroundColor: color1,
                            animation: "colorPulse 1.5s ease-in-out infinite",
                            animationDelay: `${index * delayIncrement}s`,
                        }}
                    ></div>
                ))}
            </div>
        </>
    );
};

export default ListeningLoader;
