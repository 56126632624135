import {useState, useRef, useCallback} from 'react';

interface UseAudioRecorderProps {
    onData: (data: any) => void;
    mimeType?: string;
}

interface UseAudioRecorderReturn {
    isRecording: boolean;
    startRecording: () => Promise<void>;
    stopRecording: () => void;
}

export const useAudioRecorder = ({onData, mimeType = 'audio/webm'}: UseAudioRecorderProps): UseAudioRecorderReturn => {
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioStreamRef = useRef<MediaStream | null>(null);
    const startRecording = useCallback(async () => {
        if (isRecording) return;
        try {
            audioStreamRef.current = await navigator.mediaDevices.getUserMedia({audio: true});
            mediaRecorderRef.current = new MediaRecorder(audioStreamRef.current, {mimeType});
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const bytes = new Uint8Array(reader.result as ArrayBuffer);
                        // console.log('Received data received', bytes);
                        onData(bytes);
                    }
                    reader.readAsArrayBuffer(event.data);
                }
            };
            mediaRecorderRef.current.onstart = () => setIsRecording(true);
            mediaRecorderRef.current.onstop = () => setIsRecording(false);
            mediaRecorderRef.current.start(50);
        } catch (error) {
            console.error("Error starting audio recording:", error);
        }
    }, [isRecording, onData, mimeType]);
    const stopRecording = useCallback(() => {
        mediaRecorderRef.current?.stop();
        audioStreamRef.current?.getTracks().forEach((track) => track.stop());
        setIsRecording(false);
    }, []);
    return {
        isRecording,
        startRecording,
        stopRecording,
    };
};