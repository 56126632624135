import {ChevronDownIcon, XIcon} from "@heroicons/react/outline";
import React from 'react';

type CalendarButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
    onClear?: () => void;
    hasValue?: boolean;
};

const CalenderButton = ({
                            onClear,
                            hasValue,
                            className = '',
                            value,
                            ...props
                        }: CalendarButtonProps) => {
    const handleClear = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClear?.();
    };

    return (
        <div className={`py-2 relative mx-auto text-gray-600 w-full ${className}`}>
            <input
                {...props}
                value={hasValue ? '' : value}
                className={`w-full border-2 border-gray-light bg-white h-10 px-5 pr-16 
                    rounded focus:outline-none text-sm ${className}`.trim()}
            />

            {hasValue && (
                <>
                    <div className="absolute left-3 top-[16px] bg-[#E6E6E6] rounded-sm flex items-center py-1 px-1.5">
                        <div className="text-gray-600 text-xs flex-grow truncate">
                            {value}
                        </div>
                        <button
                            type="button"
                            className="ml-2"
                            onClick={handleClear}
                        >
                            <XIcon className="text-gray-600 h-3 w-3 stroke-[3px]"/>
                        </button>
                    </div>
                </>
            )}

            <div
                className="absolute right-3 top-[18px] py-0.5 pl-2.5 border-l-2 border-l-gray-300 stroke-[3px] opacity-40">
                <ChevronDownIcon className="text-gray-300 hover:text-gray-900 h-4 w-4 stroke-[3px]"/>
            </div>
        </div>
    );
};

export default CalenderButton;
