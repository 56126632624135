export const DUMMY_CHART_DATA = [
    { label: 'Career', value: 5, color: "#B6E2A1" },
    { label: 'Self Development', value: 8, color: "#FFB26B" },
    { label: 'Communication', value: 3, color: "#8093FF" },
    { label: 'Leadership', value: 7, color: "#FF7F50" },
    { label: 'Creativity', value: 2, color: "#A0CED9" },
    { label: 'Problem Solving', value: 6, color: "#C0C0C0" },
    { label: 'Teamwork', value: 4, color: "#FFD700" },
    { label: 'Adaptability', value: 1, color: "#87CEEB" },
    { label: 'Time Management', value: 9, color: "#90EE90" },
    { label: 'Decision Making', value: 5, color: "#FF69B4" },
];

export const DUMMY_BOOKING_DATA = [
    { count : 1, label : "02-17-24" },
    { count : 2, label : "02-20-24" },
    { count : 3, label : "02-23-24" },
    { count : 0, label : "02-27-24" },
    { count : 1, label : "03-02-24" },
]

export const DUMMY_SESSION_USER_DATA = [
    { label: "1 session", count: 1, percentage: 9.09 },
    { label: "2 sessions", count: 4, percentage: 9.09 },
    { label: "3 sessions", count: 3, percentage: 9.09 },
    { label: "4 sessions", count: 1, percentage: 9.09 },
    { label: "5 sessions", count: 1, percentage: 9.09 },
    { label: "8 sessions", count: 1, percentage: 9.09 }
];

export const DUMMY_SESSION_CHART_DATA = [
    {label: "2 or more sessions", percentage: 90},
    {label: "3 or more sessions", percentage: 50},
    {label: "4 or more sessions", percentage: 27}
];

export const DUMMY_AI_COACH_USERS = {
    company_id: 1,
    session_counts: [
        { session_count: 1, user_count: 4, percentage: 56.7},
        { session_count: 2, user_count: 4, percentage: 56.7},
        { session_count: 3, user_count: 3, percentage: 56.7},
        { session_count: 4, user_count: 5, percentage: 56.7},
        { session_count: 5, user_count: 9, percentage: 56.7},
        { session_count: 6, user_count: 8, percentage: 56.7},
    ],
    summed_percentages: {
        "2_or_more_sessions": 90,  
        "3_or_more_sessions": 50,
        "4_or_more_sessions": 27,
    }
}

export const DUMMY_RATING_DATA = [
    { rating: 1, session_count: 7 },
    { rating: 2, session_count: 1 },
    { rating: 3, session_count: 1 },
    { rating: 4, session_count: 4 },
    { rating: 5, session_count: 17 },
];

export const DUMMY_TOPIC_DATA = [
    { label: 'Communication Skills', value: 3, color: "#FFF3B6" },
    { label: 'Leadership Skills', value: 2, color: "#FFF3B6" },
    { label: 'Problem-solving', value: 4, color: "#FFF3B6" },
    { label: 'Self-awareness', value: 1, color: "#FFF3B6" },
    { label: 'Organization and time management', value: 1, color: "#FFF3B6" },
];

export const DUMMY_COACHING_STATUS_DATA = [
    { label: 'Flash Sessions', value: 30, color: "#FFF3B6" },
    { label: 'Coaching Journeys', value: 67, color: "#FECBC4" },
];

export const DUMMY_SKILLS_DATA = [
    { label: 'Hard Skills', value: 75, color: "#B4E1C6" },
    { label: 'Soft Skills', value: 25, color: "rgb(172, 199, 232)" },
];

export const DUMMY_ACTION_PLANS_DATA = [
    { label: 'Actions were not implemented', value: 75, color: "#B4E1C6" },
    { label: 'Actions Implemented', value: 25, color: "#FFF3B6" },
];

export const DUMMY_SESSION_USEFULNESS_DATA = [
    { label: 'Useful', value: 67, color: "#B4E1C6" },
    { label: 'Dissatisfied', value: 15, color: "#FECBC4" },
];

export const DUMMY_COMPLETION_RATE_DATA = [
    { label: 'Completed', value: 20, color: "#B4E1C6" },
    { label: 'Incomplete', value: 10, color: "#FFF3B6" },
    { label: 'No message', value: 5, color: "#FECBC4" },
];

export const DUMMY_LANGUAGE_DATA = [
    { label: 'English', value: 60, color: "#D6CAB3" },
    { label: 'Chinese', value: 5, color: "#D6CAB3" },
    { label: 'German', value: 35, color: "#D6CAB3" },
];

export const DUMMY_LABEL_DATA = [
    { label: 'US', value: 50 },
    { label: 'AMEA', value: 25 },
    { label: 'Europe', value: 15 },
    { label: 'India', value: 10 },
];

export const DUMMY_FREQUENCY_DATA = [
    { "label": "Sep 2024", "count": 1},
    { "label": "Sep 02 - Sep 08", "count": 0 },
    { "label": "Aug 26 - Sep 01", "count": 2 },
    { "label": "Aug 19 - Aug 25", "count": 0 },
    { "label": "Aug 12 - Aug 18", "count": 2 },
    { "label": "Aug 05 - Aug 11", "count": 0 },
    { "label": "Sep 24 - Sep 28", "count": 2 },
    { "label": "Jul 22 - Jul 28", "count": 0 },
    { "label": "Jul 15 - Jul 21", "count": 0 },
    { "label": "Jul 08 - Jul 14", "count": 3 },
    { "label": "Jul 15 - Jul 21", "count": 0 },
    { "label": "Jul 08 - Jul 14", "count": 3 }
]

export const DUMMY_RADAR_DATA = [
    { label: 'work_environment', value: 13.83 },
    { label: 'stress_and_anxiety', value: 12.27  },
    { label: 'quality_of_life', value: 11.76  },
    { label: 'leadership', value: 12.99  },
    { label: 'communication', value: 12.66 },
    { label: 'self_awareness', value: 12.45 },
    { label: 'career_development', value: 12.84 },
    { label: 'job_performance', value: 11.19 },
];
