import React, {useEffect, useState} from 'react'
import {ReferenceData, ReferenceItem} from '../../AICoaching/types'
import ENG from "../../../locale/eng/resources.json";
import FR from "../../../locale/fr/resources.json";

import i18n from "../../../../../utils/lib/i18n";
import {useTranslation} from "react-i18next";
import {getAllRecommendations, updateContentState} from '../../../../../services/api/aiBookingApi'
import {useNavTitle} from "@hooks/useNavTitle";
import InfoCard from '../../../../../shared/components/InfoCard/InfoCard';
import LoaderContainer from '../../../../../shared/components/LoaderContainer';
import NoDataIcon from "@icons/noDataIcon.png";
import ArrowRight from "../../Home/components/ArrowRight";
import Button from "../../../../../shared/components/Button";
import {useNavigate} from "react-router-dom";
import useAuth from "@hooks/useAuth";
import {AICoachType} from "../../AICoaching/enum";

import {IContentState} from "../../../../../constants/types"
import {ContentState} from "../../../../../constants/enums";

type AIResourcesProps = {
    client_id: number | undefined;
}

export const NoDataWrapperArticles = () => {

    i18n.addResourceBundle("en", "resources", ENG);
    i18n.addResourceBundle("fr", "resources", FR);

    const {t} = useTranslation("resources");

    return (
        <div style={{borderRadius: 'inherit'}}
             className="z-10 inset-0 backdrop-blur-md flex flex-col items-center justify-center text-center p-7 gap-2 overflow-hidden">
            <img src={NoDataIcon} width={72} height={72}/>
            <h4 className="md:text-sm text-xs">{t('ai_resources.no_data')}</h4>
            {/* <p className="md:text-sm text-xs">{t('no_data_card.text2')}</p> */}
        </div>
    )
}

function AIResources({client_id}: AIResourcesProps) {

    const [allReferenceMaterials, setAllReferenceMaterials] = useState<ReferenceData>({})
    const [isLoading, setIsLoading] = useState(true);

    i18n.addResourceBundle("en", "resources", ENG);
    i18n.addResourceBundle("fr", "resources", FR);

    const {t} = useTranslation("resources");
    useNavTitle(t(`layout:sidebar.text13_1`));

    const navigate = useNavigate();
    const { user } = useAuth();

    const fetchRecommendations = () => {
        setIsLoading(true);
        if (client_id) {
            getAllRecommendations(client_id)
                .then((data: ReferenceItem[]) => {
                    const categorizedData = data.reduce<ReferenceData>((acc, item) => {
                        switch (item.media_type) {
                            case 'article':
                                if (!acc.articles) acc.articles = [];
                                acc.articles.push(item);
                                break;
                            case 'video':
                                if (!acc.youtubes) acc.youtubes = [];
                                acc.youtubes.push(item);
                                break;
                            case 'podcast':
                                if (!acc.podcasts) acc.podcasts = [];
                                acc.podcasts.push(item);
                                break;
                            default:
                                break;
                        }
                        return acc;
                    }, {});
                    setAllReferenceMaterials(categorizedData);
                })
                .catch((error) => {
                    console.error('Error fetching recommendations:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    };


    useEffect(() => {
        fetchRecommendations();
    }, [client_id]);

    const handleAction = () => {
        if (user?.aiCoachType === AICoachType.ai_only) {
            navigate("/dashboard/ai/coaching");
        } else {
            navigate("/dashboard/coach/booking");
        }
    }
    
    const updateItemStatus = (contentId: number, data: IContentState) => {

        setAllReferenceMaterials((prevMaterials) => {
            const newMaterials = { ...prevMaterials };
            for (const key in newMaterials) {
                if (Object.prototype.hasOwnProperty.call(newMaterials, key)) {
                    const materialArray = newMaterials[key as keyof ReferenceData];
                    if (materialArray) {
                        newMaterials[key as keyof ReferenceData] = materialArray.filter(item => item.id !== contentId);
                    }
                }
            }
            return newMaterials;
        });

        updateContentState(contentId, data)
            .catch(err => {
                console.error("Error updating the article state", err);
            });
    };

    const handleClickState = (contentId: number, data: IContentState) => {
        if(data.content_state == ContentState.irrelevant || data.content_state == ContentState.removed) {
            updateItemStatus(contentId, data);
        } else {
            updateContentState(contentId, data)
                .catch(err => {
                    console.error("Error updating the article state", err);
                });
        }
    }

    const NoDataWrapper = () => {
        return (
            <div style={{borderRadius: 'inherit'}}
                 className="z-10 absolute inset-0 backdrop-blur-md flex flex-col items-center justify-center text-center p-7 gap-2 overflow-hidden">
                <img src={NoDataIcon} width={36} height={36}/>
                <h4 className="md:text-sm text-xs">{t('ai_resources.no_data')}</h4>
                <p className="md:text-sm text-xs">{t('ai_resources.no_data_text1')} <br/> {t('ai_resources.no_data_text2')} </p>
                <div className="mt-1">
                    <Button
                        className="px-14 text-white border-2 border-red bg-red text-xs font-bold h-max py-2"
                        onClick={handleAction}
                    >
                        {t('ai_resources.schedule_session')} <ArrowRight />
                    </Button>
                </div>
            </div>
        )
    }

    if (isLoading)
        return <div className="inset-0 fixed">
            <LoaderContainer/>
        </div>
    else if (allReferenceMaterials.articles == undefined && allReferenceMaterials.youtubes == undefined && allReferenceMaterials.podcasts == undefined)
        return <NoDataWrapper/>
    else
        return (
            <div className='px-4'>
                <p className="font-bold text-xl pt-3">{t("ai_resources.title")}</p>
                {allReferenceMaterials.articles &&
                    <>
                        <p className="font-medium text-xl py-5">{t("ai_resources.articles")}</p>
                        <div className="flex w-full overflow-x-auto">
                            <div className="flex ">
                                {allReferenceMaterials.articles.map((item, index) => (
                                    <div className='w-[40vh] pr-3'>
                                        <InfoCard
                                            title={item.title}
                                            imageUrl={item.thumbnail}
                                            linkUrl={item.url}
                                            type='article'
                                            className={{font: "font-bold", img: "object-cover h-[20vh] w-full"}}
                                            contentId={item.id}
                                            markAsRead={item.mark_as_read}
                                            saved={item.saved}
                                            handleClick={handleClickState}
                                            irrelevant={item.irrelevant}
                                            removed={item.removed}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                {allReferenceMaterials.youtubes &&
                    <>
                        <p className="font-medium text-xl py-5">{t("ai_resources.videos")}</p>
                        <div className="flex w-full overflow-x-auto">
                            <div className="flex ">
                                {
                                    allReferenceMaterials.youtubes.map((item, index) => (
                                        <div className='w-[40vh] pr-3'>
                                            <InfoCard
                                                title={item.title}
                                                imageUrl={item.thumbnail}
                                                linkUrl={item.url}
                                                type='video'
                                                className={{font: "font-bold", img: "object-cover h-[20vh] w-full"}}
                                                contentId={item.id}
                                                markAsRead={item.mark_as_read}
                                                saved={item.saved}
                                                handleClick={handleClickState}
                                                irrelevant={item.irrelevant}
                                                removed={item.removed}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>}
                {allReferenceMaterials.podcasts &&
                    <>
                        <p className="font-medium text-xl py-5">{t("ai_resources.podcasts")}</p>
                        <div className="flex w-full overflow-x-auto">
                            <div className="flex mr-2">
                                {
                                    allReferenceMaterials.podcasts.map((item, index) => (
                                        <div className='w-[40vh] pr-3'>
                                            <InfoCard
                                                title={item.title}
                                                imageUrl={item.thumbnail}
                                                linkUrl={item.url}
                                                type='podcast'
                                                className={{font: "font-bold", img: "object-cover h-[20vh] w-full"}}
                                                contentId={item.id}
                                                markAsRead={item.mark_as_read}
                                                saved={item.saved}
                                                handleClick={handleClickState}
                                                irrelevant={item.irrelevant}
                                                removed={item.removed}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        )
}

export default AIResources
