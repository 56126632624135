import React, {KeyboardEvent, useEffect, useRef} from "react";
import {useAICoaching} from "../../utils/AICoachingContext";
import ArrowRightWhite from "../../../../../../assets/img/aiCoaching/arrow-right-white.svg";
import VoiceIcon from "../../../../../../assets/img/aiCoaching/voice.png"
import {useConfig} from "../../../../../../utils/providers/AppConfigProvider";

type ChatInputProps = {
    isLoading: boolean;
    onNewPrompt: (value: string, callback: () => void, voice?: boolean) => void;
    placeholder?: string;
};

const ChatInput = ({
                       isLoading,
                       onNewPrompt,
                       placeholder,
                   }: ChatInputProps) => {

    const {
        setChatPrompt,
        chatPrompt,
        selectedThreadItem,
        state,
        setVoiceState
    } = useAICoaching();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const {config} = useConfig();

    useEffect(() => {
        focusInput();
    }, [chatPrompt, selectedThreadItem]);

    const focusInput = () => {
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSubmission();
        }
    };

    const handleSubmission = () => {
        if (isLoading) return;
        if (chatPrompt.trim() === "") return;
        onNewPrompt(chatPrompt, () => {
            setChatPrompt("");
        });
    };

    const adjustHeight = () => {
        if (textAreaRef.current) {
            const textArea = textAreaRef.current;
            const parentDiv = textArea.parentElement;
            textArea.style.height = 'auto';
            if (textArea.scrollHeight < 90) {
                textArea.style.height = `${textArea.scrollHeight}px`;
                if (parentDiv) {
                    parentDiv.style.height = `${textArea.scrollHeight + 10}px`;
                }
            } else {
                textArea.style.height = '80px';
                if (parentDiv) {
                    parentDiv.style.height = '90px';
                }
            }

        }
    };


    useEffect(() => {
        adjustHeight();
    }, [chatPrompt]);

    return (
        <div className="flex flex-row gap-4 items-end">
            <div
                className={`h-auto min-h-[40px] w-full flex flex-row items-center px-3 py-2 border-[1px] ${state == 'practice' ? 'border-practice' : 'border-[#f2ebe3]'} rounded-[5px] bg-white`}>
                        <textarea
                            ref={textAreaRef}
                            className="font-dm_sans w-full text-xs text-black placeholder:font-light placeholder:text-[#828282] focus:outline-none resize-none overflow-y-auto pr-2"
                            placeholder={placeholder}
                            onKeyUp={handleKeyUp}
                            onChange={(e) => {
                                setChatPrompt(e.target.value);
                                adjustHeight();
                            }}
                            rows={1}
                            value={chatPrompt}
                        />
                {config?.ai_voice_activated && (
                    <button
                        className="rounded-[5px] w-[20px] h-[20px] flex justify-center items-center cursor-pointer"
                        onClick={() => {
                            setVoiceState("active");
                        }}
                        disabled={isLoading}
                    >
                        <img
                            src={VoiceIcon}
                            alt=""
                            className="object-cover pointer-events-none w-[20px]"
                        />
                    </button>
                )}
            </div>
            <div
                className={`${state == 'practice' ? 'bg-practice' : 'bg-black'} rounded-[5px] w-[43px] h-[40px] flex justify-center items-center cursor-pointer`}
                onClick={handleSubmission}
            >
                <img
                    src={ArrowRightWhite}
                    alt=""
                    className="object-cover pointer-events-none w-[15px]"
                />
            </div>
        </div>
    );
};

export default ChatInput;
