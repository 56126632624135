import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import UsageCard from "../../components/UsageCard";
import PerformanceCard from "../../components/PerformanceCard";
import CreditRemainCard from "./components/CreditsRemainCard";
import LiveFeedback from "./components/LiveFeed/LiveFeedback";

import useAuth from "@hooks/useAuth";
import {useNavTitle} from "@hooks/useNavTitle";
import ENG from "../../locale/eng/coaching.json";
import Fr from "../../locale/fr/coaching.json";
import {
    getBookingsSummary,
    getGoalAchieved,
    getGroups,
    getOnboardingSummary,
    getPerformance,
    getRemainCredits,
    getReviews,
    getUsage,
} from "../../../../services/api/companyApi";
import i18n from "../../../../utils/lib/i18n";

import attendanceImg from "@img/dashboard/attendance.png";
import usersImg from "@img/dashboard/activeUsers.png";
import {isEmpty} from "../../../../utils/helpers/object";
import AreaChartWithGradient from "./components/AreaChartWithGradient";
import OverallProgress from "./components/OverallProgress";
import {IMeta} from "../../../../models/IMeta";
import FilterBar from "../../components/FilterBar";
import {FilterOption, Filters} from "../../../../constants/types";
import UsedCreditsCard from "./components/UsedCreditsCard";

import TemplateChartSection from "../../../../shared/components/TemplateChartSection";
import {DUMMY_BOOKING_DATA} from "../../../../constants/data/dummyChartData";
import {downloadAsImage} from "../../../../utils/helpers/download";

enum DataState {
    NO_DATA = 'NO_DATA',
    NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
    DATA = 'DATA'
}

const Coaching = ({...props}) => {
    i18n.addResourceBundle("en", "coaching", ENG);
    i18n.addResourceBundle("fr", "coaching", Fr);
    const {t} = useTranslation("coaching");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));

    const [remainCredits, setRemainCredits] = useState<Record<any, any>>();
    const [goalAchieved, setGoalAchieved] = useState<Record<any, any>>({
        goal_achieved: "",
    });
    const [feeds, setFeeds] = useState<Array<any>>([]);

    const {user} = useAuth();
    const [usage, setUsage] = useState({
        attendance: 0,
        satisfaction: 0,
    });
    const [performance, setPerformance] = useState({
        active_users: 0,
        average_progress: 0,
        before_session: 0,
        after_session: 0,
    });
    const [groups, setGroups] = useState<FilterOption[]>([]);
    const [bookings, setBookings] = useState<Record<any, any>[]>([]);
    const [onboardingSummary, setOnboardingSummary] = useState<
        Record<any, any>[]
    >([]);
    const [allOnboardingSummary, setAllOnboardingSummary] = useState<
        Record<any, any>[]
    >([]);
    const [filters, setFilters] = useState<Filters>({});
    const [isFiltered, setIsFiltered] = useState(false);

    useEffect(() => {
        getGroups(user?.companyId!, filters.program_ids).then((data: IMeta[]) => {
            setGroups(() => {
                return data.map((programMeta) => {
                    return {label: programMeta.name, value: programMeta.id!};
                });
            });
        });
        getRemainCredits(user?.companyId!, filters)
            .then((res) => {
                setRemainCredits(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getGoalAchieved(user?.companyId!, filters)
            .then((res) => {
                setGoalAchieved(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getUsage(user?.companyId!, filters)
            .then((res) => {
                console.log(res);
                setUsage(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getPerformance(user?.companyId!, filters)
            .then((res) => {
                // console.log(res);
                setPerformance(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getReviews(user?.companyId!, filters)
            .then((res) => {
                setFeeds(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getBookingsSummary(user?.companyId!, filters)
            .then((res) => {
                setBookings(res.bookings);
            })
            .catch((e) => {
                console.log("api error");
            });
        getOnboardingSummary(user?.companyId!, filters)
            .then((res) => {
                setAllOnboardingSummary(res.onboardings);
            })
            .catch((e) => {
                console.log("api error");
            });
    }, [filters]);

    useEffect(() => {
        getOnboardingSummary(user?.companyId!, filters)
            .then((res) => {
                setOnboardingSummary(res.onboardings);
            })
            .catch((e) => {
                console.log("api error");
            });
    }, [filters]);

    const get_data_state = (isNoData: boolean, isFiltered: boolean) => {
        if (isNoData && !isFiltered) { return DataState.NO_DATA }
        if (isNoData && isFiltered) { return DataState.NO_DATA_WITH_FILTERS }
        else { return DataState.DATA }
    }

    const reviewDataState = get_data_state(!(feeds?.length > 1), isFiltered);
    const bookingsDataState = get_data_state(isEmpty(bookings), isFiltered);

    const FEEDS_DATA = [
        { id: 1, name: "string", review: "string", rating: 6 },
        { id: 2, name: "string1", review: "string1", rating: 8 },
        { id: 3, name: "string2", review: "string2", rating: 10 },
    ]

    return (
        <div className="">
            <FilterBar toPDF={() => downloadAsImage('CoachingContainer', 'coaching')} setFilters={setFilters} groups={groups} setisFiltered={setIsFiltered}/>
            <div id={'CoachingContainer'}>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 mb-5 flex-wrap">
                    <div className="grid grid-cols-2 row-span-2 col-start-1 col-end-3 gap-5">
                        <div className="grid gap-5 grid-rows-2 col-span-2 md:col-span-1">
                            {["post_billing"].includes(remainCredits?.subscription_method) || isFiltered ? (
                                <UsedCreditsCard
                                    bgImg={usersImg}
                                    title={
                                        remainCredits?.subscription_method == "license"
                                            ? t("text1_3")
                                            : t("text1_4")
                                    }
                                    value={remainCredits?.used_credit}

                                />
                            ) : (
                                <CreditRemainCard
                                    bgImg={usersImg}
                                    title={
                                        remainCredits?.subscription_method == "license"
                                            ? t("text1_1")
                                            : t("text1")
                                    }
                                    valueM={remainCredits?.remain_credits}
                                    valueS={remainCredits?.last_added_credit}
                                    tooltip={
                                        remainCredits?.subscription_method == "license"
                                            ? t("text20_1")
                                            : t("text20")
                                    }
                                    displayInfo={false}
                                />
                            )}
                            <UsageCard
                                img={attendanceImg}
                                title={t("text1_2")}
                                percentage={usage?.attendance}
                                tooltip={t("text12", {attendance: usage?.attendance})}
                                displayInfo={true}
                                displayChart={true}
                                isFiltered={isFiltered}
                                noDataText={t("text31")}
                            />
                        </div>
                        <div className="grid gap-5 grid-rows-2 col-span-2 md:col-span-1">
                            <PerformanceCard
                                img={usersImg}
                                title={t("text9")}
                                value={allOnboardingSummary?.reduce((acc, val) => {
                                    return acc + val.count;
                                }, 0)}
                                tooltip={t("text15")}
                                isFiltered={isFiltered}
                                noDataText={t("text31")}

                            />

                            <UsageCard
                                img={attendanceImg}
                                title={t("text10")}
                                percentage={usage?.satisfaction}
                                tooltip={t("text14")}
                                displayInfo={true}
                                displayChart={true}
                                isFiltered={isFiltered}
                                noDataText={t("text31")}
                            />
                        </div>
                    </div>

                    <div
                        className="relative rounded row-span-2 overflow-hidden col-span-2 lg:col-span-1 flex min-h-[340px] xl:min-h-[380px]">
                        <TemplateChartSection
                            title={t("text25")}
                            toolTip={t("text30")}
                            dataState={reviewDataState}
                        >
                            <LiveFeedback feeds={reviewDataState === DataState.DATA ? feeds : FEEDS_DATA}/>
                        </TemplateChartSection>
                    </div>
                </div>

                <div className="flex py-5 pt-0 gap-5 lg:flex-row flex-col">
                    <div className="w-1/2 flex-grow relative">
                        <TemplateChartSection
                            title={t("text21")}
                            toolTip={t("text29")}
                            dataState={bookingsDataState}
                        >
                            <div className="relative h-full">
                                <AreaChartWithGradient
                                    data={
                                        bookingsDataState === DataState.DATA ? (
                                            bookings?.map((booking, index) => {
                                                let date = new Date(booking.label);
                                                let label = "";
                                                if (!isNaN(date.getMonth())) {
                                                    label = date.toDateString().split(" ").slice(1).join(" ");
                                                }
                                                return {label: label, value: booking.count};
                                            })
                                        ) : (
                                            DUMMY_BOOKING_DATA.map((dummyItem, index) => {
                                                let date = new Date(dummyItem.label);
                                                let label = "";
                                                if (!isNaN(date.getMonth())) {
                                                    label = date.toDateString().split(" ").slice(1).join(" ");
                                                }
                                                return {label: label, value: dummyItem.count};
                                            })
                                        )
                                    }
                                />
                            </div>
                        </TemplateChartSection>
                    </div>
                    <OverallProgress
                        data={onboardingSummary}
                        groups={groups}
                        setFilters={setFilters}
                        isFiltered={isFiltered}
                        noDataText={t("text31")}
                    />
                </div>
            </div>
        </div>
    );
};

export default Coaching;
