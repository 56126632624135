import ButtonToolbar from "rsuite/esm/ButtonToolbar";
import Divider from "../../../components/Divider";
import {ButtonGroup, Button} from "rsuite";
import "../../CalanderSomeCss/Calander.css";
import PersonalUnavailability from "./PersonalUnvailability";
import BookOnBehalf from "./BookOnBehalf";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {
    ACTION,
    COMPONENTS_KEY,
    CalanderModalContainerProps,
} from "../../Constants/utilities";
import moment from "moment";
import {checkIsHaveBeforeAfter} from "../SettingsModal/controller";
import {
    updatePersonalUnavailableEvents,
    updateOnbehalfBooking,
    getCoachClients
} from "../../../../../services/api/coachApi";
import {userTimeZone} from "../../../../../utils/helpers/time";
import {toast} from "react-toastify";
import useAuth from "@hooks/useAuth";
import RescheduleEvent from "./RescheduleEvent";
import {createBooking} from "../../../../../services/api/teams/BookingActions";

const CalanderModalContainer: React.FC<CalanderModalContainerProps> = ({
                                                                           eventArgs,
                                                                           dispatch,
                                                                           setIsOpen,
                                                                           customSetting,
                                                                           eventAdd,
                                                                           setIsBooking,
                                                                           rescheduleEvent,
                                                                           rescheduleDetails
                                                                       }: CalanderModalContainerProps) => {
    const {t} = useTranslation("events");
    const {user} = useAuth();
    const timezone = userTimeZone();
    const [coachClients, setCoachClients] = useState([]);

    const _colorPicker = (isBloked: boolean) => {
        if (!isBloked) {
            return {
                className: "bg-[#FFCCD8]",
            }
        } else {
            return {
                className: "blocked-bg-color"
            }
        }
    }

    useEffect(() => {
        getCoachClients().then((response) => {
                setCoachClients(response);
            }
        ).catch((error) => {
            console.log(error);
        });
    }, []);

    const updatePersonalUnavailability = (values: any) => {
        setIsBooking && setIsBooking(true);
        updatePersonalUnavailableEvents(values).then((response) => {
                setTimeout(() => {
                    eventAdd();
                }, 1000);
            }
        ).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsBooking && setIsBooking(false);
        })
    }

    const updateBookingOnbehalf = (values: any) => {
        setIsBooking && setIsBooking(true);
        updateOnbehalfBooking(values).then((response) => {
            setTimeout(() => {
                eventAdd();
            }, 1000);
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error);
        }).finally(() => {
            setIsBooking && setIsBooking(false);
        })
    }


    const _handleSubmit = (parms: any) => {

        let intermediateObject: any = {
            title: `new Item on ${parms?.date}`,
            start: parms?.startTime,
            end: parms?.endTime,
            content: {
                client: parms?.client ? parms?.client : "",
                remarks: parms?.remarks ? parms?.remarks : "",
                isBloked: parms?.isBloked,
                isBooked: parms?.isBooked,
                titleOfTheCoach: parms?.title ? parms?.title : "",
                frequencyOftheTime: parms?.frequency ? parms?.frequency : "",
            },
            ..._colorPicker(parms?.isBloked),
        };
        dispatch({type: ACTION.ADD_EVENTS, payLoad: {...intermediateObject}});

        if (parms?.isRescheduling) {
            const event_start_time = moment(`${parms?.date.format("YYYY-MM-DD")} ${parms?.startTime.format("HH:mm")}`).format("YYYY-MM-DDTHH:mm:ssZ");
            const event_end_time = moment(`${parms?.date.format("YYYY-MM-DD")} ${parms?.endTime.format("HH:mm")}`).format("YYYY-MM-DDTHH:mm:ssZ");

            updateBookingOnbehalf({
                meeting_id: parms.meeting_id,
                client_id: parms.client_id,
                coach_id: parms.coach_id,
                event_start_time: event_start_time,
                event_end_time: event_end_time,
                coach_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                coach_note: parms.coach_note,
                tripartite_email: parms.tripartite_email,
                reschedule_by_coach: true,
            });
        }

        if (parms?.isPersonalUnavailability) {
            updatePersonalUnavailability({
                event_start_time: moment(`${parms?.startDate.format("YYYY-MM-DD")} ${parms?.startDate.format("HH:mm")}`).format("YYYY-MM-DDTHH:mm:ssZ"),
                event_end_time: moment(`${parms?.endDate.format("YYYY-MM-DD")} ${parms?.endDate.format("HH:mm")}`).format("YYYY-MM-DDTHH:mm:ssZ"),
                coach_timezone: timezone,
                title: parms?.title,
                coach_id: user?.id,
                // recurrence: (parms?.frequency)[0].name,
            });
        }
        if (parms?.isBooked) {
            updateBookingOnbehalf({
                event_start_time: moment(`${parms?.date.format("YYYY-MM-DD")} ${parms?.startTime.format("HH:mm")}`).format("YYYY-MM-DDTHH:mm:ssZ"),
                event_end_time: moment(`${parms?.date.format("YYYY-MM-DD")} ${parms?.endTime.format("HH:mm")}`).format("YYYY-MM-DDTHH:mm:ssZ"),
                coach_timezone: timezone,
                // need to change for multiple clients
                client_id: (parms?.client).id,
                coach_note: parms?.remarks,
                coach_id: user?.id,
            });
        }

        let returedResult: any = checkIsHaveBeforeAfter(customSetting, eventArgs?.end);

        if (returedResult?.content?.isTrue) {
            intermediateObject = {
                title: `new Item on ${eventArgs?.end}`,
                ...returedResult
            }
            // dispatch({ type: ACTION.ADD_EVENTS, payLoad: { ...intermediateObject } });
        }

        parms?.action?.setSubmitting(false)
        setIsOpen(false);
    }

    const dateForFuture = customSetting.daysOfFuture || 10000;
    const afterFollowingDays = moment(customSetting.dateOfSubmitted).add(dateForFuture - 1, 'days');

    const params = {
        date: moment(eventArgs?.end),
        startDate: moment(eventArgs?.start),
        endDate: moment(eventArgs?.end),
        startTime: moment(eventArgs?.start),
        endTime: moment(eventArgs?.end),
        initials: {
            personalUnavailability: {
                title: "",
                frequency: "",
            },
            bookOnBehalf: {
                client: "",
                remarks: "",

            }
        },
        lastAvailableDate: afterFollowingDays,
        clients: coachClients,
        rescheduleEventDetails: {
            rescheduleEvent,
            rescheduleDetails
        },
        _handleSubmit,
    };

    const [currentChildKey, setCurrentChildKey] = useState(
        COMPONENTS_KEY.personalUnavailability
    );
    const PersonalUnavailabilityComponent = React.createElement(
        PersonalUnavailability,
        {...params}
    );
    const BookOnBehalfComponent = React.createElement(BookOnBehalf, {
        ...params,
    });
    const RescheduleEventComponent = React.createElement(RescheduleEvent, {
        ...params,
    });

    const BuildComponent = () => {
        if (rescheduleEvent) {
            return RescheduleEventComponent;
        } else if (currentChildKey === COMPONENTS_KEY.personalUnavailability) {
            return PersonalUnavailabilityComponent;
        } else if (currentChildKey === COMPONENTS_KEY.bookOnBehalf) {
            return BookOnBehalfComponent;
        } else {
            return PersonalUnavailabilityComponent;
        }
    };

    return (
        <>
            <div>
                <div className=" flex justify-center p-5">
                    <h4>{rescheduleEvent ? t("calendar_modal_container.reschedule_title") : t("calendar_modal_container.main_title")}</h4>
                </div>
                <Divider/>
                {!rescheduleEvent &&
                    <div className="flex justify-center mt-5 w-full px-4">
                        <ButtonToolbar>
                            <ButtonGroup>
                                <Button
                                    className="modal-btn-custom-css "
                                    color="red"
                                    appearance="ghost"
                                    active={
                                        currentChildKey === COMPONENTS_KEY.personalUnavailability
                                            ? true
                                            : false
                                    }
                                    onClick={() =>
                                        setCurrentChildKey(COMPONENTS_KEY.personalUnavailability)
                                    }
                                >
                                    {t("calendar_modal_container.personal_unavailable")}
                                </Button>
                                <Button
                                    className="modal-btn-custom-css "
                                    color="red"
                                    appearance="ghost"
                                    active={
                                        currentChildKey === COMPONENTS_KEY.bookOnBehalf ? true : false
                                    }
                                    onClick={() => setCurrentChildKey(COMPONENTS_KEY.bookOnBehalf)}
                                >
                                    {t("calendar_modal_container.book_behalf")}
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </div>
                }
                <div>
                    <BuildComponent/>
                </div>
            </div>
        </>
    );
};

export default CalanderModalContainer;