interface VoiceDetail {
    voiceName: string;
    languageCode: string;
    isRTL?: boolean;
}

interface VoiceLanguageData {
    [languageCode: string]: {
        FEMALE: VoiceDetail;
        MALE: VoiceDetail;
    };
}

const commonEnglishVoice = {
    FEMALE: { voiceName: "en-GB-Journey-F", languageCode: "en-GB" },
    MALE: { voiceName: "en-GB-Journey-D", languageCode: "en-GB" },
}

const commonFrenchVoice = {
    FEMALE: { voiceName: "fr-FR-Journey-F", languageCode: "fr-FR" },
    MALE: { voiceName: "fr-FR-Journey-D", languageCode: "fr-FR" },
}

const commonGermanVoice = {
    FEMALE: { voiceName: "de-DE-Journey-F", languageCode: "de-DE" },
    MALE: { voiceName: "de-DE-Journey-D", languageCode: "de-DE" },
}

const commonSpanishVoice = {
    FEMALE: { voiceName: "es-ES-Journey-O", languageCode: "es-ES" },
    MALE: { voiceName: "es-ES-Journey-D", languageCode: "es-ES" },
}

const commonArabicVoice = {
    FEMALE: { voiceName: "ar-XA-Wavenet-D", languageCode: "ar-XA", isRTL: true },
    MALE: { voiceName: "ar-XA-Wavenet-B", languageCode: "ar-XA", isRTL: true },
}

export const voiceLanguageData: VoiceLanguageData = {
    "en-GB": {
        FEMALE: { voiceName: "en-GB-Journey-F", languageCode: "en-GB" },
        MALE: { voiceName: "en-GB-Journey-D", languageCode: "en-GB" },
    },
    "en-US": {
        FEMALE: { voiceName: "en-US-Journey-F", languageCode: "en-US" },
        MALE: { voiceName: "en-US-Journey-D", languageCode: "en-US" },
    },
    "en-IN": {
        FEMALE: { voiceName: "en-IN-Journey-F", languageCode: "en-IN" },
        MALE: { voiceName: "en-IN-Journey-D", languageCode: "en-IN" },
    },
    "en-AU": {
        FEMALE: { voiceName: "en-AU-Journey-F", languageCode: "en-AU" },
        MALE: { voiceName: "en-AU-Journey-D", languageCode: "en-AU" },
    },
    "en-ZA": commonEnglishVoice,
    "en-SG": commonEnglishVoice,
    "en-PH": commonEnglishVoice,
    "en-NZ": commonEnglishVoice,
    "en-KE": commonEnglishVoice,
    "en-IE": commonEnglishVoice,
    "en-HK": commonEnglishVoice,
    "en-CA": commonEnglishVoice,
    "fr-BE": commonFrenchVoice,
    "fr-CH": commonFrenchVoice,
    "de-CH": commonGermanVoice,
    "de-AT": commonGermanVoice,
    "de-DE": commonGermanVoice,
    "es-CO": commonSpanishVoice,
    "es-CR": commonSpanishVoice,
    "es-GT": commonSpanishVoice,
    "es-MX": commonSpanishVoice,
    "es-PA": commonSpanishVoice,
    "es-PE": commonSpanishVoice,
    "es-PR": commonSpanishVoice,
    "es-ES": commonSpanishVoice,
    "es-UY": commonSpanishVoice,
    "it-CH": {
        FEMALE: { voiceName: "it-IT-Journey-O", languageCode: "it-IT" },
        MALE: { voiceName: "it-IT-Journey-D", languageCode: "it-IT" },
    },
    "nl-BE": {
        FEMALE: { voiceName: "nl-BE-Wavenet-A", languageCode: "nl-BE" },
        MALE: { voiceName: "nl-BE-Wavenet-B", languageCode: "nl-BE" },
    },
    "nl-NL": {
        FEMALE: { voiceName: "nl-BE-Wavenet-D", languageCode: "nl-NL" },
        MALE: { voiceName: "nl-BE-Wavenet-C", languageCode: "nl-NL" },
    },
    "sr-RS": {
        FEMALE: { voiceName: "sr-RS-Standard-A", languageCode: "sr-RS" },
        MALE: { voiceName: "sr-RS-Standard-A", languageCode: "sr-RS" },
    },
    "pl-PL": {
        FEMALE: { voiceName: "pl-PL-Wavenet-D", languageCode: "pl-PL" },
        MALE: { voiceName: "pl-PL-Wavenet-B", languageCode: "pl-PL" },
    },
    "pt-PT": {
        FEMALE: { voiceName: "pt-PT-Wavenet-D", languageCode: "pt-PT" },
        MALE: { voiceName: "pt-PT-Wavenet-C", languageCode: "pt-PT" },
    },
    "pt-BR": {
        FEMALE: { voiceName: "pt-BR-Neural2-C", languageCode: "pt-BR" },
        MALE: { voiceName: "pt-BR-Neural2-B", languageCode: "pt-BR" },
    },
    "ro-RO": {
        FEMALE: { voiceName: "ro-RO-Wavenet-A", languageCode: "ro-RO" },
        MALE: { voiceName: "ro-RO-Wavenet-A", languageCode: "ro-RO" },
    },
    "cs-CZ": {
        FEMALE: { voiceName: "cs-CZ-Wavenet-A", languageCode: "cs-CZ" },
        MALE: { voiceName: "cs-CZ-Wavenet-A", languageCode: "cs-CZ" },
    },
    "tr-TR": {
        FEMALE: { voiceName: "tr-TR-Wavenet-E", languageCode: "tr-TR" },
        MALE: { voiceName: "tr-TR-Wavenet-D", languageCode: "tr-TR" },
    },
    "sq-AL": {
        FEMALE: { voiceName: "el-GR-Wavenet-A", languageCode: "el-GR" },
        MALE: { voiceName: "el-GR-Wavenet-A", languageCode: "el-GR" },
    },
    "el-GR": {
        FEMALE: { voiceName: "el-GR-Wavenet-A", languageCode: "el-GR" },
        MALE: { voiceName: "el-GR-Wavenet-A", languageCode: "el-GR" },
    },
    "et-EE": {
        FEMALE: { voiceName: "fi-FI-Wavenet-A", languageCode: "fi-FI" },
        MALE: { voiceName: "fi-FI-Wavenet-A", languageCode: "fi-FI" },
    },
    "da-DK": {
        FEMALE: { voiceName: "da-DK-Neural2-D", languageCode: "da-DK" },
        MALE: { voiceName: "da-DK-Wavenet-C", languageCode: "da-DK" },
    },
    "sv-SE": {
        FEMALE: { voiceName: "sv-SE-Wavenet-D", languageCode: "sv-SE" },
        MALE: { voiceName: "sv-SE-Wavenet-E", languageCode: "sv-SE" },
    },
    "fil-PH": {
        FEMALE: { voiceName: "fil-ph-Neural2-A", languageCode: "fil-PH" },
        MALE: { voiceName: "fil-ph-Neural2-D", languageCode: "fil-PH" },
    },
    "no-NO": {
        FEMALE: { voiceName: "nb-NO-Wavenet-E", languageCode: "nb-NO" },
        MALE: { voiceName: "nb-NO-Wavenet-D", languageCode: "nb-NO" },
    },
    "fi-FI": {
        FEMALE: { voiceName: "fi-FI-Wavenet-A", languageCode: "fi-FI" },
        MALE: { voiceName: "fi-FI-Wavenet-A", languageCode: "fi-FI" },
    },
    "id-ID": {
        FEMALE: { voiceName: "id-ID-Wavenet-A", languageCode: "id-ID" },
        MALE: { voiceName: "id-ID-Wavenet-C", languageCode: "id-ID" },
    },
    "hi-IN": {
        FEMALE: { voiceName: "hi-IN-Neural2-A", languageCode: "hi-IN" },
        MALE: { voiceName: "hi-IN-Neural2-B", languageCode: "hi-IN" },
    },
    "lt-LT": {
        FEMALE: { voiceName: "lt-LT-Standard-B", languageCode: "lt-LT" },
        MALE: { voiceName: "lt-LT-Standard-B", languageCode: "lt-LT" },
    },
    "ru-RU": {
        FEMALE: { voiceName: "ru-RU-Wavenet-C", languageCode: "ru-RU" },
        MALE: { voiceName: "ru-RU-Wavenet-B", languageCode: "ru-RU" },
    },
    "sk-SK": {
        FEMALE: { voiceName: "sk-SK-Wavenet-A", languageCode: "sk-SK" },
        MALE: { voiceName: "sk-SK-Wavenet-A", languageCode: "sk-SK" },
    },
    "hr-HR": {
        FEMALE: { voiceName: "sr-RS-Standard-A", languageCode: "sr-RS" },
        MALE: { voiceName: "sr-RS-Standard-A", languageCode: "sr-RS" },
    },
    "hu-HU": {
        FEMALE: { voiceName: "hu-HU-Standard-B", languageCode: "hu-HU" },
        MALE: { voiceName: "hu-HU-Standard-B", languageCode: "hu-HU" },
    },
    "bg-BG": {
        FEMALE: { voiceName: "bg-BG-Standard-B", languageCode: "bg-BG" },
        MALE: { voiceName: "bg-BG-Standard-B", languageCode: "bg-BG" },
    },
    "uk-UA": {
        FEMALE: { voiceName: "uk-UA-Standard-A", languageCode: "uk-UA" },
        MALE: { voiceName: "uk-UA-Standard-A", languageCode: "uk-UA" },
    },
    "ta-IN": {
        FEMALE: { voiceName: "ta-IN-Wavenet-C", languageCode: "ta-IN" },
        MALE: { voiceName: "ta-IN-Wavenet-B", languageCode: "ta-IN" },
    },
    "te-IN": {
        FEMALE: { voiceName: "te-IN-Standard-C", languageCode: "te-IN" },
        MALE: { voiceName: "te-IN-Standard-D", languageCode: "te-IN" },
    },
    "mr-IN": {
        FEMALE: { voiceName: "mr-IN-Wavenet-A", languageCode: "mr-IN" },
        MALE: { voiceName: "mr-IN-Wavenet-B", languageCode: "mr-IN" },
    },
    "iw-IL": {
        FEMALE: { voiceName: "he-IL-Wavenet-C", languageCode: "he-IL", isRTL: true },
        MALE: { voiceName: "he-IL-Wavenet-D", languageCode: "he-IL", isRTL: true },
    },
    "af-ZA": {
        FEMALE: { voiceName: "af-ZA-Standard-A", languageCode: "af-ZA" },
        MALE: { voiceName: "af-ZA-Standard-A", languageCode: "af-ZA" },
    },
    "ar-DZ": commonArabicVoice,
    "ar-BH": commonArabicVoice,
    "ar-EG": commonArabicVoice,
    "ar-IQ": commonArabicVoice,
    "ar-IL": commonArabicVoice,
    "ar-JO": commonArabicVoice,
    "ar-KW": commonArabicVoice,
    "ar-LB": commonArabicVoice,
    "ar-MR": commonArabicVoice,
    "ar-MA": commonArabicVoice,
    "ar-OM": commonArabicVoice,
    "ar-QA": commonArabicVoice,
    "ar-SA": commonArabicVoice,
    "ar-PS": commonArabicVoice,
    "ar-SY": commonArabicVoice,
    "ar-TN": commonArabicVoice,
    "ar-AE": commonArabicVoice,
    "ar-YE": commonArabicVoice,
};
