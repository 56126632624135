import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {isKeyExists} from "../../../utils/helpers/object";
import {getMeetingDetails, saveClientFeedback} from "../../../services/api/teams/ReportActions";
import {IClientFeedback} from "../../../models/IReport";
import i18n from "../../../utils/lib/i18n";
import HomeTEN from "../locale/eng/home.json";
import HomeTFR from "../locale/fr/home.json";
import {EAssessmentType} from "../../../constants/enums";
import {toast} from "react-toastify";
import Rating from "./Rating";
import {ISessionSchema} from "../../../models/IHome";
import moment from "moment";
import calendar from "@img/dashboard/home/icons/calendar.svg";
import clock from "@img/dashboard/home/icons/clock.svg";
import InformationIcon from "../../../shared/components/InformationIcon";

interface Props {
    isOpen?: boolean;
    onClose?: (e: any) => any;
    meetingId?: any;
}

const schema = ["coachMatchRating", "sessionRating", "platformExperienceRating"];

const ReviewModal: FC<Props> = (props) => {
    i18n.addResourceBundle("en", "homet", HomeTEN);
    i18n.addResourceBundle("fr", "homet", HomeTFR);
    const {t} = useTranslation("homet");

    const [reviewData, setReviewData] = useState<{ [key: string]: number | string }>({
        coachMatchRating: 0,
        coachMatchingComments: "",
        shareFeedbackWithCoach: 1,
        sessionFeedback: "",
        sessionRating: 0,
        platformExperienceRating: 0,
    });
    const [session, setSession] = useState<ISessionSchema | undefined>()
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (props.meetingId) {
            getMeetingDetails(props.meetingId).then((data) => {
                setSession(data);
            }).catch((err) => {
                console.error("Failed to fetch meeting details:", err);
            });
        }
    }, [props.meetingId]);

    const handleRatingChange = (name: string, value: number) => {
        setReviewData({
            ...reviewData,
            [name]: value,
        });

        if (value > 0 && isKeyExists(errors, name)) {
            setErrors((prevErrors) => {
                const newErrors = {...prevErrors};
                delete newErrors[name];
                return newErrors;
            });
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setReviewData({
            ...reviewData,
            [name]: value,
        });
    };

    const handleShareFeedbackChange = (value: number) => {
        setReviewData({
            ...reviewData,
            shareFeedbackWithCoach: value,
        });
    };

    const validate = () => {
        let valid = true;
        let vErrors = {};

        schema.map((input) => {
            if (input === 'coachMatchRating' && !session?.first_session) {
                return;
            }

            if (!reviewData[input]) {
                valid = false;
                vErrors = {
                    ...vErrors,
                    ...{
                        [input]: "This field is required.",
                    },
                };
            }
        });

        setErrors(vErrors);

        return valid;
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }
        submitReview(reviewData);
    };

    const submitReview = (reviewData: { [key: string]: any }) => {
        reviewData["meeting_id"] = props.meetingId;
        submitHandler(reviewData);
    };

    const submitHandler = (result: any) => {
        setLoading(true);
        const clientReview: IClientFeedback = {
            thumbs_reply: String(result.sessionRating * 2),
            review: result.sessionFeedback,
            coach_match_rating: String(result.coachMatchRating * 2),
            coach_match_comments: result.coachMatchingComments,
            share_feedback_with_coach: result.shareFeedbackWithCoach == 1,
            platform_experience_rating: String(result.platformExperienceRating * 2),
        };
        saveClientFeedback(result.meeting_id, clientReview)
            .then((res) => {
                const responseWithMeetingId = {
                    ...res,
                    meeting_id: result.meeting_id,
                    assessment_type: EAssessmentType.Review
                };
                props.onClose && props.onClose(responseWithMeetingId);
            })
            .catch((error) => {
                console.error("Error submitting feedback:", error);
                toast.error(t("text_30"));
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <>
            {session &&
                <div className="flex align-center mx-4">
                    <img
                        src={session.coach_profile}
                        className="rounded-full object-cover mr-2 inline-block h-12 w-12"
                        alt="Coach avatar"
                    />
                    <div className="inline-flex flex-col justify-center gap-0.5">
                        <h2 className="font-lexend text-black text-sm">{`${session.coach_first_name} ${session.coach_last_name ?? ""}`}</h2>
                        <div className="font-dm_sans text-gray font-light flex gap-4 items-center">
                            <span className="flex gap-1 text-xs items-center">
                              <img src={calendar} alt="" className="inline-block h-4 w-4"/>
                                {moment(session.datetime).format("dddd, DD MMMM")}
                            </span>
                            <span className="flex gap-1 text-xs items-center">
                              <img src={clock} alt=""
                                   className="inline-block h-4 w-4"/> {moment(session.datetime).format("HH:mm")}
                            </span>
                        </div>
                    </div>
                </div>
            }
            <form action="" className="m-4 mt-8 text-black w-[410px]">
                {session && session.first_session &&
                    <>
                        <div className="">
                            <label className="block text-sm font-normal mb-1">
                                {t("review.text_1")}
                            </label>
                            <Rating
                                value={Number(reviewData.coachMatchRating)}
                                max={5}
                                onChange={(value) => handleRatingChange('coachMatchRating', value)}
                                texts={[t("review.text_1_1"), t("review.text_1_2"), t("review.text_1_3"), t("review.text_1_4"), t("review.text_1_5")]}
                            />
                            {isKeyExists(errors, "coachMatchRating") ? (
                                <small className="mt-3 text-sm font-light">
                                    {t("text_15")}
                                </small>
                            ) : (
                                ""
                            )}
                        </div>
                        {Number(reviewData.coachMatchRating) * 2 < 7 && reviewData.coachMatchRating > 0 &&
                            <div className="mt-4">
                                <label className="block text-sm font-normal mb-1">
                                    {t("review.text_2")}
                                </label>
                                <textarea
                                    onChange={handleInputChange}
                                    rows={3}
                                    name="coachMatchingComments"
                                    placeholder={t("review.text_7")}
                                    className="block w-full px-3 py-2 border border-gray-600 rounded sm:text-sm resize-none"
                                />
                            </div>
                        }
                    </>
                }
                <div className="mt-4">
                    <label className="block text-sm font-normal mb-1">
                        {t("review.text_3")}
                    </label>
                    <div className="flex items-center">
                        <button
                            type="button"
                            onClick={() => handleShareFeedbackChange(1)}
                            className={`flex items-center px-3 py-2 rounded text-xs mr-2 ${
                                reviewData.shareFeedbackWithCoach ? 'bg-black text-white font-semibold' : 'bg-white font-normal text-black border border-black'
                            }`}
                        >
                            <span
                                className={`flex items-center justify-center w-4 h-4 mr-2 rounded-full border ${
                                    reviewData.shareFeedbackWithCoach ? 'bg-white text-black' : 'bg-white'
                                }`}
                            >
                                {reviewData.shareFeedbackWithCoach != 0 && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3 w-3 text-balck"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                )}
                            </span>
                            {t("review.text_3_1")}
                        </button>
                        <button
                            type="button"
                            onClick={() => handleShareFeedbackChange(0)}
                            className={`flex items-center px-3 py-2 rounded text-xs mr-3 ${
                                !reviewData.shareFeedbackWithCoach ? 'bg-black text-white font-semibold' : 'bg-white font-normal text-black border border-black'
                            }`}
                        >
                            <span
                                className={`flex items-center justify-center w-4 h-4 mr-2 rounded-full border ${
                                    !reviewData.shareFeedbackWithCoach ? 'bg-white text-black' : 'bg-white'
                                }`}
                            >
                                {!reviewData.shareFeedbackWithCoach && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3 w-3 text-black"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                )}
                            </span>
                            {t("review.text_3_2")}
                        </button>
                        {!reviewData.shareFeedbackWithCoach &&
                            <>
                                <InformationIcon className="w-4 h-4 mr-1 text-gray-400"/>
                                <p className="text-xs text-gray-400 italic">{t("review.text_3_3")}</p>
                            </>
                        }
                    </div>
                </div>
                <div className="mt-4">
                    <label className="block text-sm font-normal mb-1">
                        {t("review.text_5")}
                    </label>
                    <Rating
                        value={Number(reviewData.sessionRating)}
                        max={5}
                        onChange={(value) => handleRatingChange('sessionRating', value)}
                        texts={[t("review.text_5_1"), t("review.text_5_2"), t("review.text_5_3"), t("review.text_5_4"), t("review.text_5_5")]}
                    />
                    {isKeyExists(errors, "sessionRating") ? (
                        <small className="mt-3 text-sm font-light">
                            {t("text_15")}
                        </small>
                    ) : (
                        ""
                    )}
                </div>
                <div className="mt-4">
                    <label className="block text-sm font-normal mb-1">
                        {t("review.text_6")}
                    </label>
                    <Rating
                        value={Number(reviewData.platformExperienceRating)}
                        max={5}
                        onChange={(value) => handleRatingChange('platformExperienceRating', value)}
                        texts={[t("review.text_6_1"), t("review.text_6_2"), t("review.text_6_3"), t("review.text_6_4"), t("review.text_6_5")]}
                    />
                    {isKeyExists(errors, "platformExperienceRating") ? (
                        <small className="mt-3 text-sm font-light">
                            {t("text_15")}
                        </small>
                    ) : (
                        ""
                    )}
                </div>
                <div className="mt-4">
                    <label className="block text-sm font-normal mb-1 break-words">
                        {t("review.text_4")}
                    </label>
                    <textarea
                        onChange={handleInputChange}
                        rows={3}
                        name="sessionFeedback"
                        placeholder={t("review.text_7")}
                        className="block w-full px-3 py-2 border border-gray-600 rounded sm:text-sm resize-none"
                    />
                </div>
                <div className="flex justify-center items-center mt-8 w-full">
                    <button type="button" onClick={handleSubmit}
                            className="p-2 text-center text-white rounded bg-[#FD0054] placeholder:font-light w-full"
                            disabled={loading}>{loading ? t("review.text_8_1") : t("review.text_8")}
                    </button>
                </div>
            </form>
        </>
    );
};

export default ReviewModal;
