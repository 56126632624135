import React, {FC, useEffect, useState} from "react";
import {getMaxValueInInnerKey} from "../../../../../utils/helpers/object";
import {getAllObjectives, getFeelings, getObjectives, getPerformance} from "../../../../../services/api/companyApi";
import {IAuthUser} from "../../../../../models/IAuth";
import {Filters} from "../../../../../constants/types";
import TemplateChartSection from "../../../../../shared/components/TemplateChartSection";
import ObjectivesToolTip from "./ObjectivesToolTip";
import CustomBarChart from "../../../../../shared/components/BarChart/CustomBarChart";
import {Coachee_assessmentScore, CoacheeCategoryIcon} from "../../../../../constants/data/chartData";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/performance.json";
import Fr from "../../../locale/fr/performance.json";
import {useTranslation} from "react-i18next";
import {AcceleratorChart} from "@components";
import RingPieChart from "../../../../../shared/components/RingPieChart";
import {DUMMY_CHART_DATA} from "../../../../../constants/data/dummyChartData";

enum DataState {
    NO_DATA = 'NO_DATA',
    NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
    DATA = 'DATA'
}

interface CoacheeSectionProps {
    filters: Filters;
    isFiltered: boolean;
    user: null | IAuthUser;
    actions: Record<any, any>;
    categories: Record<any, any>;
    maxCategoryValue: number;
    SectionTitle: FC<{ title: string }>;
    getDataState: (isNoData: boolean, isFiltered: boolean) => DataState;
    getSectionGrid: (charts: any[]) => JSX.Element[];
}

const CoacheeSection: FC<CoacheeSectionProps> = ({
                                                     filters,
                                                     isFiltered,
                                                     user,
                                                     actions,
                                                     categories,
                                                     maxCategoryValue,
                                                     SectionTitle,
                                                     getDataState,
                                                     getSectionGrid
                                                 }) => {
    i18n.addResourceBundle("en", "performance", ENG);
    i18n.addResourceBundle("fr", "performance", Fr);
    const {t} = useTranslation("performance");
    const lng = i18n.language;

    const [performance, setPerformance] = useState({
        active_users: 0,
        average_progress: 0,
        before_session: 0,
        after_session: 0,
    });

    const [feelings, setFeelings] = useState<Record<any, any>[]>([]);
    const [objectives, setObjectives] = useState<Record<any, any>[]>([]);
    const [allObjectives, setAllObjectives] = useState<Record<any, any>[]>([]);

    const maxFeelingsValue =
        feelings.length > 0
            ? getMaxValueInInnerKey(feelings, "count").count + 1
            : 10;
    const maxObjectiveValue = 100;

    useEffect(() => {
        getPerformance(user?.companyId!, filters)
            .then((res) => {
                setPerformance(res);
            })
            .catch((e) => {
                console.log("api error");
            });
        getFeelings(user?.companyId!, filters)
            .then((res) => {
                setFeelings(res.feelings);
            })
            .catch((e) => {
                console.log("api error");
            });
        getObjectives(user?.companyId!, filters)
            .then((res) => {
                setObjectives(res.objectives);
            })
            .catch((e) => {
                console.log("api error");
            });
        getAllObjectives(user?.companyId!, filters)
            .then((res) => {
                setAllObjectives(res.objectives);
            })
            .catch((e) => {
                console.log("api error");
            });
    }, [filters])

    const getRandomDummyData = () => {
        const shuffled = DUMMY_CHART_DATA.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 5);
    }

    const coacheeObjectivesDataState = getDataState(!objectives || objectives.length === 0, isFiltered);
    const coacheeCategoriesDataState = getDataState(!categories || categories.length === 0, isFiltered);
    const coacheePerformanceDataState = getDataState(!performance?.before_session, isFiltered);
    const coacheeFeelingsDataState = getDataState(!feelings || feelings.length === 0, isFiltered);
    const coacheeActionsDataState = getDataState(!actions || actions.length === 0, isFiltered);

    const CoacheeObjectives = () => (
        <TemplateChartSection
            title={t("text23")}
            toolTip={<ObjectivesToolTip objectives={allObjectives}/>}
            dataState={coacheeObjectivesDataState}
        >
            <CustomBarChart
                key="coaching_objectives"
                showIcon={true}
                showTagLine={true}
                range={[0, maxObjectiveValue]}
                data={
                    coacheeObjectivesDataState === DataState.DATA ? (
                        objectives?.slice(0, 5).map((objective) => {
                            const icon =
                                (Coachee_assessmentScore as any)[objective.key] &&
                                (Coachee_assessmentScore as any)[objective.key].icon;
                            return {
                                label: objective[lng],
                                tagline: objective.sub_objective[lng],
                                value: objective.count,
                                icon: icon,
                            };
                        })
                    ) : (
                        getRandomDummyData().map((dummyItem) => {
                            return {
                                label: dummyItem.label,
                                value: dummyItem.value * 10,
                                icon: Coachee_assessmentScore.default_icon.icon,
                            }
                        })
                    )
                }
            />
        </TemplateChartSection>
    );

    const CoacheeCategories = () => (
        <TemplateChartSection
            title={t("text24")}
            toolTip={t("text24")}
            dataState={coacheeCategoriesDataState}
        >
            <CustomBarChart
                showIcon={true}
                showTagLine={true}
                range={[0, maxCategoryValue]}
                data={
                    coacheeCategoriesDataState === DataState.DATA ? (
                        categories?.map((category: any) => {
                            const icon = (CoacheeCategoryIcon as any)[category.key]
                                ? (CoacheeCategoryIcon as any)[category.key].icon
                                : (CoacheeCategoryIcon as any).default_icon.icon;
                            return {
                                label: category[`category_${lng}`],
                                tagline: category[lng],
                                value: category.count,
                                icon: icon,
                            }
                        })
                    ) : (
                        getRandomDummyData().map((dummyItem) => {
                            return {
                                label: dummyItem.label,
                                value: dummyItem.value,
                                icon: CoacheeCategoryIcon.default_icon.icon,

                            }
                        })
                    )
                }
                legendName={t("text24_2")}

            />
        </TemplateChartSection>
    );

    const CoacheePerformance = () => (
        <TemplateChartSection
            title={t("text4")}
            toolTip={t("text7")}
            dataState={coacheePerformanceDataState}
        >
            <div className="relative">
                <div className="flex gap-8 py-14 justify-between">
                    <div className="text-center w-full">
                        <AcceleratorChart
                            percentage={coacheePerformanceDataState === DataState.DATA ? performance?.before_session * 10 : 80}/>
                        <div className="flex flex-row items-center justify-center gap-2 px-10 -mt-7">
                            <span className="text-sm font-bold text-black">
                                {t("text5")}
                            </span>
                            <h3 className="text-black">{performance?.before_session}</h3>
                        </div>
                    </div>
                    <div className="text-center w-full">
                        <AcceleratorChart
                            percentage={coacheePerformanceDataState === DataState.DATA ? performance?.after_session * 10 : 70}
                            isPositive={true}
                        />
                        <div className="flex flex-row items-center justify-center gap-2 px-10 -mt-7">
                            <span className="text-sm font-bold text-black">
                                {t("text6")}
                            </span>
                            <h3 className="text-black">{performance?.after_session}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </TemplateChartSection>
    );

    const CoacheeFeelings = () => (
        <TemplateChartSection
            title={t("text8")}
            toolTip={t("text15")}
            dataState={coacheeFeelingsDataState}
        >
            <CustomBarChart
                showIcon={false}
                showTagLine={true}
                range={[0, maxFeelingsValue]}
                data={
                    coacheeFeelingsDataState === DataState.DATA ? (
                        feelings?.slice(0, 5).map((feeling) => {
                            return {
                                label: feeling[lng],
                                value: feeling.count
                            };
                        })
                    ) : (
                        getRandomDummyData().map((dummyItem) => {
                            return {
                                label: dummyItem.label,
                                value: dummyItem.value,
                            };
                        })
                    )
                }
                showXPercentage={true}
                legendName={t("text26")}
            />
        </TemplateChartSection>
    );

    const CoacheeActions = () => (
        <TemplateChartSection
            title={t("text17_1")}
            toolTip={t("text34")}
            dataState={coacheeActionsDataState}
        >
            <RingPieChart
                customLegendStyle="style1"
                data={
                    coacheeActionsDataState === DataState.DATA ? (
                        actions?.map((action: any, index: number) => {
                            let obj = {
                                label: action[lng],
                                value: action.count
                            };
                            return action.en == "None"
                                ? Object.assign(obj, {color: "rgba(254, 203, 196, 1)"})
                                : obj;
                        })
                    ) : (
                        getRandomDummyData().map((dummyItem, index) => {
                            return {
                                label: dummyItem.label,
                                value: dummyItem.value,
                                color: dummyItem.color
                            };
                        })
                    )
                }
                showTitle={false}
                paddingAngle={10}
                innerRadius={55}
                outerRadius={100}
            />
        </TemplateChartSection>
    );

    const allCharts = [
        // <CoacheeObjectives/>,
        <CoacheeCategories/>,
        <CoacheePerformance/>,
        <CoacheeFeelings/>,
        <CoacheeActions/>,
    ];

    const rows: JSX.Element[] = getSectionGrid(allCharts);

    return (
        <div>
            {rows.length > 0 && (
                <div className="mt-0">
                    <SectionTitle title={t("text22")}/>
                    {rows}
                </div>
            )}
        </div>
    );
}

export default CoacheeSection;
