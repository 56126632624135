import React, {FC, useCallback, useState} from "react";

import {UploadIcon} from "@heroicons/react/solid";
import {useDropzone} from "react-dropzone";
import Papa from "papaparse";
import {toast} from "react-toastify";

import {IGroupCoachee} from "../../../../../models/IGroup";
import {IMeta} from "../../../../../models/IMeta";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import {useTranslation} from "react-i18next";
import {addCompanyManager} from "../../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";
import Loader from "../../../../../shared/components/Loader/Loader";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";

type UploadUserListProps = {
    onDropCsv: (value: IGroupCoachee[], managers: IMeta[]) => void;
    labelOptions: IMeta[];
};

const UserListDropzone: FC<UploadUserListProps> = ({
                                                       onDropCsv,
                                                       labelOptions,
                                                   }) => {

    i18n.addResourceBundle("en", "groups", ENG);
    i18n.addResourceBundle("fr", "groups", Fr);
    const {t} = useTranslation('groups');
    const {user} = useAuth();
    const {config} = useConfig();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onDrop = useCallback(async (files: File[]) => {
        if (!files.length)
            return toast.error(t("create_group.text22"));

        const file = files[0];

        const INVALID_CSV_ERR_MSG = t("create_group.text24");

        Papa.parse<string[]>(file, {
            worker: true,
            async complete({data}) {
                setIsLoading(true);
                if (!data.length) return toast.error(INVALID_CSV_ERR_MSG);

                const headers = data[0];
                const rows = data.slice(1);

                const emailIndex = headers.findIndex((header) => header === "email");
                const firstNameIndex = headers.findIndex(
                    (header) => header === "first_name"
                );
                const lastNameIndex = headers.findIndex(
                    (header) => header === "last_name"
                );

                const labelIndex = headers.findIndex((header) => header === "label");

                const managerEmailIndex = headers.findIndex(
                    (header) => header === "manager_email"
                );
                const managerFirstNameIndex = headers.findIndex(
                    (header) => header === "manager_first_name"
                );
                const managerLastNameIndex = headers.findIndex(
                    (header) => header === "manager_last_name"
                );

                if (emailIndex < 0) return toast.error(INVALID_CSV_ERR_MSG);

                const csvData: IGroupCoachee[] = [];
                const promises: Promise<void>[] = [];
                let hasInvalidManager = false;
                const managerData: IMeta[] = [];
                rows.forEach((row) => {
                    if (row[0] !== "") {
                        let label = labelIndex >= 0 ? row[labelIndex].trim() : undefined;
                        const labelMetaObj = labelOptions.find(
                            (labelMeta) => labelMeta.name === label
                        );
                        if (label && !label.length) label = undefined;

                        const managerEmail = managerEmailIndex >= 0 ? row[managerEmailIndex]?.trim() : undefined;
                        const managerFirstName =
                            managerFirstNameIndex >= 0 ? row[managerFirstNameIndex]?.trim() : undefined;
                        const managerLastName =
                            managerLastNameIndex >= 0 ? row[managerLastNameIndex]?.trim() : undefined;

                        if ((managerEmail || managerFirstName || managerLastName) && config?.manager_access_enable) {
                            if (!managerFirstName || !managerEmail) {
                                hasInvalidManager = true;
                                return toast.error(
                                    t("create_group.text70")
                                );
                            }

                            promises.push(
                                addCompanyManager(user?.companyId!, {
                                    email: managerEmail,
                                    first_name: managerFirstName,
                                    last_name: managerLastName || ""
                                })
                                    .then((res) => {
                                        csvData.push({
                                            email: row[emailIndex],
                                            first_name: firstNameIndex >= 0 ? row[firstNameIndex] : undefined,
                                            last_name: lastNameIndex >= 0 ? row[lastNameIndex] : undefined,
                                            labels: labelMetaObj
                                                ? [labelMetaObj]
                                                : label
                                                    ? [{name: label}]
                                                    : undefined,
                                            manager: {id: res.data.id, name: res.data.name}
                                        })
                                        managerData.push({
                                            id: res.data.id,
                                            name: res.data.name,
                                        })
                                    })
                                    .catch((err) => {
                                        csvData.push({
                                            email: row[emailIndex],
                                            first_name: firstNameIndex >= 0 ? row[firstNameIndex] : undefined,
                                            last_name: lastNameIndex >= 0 ? row[lastNameIndex] : undefined,
                                            labels: labelMetaObj
                                                ? [labelMetaObj]
                                                : label
                                                    ? [{name: label}]
                                                    : undefined,
                                            manager: undefined
                                        })
                                        toast.error(t("create_group.text71", {email: managerEmail}));
                                    })
                            )
                        } else {
                            csvData.push({
                                email: row[emailIndex],
                                first_name: firstNameIndex >= 0 ? row[firstNameIndex] : undefined,
                                last_name: lastNameIndex >= 0 ? row[lastNameIndex] : undefined,
                                labels: labelMetaObj
                                    ? [labelMetaObj]
                                    : label
                                        ? [{name: label}]
                                        : undefined,
                                manager: undefined
                            })
                        }
                    }
                });
                setIsLoading(false);
                if (hasInvalidManager) return;

                await Promise.all(promises);

                onDropCsv(csvData.filter((item) => item.email), managerData);
            },
        });
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            "text/*": [".csv"],
        },
    });

    return (
        <>
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-50">
                    <Loader/>
                </div>
            )}
            <div className="flex flex-col gap-2 border-[#f2ebe3]">
                <div className="flex flex-col gap-0.5">
                    <div
                        className="relative cursor-pointer p-2 flex flex-row justify-center items-center gap-2 bg-[#fff2f6] rounded-[5px] border-2 border-[#f56e9b] border-dashed"
                        {...getRootProps()}
                    >
                        <input className="dropzone-input" {...getInputProps()} />

                        <UploadIcon className="h-[20px] text-[#4F4F4F]"/>
                        <p className="font-lexend text-sm font-medium text-[#4F4F4F]">
                            {isDragActive ? (
                                <>
                                    {t("create_group.text18")} <span
                                    className="font-bold">.CSV</span> {t("create_group.text12")}
                                </>
                            ) : (
                                <>
                                    {t("create_group.text10")} <span
                                    className="font-bold">.CSV</span> {t("create_group.text12")}
                                </>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserListDropzone;
