interface Props {
    text: string | number;
    icon: any;
}

export const NameCell = (props:Props) => {
    const icon = props.icon as string;
    return (

            <div
                className="flex items-center gap-1.5">
                <img className="w-10 h-10 rounded-full"
                     src={icon}/>
                <span
                    className="">{props.text} </span>
            </div>
    )
}
