import {useNavigate} from "react-router-dom";

import {getGuideStatus} from "../../services/api/teams/GuideActions";
import {useConfig} from "../providers/AppConfigProvider";
import useAuth from "@hooks/useAuth";
import {AICoachType} from "../../modules/dashboard/pages/AICoaching/enum";

const useGuideStatus = (): {
    checkStatus: (callback?: () => void) => void;
} => {
    const {config} = useConfig();
    const navigate = useNavigate();
    const {user} = useAuth();

    const aiOnlyCoach = user?.aiCoachType && user.aiCoachType === AICoachType.ai_only;

    const checkStatus = (callback?: () => void) => {
        if (!config?.onboarding) return callback?.();
        getGuideStatus()
            .then((statusData) => {
                if (statusData.completed) return callback?.();
                if (aiOnlyCoach) return navigate("/ai/onboarding");
                if (!statusData.step) return navigate("/welcome");
                navigate("/onboarding", {
                    state: {
                        statusData,
                    },
                });
            })
            .catch((e) => console.log(e));
    };
    return {
        checkStatus,
    };
};

export default useGuideStatus;
