export const toolOptions: {value: string, label: string}[] = [
    {value: 'teams', label: "Teams"},
    {value: 'slack', label: "Slack"},
    {value: 'other', label: "Other"},
    {value: 'none', label: "None"}
]

export const subscriptionOptions = [
    {value: "credit", label: "Credit"},
    {value: "credit_lock", label: "Credit lock"},
    {value: "license", label: "License"},
    {value: "post_billing", label: "Post billing"}
]

export const userPermissionOptions = [
    {value: "all", label: "All"},
    {value: "white_list_only", label: "White List Only"}
]

export const aiCoachTypeOptions = [
    {
        label: "AI augmented human coaching",
        value: "ai_augmented",
    },
    {
        label: "Leadership AI coaching",
        value: "ai_only"
    },
    {
        label: "Human Only Coaching",
        value: "human_only"
    },
    // {
    //     label: "Hybrid Coaching",
    //     value: "hybrid"
    // }
]