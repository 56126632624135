import React, {useEffect, useState} from 'react';
import {ICompany, ICompanyAdmin, ICreditData, ICreditRemaining} from "../../../../../models/ICompany";
import defaultCompanyImg from "../../../../../assets/img/dashboard/company.svg";
import TeamsLogo from "../../../../../assets/img/dashboard/teams.png";
import SlackLogo from "../../../../../assets/img/dashboard/slack.png";
import {CreditCardIcon, LocationMarkerIcon, TranslateIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";
import {BiSolidCoinStack} from "react-icons/bi";
import {addAdmin, addCredits, deleteAdmin, getRemainCredits} from "../../../../../services/api/companyApi";
import CompanyCreditsTab from "./CompanyCreditsTab";
import TabComponent from "./TabComponent";
import CompanyInsightsTab from "./CompanyInsightsTab";
import CompanyAdminsTab from "./CompanyAdminsTab";
import {AnimatePresence, motion} from 'framer-motion';
import {toast} from "react-toastify";
import {Tooltip} from "antd";
import {IoSettingsOutline} from "react-icons/io5";
import {AiOutlineEdit} from "react-icons/ai";
import {GlobeAltIcon} from "@heroicons/react/outline";

interface Props {
    company: ICompany
    editCompany: (company: ICompany) => void
    newCompany: ICompany | null;
    setNewCompany: (newCompany: any) => void;
}

const CompanyCard = ({company, editCompany, newCompany, setNewCompany}: Props) => {
    const {t} = useTranslation("companyInsights");
    const [creditData, setCreditData] = useState<ICreditData[]>([]);
    const [adminData, setAdminData] = useState<ICompanyAdmin[]>([]);
    const [creditRemaining, setCreditRemaining] = useState<ICreditRemaining>();
    const [selectedOption, setSelectedOption] = React.useState('insights');
    const [isFocus, setIsFocus] = useState(false);
    const options = [
        {key: 'insights', value: t("text.insights")},
        {key: 'credits', value: t("text.credits")},
        {key: 'admins', value: t("text.admins")}
    ]

    // Check company active based on the expiration of credit_data
    // const isActiveCompany = company.credit_data?.some(item => new Date(item.expires_at) >= new Date()) || false;

    const isActiveCompany = !company.expired;

    useEffect(() => {
        if (newCompany && newCompany.id === company.id) {
            setSelectedOption('admins');
            setIsFocus(true);
        }
    }, [newCompany]);

    useEffect(() => {
        if (company.credit_data) setCreditData(company.credit_data);
        if (company.admins) setAdminData(company.admins);
        setCreditRemaining(company.credits_remaining);
    }, [company])

    const handleAddCredit = (id: number, data: any) => {
        addCredits(id, data)
            .then((res) => {
                const newEntry = {
                    id: Math.random(),
                    program_id: Math.random(),
                    credits_added: data.credits,
                    usage: 0,
                    created_date: new Date().toISOString(),
                    expires_at: data.creditExpiration,
                };
                setCreditData([...creditData, newEntry]);
                getRemainCredits(company.id)
                    .then((res) => {
                        setCreditRemaining(res);
                    })
                    .catch((e) => {
                        console.log("api error");
                    });
                toast.success(t("text.credit_added_successfully"));
            })
            .catch((err) => {
                toast.error(t("text.credit_added_error"));
                console.log(err);
            })
    }

    const handleAddAdmin = (id: number, data: any) => {
        addAdmin(id, data)
            .then((res) => {
                const newAdmin = {
                    id: res.user_id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    it_admin: data.it_admin,
                }
                setAdminData([...adminData, newAdmin]);
                toast.success(t("text.admin_added_successfully"));
            })
            .catch((err) => {
                toast.error(t("text.admin_added_error"));
                console.log(err);
            })
            .finally(() => {
                removeFocus();
            })
    }

    const handleDeleteAdmin = (company_id: number, admin_id: number) => {
        deleteAdmin(company_id, admin_id)
            .then((res) => {
                setAdminData((prevAdminData) =>
                    prevAdminData.filter((admin) => admin.id !== admin_id)
                );
                toast.success(t("text.admin_deleted_successfully"));
            })
            .catch((err) => {
                toast.error(t("text.admin_deleted_error"));
                console.log(err);
            })
    }

    const removeFocus = () => {
        setIsFocus(false);
        setNewCompany(null);
    }

    return (
        <div
            className={`flex flex-col md:flex-row bg-white rounded-lg border ${isFocus ? 'border-green-500' : 'border-gray-100'} overflow-hidden mb-2`}>
            <div className="p-4 border-b border-gray-100 md:w-2/5 w-full">
                <div className="flex items-center space-x-4">
                    <div className="w-14 h-14 bg-gray-100 rounded-lg flex items-center justify-center">
                        {company.logo_url ? (
                            <img
                                src={company.logo_url}
                                alt={`${company.name} logo`}
                                className="w-12 h-auto object-cover rounded"
                            />
                        ) : (
                            <img
                                src={defaultCompanyImg}
                                alt="Default Company Logo"
                                className="w-6 h-auto object-cover rounded"
                            />
                        )}
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <h2 className="text-lg font-bold text-gray-900">{company.name}</h2>
                            {(company.tool === "teams" || company.tool === "slack") &&
                                <span className="bg-gray-200 p-0.5 rounded">
                                <img src={company.tool === "teams" ? TeamsLogo : SlackLogo} className="h-5 w-auto"/>
                            </span>}
                            <span className={`inline-flex px-2 py-1 text-xs font-medium rounded-full ${
                                isActiveCompany ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                        {isActiveCompany ? t("status.active") : t("status.expired")}
                            </span>
                        </div>
                        <div className="flex items-center space-x-3 text-sm text-gray-500">
                            <Tooltip title={t("tooltip.language")}>
                                <div className="flex items-center">
                                    <TranslateIcon className="w-4 h-4 mr-1"/>
                                    {company.primary_language?.name}
                                </div>
                            </Tooltip>
                            <Tooltip title={t("tooltip.credit")}>
                                <div className="flex items-center">
                                    <BiSolidCoinStack className="w-4 h-4 mr-1"/>
                                    {company.credit_limit || t("text.unlimited")}
                                </div>
                            </Tooltip>
                            <Tooltip title={t("tooltip.subscription")}>
                                <div className="flex items-center">
                                    <CreditCardIcon className="w-4 h-4 mr-1"/>
                                    {company.subscription_method.replace(/_/g, ' ')
                                        .split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')}
                                </div>
                            </Tooltip>
                        </div>
                        <div className="flex items-center gap-2 mt-2">
                            {company.website && <Tooltip title={t("tooltip.website")}>
                                <div className="flex items-center">
                                    <GlobeAltIcon className="w-4 h-4 mr-1"/>
                                    <a
                                        //https://coachello.io/
                                        href={company.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline">
                                        {(() => {
                                            try {
                                                return new URL(company.website.startsWith("http") ? company.website : `https://${company.website}`).hostname;
                                            } catch (error) {
                                                console.error("Invalid URL:", company.website);
                                                return company.name;
                                            }
                                        })()}
                                    </a>
                                </div>
                            </Tooltip>}
                            {company.location && <Tooltip title={t("tooltip.location")}>
                                <div className="flex items-center">
                                    <LocationMarkerIcon className="w-4 h-4 mr-1"/>
                                    {company.location}
                                </div>
                            </Tooltip>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full p-3 gap-2">
                <div className="flex justify-between items-center">
                    <TabComponent
                        options={options}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                    />
                    <div className="flex items-center space-x-2">
                        <Tooltip title={t("tooltip.edit")}>
                            <AiOutlineEdit
                                className="w-4 h-4 cursor-pointer"
                                onClick={() => editCompany(company)}
                            />
                        </Tooltip>
                        {/*<Tooltip title={t("tooltip.settings")}>*/}
                        {/*    <IoSettingsOutline className="w-4 h-4 cursor-pointer"/>*/}
                        {/*</Tooltip>*/}
                    </div>
                </div>
                <AnimatePresence mode="wait">
                    {selectedOption === 'insights' && (
                        <motion.div
                            key="insights"
                            initial={{opacity: 0, x: 50}}
                            animate={{opacity: 1, x: 0}}
                            exit={{opacity: 0, x: -50}}
                            transition={{
                                duration: 0.4,
                                ease: [0.2, 0, 0.3, 1]
                            }}
                        >
                            <CompanyInsightsTab company={company} creditRemaining={creditRemaining}/>
                        </motion.div>
                    )}
                    {selectedOption === 'credits' && (
                        <motion.div
                            key="credits"
                            initial={{opacity: 0, x: 50}}
                            animate={{opacity: 1, x: 0}}
                            exit={{opacity: 0, x: -50}}
                            transition={{
                                duration: 0.4,
                                ease: [0.2, 0, 0.3, 1]
                            }}
                        >
                            <CompanyCreditsTab companyCredits={creditData} company={company}
                                               addCredit={handleAddCredit}/>
                        </motion.div>
                    )}
                    {selectedOption === 'admins' && (
                        <motion.div
                            key="admins"
                            initial={{opacity: 0, x: 50}}
                            animate={{opacity: 1, x: 0}}
                            exit={{opacity: 0, x: -50}}
                            transition={{
                                duration: 0.4,
                                ease: [0.2, 0, 0.3, 1]
                            }}
                        >
                            <CompanyAdminsTab companyAdmins={adminData} company={company}
                                              addAdmin={handleAddAdmin} deleteAdmin={handleDeleteAdmin}
                                              isFocus={isFocus} removeFocus={removeFocus}/>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default CompanyCard;
