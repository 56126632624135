import {useEffect, useState} from "react";

import {twMerge} from "tailwind-merge";

import WelcomeCard from "./components/WelcomeCard";
import {IAIActionNotificationState, NotificationItems, OverviewItem} from "../AICoaching/types";
import useGuideStatus from "../../../../utils/hooks/useGuideStatus";
import {getAIJourneyOverview} from "../../../../services/api/AiBookingActions";
import {
    getAIOverview,
    getAllNotificationsSummary,
    updateStateAIActionNotification
} from "../../../../services/api/aiBookingApi";
import {getAllSchedule} from "./actions";
import NotificationView from "./components/NotificationView";
import {useConfig} from "../../../../utils/providers/AppConfigProvider";
import {AIActionNotificationState} from "../../../../constants/enums";
import useAuth from "@hooks/useAuth";


const AiHome = () => {
    const [overviewItems, setOverviewItems] = useState<OverviewItem[]>([]);
    const [overviewJourneyItems, setOverviewJourneyItems] = useState<
        OverviewItem[]
    >([]);
    const {config} = useConfig();

    const [schedules, setSchedules] = useState<any[]>([]);
    const guideStatus = useGuideStatus();
    const {user} = useAuth();
    const clientId = user?.id;
    const [notificationSummary, setNotificationSummary] = useState<NotificationItems[]>([]);

    const fetchAINotificationsSummary = () => {
        if (clientId) {
            getAllNotificationsSummary(clientId)
                .then((data: NotificationItems[]) => {
                    setNotificationSummary(data);
                })
                .catch((error) => {
                    console.error('Error fetching recommendations:', error);
                })
        }
    }

    useEffect(() => {
        fetchAINotificationsSummary();
    }, [clientId]);

    const updateNotificationStatus = (id: number, data: IAIActionNotificationState) => {
        setNotificationSummary((prevMaterials) => {
            return prevMaterials.map(item =>
                item.notification_id === id ? {...item, ...data} : item
            );
        });

        updateStateAIActionNotification(id, data)
            .then(() => {
                fetchAINotificationsSummary();
            })
            .catch(err => {
                console.error("Error updating the article state", err);
            });
    };

    const handleUpdateStateAIActionNotification = (id: number, data: IAIActionNotificationState) => {
        if (data.state === AIActionNotificationState.active) {
            updateNotificationStatus(id, data);
        } else {
            updateStateAIActionNotification(id, data)
                .catch((error) => {
                    console.error('Error updating state action notification:', error);
                })
        }
    }

    useEffect(() => {
        guideStatus.checkStatus(() => {
            console.log('done')
        });

    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const sessionList = await getAllSchedule();
            setSchedules(sessionList);
            const journeyOverviewData = await getAIJourneyOverview();
            if (journeyOverviewData) setOverviewJourneyItems(journeyOverviewData.reverse());
            const overviewData = await getAIOverview();
            setOverviewItems(overviewData.reverse());
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div
            className="bg-linen_gradient h-full flex flex-col justify-center gap-4 py-6 px-4 lg:px-[7.5%] overflow-x-hidden align-center items-center">
            <div className={twMerge(
                "h-full flex w-full flex-col items-center mt-8",
                schedules?.length || overviewItems?.length || overviewJourneyItems?.length
                    ? "justify-start"
                    : "justify-center"
            )}>

                <WelcomeCard overviewJourneyItems={overviewJourneyItems}/>
                {(config?.ai_action_notification && notificationSummary.length > 0) && <NotificationView
                    notificationSummary={notificationSummary}
                    handleUpdate={handleUpdateStateAIActionNotification}/>}

            </div>
            <br/>
        </div>
    );
};

export default AiHome;
