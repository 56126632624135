import { boolean } from "yup";

export const initValues = {
  titleForBlocked: "",
  daysOfFuture: "",
  indentifyInToTheFuture: false,
  timeZone: "",
  blockTransparentEvents: true,
  weeklyDays: [
    {
      id: "monday",
      title: "Mon",
      isTrue: false,
      start: "",
      end: ""
    },
    {
      id: "tuesday",
      title: "Tue",
      isTrue: false,
      start: "",
      end: ""
    },
    {
      id: "wednesday",
      title: "Wed",
      isTrue: false,
      start: "",
      end: ""
    },
    {
      id: "thursday",
      title: "Thu",
      isTrue: false,
      start: "",
      end: ""
    },
    {
      id: "friday",
      title: "Fri",
      isTrue: false,
      start: "",
      end: ""
    },
    {
      id: "saturday",
      title: "Sat",
      isTrue: false,
      start: "",
      end: ""
    },
    {
      id: "sunday",
      title: "Sun",
      isTrue: false,
      start: "",
      end: ""
    }
  ],
  afterTime: {
    isTrue: false,
    time: ""
  },
  beforeTime: {
    isTrue: false,
    time: ""
  },
};

export const eventsDummy: any = [];
export const customSettingDummy: any = {};