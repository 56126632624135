import {useEffect, useRef} from "react";

import {AnimatePresence, motion} from "framer-motion";
import {twMerge} from "tailwind-merge";

import ChatItem from "./ChatItem";
// Import Immer for more efficient state updates
import ChatLoading from "./ChatLoading";

import {useAICoaching} from "../../utils/AICoachingContext";

type ChatProps = {
    // newMessage: IChatItem | undefined
    messageRemovable?: boolean;
    // onRemoveMessage?: (message: IChatItem) => void
    // isLoading?: boolean
};

const Chat = ({messageRemovable}: ChatProps) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const {
        messages,
        addMessage,
        removeMessage,
        lastChangedIndex,
        isLoading,
        customChatSection,
        isStartState,
        isEndState
    } = useAICoaching();


    const animatingMessages = messages.slice(lastChangedIndex);


    //If browser doesnt support the anchor or scroll down the chat to bottom at initially
    useEffect(() => {
        // anchorRef.current?.scrollIntoView({behavior: "smooth"});
    }, [anchorRef, messages]);

    const getDuration = (index: number) => {
        const calIndex = index + 1;
        return calIndex * 0.1 + 0.7;
    };

    return (
        <div
            // style={{
            //     background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(194,178,187,1) 35%, rgba(255,0,0,0.27914915966386555) 100%)"}}
            className={twMerge(
                "flex flex-col-reverse", //If need to start the chat by bottom
                " h-full",
                // "bg-gradient-to-b from-white to-red-"
            )}
        >

            <div id="scroller" className="flex flex-col-reverse px-2 gap-3">
                {/*used '-reverse' to treat like bottom to up*/}
                <div className="" ref={anchorRef} id="anchor"></div>

                {/*Custom section*/}
                <div className="w-full transition-all min-h-[8rem] ">
                    <AnimatePresence>
                        {isLoading ? <ChatLoading
                                role="assistant"
                                isLoading={isLoading}
                                showProfile={isLoading}
                                isStartState={isStartState}
                                isEndState={isEndState}
                            />
                            :
                            customChatSection && <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                transition={{duration: 0.3}}
                                className="w-full p-2">
                                {customChatSection}
                            </motion.div>}
                    </AnimatePresence>
                </div>


                <AnimatePresence initial={false} mode="popLayout">
                    {messages.map((item, index) => {
                        const showProfile =
                            messages.length == index + 1 ||
                            messages[index + 1].role !== item.role;

                        const chatItem = (item.role == 'system' ? item.content() :
                                <ChatItem
                                    id={item.id}
                                    role={item.role}
                                    content={item.content}
                                    footer={item.footer}
                                    // isLoading={isLoading && index==0 && item.role=='assistant'}
                                    header={item.header}
                                    showProfile={showProfile}
                                    voice={item.voice}
                                />
                        );
                        return index < 20 ? (
                            <motion.div
                                layout
                                initial={{opacity: 0, scale: 0.8}}
                                animate={{opacity: 1, scale: 1}}
                                exit={{
                                    opacity: 0,
                                    scale: 0.8,
                                    // transition: { duration: 0.3 }
                                }}
                                transition={{
                                    opacity: {duration: 0.2},
                                    // scale: {duration: 0.4},
                                    layout: {
                                        type: "spring",
                                        bounce: 0.4,
                                        duration: getDuration(animatingMessages.indexOf(item)),
                                    },
                                }}
                                style={{
                                    originX: item.role == "me" ? 1 : 0,
                                }}
                                // ${(isLoading && index==0) ? "true":""}
                                key={`${item.id}`}
                                onClick={() => messageRemovable && removeMessage(item, index)}
                                className={twMerge("flex", "py-1")}
                            >
                                {chatItem}
                                {/*For increase performance*/}
                            </motion.div>
                        ) : (
                            <div
                                key={item.id}
                                onClick={() => removeMessage(item, index)}
                                className={twMerge("flex", "py-1")}
                            >
                                {chatItem}
                            </div>
                        );
                    })}
                </AnimatePresence>


            </div>
        </div>
    );
};

export default Chat;
