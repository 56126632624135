import Loader from "./Loader/Loader";

interface Props {
    spinnerSize?: string;
}

const LoaderContainer = ({spinnerSize} : Props) => {
  return (
    <div className="flex items-center h-full w-full justify-center">
      <Loader spinnerSize={spinnerSize} />
    </div>
  );
};

export default LoaderContainer;
