import React from 'react';
import './GradientSpinner.css';

interface SpinnerProps {
    color1?: string;
    color2?: string;
    color3?: string;
}

const GradientSpinner: React.FC<SpinnerProps> = ({
                                                     color1 = '#FFA5C3',
                                                     color2 = '#FD0054',
                                                     color3 = '#D9D9D9',
                                                 }) => {
    return (
        <div
            className="orb"
            style={{
                boxShadow: `
                    inset 0 0 50px ${color3},
                    inset 20px 0 60px ${color1},
                    inset -20px 0 60px ${color2},
                    inset 20px 0 300px ${color1},
                    inset -20px 0 300px ${color2}
                `
            }}
        >
        </div>
    );
};

export default GradientSpinner;
