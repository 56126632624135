import {AnimatePresence, motion} from "framer-motion";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {setClientPreference} from "../../../../../services/api/clientApi";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";
import useAuth from "@hooks/useAuth";
import {IMeta} from "../../../../../models/IMeta";
import {getLanguages} from "../../../../../services/api/metaApi";
import ReactSelect from "react-select";
import ReactCountryFlag from "react-country-flag";

type ChatSettingsProps = {
    isOpen: boolean;
    onClose: () => void;
}

type SettingsType = {
    quick_replies_enabled: boolean;
    voice_speed: string;
}

const ChatSettings = ({isOpen, onClose}: ChatSettingsProps) => {
    const {t} = useTranslation("ai_coaching");
    const {config, fetchConfig} = useConfig();
    const {user} = useAuth();
    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>()
    const [selectedLanguage, setSelectedLanguage] = useState<IMeta | null>(null);
    const [languagesOptionsWithCode, setLanguagesOptionsWithCode] = useState<IMeta[]>();

    const generateLanguageList = (languages: IMeta[]) => {
        const newList: any = [];

        languages.forEach(language => {
            if (language.lang_codes && language.lang_codes.length > 0) {
                language.lang_codes.forEach((lang_code: any) => {
                    let country = lang_code.split('-')[1];
                    if (lang_code === 'fr-CH') {
                        country = 'FR'; // Special case for 'fr-CH'
                    }
                    const label = `${language.name} (${country})`;

                    newList.push({
                        id: language.id,
                        name: language.name,
                        code: language.code,
                        lang_code: lang_code,
                        country: country,
                        label: label
                    });
                });
            }
        });

        return newList;
    };

    const initialSettings: SettingsType = {
        quick_replies_enabled: config?.client_settings?.quick_replies_enabled ?? false,
        voice_speed: config?.client_settings?.voice_speed ?? 'medium',
    };

    useEffect(() => {
        getLanguages().then((languages) => {
            setLanguagesOptions(languages);
            const langList = generateLanguageList(languages);
            setLanguagesOptionsWithCode(langList);
            let preferredLanguageCode = config?.client_settings?.preferred_language_code;
            const defaultLangOption = langList.find((lang: IMeta) => lang.lang_code === preferredLanguageCode);
            setSelectedLanguage(defaultLangOption || null);
        });
    }, []);

    useEffect(() => {
        if (languagesOptionsWithCode) {
            const defaultLang = config?.client_settings?.preferred_language_code;
            const defaultLangOption = languagesOptionsWithCode.find((lang: IMeta) => lang.lang_code === defaultLang);
            setSelectedLanguage(defaultLangOption || null);
        }
    }, [config]);


    const [settings, setSettings] = useState<SettingsType>(initialSettings);
    const [initialSettingsSnapshot, setInitialSettingsSnapshot] = useState(initialSettings);

    useEffect(() => {
        setSettings(initialSettings);
        setInitialSettingsSnapshot(initialSettings);
    }, [config]);


    const updateSetting = (key: keyof SettingsType, value: boolean | string) => {
        setSettings(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleSelectedLanguage = (language: IMeta | null) => {
        setSelectedLanguage(language);
    };


    const handleOnClose = () => {
        const changedSettings = (Object.keys(settings) as Array<keyof SettingsType>).reduce((changes, key) => {
            if (settings[key] !== initialSettingsSnapshot[key]) {
                changes[key] = settings[key];
            }
            return changes;
        }, {} as Record<string, boolean | string>);

        const payload: {
            preferred_language?: any;
            client_preference: { preference_key: string; preference_value: string | boolean }[];
        } = {
            client_preference: Object.entries(changedSettings).map(([key, value]) => ({
                preference_key: key,
                preference_value: value,
            })),
        };

        if (selectedLanguage && selectedLanguage?.lang_code !== config?.client_settings?.preferred_language_code) {
            payload.preferred_language = {id: selectedLanguage?.id, name: selectedLanguage?.name};
            payload.client_preference.push({
                preference_key: 'preferred_language_code',
                preference_value: selectedLanguage?.lang_code,
            });
        }

        if (payload.preferred_language || payload.client_preference.length > 0) {
            setClientPreference(payload)
                .then(() => {
                    console.log("Preferences updated successfully.");
                    fetchConfig(user);
                })
                .catch((error) => {
                    console.error("Error updating preferences:", error);
                });
        }

        onClose();
    };

    const voiceSpeedOptions = [
        {key: 'slow', label: t("settings.slow")},
        {key: 'medium', label: t("settings.medium")},
        {key: 'fast', label: t("settings.fast")},
    ];

    const getPlatformStyle = () => {
        const userAgent = navigator.userAgent;

        // Check for Windows
        if (/Windows/i.test(userAgent)) {
            return {
                width: '21px',
                height: '14px',
                borderRadius: '2.5px',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.2)',
            };
        }

        // Default styling (for other platforms)
        return {
            fontSize: '1.3em',
            lineHeight: '1.3em',
        };
    };

    const formatOptionLabel = (option: any) => (
        <div className="flex items-center space-x-1">
            <ReactCountryFlag
                countryCode={option.country}
                {...(/Windows/i.test(navigator.userAgent) ? { svg: true } : {})}
                style={getPlatformStyle()}
            />
            <span className="text-xs">{option.label}</span>
        </div>
    );

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex justify-end z-30">
            <div
                className="fixed inset-0 bg-black opacity-50"
                onClick={handleOnClose}
            ></div>
            <AnimatePresence>
                <motion.div
                    initial={{x: 300, opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    exit={{x: 300, opacity: 0}}
                    transition={{
                        duration: 0.5,
                        ease: [0.25, 0.8, 0.5, 1]
                    }}
                    className="w-80 h-full text-black font-dm_sans bg-white p-4 overflow-y-auto z-40"
                >
                    <div className="flex justify-between items-center border-b pb-2 mb-4">
                        <span className="text-base font-bold">{t("settings.title")}</span>
                        <button onClick={handleOnClose} className="text-gray-500 hover:text-gray-700">
                            ✕
                        </button>
                    </div>

                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <span>{t("settings.quick_replies")}</span>
                            <div
                                onClick={() => updateSetting("quick_replies_enabled", !settings.quick_replies_enabled)}
                                className={`w-10 h-5 flex items-center bg-gray-200 rounded-full p-1 cursor-pointer transition-colors duration-300 ${settings.quick_replies_enabled ? 'bg-[#FECBC4]' : 'bg-gray-300'}`}
                            >
                                <div
                                    className={`bg-[#FF563F] w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${settings.quick_replies_enabled ? 'translate-x-4' : ''}`}
                                ></div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span>{t("settings.preferred_lang")}</span>
                            <ReactSelect
                                getOptionLabel={(option) => option.label}
                                // @ts-ignore
                                getOptionValue={(option) => option.lang_code}
                                onChange={(item) => handleSelectedLanguage(item as IMeta)}
                                value={selectedLanguage}
                                isMulti={false}
                                name="language"
                                options={languagesOptionsWithCode}
                                placeholder={t("settings.select_lang")}
                                className="w-[140px] p-0"
                                formatOptionLabel={formatOptionLabel}
                            />
                        </div>
                        {/*<div className="flex justify-between items-center">*/}
                        {/*    <span>{t("settings.speed")}</span>*/}
                        {/*    <select*/}
                        {/*        value={settings.voice_speed}*/}
                        {/*        onChange={(e) => updateSetting("voice_speed", e.target.value)}*/}
                        {/*        className="bg-gray-200 rounded p-1"*/}
                        {/*    >*/}
                        {/*        {voiceSpeedOptions.map(option => (*/}
                        {/*            <option key={option.key} value={option.key}>*/}
                        {/*                {option.label}*/}
                        {/*            </option>*/}
                        {/*        ))}*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
}

export default ChatSettings;