export enum ERouteType {
  private = "private",
  public = "public",
  restricted = "restricted",
  teamsPrivate = "teamsPrivate",
}

export enum ETheme {
  light = "light",
  dark = "dark",
}

export enum EAuthType {
  Email = "Email",
  GAuth = "GAuth",
  MS = "MS",
  Token = "Token",
}

export enum ERole {
  SuperAdmin = "super_admin",
  Coach = "coach",
  HrAdmin = "hr_admin",
  GroupAdmin = "group_admin",
  Manager = "manager",
  ItAdmin = "it_admin",
  Coachee = "coachee",
}

export enum EHomeDropdownAction {
  Reshedule = "Reshedule",
  Cancel = "Cancel",
  CheckReport = "CheckReport",
  LeaveReview = "LeaveReview",
  JoinSession = "JoinSession",
  ScheduleNext = "ScheduleNext",
  Message = "Message",
  CompleteNow = "CompleteNow",
}

export enum EAssessmentType {
  SelfReflection = "self_reflection",
  Reflection360 = "reflection360",
  ImpactReflection = "impact_reflection",
  Review = "review",
  Coaching = "coaching"
}

export enum EConfirmBookingFormField {
  firstName = "firstName",
  lastName = "lastName",
  noteForCoach = "noteForCoach",
}

export enum EAssessmentScoreField {
  career = "career",
  intellect = "intellect",
  organisation = "organisation",
  leadership = "leadership",
  communication = "communication",
  worklife = "worklife",
  assertivity = "assertivity",
  self_esteem = "self_esteem",
}

export enum ESessionSlotSize {
  Sixty = "Sixty",
  Fifteen = "Fifteen",
}

export enum Colors {
  red = "#FD0054",
  blue = "#ACC7E8",
  green = "#B4E1C6",
  pink = "#FECBC4",
  purple = "#BDACE8",
  yellow = "#FFE589",
  yellowLight = "#FFEADD",
  warm = "#F2EBE3",
  warmDark = "#e6dcd1",
}

export enum ContentState {
  mark_as_read = "mark_as_read",
  saved = "saved",
  irrelevant = "irrelevant",
  removed = "removed",
  has_clicked = "has_clicked",
}

export enum FeedbackStatus {
  pending = "pending",
  received = "received"
}

export enum DataState {
  NO_DATA = 'NO_DATA',
  NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
  DATA = 'DATA'
}

export enum FrequencyType {
  weekly = "weekly",
  monthly = "monthly"
}

export enum UsageStatusKeys {
  completed = 'Completed',
  ongoing = 'Ongoing',
  paused = 'Paused',
  not_started = 'Not Started'
}

export enum SessionStatusKeys {
  not_started = 'Upcoming',
  success = 'Completed',
  no_show_by_client = 'No Show',
}

export enum AllowedFromTable {
  group_client = "group_client",
  client = "client",
  company = "company",
  group = "group"
}

export enum VoiceSupportCommands{
    DISCONNECT = "DISCONNECT",
    START_VOICE = "START-VOICE",
    STOP_VOICE = "STOP-VOICE",
  }
export enum VoiceSupportInfo{
    DISCONNECTED = "DISCONNECTED",
    DISCONNECT_TIMEOUT = "DISCONNECT-TIMEOUT",
    TRANSCRIBING = "TRANSCRIBING",
    SILENCE_TIMEOUT = "SILENCE-TIMEOUT",
    PAUSE_TIMEOUT = "PAUSE-TIMEOUT",
}
export enum AIActionNotificationState {
  active = "active",
  completed = "completed",
}

export enum RenewalCycleFrequency{
  yearly = "yearly",
  bi_annually = "bi_annually",
  tri_annually = "tri_annually"
}