import {useMemo} from "react";

import ArrowRight from "../../../components/ArrowRight";
import Button from "../../../components/Button";

import img_stairs from "../../../../../assets/img/aiCoaching/stairs.png";
import img_puzzle_blocks from "../../../../../assets/img/aiCoaching/puzzle_blocks.png";
import homeHeaderDummyDp from "../../../../../assets/img/icons/user.png";
import {OverviewItem} from "../../AICoaching/types";
import useAuth from "../../../../../utils/hooks/useAuth";
import {AIModel} from "../../AIOnlyCoaching/enums";
import {useWithParamNavigation} from "../../../../../utils/hooks/useWithParamNavigation";
import { useTranslation } from "react-i18next";
import ENG from "../locale/en/ai_home.json"
import Fr from "../locale/fr/ai_home.json"
import i18n from "../../../../../utils/lib/i18n";

type Props = {
    overviewJourneyItems: OverviewItem[];
    progress?: number;
};
const WelcomeCard = ({
                         overviewJourneyItems, progress,
                     }: Props) => {
    const {t} = useTranslation("ai_home");
    i18n.addResourceBundle("en", "ai_home", ENG);
    i18n.addResourceBundle("fr", "ai_home", Fr); 
    const {user} = useAuth();
    const navigateWithParams = useWithParamNavigation();

    const totalJourney = 7

    const name = useMemo(() => {
        if (!user) return '';
        return `${user.firstName} ${user.lastName}!`.trim();
    }, [user]);

    const createContext = () => {
        if (!overviewJourneyItems || overviewJourneyItems.length == 0) {
            return {aiModel: AIModel.journey, new: true}
        } else {
            // @ts-ignore
            return {aiModel: AIModel.journey, jump: true, threadId: overviewJourneyItems.at(0).id}
        }
    }
    if (overviewJourneyItems.length > 0) {
        let sessions = 1
        if (overviewJourneyItems[0].sub_session_ids && overviewJourneyItems[0].sub_session_ids.length > 0) {
            sessions = sessions + overviewJourneyItems[0].sub_session_ids.length
        }
        progress = Number((sessions / totalJourney * 100).toPrecision(3))
    } else {
        progress = 0
    }

    return (
        <div className="flex flex-col rounded w-full p-6 gap-6">
            <div className="flex flex-row items-center gap-3">
                <img
                    src={user?.imageUrl || homeHeaderDummyDp}
                    className="w-10 h-10 rounded-full object-contain"
                    alt="profile"
                />

                <div className="flex flex-col">
                    <p className="font-bold font-dm_sans text-base text-black m-0">
                        {t("welcome_card.title")}, {name}
                    </p>
                    <p className="font-bold text-base font-dm_sans text-black m-0">
                        {t("welcome_card.text1")}
                    </p>
                </div>
            </div>
            <div className="flex flex-col bg-white rounded w-full p-6 gap-6">
                <div className="relative flex flex-col w-full justify-between items-center gap-4 lg:flex-row">
                    <div className="relative flex flex-col w-full items-start md:items-center gap-4 md:flex-row">
                        <img src={img_stairs} className="h-10 w-auto" alt="journey"/>

                        <div className="flex flex-col gap-1">
                            <p className="font-bold text-base font-dm_sans text-black m-0">
                                {progress === 0 ? t("welcome_card.text2") : t("welcome_card.text9")}
                            </p>
                            <p className="text-sm font-dm_sans text-black m-0">
                                {t("welcome_card.text3")}
                            </p>
                        </div>
                    </div>
                    <Button
                        className="absolute flex items-center justify-center gap-2 px-2 py-2.5
                border-2 border-red bg-red text-white text-sm whitespace-nowrap lg:h-8 "
                        onClick={() => {
                            navigateWithParams('/dashboard/ai/coaching', createContext());
                        }}
                    >
                        <div className="flex items-center gap-2">
                            {(!overviewJourneyItems || overviewJourneyItems.length == 0) ? t("welcome_card.text4") : t("welcome_card.text5")}
                            <ArrowRight/>
                        </div>
                    </Button>
                </div>
                <div className="flex items-center gap-2 mt-2">
                    <div className="flex w-full h-1.5">
                        <div
                            className="h-full bg-red-400 rounded-l-full"
                            style={{
                                width: `${progress}%`,
                                backgroundColor: "#FECBC4",
                            }}
                        />
                        <div
                            className="flex-1 bg-red-400 rounded-r-full opacity-30"
                            style={{
                                width: `${progress}%`,
                                backgroundColor: "#FECBC4",
                            }}
                        />
                    </div>
                    <p className="text-s font-dm_sans text-black m-0">{progress}%</p>
                </div>
            </div>
            <div className="flex flex-col bg-white rounded w-full p-6 gap-6">
                <div className="relative flex flex-col w-full justify-between items-center gap-4 lg:flex-row">
                    <div className="relative flex flex-col w-full items-start md:items-center gap-4 md:flex-row">

                        <img src={img_puzzle_blocks} className="h-10 w-auto scale-x-[-1]" alt="adhoc"/>

                        <div className="flex flex-col gap-1">
                            <p className="font-bold text-base font-dm_sans text-black m-0">
                                {t("welcome_card.text6") }
                            </p>
                            <p className="text-sm font-dm_sans text-black m-0">{t("welcome_card.text7") }</p>
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            navigateWithParams('/dashboard/ai/coaching', {aiModel: AIModel.flash, new: true});
                        }}
                        className="whitespace-nowrap justify-center rounded flex items-center h-8 gap-2 px-2 py-1
                    border-2 border-black text-black text-sm w-full lg:w-48"
                    >
                        <div className="flex items-center  gap-3">
                            {t("welcome_card.text8") }
                        </div>
                        <ArrowRight/>
                    </button>

                </div>

            </div>
        </div>
    );
};

export default WelcomeCard;
