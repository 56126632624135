import React, {useEffect, useRef, useState} from 'react';
import useOutsideDetector from "@hooks/useOutsideDetector";

interface Props {
    button: JSX.Element;
    body: JSX.Element;
    bodyWrapperClassName?: string;
    isOpen?: boolean;
}

const DropdownWrapper = ({button, body, bodyWrapperClassName, isOpen}: Props) => {
    const [show, setShow] = useState(false)
    const classes = "z-20 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none " + bodyWrapperClassName;
    const wrapperRef = useRef(null);
    const outSide = useOutsideDetector(wrapperRef);

    useEffect(() => {
        if (isOpen === false) {
            setShow(false)
        }
    }, [isOpen]);

    useEffect(()=>{
        setShow(false)
    },[outSide])
    return (
        <div ref={wrapperRef} className="relative">
            <div onClick={() => setShow((prevState) => !prevState)} className={""}>
                {button}
            </div>
            {show && <div
                className={classes}>
                {body}
            </div>}
        </div>
    );
};

export default DropdownWrapper;
