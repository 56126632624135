import React from "react";
import {ICompany} from "../../../../models/ICompany";
import CompanyProfileForm from "../../forms/CompanyProfileForm";
import {createCompany} from "../../../../services/api/companyApi";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/company.json";
import FR from "../../locale/fr/company.json";

interface CompanyCreateProps {
    onSave?: (company: ICompany) => void;
}

const CompanyCreate = ({onSave}: CompanyCreateProps) => {
    i18n.addResourceBundle("en", "company", ENG);
    i18n.addResourceBundle("fr", "company", FR);
    const {t} = useTranslation("company");
    const initialValues = {
        name: "",
        tool: "",
        credit_limit: 0,
        website: "",
        logo_url: "",
        user_permission: "",
        domain: "",
        primary_language: "",
        location: "",
        challenges: "",
        head_count: "0",
        seniority: "",
        subscription_method: "",
        other_info: "",
        teams_event_on_coachello: true,
        intro_session: false,
        group_name: 'Default',
        ai_coach_type: {value: "ai_augmented", label: "AI augmented human coaching"}
    };

    const handleSubmit = (values: any) => {
        const newCompany = {
            ...values,
            tool: values.tool.value,
            user_permission: values.user_permission.value,
            subscription_method: values.subscription_method.value,
            primary_language_id: values.primary_language.id,
            credit_limit: parseInt(values.credit_limit, 10)
        }

        createCompany(newCompany)
            .then((res) => {
                const {company} = res.data;
                toast.success(t("create_success"));
                onSave && onSave({
                    ...company,
                    id: res.data.id,
                    primary_language: {
                        id: company.primary_language.id,
                        name: company.primary_language.value
                    },
                    credit_limit: res.max_credit_per_client
                });
            })
            .catch((err) => {
                toast.error(t("create_error"));
                console.log(err);
            });
    };

    return <CompanyProfileForm initialValues={initialValues} onSubmit={handleSubmit} isCreate={true} />;
};

export default CompanyCreate;
