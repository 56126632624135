import {useEffect, useMemo, useState} from "react";

import {DocumentDownloadIcon, FilterIcon} from "@heroicons/react/solid";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import {useSearchParams} from "react-router-dom";
import Select from "react-select";
import {RefreshIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";

import Pagination from "../Pagination";
import Search from "./Search";
import MyDateRange from "../MyDateRange";
import NoDataWrapper from "../NoDataWrapper";
import {UsageColumns, UsageSessionViewColumns,} from "../../../constants/data/TableData";
import ToggleButton from "../ToggleButton";

import {isEmpty} from "../../../utils/helpers/object";
import {exportToCsv} from "../../../utils/helpers/download";
import {filterInnerDictArr} from "../../../utils/helpers/arr";
import useAuth from "@hooks/useAuth";
import {
    getLabels,
    getMetaGroups,
    getPrograms,
    getUtilisation,
    getUtilisationSessionView,
} from "../../../services/api/companyApi";

import {IMeta} from "../../../models/IMeta";
import {Colors, SessionStatusKeys, UsageStatusKeys} from "../../../constants/enums";
import {CalendarSelectionType} from "../../../models";

import NoDataIcon from "@icons/noDataIcon.png";
import {useConfig} from "../../../utils/providers/AppConfigProvider";
import LoaderContainer from "../LoaderContainer";
import {DropdownWrapper} from "@components";

type FilterOption = {
    label: string;
    value: string | number;
};

const UsageTable = () => {
    const {t} = useTranslation("usage");
    const {config} = useConfig();

    const {user} = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();

    const [utilisation, setUtilisation] = useState<Record<any, any>[]>([]);
    const [isfiltered, setIsfiltered] = useState(false);
    const [sessionView, setSessionView] = useState(
        searchParams.get("sessions") === "true" ? true : false,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [withPreviousCycles, setWithPreviousCycles] = useState(() => {
        const storedValue = localStorage.getItem("withPreviousCycles");
        return storedValue !== null ? JSON.parse(storedValue) : false;
    });

    const handleWithPreviousCycles = (value: boolean) => {
        setWithPreviousCycles(value)
        getUsageData(null, null, undefined, value);
    }

    useEffect(() => {
        localStorage.setItem("withPreviousCycles", JSON.stringify(withPreviousCycles));
    }, [withPreviousCycles]);

    const initialCalState = [
        {
            startDate: null,
            endDate: null,
            key: "selection",
        },
    ];

    const columns = useMemo(() => {
        if (sessionView) {
            setUtilisation([]);
            return UsageSessionViewColumns;
        } else {
            setUtilisation([]);
            return UsageColumns;
        }
    }, [sessionView]);

    const [columnVisibility, setColumnVisibility] = useState<any>({})

    useEffect(() => {
        if (!config?.self_reflection && !sessionView) {
            setColumnVisibility({assessment_status: false})
        } else {
            setColumnVisibility({})

        }
    }, [config?.self_reflection, sessionView]);

    const usageStatusKeyValues = Object.keys(UsageStatusKeys).map((key) => ({
        label: t(`status.${key}`),
        value: UsageStatusKeys[key as keyof typeof UsageStatusKeys]
    }));

    const sessionStatusKeyValues = Object.keys(SessionStatusKeys).map((key) => ({
        label: t(`tables:onboarding_status.${key === "not_started" ? "upcoming" : key}`),
        value: SessionStatusKeys[key as keyof typeof SessionStatusKeys]
    }));

    const [openFilterableColumnDropdown, setOpenFilterableColumnDropdown] =
        useState<string | undefined>();

    const [filterData, setFilterData] = useState<{
        [key: string]: FilterOption[];
    }>({
        program: [],
        label: [],
        group: [],
        status: sessionView ? sessionStatusKeyValues : usageStatusKeyValues,
    });

    const [selectedFilterData, setSelectedFilterData] = useState<{
        [key: string]: FilterOption[];
    }>({
        program: [],
        label: [],
        group: [],
        status: [],
    });

    const [filteredTableData, setFilteredTableData] = useState<
        Record<any, any>[]
    >([]);

    useEffect(() => {
        setFilterData((currVal) => {
            return {
                ...currVal,
                status: sessionView ? sessionStatusKeyValues : usageStatusKeyValues,
            };
        });
    }, [sessionView]);

    useEffect(() => {
        getAllFiltrationData();
    }, []);

    const getAllFiltrationData = () => {
        if (!user) return;
        getLabels(user.companyId).then((data: IMeta[]) => {
            setFilterData((currVal) => {
                return {
                    ...currVal,
                    label: data.map((labelMeta) => {
                        return {label: labelMeta.name, value: labelMeta.name};
                    }),
                };
            });
        });
        getPrograms(user.companyId).then((data: IMeta[]) => {
            setFilterData((currVal) => {
                return {
                    ...currVal,
                    program: data.map((programMeta) => {
                        return {label: programMeta.name, value: programMeta.name};
                    }),
                };
            });
        });
        getMetaGroups(user.companyId)
            .then((groups) => {
                setFilterData((currVal) => {
                    return {
                        ...currVal,
                        group: groups.map((group) => {
                            return {label: group.name, value: Number(group.id)};
                        }),
                    };
                });
            })
            .catch((err) => console.log(err));
    };

    const data = useMemo(
        () => (!isEmpty(utilisation) ? utilisation : []),
        [utilisation],
    );
    const [calState, setCalState] = useState<CalendarSelectionType[]>(initialCalState);

    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState("");

    const updateProgramData = (data: any) => {
        const colors = [
            Colors.green,
            Colors.pink,
            Colors.yellowLight,
            Colors.purple,
            Colors.yellow,
        ];
        let programNames = data.map((val: any) => val.program);
        programNames = [...new Set(programNames)];
        const nameIdx: Record<string, number> = {};
        programNames?.forEach((name: string, index: number) => {
            nameIdx[name] = index;
        });
        let statusColors: Record<string, string> = {
            completed: Colors.green,
            ongoing: Colors.purple,
            paused: Colors.yellowLight,
            not_started: Colors.pink,
            success: Colors.green,
            no_show_by_client: Colors.purple,
            deactivated: Colors.warmDark,
            upcoming: Colors.pink
        };
        data.forEach((obj: any) => {
            let status = obj.status;
            const deactivatedStatus = obj.inactive
            if (deactivatedStatus) {
                status = "deactivated";
            } else if (sessionView && obj.status === "not_started") {
                status = "upcoming"
            }
            status = status.toLowerCase().replace(" ", "_")
            obj.program = {
                name: obj.program,
                color_code: colors[nameIdx[obj.program] % colors.length],
            };
            obj.status = {
                name: t(`tables:onboarding_status.${status}`),
                color_code: statusColors[status],
            };
        });
        return data;
    };
    const getUsageData = (startDate: any = null, endDate: any = null, isStarting?: boolean, withPreviousCycles?: boolean) => {
        isStarting && setIsLoading(true);
        if (sessionView) {
            setSearchParams({sessions: "true"});
            getUtilisationSessionView(user?.companyId!, startDate, endDate)
                .then((res) => {
                    const data = updateProgramData(res?.user_session);
                    setUtilisation(data);
                })
                .catch((e) => {
                    console.log("api error");
                }).finally(() => setIsLoading(false));
        } else {
            setSearchParams({sessions: "false"});
            getUtilisation(user?.companyId!, startDate, endDate, withPreviousCycles)
                .then((res) => {
                    const data = updateProgramData(res?.utilisation);
                    setUtilisation(data);
                })
                .catch((e) => {
                    console.log("api error");
                }).finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        getUsageData(null, null, true);
    }, [sessionView, selectedFilterData]);

    useEffect(() => {
        getUsageData(calState[0].startDate, calState[0].endDate);
    }, [calState]);

    useEffect(() => {
        const filteredData = data.filter((dataItem) => {
            if (
                !globalFilter.length &&
                !selectedFilterData.program?.length &&
                !selectedFilterData.label?.length &&
                !selectedFilterData.group.length &&
                !selectedFilterData.status?.length
            ) {
                return true;
            }

            let isValid = true;

            if (globalFilter.length) {
                isValid = dataItem.name
                    .toLowerCase()
                    .includes(globalFilter.toLowerCase());
            }

            if (isValid && selectedFilterData.group?.length) {
                isValid = selectedFilterData.group.some(
                    (value) => String(value.label) === dataItem.group?.name,
                );
            }

            if (isValid && selectedFilterData.program?.length) {
                isValid = selectedFilterData.program.some(
                    (value) => String(value.label) === dataItem.program?.name,
                );
            }
            if (isValid && selectedFilterData.label?.length) {
                isValid = selectedFilterData.label.some(
                    (value) => String(value.label) === dataItem.label,
                );
            }
            if (isValid && selectedFilterData.status?.length) {
                isValid = selectedFilterData.status.some(
                    (value) => String(value.label) === dataItem.status.name,
                );
            }

            return isValid;
        });
        setFilteredTableData(() => filteredData);
    }, [data, globalFilter, selectedFilterData]);

    const tableInstance = useReactTable({
        getCoreRowModel: getCoreRowModel(),
        columns,
        data: filteredTableData,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onPaginationChange: setPagination,

        state: {
            sorting,
            pagination,
            columnVisibility,
            // globalFilter,
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        // onGlobalFilterChange: setGlobalFilter,
    });

    const dropdownFilterableColumns = ["group", "program", "label", "status"];

    return (
        <div className="pb-5">
            <div
                className="flex gap-3 bg-white justify-between items-center py-2 pl-3 rounded-t-md flex-col sm:flex-row">
                <h4>{t("text0")}</h4>
                <div className="flex font-bold text-md items-center gap-3">
                    {" "}
                    {t("text5")}
                    <ToggleButton enabled={sessionView} setEnabled={setSessionView}/>
                </div>

                <div className="flex items-end gap-3 flex-col lg:flex-row lg:justify-between lg:items-center pr-3">
                    <Search
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value || ""))}
                    />
                    {/* <Select
            isClearable
            isSearchable={false}
            placeholder="Filter by group"
            className="min-w-[200px] z-20"
            value={selectedGroup}
            options={groups}
            onChange={(option) => setSelectedGroup(option)}
            menuPlacement="top"
          /> */}
                    <MyDateRange
                        state={calState}
                        setState={setCalState}
                        style="w-64 cursor-pointer"
                    />
                    <DropdownWrapper
                        button={
                            <button>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                    />
                                </svg>
                            </button>
                        }
                        body={
                            <div className="p-3 whitespace-nowrap">
                                <span className="flex gap-2 items-center">
                                  {t("text10")}{" "}
                                    <ToggleButton enabled={withPreviousCycles} setEnabled={handleWithPreviousCycles}/>
                                </span>
                            </div>
                        }
                    />

                    <div className="flex gap-2">
                        <button
                            title={t("text6")}
                            onClick={() => {
                                getUsageData();
                                setCalState(initialCalState);
                            }}
                            className="hover:shadow-lg bg-white rounded-md p-2 border-2 border-black"
                        >
                            <RefreshIcon className="text-black w-5 h-5"/>
                        </button>
                        <button
                            onClick={() => {
                                if (sessionView) {
                                    exportToCsv(
                                        filterInnerDictArr(utilisation, [
                                            "name",
                                            "email",
                                            "meeting_no",
                                            "allowed",
                                            "meeting_time",
                                            "program",
                                            "group",
                                            "labels",
                                        ]),
                                        [
                                            t(`tables:session_view.text1`),
                                            t(`tables:session_view.text7`),
                                            t(`tables:session_view.text4_1`),
                                            t(`tables:session_view.text4_2`),
                                            t(`tables:session_view.text3`),
                                            t(`tables:usage.text3`),
                                            t(`tables:usage.text10`),
                                            t(`tables:usage.text11`),
                                        ],
                                        "Sessions.csv",
                                    );
                                } else {
                                    exportToCsv(
                                        filterInnerDictArr(utilisation, [
                                            "name",
                                            "email",
                                            "attended",
                                            "allowed",
                                            "assessment_status",
                                            "program",
                                            // "id",
                                            "group",
                                            "status",
                                            "labels",
                                            "last_meeting",
                                            "created_at",
                                            "group.group_expiration"
                                        ]),
                                        [
                                            t(`tables:usage.text1`),
                                            t(`tables:session_view.text7`),
                                            t(`tables:usage.text2_1`),
                                            t(`tables:usage.text2_2`),
                                            t(`tables:usage.text6`),
                                            t(`tables:usage.text3`),
                                            t(`tables:usage.text10`),
                                            t(`tables:usage.text12`),
                                            t(`tables:usage.text11`),
                                            t(`tables:usage.text22`),
                                            t(`tables:usage.text14`),
                                            t(`tables:usage.text23`),
                                        ],
                                        t("text8"),
                                    );
                                }
                            }}
                            className="hover:shadow-lg bg-white rounded-md p-2 border-2 border-red text-red flex gap-2 items-center"
                        >
                            <DocumentDownloadIcon className="text-red w-5 h-5"/>
                            {/*{t('text4')}*/}
                        </button>
                    </div>
                </div>
            </div>

            <table className={`dashboard-table ${isEmpty(utilisation) ? 'overflow-hidden' : 'overflow-auto'}`}>
                {isEmpty(utilisation) && !isfiltered && !isLoading && <NoDataWrapper/>}
                <thead className="dashboard-thead">
                {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                    <tr key={index}>
                        {headerGroup.headers.map((column, thIndex) => {
                            return (
                                <th colSpan={column.colSpan} key={index + "" + thIndex}>
                                    <div
                                        className="flex flex-row items-center gap-2 hover:cursor-pointer"
                                        onClick={column.column.getToggleSortingHandler()}
                                    >
                                        {dropdownFilterableColumns.includes(column.id) ? (
                                            <Select
                                                isClearable
                                                isSearchable={false}
                                                value={selectedFilterData[column.id]}
                                                options={filterData[column.id]}
                                                onChange={(value) => {
                                                    setSelectedFilterData({
                                                        ...selectedFilterData,
                                                        [column.id]: value as FilterOption[],
                                                    });
                                                    setIsfiltered(true);
                                                }}
                                                menuPlacement="bottom"
                                                isMulti
                                                components={{
                                                    Control: () => (
                                                        <FilterIcon
                                                            className="w-4 h-4 text-red cursor-pointer hover:text-black"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                const newValue =
                                                                    openFilterableColumnDropdown !== column.id
                                                                        ? column.id
                                                                        : undefined;
                                                                setOpenFilterableColumnDropdown(newValue);
                                                            }}
                                                        />
                                                    ),
                                                }}
                                                styles={{
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        minWidth: "200px",
                                                        zIndex: 50
                                                    }),
                                                }}
                                                menuIsOpen={
                                                    openFilterableColumnDropdown === column.id
                                                }
                                                formatOptionLabel={(
                                                    {label, value},
                                                    {context},
                                                ) => (
                                                    <div className="flex items-center gap-2">
                                                        <input
                                                            type="checkbox"
                                                            className="w-4 h-4"
                                                            onChange={() => {
                                                                const updatedSelectedOptions = [
                                                                    ...selectedFilterData[column.id],
                                                                ];
                                                                const selectedIndex =
                                                                    updatedSelectedOptions.findIndex(
                                                                        (option) => option.value === value,
                                                                    );

                                                                if (selectedIndex === -1) {
                                                                    updatedSelectedOptions.push({
                                                                        label,
                                                                        value,
                                                                    });
                                                                } else {
                                                                    updatedSelectedOptions.splice(
                                                                        selectedIndex,
                                                                        1,
                                                                    );
                                                                }

                                                                // setSelectedFilterData({
                                                                //     ...selectedFilterData,
                                                                //     [column.id]: updatedSelectedOptions,
                                                                // });
                                                            }}
                                                            checked={
                                                                !!selectedFilterData[column.id]?.find(
                                                                    (selectedOption) =>
                                                                        selectedOption.value === value,
                                                                )
                                                            }
                                                        />
                                                        <span>{label}</span>
                                                    </div>
                                                )}
                                                hideSelectedOptions={false}
                                            />
                                        ) : null}
                                        {t(
                                            `tables:usage.${flexRender(
                                                column.column.columnDef.header,
                                                column.getContext(),
                                            )}`,
                                        )}
                                        <span
                                            className={
                                                !column.column.getIsSorted() ? "opacity-0" : ""
                                            }
                                        >
                        {{desc: " ▼", asc: " ▲"}[
                            column.column.getIsSorted() as string
                            ] ?? null}
                      </span>
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                ))}
                </thead>

                <tbody className="dashboard-tbody">
                {isLoading ?
                    <div className="z-10 fixed h-80 inset-x-0 flex justify-center items-center py-5">
                        <LoaderContainer spinnerSize={"40px"}/>
                    </div>
                    : tableInstance.getRowModel().rows.length === 0 ? (
                        <tr className="dashboard-tr">
                            <td
                                colSpan={tableInstance.getHeaderGroups()[0].headers.length}
                                className="text-center"
                            >
                                <div className="flex flex-col items-center justify-center h-full p-10">
                                    <img
                                        src={NoDataIcon}
                                        width={54}
                                        height={54}
                                        alt="No Data Icon"
                                    />
                                    <h4 className="text-m pt-3">{t(`tables:usage.text13`)}</h4>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        tableInstance.getRowModel().rows.map((row, i) => {
                            return (
                                <tr
                                    key={i}
                                    className={
                                        "dashboard-tr " +
                                        (row.original.inactive ? "text-gray-400 bg-gray-100" : "")
                                    }
                                    onClick={() =>
                                        console.log(
                                            selectedFilterData,
                                            row.getVisibleCells()[3].getValue(),
                                        )
                                    }
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>

            {/* Pagination */}
            {/*<div className="py-3 flex items-center justify-between">*/}
            {/*    <div className="flex-1 flex justify-between sm:hidden">*/}
            {/*        <button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>*/}
            {/*        <button onClick={() => nextPage()} disabled={!canNextPage}>Next</button>*/}
            {/*    </div>*/}
            {/*    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">*/}
            {/*        <div className="flex gap-x-2">*/}
            {/*<span className="text-sm text-gray-700">*/}
            {/*  Page <span className="font-medium">{state.pageIndex + 1}</span> of <span*/}
            {/*    className="font-medium">{pageOptions.length}</span>*/}
            {/*</span>*/}
            {/*            <select*/}
            {/*                value={state.pageSize}*/}
            {/*                onChange={e => {*/}
            {/*                    setPageSize(Number(e.target.value))*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                {[5, 10, 20].map(pageSize => (*/}
            {/*                    <option key={pageSize} value={pageSize}>*/}
            {/*                        Show {pageSize}*/}
            {/*                    </option>*/}
            {/*                ))}*/}
            {/*            </select>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"*/}
            {/*                 aria-label="Pagination">*/}
            {/*                <button*/}
            {/*                    className="rounded-l-md"*/}
            {/*                    onClick={() => gotoPage(0)}*/}
            {/*                    disabled={!canPreviousPage}*/}
            {/*                >*/}
            {/*                    <span className="sr-only">First</span>*/}
            {/*                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true"/>*/}
            {/*                </button>*/}
            {/*                <button*/}
            {/*                    onClick={() => previousPage()}*/}
            {/*                    disabled={!canPreviousPage}*/}
            {/*                >*/}
            {/*                    <span className="sr-only">Previous</span>*/}
            {/*                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>*/}
            {/*                </button>*/}
            {/*                <button*/}
            {/*                    onClick={() => nextPage()}*/}
            {/*                    disabled={!canNextPage*/}
            {/*                    }>*/}
            {/*                    <span className="sr-only">Next</span>*/}
            {/*                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>*/}
            {/*                </button>*/}
            {/*                <button*/}
            {/*                    className="rounded-r-md"*/}
            {/*                    onClick={() => gotoPage(pageCount - 1)}*/}
            {/*                    disabled={!canNextPage}*/}
            {/*                >*/}
            {/*                    <span className="sr-only">Last</span>*/}
            {/*                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true"/>*/}
            {/*                </button>*/}
            {/*            </nav>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Pagination
                canNextPage={tableInstance.getCanNextPage()}
                canPreviousPage={tableInstance.getCanPreviousPage()}
                setPageSize={tableInstance.setPageSize}
                pageSize={tableInstance.getState().pagination.pageSize}
                gotoPage={tableInstance.setPageIndex}
                totalPageCount={tableInstance.getPageCount()}
                currentPage={tableInstance.getState().pagination.pageIndex}
            />
        </div>
    );
};

export default UsageTable;
