import React, {FC, useEffect, useState} from "react";
import {getOrderIndexArr} from "../../../utils/helpers/arr";
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import CustomizedLabel from "./CustomizedLabel";
import {CustomRatingChartProps, DataItem} from "./types";
import CustomTooltip from "./CustomTooltip";
import {useTranslation} from "react-i18next";

const CustomRatingChart: FC<CustomRatingChartProps> = ({
                                                     showTagLine = false,
                                                     verticalLabel = false,
                                                     data = [],
                                                     range,
                                                     showPercentageLabel = false,
                                                     legendName,
                                                     key,
                                                     layoutDirection = "row",
                                                 }) => {

    const {t} = useTranslation("commons");
    const getColourArr = (data: DataItem[]) => {
        const orderIndexes = getOrderIndexArr(data.map((entry, i) => {
            return entry.value
        }))
        const len = data.length + 1
        const potion = 1 / len
        return data.map((value, i) => {
            return `rgba(180, 225, 198,${(orderIndexes[i] + 1) * potion})`
        })
    }

    const getAvgTickLength = (data: DataItem[]) => {
        const lengths: number[] = data?.map((item) => {
            return item.label ? item.label.length : 0
        })
        const sum = lengths?.reduce((acc, val) => acc + val)
        return lengths.length ? sum / lengths.length : 0
    }

    const getAvailableWidth = () => {
        const container = document.getElementById('CustomBarChartContainer');
        if (container) {
            const computedStyle = window.getComputedStyle(container);
            const width = computedStyle.width;
            const numericWidth = parseFloat(width);
            return numericWidth;
        } else {
            // Handle the case where the element doesn't exist
            return 150;
        }
    };

    const [availableWidth, setAvailableWidth] = useState(getAvailableWidth())
    const handleResize = () => {
        setAvailableWidth(getAvailableWidth())
    };

    const tickFormatter = (value: string, index: number) => {
        value = value.toString()
        const limit = 40;
        if (value.length < limit) return value;
        return `${value.substring(0, limit)}...`;
    };
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const avgYTickLength = data && data.length > 0 ? getAvgTickLength(data) : 20;
    const normalTickWidth = availableWidth > 768 ? availableWidth / 7 : availableWidth / 5;
    const barThickness = data.length > 4 ? 15 : 30
    const barSize = verticalLabel ? barThickness + 10 : barThickness
    const barContainerHeight = 25 + (verticalLabel ? barThickness + 50 : barThickness - 5)
    const axisColor = "rgb(243, 235, 226)"
    const barColor = '#ACC7E8'
    const xTickColor = '#c4c4c4'
    const defaultColors = getColourArr(data);

    const totalRatings = data.reduce((sum, item) => sum + item.value, 0);
    const weightedSum = data.reduce((sum, item) => {
        const labelValue = Number(item.label);
        return sum + (labelValue * item.value);
    }, 0);

    const avgRating = weightedSum / totalRatings;
    const formattedAvgRating = parseFloat(avgRating.toFixed(2));

    return (
        <div className="relative">
            <div className={`flex ${layoutDirection === 'row' ? 'flex-row' : 'flex-col'} items-center justify-between pb-5`}>
                <div className="w-2/3">
            <ResponsiveContainer width="95%" minWidth={data.length > 5 ? 500 : undefined}
                                 height={(data.length > 4 ? data.length * barContainerHeight : 300)}
                                 id="CustomBarChartContainer" key={key}>
                <BarChart data={data} layout={"vertical"}
                          margin={{left: 10, right: 20,}}
                          barCategoryGap={10} barGap={10}>
                    <CartesianGrid horizontal={false} vertical={false} strokeDasharray="5 5" stroke={axisColor}/>
                        <>
                            <XAxis
                                tickLine={false} axisLine={false}
                                    tick={{fill: xTickColor, fontSize: 14, fontWeight: "normal"}}
                                    type="number" hide={true} tickCount={10} domain={range}/>
                             verticalLabel ?
                                    <YAxis
                                        tickLine={false} axisLine={false}
                                        dataKey="label" type="category"
                                        // @ts-ignore
                                        tick={{ angle: 0,
                                            textAnchor: "middle",
                                            dy: -5,
                                            dx: -20,
                                            fontSize: 14,
                                            fill: "black",
                                            fontWeight: showTagLine ? "bold" : "normal"
                                        }}/>
                                    :
                                    <YAxis
                                        tickLine={false} axisLine={false}
                                        tickFormatter={tickFormatter}
                                        // @ts-ignore
                                        dataKey="label" type="category"
                                        width={avgYTickLength > 20 ? normalTickWidth + 20 : normalTickWidth} tick={{
                                        fontSize: avgYTickLength > 20 ? 11 : 14,
                                        fill: "black",
                                        dx: avgYTickLength > 20 ? 0 : -5,
                                        fontWeight: showTagLine ? "bold" : "normal"
                                    }}
                                    />
                        </>
                    {/*<Tooltip content={<CustomTooltip customDataKey={tooltipDataKey} />} cursor={{fill: highlightColor, strokeWidth: 1}}/>*/}
                    <Bar
                        radius={20}
                        background={{ fill: '#E7EAED', radius: 20 }}
                        // @ts-ignore
                        name={legendName} dataKey="value" fill={barColor} barSize={barSize} label={showPercentageLabel && <CustomizedLabel isVertical={isVertical}/>}>
                        {showPercentageLabel ?
                            (data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color ? entry.color : defaultColors[index]}/>
                            ))) : (
                                (data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color ? entry.color : barColor}/>
                                ))))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
                </div>
                <div className="w-1/3 flex flex-col justify-center items-center">
                    <h2>{formattedAvgRating}</h2>
                    <h4 className="font-lexend font-normal">{totalRatings} {t("rating")}</h4>
                </div>
            </div>
        </div>
    );
};

export default CustomRatingChart;
