import {useTranslation} from "react-i18next";

import AssessmentCard from "../components/AssessmentCard";
import Section from "../components/Section";
import EmptyResults from "../components/EmptyResults";

import IAssessmentScore from "../../../../../models/IAssessmentScore";
import {EAssessmentType} from "../../../../../constants/enums";

import assessment_career from "@img/dashboard/reports/icons/assessment_career.png";
import assessment_work from "@img/dashboard/reports/icons/assessment_work.png";
import assessment_job from "@img/dashboard/reports/icons/assessment_job.png";
import assessment_leadership from "@img/dashboard/reports/icons/assessment_leadership.png";
import assessment_communication from "@img/dashboard/reports/icons/assessment_communication.png";
import assessment_quality from "@img/dashboard/reports/icons/assessment_quality.png";
import assessment_self from "@img/dashboard/reports/icons/assessment_self.png";
import assessment_stress from "@img/dashboard/reports/icons/assessment_stress.png";

type Props = {
    data: IAssessmentScore;
    openModal: (key: EAssessmentType, title: string) => void;
    retake?: boolean;
};

const AssessmentResultsSection = ({data, openModal, retake = false}: Props) => {
    const {t} = useTranslation("reports");

    return (
        <Section
            title={t("assessment_results.title")}
            {...(Object.keys(data).length && retake ? {
                buttonTitle: t("assessment_results.retake"),
                onClick: () => openModal(EAssessmentType.SelfReflection, t("assessment_results.reflection_quiz"))
            } : {})}
        >
            {!Object.keys(data).length ? (
                <EmptyResults
                    title={t("assessment_results.empty_results.title")}
                    buttonText={t("assessment_results.empty_results.button_text")}
                    onButtonClick={() =>
                        openModal(
                            EAssessmentType.SelfReflection,
                            t("assessment_results.reflection_quiz")
                        )
                    }
                />
            ) : (
                <div className="grid grid-cols-4 gap-2">
                    <AssessmentCard
                        title={t("assessment_results.career_development.title")}
                        image={assessment_career}
                        progress={data?.career}
                        tooltip={t("assessment_results.career_development.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.work_environment.title")}
                        image={assessment_work}
                        progress={data?.intellect}
                        tooltip={t("assessment_results.work_environment.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.job_performance.title")}
                        image={assessment_job}
                        progress={data?.organisation}
                        tooltip={t("assessment_results.job_performance.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.leadership.title")}
                        image={assessment_leadership}
                        progress={data?.leadership}
                        tooltip={t("assessment_results.leadership.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.communication.title")}
                        image={assessment_communication}
                        progress={data?.communication}
                        tooltip={t("assessment_results.communication.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.quality_of_life.title")}
                        image={assessment_quality}
                        progress={data?.worklife}
                        tooltip={t("assessment_results.quality_of_life.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.self_awareness.title")}
                        image={assessment_self}
                        progress={data?.assertivity}
                        tooltip={t("assessment_results.self_awareness.tooltip")}
                    />
                    <AssessmentCard
                        title={t("assessment_results.stress_and_anxiety.title")}
                        image={assessment_stress}
                        progress={data?.self_esteem}
                        tooltip={t("assessment_results.stress_and_anxiety.tooltip")}
                    />
                </div>
            )}
        </Section>
    );
};

export default AssessmentResultsSection;
