import React from 'react';
import {IChartData, IChartEmbeddedTableData} from "../../constants/types";

interface ChartEmbeddedTableProps {
    tableData: IChartEmbeddedTableData[];
    chartData: IChartData[];
    chartTitle?: string;
    labelTitle?: string;
    countTitle?: string;
}

const ChartEmbeddedTable: React.FC<ChartEmbeddedTableProps> = ({
                                                                   tableData,
                                                                   chartData,
                                                                   chartTitle = "%",
                                                                   labelTitle = "",
                                                                   countTitle = ""
                                                               }) => {
    const calculateRowsToColor = (percentage: number) => {
        const totalRows = tableData.length;
        const fullRows = Math.floor((percentage / 100) * totalRows);
        const partialRowPercentage = (percentage / 100) * totalRows - fullRows;
        return {fullRows, partialRowPercentage};
    }

    const getRowStyle = (partialRowPercentage: number, color: string) => ({
        background: `linear-gradient(to top, ${color} ${partialRowPercentage * 100}%, transparent ${partialRowPercentage * 100}%)`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom'
    });

    const isRowColored = (index: number, fullRows: number, partialRowPercentage: number, color: string) => {
        const rowOffset = tableData.length - (index + 1);
        return {
            color: rowOffset < fullRows || (rowOffset === fullRows && partialRowPercentage > 0),
            showPercentage: rowOffset == Math.floor(fullRows / 2),
            style: rowOffset < fullRows ? getRowStyle(100, color) : (rowOffset === fullRows && partialRowPercentage > 0) ? getRowStyle(partialRowPercentage, color) : {}
        };
    };

    return (
        <div className="sm:rounded-lg">
            <table
                className="min-w-full text-center text-black md:text-sm text-xs font-light border-collapse border border-radius-lg">
                <thead className="bg-white">
                <tr>
                    <th className="px-4 py-2 border-r border-b w-1/6" rowSpan={2}>{labelTitle}</th>
                    <th className="px-4 py-2 border-r border-b w-1/6" rowSpan={2}>{countTitle}</th>
                    <th className="px-4 py-2 border-r border-b w-1/6" rowSpan={2}>%</th>
                    <th className="px-4 py-2 text-center border-b" colSpan={chartData.length}>{chartTitle}</th>
                </tr>
                <tr>
                    {chartData.map((item, index) => (
                        <th key={index} className="px-4 py-2 border-r border-b text-xs">{item.label}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="odd:bg-white even:bg-white">
                        <td className="px-4 py-2 border-r border-t">{row.firstColumn}</td>
                        <td className="px-4 py-2 border-r border-t">{row.secondColumn}</td>
                        <td className="px-4 py-2 border-t">{row.thirdColumn}%</td>
                        {chartData.map((item, colIndex) => {
                            const {fullRows, partialRowPercentage} = calculateRowsToColor(item.percentage);
                            const {
                                color,
                                showPercentage,
                                style
                            } = isRowColored(rowIndex, fullRows, partialRowPercentage, item.color || '#B4E1C6');

                            return (
                                <td key={colIndex} className="px-4 border-l" style={color ? style : {}}>
                                    {color && showPercentage ? `${item.percentage}%` : ''}
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ChartEmbeddedTable;
