import React from "react";

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string | number;
    customDataKey?: string;
    customDataKeyColor?: string;
    tooltipStyle?: string;
    showPercentage?: boolean;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, customDataKey, customDataKeyColor, tooltipStyle = "style1", showPercentage }) => {
    if (active && payload && payload.length && label !== undefined) {
        return (
            <div className={`custom-tooltip bg-white border p-2 ${label.toString().length > 40 ? "w-11/12" : ""}`}>
                {payload.map((pld) =>
                    <div className={`${pld.payload.tooltipLabel && pld.payload.tooltipLabel.toString().length > 40 ? "w-11/12" : ""}`}>
                   <p className="label">{pld.payload.tooltipLabel ? pld.payload.tooltipLabel : label}
                       {tooltipStyle === "style2" &&
                        payload.map((pld) => (
                            <span style={{color: customDataKeyColor ? customDataKeyColor : pld.fill}}>: {pld.value} {showPercentage ? "%" : ""}</span>
                        ))
                       }
                   </p>
                    </div>
                )}
                {tooltipStyle === "style1" && <div>
                    {payload.map((pld) => (
                        <div className="py-2" style={{color: customDataKeyColor ? customDataKeyColor : pld.fill}}>{customDataKey ? customDataKey : pld.dataKey} : {pld.value}</div>
                    ))}
                </div>}
            </div>
        );
    }
    return null;
};

export default CustomTooltip;