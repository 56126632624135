import React, {HTMLProps} from 'react';
import {COLOR} from "rsuite/utils";
import {Tooltip} from "antd";

interface DynamicColorSpanProps extends HTMLProps<HTMLSpanElement>{
    backgroundColor: string;
    children: any;
}

function DynamicColorSpan({ backgroundColor, children,...rest }: DynamicColorSpanProps) {
    // Calculate the text color based on the background color
    backgroundColor = backgroundColor ? backgroundColor : '#B4E1C6'
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    const lightBackgroundColor = `rgba(${r},${g},${b},0.23)`

    // Determine the text color class based on luminance
    // const textColor = luminance > 0.5 ? backgroundColor : 'white';
    const amount = 100
    const textColor = `rgba(${Math.max(0, r - amount)},${Math.max(0, g - amount)},${Math.max(0, b - amount)},1)`

    const isActiveGroup = rest.className?.includes('active-group');
    const isExpiredGroup = rest.className?.includes('expired-group');

    return (
        <span
            style={{ backgroundColor: lightBackgroundColor, color:textColor}}
            className={`inline-flex items-center p-2 rounded text-center font-normal capitalize ${rest.className}`}
        >
            {isActiveGroup && (
                <Tooltip title={"active"}>
                    <span className="mr-2 w-2 h-2 rounded-full bg-green-500" />
                </Tooltip>
            )}
            {isExpiredGroup && (
                <Tooltip title={"expired"}>
                    <span className="mr-2 w-2 h-2 rounded-full bg-black" />
                </Tooltip>
            )}
            {children}
        </span>
    );
}

export default DynamicColorSpan;
