import { InformationCircleIcon, LinkIcon, MailIcon, OfficeBuildingIcon } from "@heroicons/react/outline";
import { CheckIcon, DocumentTextIcon, PencilIcon, XIcon } from "@heroicons/react/solid";
import enFlag from '@icons/english.png';
import frFlag from '@icons/france.png';
import deFlag from '@icons/germany.png';
import defaultProfileImg from "@img/icons/default-profile.png";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast } from "react-toastify";
import * as Yup from "yup";
import ICoachProfile from "../../../models/ICoachProfile";
import { IMeta } from "../../../models/IMeta";
import { createCoachProfile, getCoachProfile, updateCoachProfile } from "../../../services/api/coachApi";
import MetaApi from "../../../services/api/metaApi";
import InputFormik from "../../../shared/components/formik/InputFormik/InputFormik";
import InputFormikWithIcon from "../../../shared/components/formik/InputFormik/InputFormikWithIcon";
import RadioFormik from "../../../shared/components/formik/RadioFormik";
import SelectFormik from "../../../shared/components/formik/SelectFormik";
import SubmitFormik from "../../../shared/components/formik/SubmitFormik/SubmitFormik";
import TextareaFormik from "../../../shared/components/formik/TextareaFormik/TextareaFormik";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/coaches.json";
import Fr from "../locale/fr/coaches.json";

interface CoachProfileFormProps {
    coach_id?: number
    mode?: 'create' | 'update' | 'admin-update';
}

const CoachProfileForm: React.FC<CoachProfileFormProps> = ({coach_id, mode}) => {

    i18n.addResourceBundle("en", "coaches", ENG);
    i18n.addResourceBundle("fr", "coaches", Fr);
    const {t} = useTranslation("coaches");
    const [languagesOptions, setLanguagesOptions] = useState<IMeta[]>()
    const [specializationsOptions, setSpecializationsOptions] = useState<IMeta[]>()
    const [isEditingImage, setIsEditingImage] = useState(false);
    const [tempImageUrl, setTempImageUrl] = useState('');

    const [profile, setProfile] = useState<ICoachProfile>({
        first_name: "",
        last_name: "",
        location: "",
        languages: [],
        specializations: [],
        email: "",
        phone: "",
        linkedin_url: "",
        video_url: "",
        video_url_fr: "",
        video_url_de: "",
        image_url: "",
        management: '0',
        certified_by: "",
        years_coaching: 0,
        years_experience: 0,
        bio: "",
        coaching_level: "",
        cv_url: ""
    })

    const _handleSubmit = async (values: FormikValues, actions: FormikHelpers<any>) => {
        if (mode === 'create') {

            const primaryLanguage = values.languages.find((lang: { primary: boolean }) => lang.primary);

            const updatedValues = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone_number: values.phone,
                image: values.image_url,
                specializations: values.specializations.map((spec: IMeta) => spec.id),
                languages: values.languages.map((lang: IMeta) => lang.id),
                management: values.management === '1',
                certified_by: values.certified_by ? values.certified_by.value : "",
                coaching_level: values.coaching_level ? values.coaching_level.value : "",
                city: values.location,
                website: values.linkedin_url,
                years_coaching: values.years_coaching,
                years_experience: parseInt(values.years_experience, 10),
                video: values.video_url,
                video_url_fr: values.video_url_fr,
                video_url_de: values.video_url_de,
                bio: values.bio,
                primary_language_id: primaryLanguage ? primaryLanguage.id : null,
                cv_url: values.cv_url
            };

            createCoachProfile(updatedValues).then((res) => {
                actions.setSubmitting(false);
                actions.resetForm();
                toast.success(t("create_success"));
            }).catch((e) => {
                console.log(e);
                toast.error(t("create_error"));
            })
        } else {
            const updatedValues = {
                ...values,
                certified_by: values.certified_by ? values.certified_by.value : "",
                coaching_level: values.coaching_level ? values.coaching_level.value : "",
                management: values.management === '1',
                years_coaching: values.years_coaching,
                years_experience: parseInt(values.years_experience, 10),
            }
            updateCoachProfile(updatedValues as ICoachProfile, coach_id!).then((res) => {
                actions.setSubmitting(false);
                const certifiedByOption = certifiedByOptions.find(
                    (option) => option.value === res.certified_by
                );

                const coachingLevelsOption = coachingLevelsOptions.find(
                    (option) => option.value === res.coaching_level
                );

                setProfile({
                    ...res,
                    certified_by: certifiedByOption || "",
                    coaching_level: coachingLevelsOption || "",
                    management: res.management ? '1' : '0',
                });
                toast.success(t("update_success"))

            }).catch((e) =>
                toast.error(e.response.data.message)
            )
            console.log(values);
        }
    }

    useEffect(() => {
        const lng = MetaApi.getLanguages()
        const spc = MetaApi.getSpecializations()

        Promise.all([lng, spc]).then(([lng, spc]) => {
                setLanguagesOptions(lng)
                setSpecializationsOptions(spc)
            }
        ).catch((e) => {
            console.log("api error getting meta");
        }).then(() => {
            if (mode !== 'create') {
                getCoachProfile(coach_id!)
                    .then(res => {
                        const certifiedByOption = certifiedByOptions.find(
                            (option) => option.value === res.certified_by
                        );

                        const coachingLevelsOption = coachingLevelsOptions.find(
                            (option) => option.value === res.coaching_level
                        );

                        setProfile({
                            ...res,
                            certified_by: certifiedByOption || "",
                            coaching_level: coachingLevelsOption || "",
                            management: res.management ? '1' : '0',
                        });
                    }).catch((e) => {
                    console.log("api error");
                })
            }
        })
    }, [])

    const certifiedByOptions = [
        {value: "AADCT", label: "AADCT"},
        {value: "RNCP", label: "RNCP"},
        {value: "RNCP 6", label: "RNCP 6"},
        {value: "ICF", label: "ICF"},
        {value: "ICC", label: "ICC"},
        {value: "HEC", label: "HEC"},
        {value: "FVB", label: "FVB"},
        {value: "ICF & EMCC", label: "ICF & EMCC"},
        {value: "EMCC", label: "EMCC"},
        {value: "IPACT", label: "IPACT"}
    ]

    const coachingLevelsOptions = [
        {value: "ACC", label: "ACC"},
        {value: "PCC", label: "PCC"},
        {value: "MCC", label: "MCC"},
        {value: "Executive (MCC / PCC)", label: "Executive (MCC / PCC)"},
    ]

    return (
        <Formik
            enableReinitialize={true}
            initialValues={profile}

            validationSchema={
                Yup.object({
                    languages: Yup.array().min(1, t("choose_1"))
                        .required(t("required")),
                    specializations: Yup.array().min(1, t("choose_1")).max(4, t("choose_3"))
                        .required(t("required")),
                    first_name: Yup.string()
                        .required(t("required")),
                    last_name: Yup.string()
                        .required(t("required")),
                    video_url: Yup.string().nullable()
                        .required(t("required")),
                    image_url: Yup.string().nullable()
                        .required(t("required")),
                    email: Yup.string().trim().email(t("invalid_email"))
                        .required(t("required")),
                })}
            onSubmit={_handleSubmit}
        >
            {({values, errors, touched, setFieldValue}) => {
                const hasErrors = Object.keys(errors).length > 0 && Object.keys(touched).length > 0;

                return (
                    <Form className="flex flex-col gap-5 px-5 py-4">
                        <div
                            className="absolute left-0 right-0 mx-auto bg-transparent -top-10 flex flex-col items-center">
                            <div className="relative">
                                <img className="rounded-full h-24 w-24 border-4 border-white"
                                     src={values.image_url || defaultProfileImg} alt="Profile"/>
                                {(mode === 'create' || mode === 'admin-update') && <div
                                    className="absolute bottom-0 right-0 bg-[#FD0054] p-1 rounded-full cursor-pointer shadow-md"
                                    onClick={() => {
                                        setTempImageUrl(values.image_url);
                                        setIsEditingImage(prev => !prev);
                                    }}
                                >
                                    <PencilIcon className="h-4 w-4 text-white"/>
                                </div>}
                            </div>
                            {errors.image_url && touched.image_url && (
                                <div className="text-red-500 text-sm">{t("profile_required")}</div>
                            )}

                            {isEditingImage && (
                                <div className="mt-1 rounded-lg border border-gray-600 bg-white">
                                    <div className="flex flex-row items-center rounded-lg gap-2">
                                        <input
                                            type="text"
                                            value={tempImageUrl}
                                            onChange={(e) => setTempImageUrl(e.target.value)}
                                            className="p-2 rounded-lg"
                                            placeholder={`${t("profile_url_placeholder")}`}
                                        />
                                        <button
                                            onClick={() => {
                                                setFieldValue('image_url', tempImageUrl);
                                                setIsEditingImage(false);
                                            }}
                                            className="text-black p-1 rounded-full hover:bg-gray-200"
                                        >
                                            <CheckIcon className="h-4 w-4"/>
                                        </button>
                                        <button
                                            onClick={() => setIsEditingImage(false)}
                                            className="text-black p-1 rounded-full hover:bg-gray-200"
                                        >
                                            <XIcon className="h-4 w-4"/>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <hr className="w-full mt-16"/>
                        <div className="flex gap-3">
                            <InputFormik placeholder={t("first_name_placeholder")}
                                         label={t("first_name")+" *"} name={"first_name"}/>

                            <InputFormik placeholder={t("last_name_placeholder")}
                                         label={t("last_name")+" *"} name={"last_name"}/>

                        </div>

                        <InputFormik placeholder={t("location_placeholder")}
                                     label={t("location")} name={"location"}/>

                        <div className="flex flex-col gap-2">
                            <span className="font-medium text-base text-black">{t("language")+" *"}</span>
                            <SelectFormik name="languages" labelKey="name" valueKey="id" options={languagesOptions}
                                          primaryValueAvailable={true} primaryValueText={t("native")}
                                          tooltip={t("set_as_native")} placeholder={t("language_placeholder")}/>
                        </div>
                        <div className="flex flex-col gap-2">
                            <span className="font-medium text-base text-black">{t("expertise")+" *"}</span>
                            <SelectFormik name="specializations" labelKey="name" valueKey="id"
                                          options={specializationsOptions} placeholder={t("expertise_placeholder")}/>
                        </div>

                        <div className="grid grid-cols-2 gap-3">
                            <InputFormikWithIcon
                                placeholder={t("email_placeholder")}
                                label={t("email")+" *"}
                                name={"email"}
                                HeroIcon={MailIcon}
                            />
                            <div className="py-[5px]">
                                <label className="font-medium text-base text-black">{t("phone")}</label>
                                <PhoneInput
                                    defaultCountry="FR"
                                    value={values.phone}
                                    onChange={phone => setFieldValue('phone', phone)}
                                    className="phone-input mt-[5px]"
                                    international
                                    countryCallingCodeEditable={false}
                                />
                                {errors.phone && touched.phone && (
                                    <div className="text-red-500 text-sm">{errors.phone}</div>
                                )}
                            </div>
                        </div>

                        <InputFormikWithIcon placeholder={t("linkedin_placeholder")}
                                             label={t("linkedin")} name={"linkedin_url"}
                                             HeroIcon={OfficeBuildingIcon}/>

                        <InputFormikWithIcon placeholder={t("cv_url_placeholder")}
                                             label={t("cv_url")} name={"cv_url"}
                                             HeroIcon={DocumentTextIcon}/>

                        <div className="flex-col gap-3">
                            <span className="font-medium text-base text-black">{t("video_section")}</span>
                            <div className="flex items-center gap-3 pl-5">
                                <img height="24" width="24" src={enFlag} alt=""/>
                                <div className="w-full">
                                    <InputFormikWithIcon
                                        placeholder={t("video_eng_placeholder")}
                                        label=""
                                        requiredasterisk
                                        name={"video_url"}
                                        HeroIcon={LinkIcon}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-3 pl-5">
                                <img height="24" width="24" src={frFlag} alt=""/>
                                <div className="w-full">
                                    <InputFormikWithIcon
                                        placeholder={t("video_fr_placeholder")}
                                        label=""
                                        name={"video_url_fr"}
                                        HeroIcon={LinkIcon}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-3 pl-5">
                                <img height="24" width="24" src={deFlag} alt=""/>
                                <div className="w-full">
                                    <InputFormikWithIcon
                                        placeholder={t("video_de_placeholder")}
                                        label=""
                                        name={"video_url_de"}
                                        HeroIcon={LinkIcon}
                                    />
                                </div>
                            </div>
                        </div>

                        {(mode === 'create' || mode === 'admin-update') && <>
                            <div className="grid grid-cols-2 gap-3">
                                <div className="flex flex-col py-[5px]">
                                    <span
                                        className="font-medium text-base text-black mb-[5px]">{t("years_coaching")}</span>
                                    <DatePicker
                                        value={values.years_coaching ? dayjs(String(values.years_coaching), 'YYYY') : null}
                                        onChange={(date) => {
                                            setFieldValue('years_coaching', date ? parseInt(date.format('YYYY'), 10) : null);
                                        }}
                                        format={"YYYY"}
                                        placeholder={t("years_coaching_placeholder")}
                                        picker="year"
                                        disabledDate={(current) => current && current.year() > dayjs().year()}
                                        className="border-[2px] border-gray-200 rounded-md p-2 text-base custom-date-picker focus:outline"
                                    />
                                    {errors.years_coaching && touched.years_coaching && (
                                        <div className="text-red-500 text-sm">{errors.years_coaching}</div>
                                    )}
                                </div>

                                <InputFormik placeholder={t("years_experience_placeholder")}
                                             label={t("years_experience")} name={"years_experience"}/>
                            </div>

                            <div className="grid grid-cols-2 gap-3">
                                <div className="flex flex-col gap-2 pt-[5px]">
                                    <span className="font-medium text-base text-black">{t("coaching_level")}</span>
                                    <SelectFormik name="coaching_level" isMulti={false}
                                                  options={coachingLevelsOptions}
                                                  placeholder={t("coaching_level_placeholder")}/>
                                </div>

                                <div className="flex flex-col gap-2 pt-[5px]">
                                    <span className="font-medium text-base text-black">{t("certified_by")}</span>
                                    <SelectFormik name="certified_by" isMulti={false}
                                                  options={certifiedByOptions}
                                                  placeholder={t("certified_by_placeholder")}/>
                                </div>
                            </div>

                            <RadioFormik
                                label={t("management")}
                                name="management"
                                options={[
                                    {value: '1', label: t("yes")},
                                    {value: '0', label: t("no")},
                                ]}
                            />

                            <TextareaFormik readOnly={false} name="bio" label={t("bio")}/>
                        </>}

                        {hasErrors && (
                            <div className="flex items-center bg-red-50 text-red-700 p-3 rounded-md">
                                <InformationCircleIcon className="w-4 h-4 mr-2" />
                                {t("please_fill_all_required_fields")}
                            </div>
                        )}

                        <SubmitFormik
                            className="dashboard-button bg-black py-3 justify-center"
                            text={mode === 'create' ? t("submit") : t("update")}
                            onSubmittingText={mode === 'create' ? t("submitting") : t("updating")}
                        />

                    </Form>
                )
            }}
        </Formik>
    );
};

export default CoachProfileForm;
