import React from "react";
import {Tooltip} from "antd";
import {HiOutlineInformationCircle} from "react-icons/hi";

interface Props {
    title: string;
    children: React.ReactNode;
    tooltip?: string;
}

const InsightCard = ({title, children, tooltip}: Props) => {
    return (
        <div className="border rounded-md p-3">
            <div className="flex flex-row justify-between">
                <div className="text-sm text-gray-500">{title}</div>
                {tooltip && <Tooltip title={tooltip}>
                    <div className="flex items-center">
                        <HiOutlineInformationCircle className="w-4 h-4 mr-1"/>
                    </div>
                </Tooltip>}
            </div>
            {children}
        </div>
    )
}

export default InsightCard;