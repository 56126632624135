import { useState } from "react";

import { useTranslation } from "react-i18next";

import HighlightedText from "./HighlightedText";
import VerticalExpander from "./VerticalExpander";
import ChatGuide from "../../../../../shared/components/ChatGuide";

import introStep3 from "@img/dashboard/welcome/img/introStep3.png";

interface Props {
    description?: string;
}

const Step3 = ({description}: Props) => {
  const { t } = useTranslation("welcome");
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      <ChatGuide
        title={<HighlightedText>{t("step_3.title")}</HighlightedText>}
        description={description || t("step_3.description")}
        onShowContent={() => setShowContent(true)}
        onboarding
      />

      <div className="w-full flex flex-row justify-center items-center flex-1">
        <VerticalExpander expanded={showContent}>
          <div className="w-[700px] 2xl:w-[1000px] my-10">
            <img src={introStep3} alt="step 3" />
          </div>
        </VerticalExpander>
      </div>
    </>
  );
};

export default Step3;
