import React, {useState} from "react";
import img_puzzle_blocks from "../../../../../assets/img/aiCoaching/puzzle_blocks.png";
import {CheckCircleIcon, DotsVerticalIcon, BellIcon, CalendarIcon, ClockIcon} from "@heroicons/react/outline";
import {Dropdown} from 'rsuite';
import {IAIActionNotificationState} from "../../AICoaching/types";
import {AIActionNotificationState} from "../../../../../constants/enums";
import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../locale/en/ai_home.json";
import Fr from "../locale/fr/ai_home.json";

type Props = {
    title: string;
    actions: string;
    frequency: string;
    goalDeadline: string;
    notification_id: number;
    completed?: boolean;
    handleClick: (id: number, data: IAIActionNotificationState) => void;
};

const NotificationItem: React.FC<Props> = ({
                                               title,
                                               actions,
                                               frequency,
                                               goalDeadline,
                                               notification_id,
                                               completed,
                                               handleClick
                                           }) => {
    const {t} = useTranslation("ai_home");
    i18n.addResourceBundle("en", "ai_home", ENG);
    i18n.addResourceBundle("fr", "ai_home", Fr);
    const [isCompleted, setIsCompleted] = useState(completed || false);
    const detailList: string[] = JSON.parse(actions);

    const renderIconButton = ({...props}) => {
        return (
            <DotsVerticalIcon
                {...props}
                className="cursor-pointer text-black h-4 -ml-0.5"
            />
        );
    }

    const handleComplete = () => {
        handleClick(
            notification_id, {state: AIActionNotificationState.completed, state_value: !isCompleted})
        setIsCompleted(!isCompleted);
    }

    return <div className="p-3 border border-[#E6E6E6] rounded">
        <div className="grid grid-cols-12 gap-2 w-full">
            <img src={img_puzzle_blocks} className="h-8 w-auto" alt="adhoc"/>
            <div className="col-span-10 px-1">
                <p className="text-sm font-dm_sans text-black m-0 font-bold">{title}</p>
                <ul className="list-disc text-xs pl-4 py-2">
                    {detailList.map((item, key) => (
                        <li className="pb-1" key={key}>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex items-start">
                <div className="flex justify-between items-center">
                    <CheckCircleIcon
                        className={`cursor-pointer  h-4 ${isCompleted ? "text-green-500" : "text-black"}`}
                        onClick={handleComplete}
                    />
                    <Dropdown placement="bottomEnd" renderToggle={renderIconButton} onSelect={
                        (eventKey: number) => handleClick(
                            eventKey, {state: AIActionNotificationState.active, state_value: false})}>
                        <Dropdown.Item className="hover:bg-red-100 text-black hover:text-black"
                                       eventKey={notification_id}>{t("notification_view.delete")}</Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
        </div>
        <div className="flex flex-col gap-1">
            <div className="flex gap-2 w-full py-1">
                <BellIcon className="h-5 text-red"/>
                <div>
                    <p className="text-[13px] font-bold font-dm_sans text-black">
                        {`${t("notification_view.notification_schedule")}:`}
                    </p>
                    <div className="flex gap-2 w-full py-2">
                        <CalendarIcon className="h-4 text-black"/>
                        <div className="flex flex-col space-y-1">
                            <p className="text-xs font-dm_sans text-black">
                                {frequency}
                            </p>
                        </div>
                    </div>
                    <div className="flex gap-2 w-full">
                        <ClockIcon className="h-4 text-black"/>
                        <div className="flex flex-col space-y-1">
                            <p className="text-xs font-dm_sans text-gray-500">
                                {`${t("notification_view.ends_on")} ${goalDeadline.replace('T', ", ")}`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default NotificationItem;