import Typography from "antd/es/typography/Typography";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import moment from "moment";
import TimeSlotsFrequency from "../Components/TimeSlotFrequency";
import {useTranslation} from "react-i18next";
import InputFormik from "../../../../../shared/components/formik/InputFormik/InputFormik";
import SelectFormik from "../../../../../shared/components/formik/SelectFormik";
import SubmitFormik from "../../../../../shared/components/formik/SubmitFormik/SubmitFormik";


const PersonalUnavailability = (params: any) => {
    const {t} = useTranslation("events");
    const dateObject = new Date(params?.date);
    const dayOfWeek = dateObject.getDay();
    const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const dayName = dayNames[dayOfWeek];
    const options = [{id: 1, name: `Weekly on ${dayName}`}, {id: 2, name: "Every Weekday"}, {
        id: 3,
        name: "Every Weekend"
    }];
    return (
        <div>
            <Formik
                initialValues={{
                    ...params.initials.personalUnavailability,
                    startDate: moment(params.startDate),
                    endDate: moment(params.endDate),
                    startTime: moment(params.startTime),
                    endTime: moment(params.endTime)
                }
                }
                validationSchema={Yup.object({
                    title: Yup.string().required(t("required")),
                    startDate: Yup.date().required(t("required")),
                    endDate: Yup.date()
                        .required(t("required"))
                        .min(Yup.ref("startDate"), t("end_date_error")),
                    startTime: Yup.date().required(t("required")),
                    endTime: Yup.date()
                        .required(t("required"))
                        .min(Yup.ref('startTime'), t("end_time_error")),
                    // frequency: Yup.array().required(t("required"))
                })}
                onSubmit={(values, action) => {
                    const adjustedStartDate = values.startDate;
                    const adjustedEndDate = values.endDate;
                    const adjustedStartTime = values.startTime
                    const adjustedEndTime = values.endTime
                    params._handleSubmit({
                        ...values,
                        startDate: adjustedStartDate,
                        endDate: adjustedEndDate,
                        startTime: adjustedStartTime,
                        endTime: adjustedEndTime,
                        isBloked: true,
                        isBooked: false,
                        action,
                        isPersonalUnavailability: true
                    });
                }}
            >
                {({setFieldValue, errors, touched}) => (
                    <Form>
                        <div className="w-full flex flex-col gap-6 px-5 py-4">
                            <div>
                                <Typography className="font-bold">{t("personal_unavailable.add_title")}</Typography>
                                <Typography>
                                    {t("personal_unavailable.reference")}
                                </Typography>
                                <InputFormik
                                    label=""
                                    name="title"
                                    onKeyUp={(e: any) => {
                                    }}
                                    className="p-3 border-solid border border-[#B3B3B3] rounded"
                                />
                            </div>
                            <TimeSlotsFrequency
                                title={t("personal_unavailable.blocked_time_frequency")}
                                params={params}
                                setFieldValue={setFieldValue}
                                multiDaySelection={true}
                            />
                            <span className="text-red h-[18px]">
                {touched.endTime && errors.endTime && errors.endTime as string}
              </span>
                            {/* <div>
              <Typography className="font-bold pb-1"> {t("personal_unavailable.frequency")}</Typography>

              <div>
                <SelectFormik
                  name="frequency"
                  labelKey="name"
                  valueKey="id"
                  multiple={false}
                  options={options}
                  placeholder={t("select")}
                />
              </div>
            </div> */}
                            <div>
                                <SubmitFormik
                                    className="dashboard-button w-full bg-black py-3 justify-center"
                                    text={t("applyBtn")}
                                    onSubmittingText="Updating..."
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PersonalUnavailability;