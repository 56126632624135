import React, {useEffect, useState} from "react";
import ReactModal from "react-modal";
import {AiOutlineClose} from "react-icons/ai";
import {getUtilisationSessionView} from "../../services/api/companyApi";
import {capitalizeSentence} from "../../utils/helpers/str";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {IGroup} from "../../models/IGroup";
import defaultProfileImg from "@img/icons/default-profile.png";
import {Colors} from "../../constants/enums";
import DynamicColorSpan from "./DynamicColorSpan";
import LoaderContainer from "./LoaderContainer";

interface Session {
    name: string;
    image_url: string;
    meeting_time: string;
    status: any;
    program: any;
    coach_name: string;
    coach_image_url: string;
    group: IGroup;
}

interface Props {
    companyId: number;
    coacheeId?: number;
    coachId?: number;
    groupId: number | null;
    isOpen: boolean;
    onRequestClose: () => void;
}

const filterSessionsByGroupId = (sessions: Session[], groupId: number | null, coachId?: number): Session[] => {
    if (coachId) return sessions;
    return sessions.filter(session =>
        groupId === null ? session.group === null : session.group?.id === groupId
    );
}

const SessionDetailsModal = (props: Props) => {
    const {t} = useTranslation('commons');
    const [sessions, setSessions] = useState<Session[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const colors = [
        Colors.green,
        Colors.pink,
        Colors.yellowLight,
        Colors.purple,
        Colors.yellow,
    ];

    const statusColors: Record<string, string> = {
        completed: Colors.green,
        ongoing: Colors.purple,
        paused: Colors.yellowLight,
        not_started: Colors.pink,
        success: Colors.green,
        no_show_by_client: Colors.purple,
        deactivated: Colors.warmDark,
        upcoming: Colors.pink,
    };

    useEffect(() => {
        if (props.isOpen) {
            setIsLoading(true);
            getUtilisationSessionView(props.companyId, null, null, props.coacheeId, props.coachId)
                .then((res) => {
                    const mappedSessions = res.user_session.map((session: Session) => {
                        let status = session.status.toLowerCase().replace(" ", "_");

                        return {
                            ...session,
                            program: {
                                name: session.program,
                                color_code: colors[session.program.charCodeAt(0) % colors.length],
                            },
                            status: {
                                name: capitalizeSentence(t(`more_detail.status.${status}`)),
                                color_code: statusColors[status]
                            },
                        };
                    });
                    setSessions(mappedSessions);
                })
                .catch(() => {
                    console.error("Failed to fetch session data");
                })
                .finally(() => setIsLoading(false));
        }
    }, [props.isOpen]);

    const filteredSessions = filterSessionsByGroupId(sessions, props.groupId, props.coachId);
    const sortedSessions: Session[] = filteredSessions.sort(
        (a: Session, b: Session) => new Date(a.meeting_time).getTime() - new Date(b.meeting_time).getTime()
    );

    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            contentLabel="Session Details Modal"
            ariaHideApp={false}
            closeTimeoutMS={200}
            className="bg-white rounded-lg shadow-lg p-4 max-w-4xl mx-auto transition-transform transform-gpu ease-out duration-200"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
            {isLoading && <LoaderContainer/>}
            {sortedSessions.length > 0 &&
                <div>
                    <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center gap-2">
                            <img
                                src={(props.coacheeId ? sessions[0].image_url : sessions[0].coach_image_url) || defaultProfileImg}
                                className="w-8 h-8 rounded-full" alt={"profile_img"}/>
                            <h2 className="text-sm font-semibold">{props.coacheeId ? sessions[0].name : sessions[0].coach_name}</h2>
                        </div>
                        <button onClick={props.onRequestClose}
                                className="text-gray-500 hover:text-gray-800 focus:outline-none">
                            <AiOutlineClose size={20}/>
                        </button>
                    </div>

                    <div className="overflow-y-auto" style={{maxHeight: '60vh'}}>
                        <table
                            className="min-w-full bg-white border border-gray-200 text-xs text-gray-700 text-center">
                            <thead className="bg-gray-100">
                            <tr>
                                <th className="py-2 px-4 border-b">#</th>
                                <th className="py-2 px-4 border-b">{t('more_detail.session_date')}</th>
                                <th className="py-2 px-4 border-b">{t('more_detail.statuss')}</th>
                                <th className="py-2 px-4 border-b">{t('more_detail.coach')}</th>
                                <th className="py-2 px-4 border-b">{t('more_detail.program')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sortedSessions.map((session, index) => {
                                const personName = props.coachId
                                    ? session.name
                                    : session.coach_name;
                                const personImage = props.coachId
                                    ? session.image_url
                                    : session.coach_image_url;

                                return (
                                    <tr key={index} className="hover:bg-gray-50 transition duration-150">
                                        <td className="py-2 px-4 border-b">{index + 1}</td>
                                        <td className="py-2 px-4 border-b">{moment(session.meeting_time).format("MMM D, YYYY")}</td>
                                        <td className="py-2 px-4 border-b">
                                            <DynamicColorSpan
                                                backgroundColor={session.status?.color_code}>{capitalizeSentence(session.status?.name)}
                                            </DynamicColorSpan>
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <div className="flex items-center gap-1">
                                                <img src={personImage || defaultProfileImg} alt={"profile_img"}
                                                     className="w-8 h-8 rounded-full"/>
                                                <span
                                                    className="">{personName}</span>
                                            </div>
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <DynamicColorSpan
                                                backgroundColor={session.program?.color_code}>{capitalizeSentence(session.program?.name)}
                                            </DynamicColorSpan>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </ReactModal>
    );
};

export default SessionDetailsModal;
