import { Objective } from "../AICoaching/types";

export const dummyObjectives: Objective[] = [
    {
        id: 1,
        name: "Succeed my career transition",
        name_fr: "Transition de carrière",
        key: "career_development",
    },
    {
        id: 2,
        name: "Adapt collective intelligence",
        name_fr: "Intelligence collective",
        key: "work_environment",
    },
    {
        id: 3,
        name: "Nail my public speaking",
        name_fr: "Prise de parole en public",
        key: "job_performance",
    },
];