import useAuth from "@hooks/useAuth";
import React, {useEffect, useRef, useState} from 'react';
import logoImg from '@img/coachelloLogo.png'
import loginImg from '@img/auth/login.webp'
import googleIcon from '@icons/google.png'
import microsoftIcon from '@icons/microsoft.png'
import {useLocation, useNavigate} from "react-router-dom";
import {DocumentTextIcon, HomeIcon, KeyIcon, LockClosedIcon, MailIcon} from "@heroicons/react/outline";
import {EAuthType} from "../../../constants/enums";
import LanguageSelector from "../../../shared/components/LanguageSelector/LanguageSelector";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/login.json";
import Fr from "../locale/fr/login.json";
import {useTranslation} from "react-i18next";
import useModal from "@hooks/useModal";
import ResetPassword from "../components/ResetPassword";
import Modal from "../../dashboard/components/Modal";
import {toast} from "react-toastify";

interface LocationProps {
    state: {
        from: Location;
    };
};


const Login = () => {
    i18n.addResourceBundle('en', 'login', ENG);
    i18n.addResourceBundle('fr', 'login', Fr);
    const {t} = useTranslation('login')
    const languageSelectorRef =
        useRef<React.ElementRef<typeof LanguageSelector>>(null);

    const navigate = useNavigate();
    const {login, gLoginRef, loginImplicitFlow, loginAuthCodeFlow} = useAuth();
    const {state} = useLocation() as LocationProps;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {isOpen, toggle} = useModal();

    useEffect(() => {
        languageSelectorRef.current?.changeLanguageAction('en');
    }, [languageSelectorRef]);
    return (
        <div className="flex-col lg:flex-row flex">
            <div className="flex flex-col flex-1">

                <div className="flex justify-center">
                    <img className={"w-1/2 m-8"} src={logoImg}/>
                </div>
                <div className="flex flex-col mt-24 justify-center items-center">
                    <div className="flex flex-col gap-3">

                        <h1> {t('text12')}</h1>
                        <button onClick={loginAuthCodeFlow} className="dashboard-button bg-black w-full">
                            <img height={18} width={18} src={googleIcon}/>
                            {t('text1')}
                        </button>
                        {/*<div ref={gLoginRef} className={"m-auto"} ></div>*/}
                        <button onClick={() => login(EAuthType.MS, state?.from.href)}
                                className="dashboard-button bg-black w-full">
                            <img height={18} width={18} src={microsoftIcon}/>
                            {t('text2')}

                        </button>
                        <LoginWithMagicLink/>
                        <div className="mt-4 relative text-gray-600 rounded">
                            <button className="absolute left-2 top-0 bottom-0 mr-4">
                                <MailIcon className="text-gray-600 h-5 w-5"/>

                            </button>
                            <input
                                onChange={e => setEmail(e.target.value)}
                                placeholder={t('text3')}

                                className="w-full border-2 border-gray-light bg-white h-10 px-5 pl-8 rounded text-sm focus:outline-none"
                                name="email"/>
                        </div>
                        <div className=" relative text-gray-600 rounded">
                            <button className="absolute left-2 top-0 bottom-0 mr-4">
                                <KeyIcon className="text-gray-600 h-5 w-5"/>
                            </button>
                            <input
                                onChange={e => setPassword(e.target.value)}
                                placeholder={t('text4')}
                                className="w-full border-2 border-gray-light bg-white h-10 px-5 pl-8 rounded text-sm focus:outline-none"
                                name="password"
                                type="password"/>
                        </div>
                        <button disabled={email == '' || password == ''} className="dashboard-button bg-black m-auto"
                                onClick={() => login(EAuthType.Email, undefined, {email: email, password: password})}>
                            {t('text11')}
                        </button>
                        <button
                            onClick={() => toggle()}
                            className=" hover:underline mx-auto"
                        >{t('text14')}</button>
                        {/*<button onClick={() => navigate('/auth/signup')}*/}
                        {/*        className="dashboard-button bg-black m-auto">*/}
                        {/*    {t('text13')}*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="flex justify-center gap-9 mt-28 mb-8">
                    <a href={'https://coachello.io/'} target="_blank" className={"flex items-center gap-1.5"}>
                        <HomeIcon className="text-gray-600 h-5 w-5"/>
                        {t('text5')}
                    </a>
                    <a href={'https://coachello.io/privacy-policy/'} target="_blank"
                       className={"flex items-center gap-1.5"}>
                        <LockClosedIcon className="text-gray-600 h-5 w-5"/>
                        {t('text6')}
                    </a>
                    <a href={'https://coachello.io/terms-and-conditions/'} target="_blank"
                       className={"flex items-center gap-1.5"}>
                        <DocumentTextIcon className="text-gray-600 h-5 w-5"/>
                        {t('text7')}
                    </a>
                </div>
            </div>
            <div
                style={{backgroundImage: `url(${loginImg})`}}
                className="flex-1 bg-cover lg:h-screen items-end flex hidden lg:flex">
                {/*<img src={loginImg}/>*/}
                <div className={"text-white ml-20 mb-16"}>
                    <h2>{t('text8')}</h2>
                    <div className={"mt-5"}>
                        <h4>
                            {t('text9')}
                        </h4>
                        <span>{t('text10')}</span>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0">
                <LanguageSelector ref={languageSelectorRef}/>
            </div>
            <Modal
                isOpen={isOpen}
                onClose={toggle}
            >
                <ResetPassword toggle={toggle}/>
            </Modal>

        </div>
    );
};


export default Login;


const LoginWithMagicLink = () => {
    i18n.addResourceBundle('en', 'login', ENG);
    i18n.addResourceBundle('fr', 'login', Fr);
    const {t} = useTranslation('login')
    const {sendMagicLink} = useAuth();
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('');


    if (step == 1) {
        return <div>
            <button onClick={() => setStep(2)}
                    className="dashboard-button bg-black w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"/>
                </svg>
                {t('text2_1')}
            </button>
        </div>
    } else {
        return <div>
            <div className="relative text-gray-600 rounded">
                <button className="absolute left-2 top-0 bottom-0 mr-4">
                    <MailIcon className="text-gray-600 h-5 w-5"/>

                </button>
                <input
                    onChange={e => setEmail(e.target.value)}
                    placeholder={t('text3')}

                    className=" mx-auto w-full border-2 border-gray-light bg-white h-10 px-5 pl-8 rounded text-sm focus:outline-none"
                    name="email"/>
            </div>
            <br/>
            <button disabled={email == ''}
                    className="dashboard-button bg-black m-auto disabled:bg-gray-600"
                    onClick={() => {
                        sendMagicLink(email)
                            .then(r => toast.success(t('text18')))
                            .catch(e => toast.error('Sorry! Something went wrong.'))
                    }}>
                {t('text11_1')}
            </button>
        </div>
    }

}
