import {useEffect, useRef, useState} from "react";

import ReportSessionItem from "../../components/ReportSessionItem";
import ReportCompanyOverview from "../../components/ReportCompanyOverview";
import ReportSessionOverview from "../../components/ReportSessionOverview";
import { Loader } from "@components";

import { getPendingReports } from "../../../../services/api/coachApi";
import useAuth from "@hooks/useAuth";
import { isEmpty } from "../../../../utils/helpers/object";
import { useTranslation } from "react-i18next";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/reports.json";
import Fr from "../../locale/fr/reports.json";
import {EAssessmentType} from "../../../../constants/enums";
import FrameModal from "../../../../shared/components/FrameModal";
import AssessmentResultsSection from "../Reports/sections/AssessmentResultsSection";

const CreateReportOverview = () => {
  i18n.addResourceBundle('en', 'reports', ENG);
  i18n.addResourceBundle('fr', 'reports', Fr);
  const { t } = useTranslation('reports')
  const { user } = useAuth();
  const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);

  const [loading, setLoading] = useState<Boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  const [pendingSessions, setPendingSessions] = useState<Record<any, any>[]>(
    []
  );
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const openModal = (key: EAssessmentType, title: string) => {
    frameModalRef.current?.open(key, title);
  };

  useEffect(() => {
    setIsDataLoaded(false);
    setLoading(true);
    getPendingReports(user?.id!)
      .then((res: any) => {
        setPendingSessions(res);
        setSelected(0);
        setLoading(false);
        setIsDataLoaded(true);
      })
      .catch(() => {
        setLoading(false);
        setIsDataLoaded(true);
      });
  }, []);
  return (
    <div className="flex gap-1 flex-col lg:flex-row p-5">
      {loading && (
        <div className="flex justify-center items-center p-5 inset-0 fixed w-full">
          <Loader />
        </div>
      )}
      {isDataLoaded && !isEmpty(pendingSessions) ? (
        <>
          <div className="basis-1/3 flex flex-col gap-5 pr-5 h-screen overflow-auto lg:-mb-7 lg:sticky lg:top-2">
            {pendingSessions?.map((ses, i) => {
              return (
                <ReportSessionItem
                  key={i}
                  index={i}
                  name={ses.coachee_overview.name}
                  companyName={ses.company_overview.name}
                  profile={ses.coachee_overview.profile}
                  datetime={ses.datetime}
                  selected={i == selected}
                  setSelected={setSelected}
                />
              );
            })}
          </div>
          <div className="basis-full">
            <ReportCompanyOverview
              name={pendingSessions[selected].company_overview.name}
              medium={pendingSessions[selected].company_overview.medium}
              locale={pendingSessions[selected].company_overview.locale}
              challenges={pendingSessions[selected].company_overview.challenges}
              logo={pendingSessions[selected].company_overview.logo_url}
              seniority={pendingSessions[selected].company_overview.seniority}
              headCount={pendingSessions[selected].company_overview.head_count}
              location={pendingSessions[selected].company_overview.location}
              maxCredits={
                pendingSessions[selected].company_overview.max_credits
              }
              other_info={pendingSessions[selected].company_overview.other_info}
            />

            { pendingSessions[selected].assessment_score &&
                <div className="card-container mt-5 p-4">
                  <AssessmentResultsSection
                      data={pendingSessions[selected].assessment_score}
                      openModal={openModal}
                  />
                </div>
            }

            <ReportSessionOverview
              coacheeName={pendingSessions[selected].coachee_overview.name}
              datetime={pendingSessions[selected].datetime}
              locale={pendingSessions[selected].coachee_overview.locale}
              sessionId={pendingSessions[selected].id}
              profile={pendingSessions[selected].coachee_overview.profile}
              sessionNo={pendingSessions[selected].session_no}
              categories={pendingSessions[selected].categories}
              location={pendingSessions[selected].company_overview.location}
              coacheeNote={pendingSessions[selected].coachee_note}
            />
          </div>
        </>
      ): isDataLoaded && <div className="flex justify-center items-center w-full"><h2 className="text-center">{t("loading_text")}</h2></div>
      }
    </div>
  );
};

export default CreateReportOverview;
