import {useEffect, useState} from "react";

import moment from "moment";
import {useTranslation} from "react-i18next";
import {Loader} from "rsuite";

import Button from "../../../../../shared/components/Button";
import ArrowRight from "./ArrowRight";

import {HomeDropdownActions} from "../../../../../constants";
import {useConfig} from "../../../../../utils/providers/AppConfigProvider";

import {ISessionSchema} from "../../../../../models/IHome";
import {EHomeDropdownAction} from "../../../../../constants/enums";
import {THomeDropdownActionItem} from "../../../../../constants/types";

import calendar from "../../../../../assets/img/dashboard/home/icons/calendar.svg";
import clock from "../../../../../assets/img/dashboard/home/icons/clock.svg";
import cancel from "../../../../../assets/img/dashboard/home/icons/Cancel.svg";
import review from "../../../../../assets/img/dashboard/home/icons/Review.svg";
import report from "../../../../../assets/img/dashboard/home/icons/Check Report.svg";
import reschedule from "../../../../../assets/img/dashboard/home/icons/Reschedule.svg";
import joinSessionIcon from "../../../../../assets/img/dashboard/home/icons/join_session.png";
import messageIcon from "../../../../../assets/img/dashboard/home/icons/message.png";
import {Tooltip} from "antd";

type Props = {
    session: ISessionSchema;
    className?: string;
    handleAction: (
        action: string | EHomeDropdownAction,
        session?: ISessionSchema,
    ) => void;
    cancelInProgress?: boolean;
    disableNextButton?: boolean;
};

const SessionCard = ({
                         session,
                         className,
                         handleAction,
                         cancelInProgress,
                         disableNextButton
                     }: Props) => {
    const {config} = useConfig();
    const [dropdownActions, setDropdownActions] = useState<
        THomeDropdownActionItem[]
    >([]);
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    moment.locale(lng);
    const date = moment(session.datetime).format("dddd, DD MMMM");
    const time = moment(session.datetime).format("HH:mm");
    const coachName = `${session.coach_first_name} ${
        session.coach_last_name ?? ""
    }`;

    const {t} = useTranslation("home");

    const title = session.upcoming
        ? t("session_card.next_session")
        : t("session_card.last_session");

    useEffect(() => {
        if (session.upcoming) {
            return setDropdownActions([
                HomeDropdownActions.Reshedule,
                HomeDropdownActions.Cancel,
            ]);
        }
        const pastSessionDropdownActions = [HomeDropdownActions.LeaveReview];
        if (config?.report) {
            pastSessionDropdownActions.unshift(HomeDropdownActions.CheckReport);
        }
        setDropdownActions(pastSessionDropdownActions);
    }, [session, config]);
    const getAction = (action: EHomeDropdownAction) => {
        switch (action) {
            case EHomeDropdownAction.Cancel:
                return  <img src={cancel} alt="" className="object-cover pointer-events-none"/>

            case EHomeDropdownAction.Reshedule:
                return <img src={reschedule} alt="" className="object-cover pointer-events-none"/>

            case EHomeDropdownAction.LeaveReview:
                return  <img src={review} alt="" className="object-cover pointer-events-none"/>

            case EHomeDropdownAction.CheckReport:
                return <img src={report} alt="" className="object-cover pointer-events-none"/>


        }

    }

    const getTooltipText = (action: EHomeDropdownAction) => {
        switch (action) {
            case EHomeDropdownAction.Cancel:
                return t("session_card.cancel_session");
            case EHomeDropdownAction.Reshedule:
                return t("session_card.reschedule_session");
            case EHomeDropdownAction.LeaveReview:
                return t("session_card.leave_review");
            case EHomeDropdownAction.CheckReport:
                return t("session_card.check_report");
            default:
                return "";
        }
    };

    const handleSessionAction = () => {
        const action = session.upcoming ? EHomeDropdownAction.JoinSession : EHomeDropdownAction.ScheduleNext
        if (!session.upcoming && session.orientation) return handleAction(action);
        handleAction(action, session);
    }

    return (
        <div
            className={`rounded bg-white min-h-min min-w-min p-4 pt-3 flex flex-col gap-5 border-gray-50 border-[1px] ${className}`}
        >
            <div className="flex flex-row justify-between items-center">
                <h2 className="text-lg font-bold font-dm_sans">{title}</h2>
                <div className="flex flex-row gap-2">
                    {cancelInProgress ? (
                        <Loader/>
                    ) : (
                        <>
                            {dropdownActions.map((item, index) => {
                                return <Tooltip title={getTooltipText(item.action)} key={index}><Button
                                    className="h-7 w-7 group border-2 border-black flex items-center justify-center text-xs px-1 py-1 text-black font-bold relative hover:border-green-500"
                                    onClick={() => {
                                        handleAction(item.action, session);
                                    }}
                                >
                                    {getAction(item.action)}
                                </Button>
                                    </Tooltip>
                            })}
                        </>
                    )}
                </div>
            </div>

            <div className="flex align-center">
                {session.coach_profile && (
                    <img
                        src={session.coach_profile}
                        className="rounded-full object-cover mr-2 inline-block h-12 w-12"
                        alt="Coach avatar"
                    />
                )}
                <div className="inline-flex flex-col justify-center gap-0.5">
                    <h2 className="font-lexend text-xs">{coachName}</h2>
                    <div className="font-dm_sans text-gray font-light flex gap-4 items-center">
            <span className="flex gap-1 text-xs items-center">
              <img src={calendar} alt="" className="inline-block h-4 w-4"/>
                {date}
            </span>
                        <span className="flex gap-1 text-xs items-center">
              <img src={clock} alt="" className="inline-block h-4 w-4"/> {time}
            </span>
                    </div>
                </div>
            </div>

            <div className="flex justify-items-stretch gap-2">
                <a
                    href={`mailto:${session.coach_email}`}
                    className="no-underline h-full"
                >
                    <Button
                        className="bg-white text-black border-2 border-black flex gap-4 text-xs px-10 w-full h-full justify-center"
                        onClick={() => handleAction(EHomeDropdownAction.Message)}
                    >
                        <img src={messageIcon} alt="" className="w-4"/>
                        <span className="my-2">{t("message")}</span>
                    </Button>
                </a>
                {session.coach_active &&
                <Button
                    className="bg-white text-black border-2 border-black flex gap-4 text-xs h-max px-10 w-full justify-center"
                    onClick={handleSessionAction}
                    disabled={disableNextButton}
                >
                    <img src={joinSessionIcon} alt="" className="w-4"/>
                    <span className="my-2">
            {session.upcoming
                ? t("join_session")
                : t("session_card.schedule_next")}
          </span>
                    <ArrowRight/>
                </Button>}
            </div>
        </div>
    );
};

export default SessionCard;
