import React, {useCallback, useEffect, useState} from 'react';
import SettingCard from "./components/SettingCard";
import {ICompanyConfig, ICompanySettings, IConfigSchema, ISettingSchema} from "../../../../constants/types";
import useAuth from "@hooks/useAuth";
import {
    deleteGroupSetting,
    getCompanyConfig,
    getCompanySettings,
    updateCompanyConfig,
    updateCompanySetting,
    updateGroupSetting
} from "../../../../services/api/companyApi";
import {useTranslation} from "react-i18next";
import i18n from "../../../../utils/lib/i18n";
import SettingENG from "../../locale/eng/setting.json";
import SettingFR from "../../locale/fr/setting.json";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import {useNavTitle} from "@hooks/useNavTitle";
import CompanyConfigCard from "./components/CompanyConfigCard";
import defaultCompanyImg from "@img/dashboard/company.svg";

const Configuration = ({...props}) => {
    const {user} = useAuth();
    const {t} = useTranslation("setting");
    i18n.addResourceBundle("en", "setting", SettingENG);
    i18n.addResourceBundle("fr", "setting", SettingFR);
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const [companySettings, setCompanySettings] = useState<ICompanySettings[]>([]);
    const [companyConfig, setCompanyConfig] = useState<ICompanyConfig[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [openSettings, setOpenSettings] = useState<number[]>([0]);
    const [isLoadingConfig, setIsLoadingConfig] = useState(false);

    const readOnly = user?.email !== "lasitha.work@gmail.com";

    const formatOption = (option: boolean | string): string =>
        option === true ? t("yes") : option === false ? t("no") : option;

    const fetchCompanySettings = useCallback((isStarting?: boolean) => {
        isStarting && setIsLoading(true);
        if (user) {
            getCompanySettings(user?.companyId)
                .then((data) => {
                    setCompanySettings(data)
                }).catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }
    }, []);

    const fetchCompanyConfig = useCallback((isStarting?: boolean) => {
        isStarting && setIsLoadingConfig(true);
        if (user) {
            getCompanyConfig(user?.companyId)
                .then((data) => {
                    setCompanyConfig(data);
                }).catch((err) => console.log(err))
                .finally(() => setIsLoadingConfig(false));
        }
    }, [])

    useEffect(() => {
        fetchCompanySettings(true);
        fetchCompanyConfig(true);
    }, []);

    const handleUpdateCompanySettings = (settings: ISettingSchema) => {
        if (user) {
            setIsUpdating(true);
            updateCompanySetting(user.companyId, settings)
                .then(() => {
                    fetchCompanySettings();
                }).catch((err) => console.error(err))
                .finally(() => setTimeout(() => {
                    setIsUpdating(false)
                }, 1000));
        }
    }

    const handleUpdateGroupSettings = (groupId: number, settings: ISettingSchema) => {
        if (user) {
            setIsUpdating(true);
            updateGroupSetting(user.companyId, groupId, settings)
                .then(() => {
                    fetchCompanySettings();
                }).catch((err) => console.error(err))
                .finally(() => setTimeout(() => {
                    setIsUpdating(false)
                }, 1000));
        }
    }

    const handleDeleteGroupSetting = (groupId: number, settingId: number) => {
        if (user) {
            setIsDeleting(true);
            deleteGroupSetting(user?.companyId, groupId, settingId)
                .then(() => {
                    fetchCompanySettings();
                }).catch((err) => console.error(err))
                .finally(() => setTimeout(() => {
                    setIsDeleting(false)
                }, 1000));
        }
    }

    const handleUpdateCompanyConfig = (config: IConfigSchema) => {
        if (user) {
            setIsUpdating(true);
            updateCompanyConfig(user.companyId, config)
                .then(() => {
                    fetchCompanyConfig();
                }).catch((err) => console.error(err))
                .finally(() => setTimeout(() => {
                    setIsUpdating(false)
                }, 1000));
        }
    }

    const toggleSetting = (id: number) => {
        setOpenSettings(prevOpenSetting => {
            if (prevOpenSetting.includes(id)) {
                return prevOpenSetting.filter(setting => setting !== id);
            } else {
                return [...prevOpenSetting, id];
            }
        });
    };

    return <div className="p-2">
        <div className="flex items-center pt-2 pb-4">
            <h4 className="text-xl text-black font-bold pr-2">{t("subtitle1")} </h4>
            <img
                src={defaultCompanyImg}
                alt="Default Company Logo"
                className="w-6 h-auto object-cover rounded"
            />
            <h4 className="px-2 text-lg text-gray-600">{user?.companyName}</h4>
        </div>
        {isLoading ? (
            <div className="h-screen">
                <LoaderContainer/>
            </div>
        ) : (
            companySettings.filter(item => !item.invisible).map((item, key) =>
                item.key !== "ai_coach" &&
                <SettingCard
                    key={key}
                    companySetting={item}
                    handleUpdateCompanySettings={handleUpdateCompanySettings}
                    handleUpdateGroupSettings={handleUpdateGroupSettings}
                    handleDeleteGroupSetting={handleDeleteGroupSetting}
                    isDeleting={isDeleting}
                    isUpdating={isUpdating}
                    toggleSetting={toggleSetting}
                    isExpanded={openSettings.includes(item.id)}
                    readOnly={readOnly}
                />
            )
        )}
        <div className="pt-2 pb-16">
            <div className="flex items-center pt-2 pb-4">
                <h4 className="text-xl text-black font-bold pr-2">{t("subtitle2")} </h4>
                <img
                    src={defaultCompanyImg}
                    alt="Default Company Logo"
                    className="w-6 h-auto object-cover rounded"
                />
                <h4 className="px-2 text-lg text-gray-600">{user?.companyName}</h4>
            </div>
            {isLoadingConfig ? (
                <div className="h-screen">
                    <LoaderContainer/>
                </div>
            ) : (
                companyConfig.filter(item => item.key !== "ai_bot").map((item, key) => {
                        const formattedOptions = item.options
                            .slice(0, -1)
                            .map((option) => formatOption(option))
                            .join(", ");

                        const lastOption = item.options.length > 0
                            ? formatOption(item.options[item.options.length - 1])
                            : "";
                        return <CompanyConfigCard key={key}
                                                  settingName={item.key}
                                                  value={item.value}
                                                  options={item.options}
                                                  isLoading={isUpdating}
                                                  handleUpdate={handleUpdateCompanyConfig}
                                                  tooltip={`${t("tooltipPrevText")} ${formattedOptions}${item.options.length > 1 ? `, ${t("or")} ` : ""}${lastOption} ${t("tooltipNextText")}`}
                                                  readOnly={readOnly}/>
                    }
                )
            )}
        </div>
    </div>
}

export default Configuration;