import {voiceLanguageData} from "../../constants/data/VoiceLanguageData";

export function getVoiceDetails(languageCode: string, gender: "FEMALE" | "MALE") {
    const voiceDetails = voiceLanguageData[languageCode]?.[gender];
    if (!voiceDetails) {
        console.log(`No voice mapping found for language: ${languageCode} and gender: ${gender}`);
        return { voiceName: "en-GB-Journey-D", languageCode: "en-GB" }
    }

    return voiceDetails;
}