import {ICompany, ICompanyAdmin} from "../../../../../models/ICompany";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FaTrash} from "react-icons/fa";
import ReactModal from "react-modal";
import {Tooltip} from "antd";
import Select from "react-select";

interface Props {
    companyAdmins: ICompanyAdmin[];
    company: ICompany;
    addAdmin: (companyId: number, admin: any) => void;
    deleteAdmin: (companyId: number, adminId: number) => void;
    isFocus: boolean;
    removeFocus: () => void;
}

const CompanyAdminsTab = ({companyAdmins, company, addAdmin, deleteAdmin, isFocus, removeFocus}: Props) => {
    const {t} = useTranslation("companyInsights");
    const [adminData, setAdminData] = useState<ICompanyAdmin[]>([]);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState<ICompanyAdmin | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newAdmin, setNewAdmin] = useState({
        first_name: "",
        last_name: "",
        email: "",
        it_admin: false,
    });
    const [formErrors, setFormErrors] = useState<{
        first_name: string;
        last_name: string;
        email: string;
    }>({
        first_name: "",
        last_name: "",
        email: "",
    });

    useEffect(() => {
        setAdminData(companyAdmins);
    }, [companyAdmins]);

    useEffect(() => {
        if (isFocus) handleAddAdminClick();
    }, [isFocus]);

    const handleDeleteAdmin = (admin: ICompanyAdmin) => {
        setSelectedAdmin(admin);
        setDeleteConfirmModal(true);
    };

    const confirmDeleteAdmin = () => {
        if (selectedAdmin) {
            deleteAdmin(company.id, selectedAdmin.id);
        }
        setDeleteConfirmModal(false);
    };

    const handleAddAdminClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setNewAdmin({
            first_name: "",
            last_name: "",
            email: "",
            it_admin: false,
        });
        setFormErrors({
            first_name: "",
            last_name: "",
            email: "",
        });
        setShowModal(false);
        removeFocus();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setNewAdmin({
            ...newAdmin,
            [name]: value,
        });

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name as keyof typeof prevErrors]: value ? "" : t(`admin.${name}_required`),
        }));
    };

    const itAdminOptions = [
        {value: true, label: t("status.it_admin")},
        {value: false, label: t("status.hr_admin")},
    ];

    const validateForm = () => {
        const errors = {
            first_name: "",
            last_name: "",
            email: "",
        };

        if (!newAdmin.first_name) {
            errors.first_name = t("admin.first_name_required");
        }

        if (!newAdmin.last_name) {
            errors.last_name = t("admin.last_name_required");
        }

        if (!newAdmin.email) {
            errors.email = t("admin.email_required");
        } else if (!/\S+@\S+\.\S+/.test(newAdmin.email)) {
            errors.email = t("admin.email_invalid");
        }

        setFormErrors(errors);

        return !errors.first_name && !errors.last_name && !errors.email;
    };

    const handleAddAdmin = () => {
        if (validateForm()) {
            setIsAdding(true);
            addAdmin(company.id, newAdmin);
            setIsAdding(false);
            handleModalClose();
        }
    };


    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-full border md:text-sm text-xs text-center [&>*>tr>th]:font-normal">
                    <thead className="border-b-2 bg-[#F9F9F9FF]">
                    <tr>
                        <th className="px-4 py-3 tracking-wider">{t("admin.first_name")}</th>
                        <th className="px-4 py-3 tracking-wider">{t("admin.last_name")}</th>
                        <th className="px-4 py-3 tracking-wider">{t("admin.email")}</th>
                        <th className="px-4 py-3 tracking-wider">{t("admin.type")}</th>
                        <th className="px-4 py-3 tracking-wider"></th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {adminData && adminData.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="px-4 py-3">{item.first_name}</td>
                                <td className="px-4 py-3">{item.last_name || 0}</td>
                                <td className="px-4 py-3">{item.email}</td>
                                <td className="px-4 py-3">
                                    <span className={`inline-flex px-2 py-1 text-xs font-medium rounded-full ${
                                        item.it_admin
                                            ? 'bg-green-100 text-green-800'
                                            : 'bg-red-100 text-red-800'
                                    }`}>
                                        {item.it_admin ? t("status.it_admin") : t("status.hr_admin")}
                                    </span>
                                </td>
                                <td className="px-4 py-3">
                                    <Tooltip title={t("tooltip.delete_admin")}>
                                        <button onClick={() => handleDeleteAdmin(item)}>
                                            <FaTrash className="text-gray-300 hover:text-gray-700"/>
                                        </button>
                                    </Tooltip>
                                </td>
                            </tr>
                        )
                    })}
                    {!adminData.length &&
                        <tr className="text-center">
                            <td colSpan={5} className="font-normal p-3">
                                {t("admin.no_data")}
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
                <div className="flex md:justify-end mt-2">
                    <button
                        onClick={handleAddAdminClick}
                        className="text-white bg-black font-semibold text-xs py-1 px-2 rounded border border-black"
                    >
                        {t("admin.add_admin")}
                    </button>
                </div>
            </div>
            <ReactModal
                isOpen={showModal}
                onRequestClose={handleModalClose}
                contentLabel={t("admin.add_admin")}
                ariaHideApp={false}
                className="bg-white p-6 rounded-md shadow-lg w-96"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                <h2 className="text-base font-semibold mb-4">{`${t("admin.add_admin")} - ${company?.name || ''}`}</h2>

                <div className="mb-2">
                    <input
                        type="text"
                        name="first_name"
                        placeholder={t("admin.first_name")}
                        value={newAdmin.first_name}
                        onChange={handleInputChange}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.first_name && <p className="text-red-500 text-xs">{formErrors.first_name}</p>}
                </div>

                <div className="mb-2">
                    <input
                        type="text"
                        name="last_name"
                        placeholder={t("admin.last_name")}
                        value={newAdmin.last_name}
                        onChange={handleInputChange}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.last_name && <p className="text-red-500 text-xs">{formErrors.last_name}</p>}
                </div>

                <div className="mb-2">
                    <input
                        type="email"
                        name="email"
                        placeholder={t("admin.email")}
                        value={newAdmin.email}
                        onChange={handleInputChange}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.email && <p className="text-red-500 text-xs">{formErrors.email}</p>}
                </div>

                <div className="mb-2">
                    <Select
                        name="it_admin"
                        options={itAdminOptions}
                        value={itAdminOptions.find(option => option.value === newAdmin.it_admin)}
                        onChange={(selectedOption: any) => {
                            setNewAdmin((prev) => ({
                                ...prev,
                                it_admin: selectedOption.value === true,
                            }))
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </div>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={handleModalClose}
                        disabled={isAdding}
                        className="px-4 py-2 bg-gray-200 rounded-md"
                    >
                        {t("button.cancel")}
                    </button>
                    <button
                        onClick={handleAddAdmin}
                        disabled={isAdding}
                        className="px-4 py-2 bg-black text-white rounded-md"
                    >
                        {isAdding ? t("button.adding") : t("button.add")}
                    </button>
                </div>
            </ReactModal>
            <ReactModal
                isOpen={deleteConfirmModal}
                onRequestClose={() => setDeleteConfirmModal(false)}
                contentLabel={t("admin.confirm_delete")}
                ariaHideApp={false}
                className="bg-white p-6 rounded-md shadow-lg w-96"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                <h2 className="text-base font-semibold mb-4">{t("admin.confirm_delete")}</h2>
                <p>{t("admin.confirm_delete_message", {email: selectedAdmin?.email, company: company?.name})}</p>
                <div className="flex justify-end space-x-2 mt-4">
                    <button onClick={() => setDeleteConfirmModal(false)} className="px-4 py-2 bg-gray-200 rounded-md">
                        {t("button.cancel")}
                    </button>
                    <button onClick={confirmDeleteAdmin} className="px-4 py-2 bg-black text-white rounded-md">
                        {t("button.delete")}
                    </button>
                </div>
            </ReactModal>
        </>
    )
}

export default CompanyAdminsTab;