export const COACH = {
  get: {
    coachProfile: {
      private: true,
      path: "/api/coaches/me/profile",
    },
    coachSessions: {
      private: true,
      path: "/api/coaches/me/sessions",
    },
    pendingReports: {
      private: true,
      path: "/api/coaches/me/pending-reports",
    },
    sessionsOverview: {
      private: true,
      path: "/api/coaches/me/sessions-overview",
    },
    report: {
      private: true,
      path: "/api/coaches/me/reports/{session_id}",
    },
    getOccupiedEvents: {
      private: true,
      path: "api/coach/{coachId}/events",
    },
    getAllCoaches: {
      private: true,
      path: "api/coach/admin/coaches",
    },
    getCoachBookingConfig: {
      private: true,
      path: "/api/coaches/me/booking-config",
    },
    getCoachEvents: {
      private: true,
      path: "/api/coaches/me/events",
    },
    getClients: {
      private: true,
      path: "/api/coaches/me/clients",
    },
    getClientEvent: {
      private: true,
      path: "/api/coach/{coach_id}/event",
    },
    getCoach: {
      private: true,
      path: "/api/coach/{coach_id}",
    }
  },
  post: {
    report: {
      private: true,
      path: "/api/coaches/me/reports/{session_id}",
    },
    onbehalfBooking: {
      private: true,
      path: "/api/coaches/me/book-on-behalf",
    },
    coachProfile: {
      private: true,
      path: "/api/coach",
    }
  },
  put: {
    coachProfile: {
      private: true,
      path: "/api/coaches/me/profile",
    },
    coachBookingConfig: {
      private: true,
      path: "/api/coaches/me/booking-config",
    },
    personalUnavailableEvents: {
      private: true,
      path: "/api/coaches/me/personal-unavailability",
    },
  },
  delete: {
    deletePersonalUnavailability: {
      private: true,
      path: "/api/coaches/me/personal-unavailability/{id}",
    },
    deleteBookOnBehalf: {
      private: true,
      path: "/api/coaches/me/delete-book-on-behalf/{meeting_id}",
    }
  }
};
