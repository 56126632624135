import CoachProfileForm from "../../forms/CoachProfileForm";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "@hooks/useNavTitle";
import {useParams} from "react-router-dom";

const UpdateCoach = ({...props}) => {

    const {t} = useTranslation();
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {coachId} = useParams();

    return (
        <div className="m-5">
            <div className="w-full md:w-2/3 mx-auto">
                <div className="bg-white relative mt-24 flex flex-col rounded">
                    <CoachProfileForm coach_id={Number(coachId!)} mode='admin-update'/>
                </div>
            </div>
        </div>
    );
};

export default UpdateCoach;