import React, {useState} from "react";
import {Panel} from "rsuite";
import {createSchedule, createSummary, processChatPrompt, updateSchedule} from "../../actions";
import {useAICoaching} from "../../utils/AICoachingContext";
import moment from "moment/moment";
import DatePopup from "../DatePopup";
import {toast} from "react-hot-toast";
import {AIModel} from "../../enums";
import {useTranslation} from "react-i18next";
import Modal from "../../../../components/Modal";
import Loading from "./Loading";

type ChatEndActionsProps = {
    threadID: number
    journeyId?: number
    aiModel?: AIModel | null
    summaryAvailable?: boolean
    schedule?: any | null
};

const ChatEndActions = ({
                            summaryAvailable,
                            threadID,
                            journeyId,
                            schedule,
                            aiModel = AIModel.session
                        }: ChatEndActionsProps) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [summary, setSummary] = useState(null);

    const [isSLoading, setIsSLoading] = useState(false)
    const [isALoading, setIsALoading] = useState(false)
    const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false);

    const {
        state,
        setState,
        setIsLoading,
        setIsEndState,
        addMessage,
        setMessages,
        createNewThread,
        selectedThreadItem,
        setSelectedThreadItem,
        setCustomChatSection,
        parentThreadItem,
        voiceState,
        setVoiceState
    } = useAICoaching();

    // console.log(schedule, aiModel, selectedThreadItem);

    const {t} = useTranslation("ai_coaching");
    const handleCreateSummary = async () => {
        return await createSummary(threadID);

    }

    const spinSummaryMessages = [
        t("spin_message.analyzing_your_session_data"),
        t("spin_message.preparing_your_session_summary"),
        t("spin_message.finalizing_your_session_summary")
    ];

    const spinAMessages = [
        t("spin_message.just_a_moment"),
        t("spin_message.scheduling_your_session")
    ]

    const processSchedule = (date: Date) => {
        setIsALoading(true);
        setScheduleModalOpen(false);
        createSchedule(
            threadID,
            moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        )
            .then((res) => {
                if (res && res.datetime) {
                    const date = moment(res?.datetime).format("YYYY-MM-DD, HH:mm a");
                    toast.success(`Your session is scheduled on ${date}`)
                }
            }).catch(() => toast.error(`Sorry! Something went wrong.`)
        )
            .finally(() => setIsALoading(false));
    };
    if (isSLoading || isALoading) {
        return (
            <div className="flex flex-col w-full items-center justify-center h-full ">
                <Loading messages={isSLoading ? spinSummaryMessages : spinAMessages} intervalTime={1000}
                         spinner_size="32" center={true}/>
            </div>
        )
    }
    if (state == "practice") {
        return (
            <div className="flex flex-col w-full items-center justify-center h-full text-center">
                <div className="text-practice font-normal p-2">
                    {t("practice_success_message")}
                </div>
                <div onClick={() => {
                    setCustomChatSection(undefined)
                    if (parentThreadItem) setSelectedThreadItem({...parentThreadItem, isNew: false});
                    setState("thread");
                    setMessages([]);
                }}
                     className="flex flex-col gap-0.5 rounded-xl border-[1px] bg-red">
                    <p className="hover:text-gray-200 cursor-pointer font-dm_sans m-0 text-xs px-4 py-2 text-white font-semibold">
                        {t("continue_main")}
                    </p>
                </div>
            </div>
        );
    } else {
        return (<>
                {scheduleModalOpen && (
                    <Modal closeHandler={() => setScheduleModalOpen(false)} isOpen={scheduleModalOpen}>
                        <div className="p-8">
                            <DatePopup onSelectDate={processSchedule}/>
                        </div>
                    </Modal>
                )}
                <Panel collapsible expanded={isExpanded}>

                    <div className="flex flex-col w-full items-center gap-3 relative">
                        {(summaryAvailable || summary) ? <div
                            className="flex flex-col gap-0.5 rounded-xl border-[1px] bg-black"
                            onClick={() => {
                                setVoiceState("not-active")
                                setState("summary")
                            }}>
                            <p className="hover:text-gray-200 cursor-pointer font-dm_sans m-0 text-xs px-4 py-2 text-white font-semibold">
                                {t("chatbox.text1")}
                            </p>
                        </div> : <div
                            className="flex flex-col gap-0.5 rounded-xl border-[1px] bg-black"
                            onClick={() => {
                                if (voiceState === "active") {
                                    setVoiceState("switching");
                                }
                                setIsSLoading(true)
                                handleCreateSummary().then((res) => {
                                    console.log(res);
                                    setSummary(res)
                                    if (res) {
                                        setState("summary")
                                    } else {
                                        toast.error('Sorry!\n Our AI bot is busy. Please try again.')
                                    }
                                }).catch(() => toast.error('Sorry!\n Our AI bot is busy. Please try again.')
                                ).finally(() => {
                                    if (voiceState !== "not-active") {
                                        setVoiceState("summary");
                                    }
                                    setIsSLoading(false)
                                })
                            }}>
                            <p className="hover:text-gray-200 cursor-pointer font-dm_sans m-0 text-xs px-4 py-2 text-white font-semibold">
                                {t("chatbox.text2")}

                            </p>
                        </div>
                        }
                        {(aiModel == AIModel.journey || aiModel == AIModel.session) && (selectedThreadItem && !selectedThreadItem?.nextThread) &&
                            <div className="flex flex-col items-center">
                                {!schedule ? <div onClick={() => setScheduleModalOpen(true)}
                                                  className=" flex flex-col gap-0.5 rounded-xl border-[1px] bg-black">
                                        <p className="hover:text-gray-200 cursor-pointer font-dm_sans m-0 text-xs px-4 py-2 text-white font-semibold">
                                            {t("chatbox.text3")}
                                        </p>
                                    </div> :
                                    <p className="hover:text-gray-200 cursor-pointer font-dm_sans m-0 text-xs px-4 py-2 text-gray font-semibold">
                                        {t("chatbox.text4")} {moment(schedule.datetime).format("ddd, D MMM [at] hh.mm A")}
                                    </p>}
                                <br/>
                                <div onClick={async () => {
                                    setVoiceState("not-active")
                                    setCustomChatSection(undefined)
                                    setIsLoading(true);
                                    setIsEndState(true)
                                    if (schedule) await updateSchedule(schedule.id, true)
                                    const newThread = await createNewThread(AIModel.session, journeyId)
                                    const promptResponse = await processChatPrompt(newThread.id, '');
                                    setIsLoading(false);
                                    setIsEndState(false)
                                    setMessages([])
                                    addMessage({
                                        id: Math.random().toString(),
                                        content: promptResponse.reply,
                                        role: "assistant",
                                    });
                                }}
                                     className="flex flex-col gap-0.5 rounded-xl border-[1px] bg-black">
                                    <p className="hover:text-gray-200 cursor-pointer font-dm_sans m-0 text-xs px-4 py-2 text-white font-semibold">
                                        {t("chatbox.text5")}
                                    </p>
                                </div>
                            </div>}
                    </div>
                </Panel>
            </>
        );
    }
};

export default ChatEndActions;
