import React, {FC} from "react";
import {useField, FieldHookConfig} from "formik";
import style from "./InputFormik/InputFormik.module.css";
import {setClasses} from "../../../utils/helpers/css";

interface RadioOption {
    value: string;
    label: string;
}

interface Props {
    label?: string;
    options: RadioOption[];
}

const RadioFormik: FC<Props & FieldHookConfig<string>> = ({
                                                              label,
                                                              options,
                                                              ...props
                                                          }) => {
    const [field, meta] = useField(props);

    return (
        <div className={setClasses(style.wrapper, meta.touched && meta.error && style.error)}>
            <div className="flex items-center mb-2">
                {label && <label className={style.label}>{label}</label>}
                <div className="flex flex-row items-center ml-5">
                    {options.map((option) => (
                        <label key={option.value} className="flex items-center mr-6 relative cursor-pointer">
                            <input
                                type="radio"
                                {...field}
                                value={option.value}
                                checked={field.value === option.value}
                                className={style.radio + " absolute opacity-0 cursor-pointer"}
                            />
                            <span
                                className={style.customRadio + " w-5 h-5 border-2 border-gray-500 rounded-full inline-block mr-2"}>
                            {field.value === option.value &&
                                <span className="block w-3 h-3 bg-[#FD0054] rounded-full mx-auto"></span>}
                        </span>
                            <span>{option.label}</span>
                        </label>
                    ))}
                </div>
            </div>
            {meta.touched && meta.error ? (
                <span className="text-red">{meta.error}</span>
            ) : null}
        </div>
    );
};

export default RadioFormik;
