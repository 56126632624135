import React from 'react';
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {
    colourStyles,
    creatableSelectOptionSettings,
    multiSelectOptionSettings,
    selectOptionSettings
} from "./SettingCard";
import {ICompanySettings} from "../../../../../constants/types";
import {useTranslation} from "react-i18next";
import i18n from "../../../../../utils/lib/i18n";
import SettingENG from "../../../locale/eng/setting.json";
import SettingFR from "../../../locale/fr/setting.json";

interface Props {
    isEditing: boolean;
    companySetting: ICompanySettings;
    setSelectedValue: React.Dispatch<React.SetStateAction<any[]>>;
    selectedValue: any[];
    setSettingValue: React.Dispatch<React.SetStateAction<string>>
    value?: string;
}

const SettingOptionInput: React.FC<Props> = ({
                                                 isEditing,
                                                 companySetting,
                                                 setSelectedValue,
                                                 selectedValue,
                                                 setSettingValue,
                                                 value
                                             }) => {
    const {t} = useTranslation("setting");
    i18n.addResourceBundle("en", "setting", SettingENG);
    i18n.addResourceBundle("fr", "setting", SettingFR);

    const renderCreatableSelect = (isEditing: boolean) => (
        <CreatableSelect
            {...(multiSelectOptionSettings.includes(companySetting.key) ? {isMulti: true} : {})}
            closeMenuOnSelect={isEditing}
            isSearchable={isEditing}
            isClearable={false}
            {...!isEditing && {menuIsOpen: false}}
            placeholder="Select..."
            onChange={isEditing ? (value) => setSelectedValue(value as any[]) : undefined}
            value={selectedValue}
            options={(companySetting.options || []).map((option) => ({
                label: option,
                value: option,
            }))}
            styles={colourStyles}
            className="border border-gray-200 w-full rounded focus:outline-none text-base bg-white text-gray-600"
        />
    );

    const renderSelect = (isEditing: boolean) => {
        let options = (companySetting.options || []).map((option) => {
            if (typeof option === "boolean") {
                return {
                    label: option ? t("yes") : t("no"),
                    value: option,
                };
            } else if (typeof option === "object" && option !== null) {
                return {
                    label: option.value,
                    value: option.code,
                };
            }
            return {
                label: option,
                value: option,
            };
        });

        return <Select
            {...(multiSelectOptionSettings.includes(companySetting.key) ? {isMulti: true} : {})}
            closeMenuOnSelect={isEditing}
            isSearchable={isEditing}
            isClearable={false}
            {...!isEditing && {menuIsOpen: false}}
            onChange={isEditing ? (value) => setSelectedValue(value as any[]) : undefined}
            options={options}
            styles={colourStyles}
            placeholder="Select..."
            value={selectedValue}
            className="border border-gray-200 w-full rounded focus:outline-none text-base bg-white text-gray-600"
        />
    };

    return isEditing ? (
        creatableSelectOptionSettings.includes(companySetting.key)
            ? renderCreatableSelect(true)
            : [...multiSelectOptionSettings, ...selectOptionSettings].includes(companySetting.key)
                ? renderSelect(true)
                : <input
                    type={companySetting.type === "int" ? "number" : "text"}
                    className="w-full text-base font-normal bg-white text-gray-600 py-0.5 shadow-sm border border-gray-200 rounded text-center focus:outline-none"
                    onChange={(e) => {
                        setSettingValue(e.target.value);
                    }}
                    defaultValue={value}
                />
    ) : (
        creatableSelectOptionSettings.includes(companySetting.key)
            ? renderCreatableSelect(false)
            : [...multiSelectOptionSettings, ...selectOptionSettings].includes(companySetting.key)
                ? renderSelect(false)
                :
                <p className="w-full text-base font-normal bg-white text-gray-600 py-0.5 shadow-sm border border-gray-200 rounded text-center focus:outline-none">
                    {value ? value : "-"}
                </p>
    );
}

export default SettingOptionInput;
