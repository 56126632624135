import React from 'react';
import {AiOutlineClose} from "react-icons/ai";
import Button from "../../../../../shared/components/Button";
import ReactModal from "react-modal";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import {useTranslation} from "react-i18next";

interface Props {
    isValidatingCoachees: boolean;
    handleClose: () => void;
    enrolledCoachees: Record<any, any>[];
    handleConfirm: () => void;
    isSaving?: boolean;
}

const EnrolledUsersModal: React.FC<Props> = ({isValidatingCoachees, handleClose, enrolledCoachees, handleConfirm, isSaving}) => {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups:create_group");

    return <ReactModal
        isOpen={isValidatingCoachees}
        onRequestClose={handleClose}
        contentLabel="Enrolled User details"
        ariaHideApp={false}
        closeTimeoutMS={200}
        className="bg-white rounded-lg shadow-lg p-4 max-w-2xl mx-auto transition-transform transform-gpu ease-out duration-200"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
        {enrolledCoachees.length > 0 &&
            <>
                <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center gap-2">
                        <h2 className="text-sm font-semibold">{t("create_group.text50")}</h2>
                    </div>
                    <button onClick={handleClose}
                            className="text-gray-500 hover:text-gray-800 focus:outline-none">
                        <AiOutlineClose size={18}/>
                    </button>
                </div>
                <p className="pb-4">{t("create_group.text51")}</p>
                <div className="overflow-y-auto" style={{maxHeight: '60vh'}}>
                    <table
                        className="min-w-full bg-white border border-gray-200 text-xs text-gray-700 text-center">
                        <thead className="bg-gray-100">
                        <tr>
                            <th className="py-2 px-4 border-b">#</th>
                            <th className="py-2 px-4 border-b">{t("create_group.text2")}</th>
                            <th className="py-2 px-4 border-b">{t("create_group.text52")}</th>
                            <th className="py-2 px-4 border-b">{t("text0")}</th>
                            <th className="py-2 px-4 border-b">{`${t("create_group.text53")} / ${t("create_group.text54")}`}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {enrolledCoachees.map((user, index) => (
                            <tr key={index} className="hover:bg-gray-50 transition duration-150">
                                <td className="py-2 px-4 border-b">{index + 1}</td>
                                <td className="py-2 px-4 border-b">{user.name}</td>
                                <td className="py-2 px-4 border-b">{user.email}</td>
                                <td className="py-2 px-4 border-b"><span
                                    className="p-1.5 rounded text-center font-normal capitalize bg-warm-light">{user.group?.name}</span>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    {user.group?.ai_coach_type && user.group?.ai_coach_type.value === "ai_only" ?
                                        <div>_</div> :
                                        <div className="flex items-center gap-1">
                                            <span>{user.attended}</span> /
                                            <span>{user.allowed}</span>
                                        </div>
                                    }
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <p className="py-4">
                    {t("create_group.text55")}
                </p>
                <div className="relative flex flex-row justify-between w-full items-center">

                    <Button
                        className="w-36 flex justify-center text-black border-2 border-black text-xs font-bold h-9 px-4 hover:shadow-lg"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClose();
                        }}
                    >
                        {t("create_group.text32")}
                    </Button>

                    <Button
                        className="w-36 flex justify-center text-white border-2 border-red bg-red text-xs font-bold h-9 px-4 hover:shadow-xl"
                        onClick={handleConfirm}
                    >
                        {isSaving ? t("create_group.text39") : t("create_group.text56")}
                    </Button>

                </div>
            </>}
    </ReactModal>
};

export default EnrolledUsersModal;
