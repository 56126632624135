import React, {ReactElement, useEffect, useRef, useState} from "react";

import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import moment from "moment";
import {AxiosError} from "axios";

import Feedback360Emails from "../../../teams/pages/Feedback360Emails";
import Button from "../../../../shared/components/Button";
import ScrollBouncer from "./components/ScrollBouncer";
import StepIndicator from "../../../../shared/components/StepIndicator";
import ArrowRight from "../Home/components/ArrowRight";
import ConfirmBooking from "../../../coaching/pages/Coaching/components/ConfirmBooking";
import ChooseTimeSlot from "../../../coaching/pages/Coaching/components/ChooseTimeSlot";
import Coaches from "../../../coaching/pages/Coaching/components/Coaches";
import FrameModal from "../../../../shared/components/FrameModal";
import BookingConfirmed from "../../../coaching/pages/Coaching/components/BookingConfirmed";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import AssessmentStep from "./components/AssessmentStep";
import ReflectionEmailStep from "./components/ReflectionEmailStep";
import ObjectiveStep from "../../components/ObjectiveStep";

import useGuideStatus from "../../../../utils/hooks/useGuideStatus";
import useAuth from "../../../../utils/hooks/useAuth";
import {formatUTC} from "../../../../utils/helpers/time";
import {createBooking} from "../../../../services/api/coachApi";
import {getAssessmentScore} from "../../../../services/api/teams/AssessmentActions";
import {setClientObjectives, setGuideStatus,} from "../../../../services/api/teams/GuideActions";
import {getCoachesConsole} from "../../../../services/api/companyApi";
import selfReflectionData from "../../../../assets/selfReflection.json";
import WelcomeENG from "../../locale/eng/welcome.json";
import WelcomeFR from "../../locale/fr/welcome.json";
import i18n from "../../../../utils/lib/i18n";
import {ILanguageSchema, useConfig} from "../../../../utils/providers/AppConfigProvider";

import {TAssessmentScore, TConfirmBookingForm, TSelfReflection,} from "../../../../constants/types";
import {EConfirmBookingFormField} from "../../../../constants/enums";
import ICoach from "../../../../models/ICoach";
import {IOccupiedEvents, ITimeSlot} from "../../../../models/IBooking";
import {IMeta} from "../../../../models/IMeta";
import PreferenceSection from "./components/PreferenceSection";
import {setClientPreference} from "../../../../services/api/clientApi";

const OBJECTIVES_STEP_NO = 7;
const CHOOSE_TIME_SLOT_STEP_NO = 9;
const CHOOSE_COACH_STEP_NO = 8;

type OnboardingStep = {
    step: number;
    component: ReactElement;
    handleNext?: () => void;
    backButton?: ReactElement;
    nextButton?: ReactElement;
};

type SpecificStepNumbers = {
    OBJECTIVES_STEP_NO: number;
    CHOOSE_TIME_SLOT_STEP_NO: number;
    CHOOSE_COACH_STEP_NO: number;
};

interface PreferencesState {
    selectedLanguage: IMeta | undefined;
    consentAssessmentData: boolean;
    consentFlashFeedback: boolean;
}

const Onboarding = () => {
    i18n.addResourceBundle("en", "welcome", WelcomeENG);
    i18n.addResourceBundle("fr", "welcome", WelcomeFR);
    const lng = localStorage.getItem("I18N_LANGUAGE") || "en";
    const {user} = useAuth();
    const {config, fetchConfig} = useConfig();
    const externalVisit = !user;
    const feedback360EmailsRef =
        useRef<React.ElementRef<typeof Feedback360Emails>>(null);
    const frameModalRef = useRef<React.ElementRef<typeof FrameModal>>(null);

    const {state}: any = useLocation();
    const step = state && state.statusData ? state.statusData.step : 0;

    const navigate = useNavigate();
    const {t} = useTranslation("welcome");
    const guideStatus = useGuideStatus();

    const [visibleStep, setVisibleStep] = useState(1);

    const [specificStepNumbers, setSpecificStepNumbers] = useState<
        SpecificStepNumbers | {}
    >({});

    const [selfReflectionQuizDone, setSelfReflectionQuizDone] = useState(false);

    const [feedbackEmails, setFeedbackEmails] = useState<string[]>([]);

    const [selectedObjectives, setSelectedObjectives] = useState<IMeta[]>([]);
    const [assessmentScore, setAssessmentScore] = useState<TAssessmentScore | {}>(
        {},
    );

    const [coaches, setCoaches] = useState<ICoach[]>([]);
    const [selectedCoach, setSelectedCoach] = useState<ICoach | null>(null);

    const [timeSlot, setTimeSlot] = useState<ITimeSlot | null>(null);
    const [occupiedEvents, setOccupiedEvents] = useState<IOccupiedEvents>({});

    const [step7Validated, setStep7Validated] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [preferences, setPreferences] = useState<PreferencesState>({
        selectedLanguage: undefined,
        consentAssessmentData: false,
        consentFlashFeedback: false
    });

    const setSelectedLanguage = (language: IMeta | undefined) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            selectedLanguage: language
        }));
    };

    const setConsentAssessmentData = (consent: boolean) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            consentAssessmentData: consent
        }));
    };

    const setConsentFlashFeedback = (consent: boolean) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            consentFlashFeedback: consent
        }));
    };

    const [scrollBouncerVisible, setScrollBouncerVisibility] = useState(false);

    const [confirmBookingForm, setConfirmBookingForm] =
        useState<TConfirmBookingForm>({
            noteForCoach: {
                value: "",
                error: false,
                errorText: t("note_is_required"),
            },
        });

    const [tripartiteEmail, setTripartiteEmail] = useState({
        value: "",
        error: false,
        errorText: t("email_of_the_manager_is_required"),
    })

    const [isOrientation, setIsOrientation] = useState(false);

    const [isFirstSession, setIsFirstSession] = useState(false);

    const company_id = user?.companyId;
    const client_id = user?.id;
    const flash_feedback_required = config?.flash_feedback_required;

    useEffect(() => {
        guideStatus.checkStatus(() => {
            navigate("/");
        });

        setSelectedCoach(JSON.parse(localStorage.getItem("coach") as string));
        setSelfReflectionQuizDone(
            JSON.parse(localStorage.getItem("selfReflectionQuizDone") as string),
        );
        const persistedObjectives = localStorage.getItem("objectives") as string;
        setSelectedObjectives(
            persistedObjectives ? JSON.parse(persistedObjectives) : [],
        );
    }, []);

    useEffect(() => {
        if (!config || Object.keys(specificStepNumbers).length) return;
        let stepDeduction = 0;
        if (!config.client_preferences_screen) stepDeduction += 1;
        if (!config.self_reflection) stepDeduction += 1;
        if (!config.flash_reflection) stepDeduction += 1;

        setSpecificStepNumbers({
            OBJECTIVES_STEP_NO: OBJECTIVES_STEP_NO - stepDeduction,
            CHOOSE_TIME_SLOT_STEP_NO: CHOOSE_TIME_SLOT_STEP_NO - stepDeduction,
            CHOOSE_COACH_STEP_NO: CHOOSE_COACH_STEP_NO - stepDeduction,
        });
    }, [config]);

    useEffect(() => {
        // const chooseTimeSlotStepNo = (specificStepNumbers as SpecificStepNumbers)
        //     .CHOOSE_TIME_SLOT_STEP_NO;
        const chooseCoachStepNo = (specificStepNumbers as SpecificStepNumbers)
            .CHOOSE_COACH_STEP_NO;
        setVisibleStep(step >= chooseCoachStepNo ? chooseCoachStepNo : step);
    }, [step]);

    useEffect(() => {
        setVisibleStep(step >= CHOOSE_COACH_STEP_NO ? CHOOSE_COACH_STEP_NO - 1 : step);
    }, []);

    const handleConfirmBooking = async (is_consented: boolean = false) => {
        const errorField = Object.keys(confirmBookingForm).find((key) => {
            const fieldName = key as EConfirmBookingFormField;
            return !externalVisit
                ? key !== EConfirmBookingFormField.firstName &&
                key !== EConfirmBookingFormField.lastName &&
                confirmBookingForm[fieldName]?.value === ""
                : confirmBookingForm[fieldName]?.value === "";
        }) as EConfirmBookingFormField;

        let hasError = false;

        if ((config?.tripartie_email_required) && (tripartiteEmail.value === undefined || tripartiteEmail.value === "")) {
            if (!isOrientation && isFirstSession) {
                setTripartiteEmail({
                    value: "",
                    error: true,
                    errorText: t("email_of_the_manager_is_required"),
                });
                hasError = true;
            }
        }

        if (errorField && errorField !== EConfirmBookingFormField.noteForCoach) {
            setConfirmBookingForm({
                ...confirmBookingForm,
                [errorField]: {
                    ...confirmBookingForm[errorField],
                    error: true,
                },
            });
            hasError = true;
        }

        if (hasError) {
            return;
        }

        try {
            const bookingData = contructBookingData(
                timeSlot,
                selectedCoach,
                confirmBookingForm.noteForCoach?.value,
                is_consented,
                undefined,
                tripartiteEmail.value,
            );
            console.log(bookingData);
            setIsLoading(true);

            const res = await createBooking(bookingData);
            if (res.error === false) {
                handleStepNavigation(true, true);
                localStorage.removeItem("coach");
                localStorage.removeItem("objectives");
                localStorage.removeItem("selfReflectionQuizDone");
            } else {
                toast.error(t("slot_occupied"));
            }
        } catch (err: any) {
            const e = err as AxiosError<any>;
            toast.error(e?.response?.data.message);
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetAssessmentScore = async () => {
        const res = await getAssessmentScore(+client_id!);
        setAssessmentScore(res);
    };

    const contructBookingData = (
        timeSlot: ITimeSlot | null,
        coach: ICoach | null,
        note?: string,
        is_consented?: boolean,
        meetingId?: number | null,
        tripartiteEmail?: string | undefined,
    ) => {
        const startTime = moment(
            new Date(`${timeSlot?.time.date}T${timeSlot?.time.start}`).toUTCString(),
        );
        const endTime = moment(
            new Date(`${timeSlot?.time.date}T${timeSlot?.time.end}`).toUTCString(),
        );

        return {
            user: {
                first_name: user?.firstName,
                last_name: user?.lastName,
                user_comment: note,
                email: user?.email,
                medium: "teams",
            },
            event: {
                start_time: formatUTC(startTime.format("YYYY-MM-DD HH:mm")),
                end_time: formatUTC(endTime.format("YYYY-MM-DD HH:mm")),
            },
            company_id: user?.companyId,
            coach_id: coach?.id,
            user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            coach_time_zone: timeSlot?.coachTimezone,
            is_consented,
            reschedule: !!meetingId,
            meeting_id: meetingId ? meetingId : undefined,
            tripartite_email: tripartiteEmail,
            orientation: coach?.orientation

        };
    };

    const handleSetConfirmBookingForm = (key: string, value: string) => {
        const updatedForm: any = {...confirmBookingForm};
        if (value === "") {
            updatedForm[key] = {
                ...updatedForm[key],
                value,
                error: true,
            };
        } else {
            updatedForm[key] = {...updatedForm[key], value, error: false};
        }
        setConfirmBookingForm(updatedForm);
    };

    const handleSaveClientObjectives = () => {
        const objectiveData = {
            objectives: selectedObjectives,
        };
        setIsLoading(true);
        setClientObjectives(objectiveData)
            .then(() => {
                handleStepNavigation(true);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSetTripartiteEmail = (email: string | undefined) => {
        if (config?.tripartie_email_required && (email === "" || email == undefined) && isFirstSession) {
            return setTripartiteEmail({
                value: "",
                error: true,
                errorText: t("email_of_the_manager_is_required"),
            })
        }
        setTripartiteEmail({value: email!, error: false, errorText: ""});
    }

    const handleStepNavigation = (
        nextStep?: boolean,
        guideComplete?: boolean,
    ) => {
        const stepNo = visibleStep + (nextStep ? +1 : -1);
        if (!stepNo) {
            return navigate("/dashboard/welcome");
        }
        if (stepNo > stepArr.length) return;
        setGuideStatus(stepNo, guideComplete)
            .then(() => {
                setVisibleStep(() => stepNo);
            })
            .catch((e) => console.log(e));
    };

    const getConfigDescription = (configSetting?: ILanguageSchema) => {
            if (lng === "fr") {
                return configSetting?.fr
            } else {
                return configSetting?.en
            }
    }

    const stepArr: OnboardingStep[] = [
        {
            step: 1,
            component: <Step1 description={getConfigDescription(config?.onboarding_journey_screen_des)}/>,
            handleNext: () => handleStepNavigation(true),
        },
        {
            step: 1,
            component: <Step2 description={getConfigDescription(config?.onboarding_method_screen_des)}/>,
            handleNext: () => handleStepNavigation(true),
        },
        {
            step: 1,
            component: <Step3 description={getConfigDescription(config?.onboarding_functionality_screen_des)}/>,
            handleNext: () => handleStepNavigation(true),
        },
        {
            step: 2,
            component: (
                <PreferenceSection
                    onSetSelectedLanguage={setSelectedLanguage}
                    onSetConsentAssessmentData={setConsentAssessmentData}
                    onSetConsentFlashFeedback={setConsentFlashFeedback}
                />
            ),
            nextButton: (
                <Button
                    className={`min-w-[144px] flex justify-center text-xs font-bold border-2 px-4 h-9 border-black bg-black text-white`}
                    disabled={!preferences.selectedLanguage}
                    onClick={() => {
                        setIsLoading(true);
                        const payload = {
                            preferred_language: preferences.selectedLanguage,
                            client_preference: [
                                {
                                    preference_key: "assessment_data_consent",
                                    preference_value: preferences.consentAssessmentData
                                },
                                {
                                    preference_key: "flash_feedback_consent",
                                    preference_value: preferences.consentFlashFeedback
                                }
                            ]
                        };
                        setClientPreference(payload)
                            .then(() => {
                                fetchConfig(user);
                                handleStepNavigation(true);
                            })
                            .catch((err) => console.log(err))
                            .finally(() => {
                                setIsLoading(false);
                            });
                    }}
                >
                    {t("next")}
                </Button>
            ),
        },
        {
            step: 2,
            component: (
                <AssessmentStep
                    onSubmitAssessment={() => {
                        localStorage.setItem(
                            "selfReflectionQuizDone",
                            JSON.stringify(true),
                        );
                        setSelfReflectionQuizDone(true);
                    }}
                    description={getConfigDescription(config?.onboarding_self_reflection_screen_des)}
                />
            ),
            nextButton: (
                <Button
                    className={`w-36 flex justify-center ${selfReflectionQuizDone ? "text-white bg-black" : "text-black"} border-2 border-black text-xs font-bold h-9 px-4`}
                    onClick={() => handleStepNavigation(true)}
                >
                    {selfReflectionQuizDone ? t("next") : t("skip")}
                </Button>
            ),
        },
        {
            step: 2,
            component: (
                <ReflectionEmailStep
                    onChangeEmails={(emails) => setFeedbackEmails(emails)}
                    feedback360EmailsRef={feedback360EmailsRef}
                    description={getConfigDescription(config?.onboarding_peer_reflection_screen_des)}
                />
            ),
            nextButton: (
                <Button
                    className={`min-w-[144px] flex justify-center text-xs font-bold border-2 px-4 h-9 border-black ${feedbackEmails.length ? "bg-black text-white" : "text-black"
                    }`}
                    onClick={() => {
                        if (!feedbackEmails.length) {
                            return handleStepNavigation(true);
                        }
                        setIsLoading(true);
                        feedback360EmailsRef.current
                            ?.sendNotify()
                            .then(() => handleStepNavigation(true))
                            .finally(() => {
                                setIsLoading(false);
                            });
                    }}
                    disabled={!feedbackEmails.length && flash_feedback_required}
                    loading={isLoading}
                >
                    {(feedbackEmails.length || flash_feedback_required) ? t("send_and_next") : t("skip")}
                </Button>
            ),
        },

        {
            step: 2,
            component: (
                <ObjectiveStep
                    selfReflections={selfReflectionData as TSelfReflection[]}
                    assessmentScore={assessmentScore}
                    handleGetAssessmentScore={handleGetAssessmentScore}
                    onValidated={setStep7Validated}
                    selectedObjectives={selectedObjectives}
                    setSelectedObjectives={setSelectedObjectives}
                    setScrollBouncerVisibility={(visible: boolean) =>
                        setScrollBouncerVisibility(visible)
                    }
                />
            ),
            nextButton: (
                <Button
                    className={`min-w-[144px] flex justify-center text-xs font-bold border-2 border-black px-4 h-9 ${step7Validated ? "bg-black text-white" : "text-black"
                    }`}
                    onClick={async () => {
                        if (!step7Validated) return;
                        handleSaveClientObjectives();
                    }}
                >
                    {t("next")}
                </Button>
            ),
        },
        {
            step: 3,
            component: (
                <Coaches
                    onMount={async () => {
                        setIsLoading(true);
                        const res = await getCoachesConsole(+company_id!, client_id);
                        setCoaches(res);
                        const foundReservedCoach = res.find((coach: any) => coach.reserved);
                        setIsFirstSession(!foundReservedCoach)
                        setIsLoading(false);
                    }}
                    coaches={coaches}
                    selectedCoach={selectedCoach}
                    handleSelectCoach={(coach: ICoach, orientation?: boolean) => {
                        localStorage.setItem("coach", JSON.stringify(coach));
                        setOccupiedEvents({});
                        setTimeSlot(null);
                        setSelectedCoach(coach);
                        setIsOrientation(orientation || false);
                        handleStepNavigation(true);
                    }}
                    playCoachVideo={(url: string, title: string) => {
                        frameModalRef.current?.open(url, title);
                    }}
                    isOnboarding
                />
            ),
            handleNext: () => {
                if (!selectedCoach) return;
                handleStepNavigation(true);
            },
        },
        {
            step: 3,
            component: (
                <ChooseTimeSlot
                    selectedCoach={selectedCoach}
                    timeSlot={timeSlot}
                    setTimeSlot={setTimeSlot}
                    occupiedEvents={occupiedEvents}
                    setOccupiedEvents={setOccupiedEvents}
                    isOnboarding
                    onGetOccupiedEventsFail={() => {
                        toast.error(t("choose_time_slot.unable_to_fetch_availability"));
                        handleStepNavigation();
                    }}
                />
            ),
            handleNext: () => {
                if (!timeSlot || !timeSlot.time) return;
                handleStepNavigation(true);
            },
        },
        {
            step: 3,
            component: (
                <ConfirmBooking
                    selectedCoach={selectedCoach}
                    timeSlot={timeSlot}
                    confirmBookingForm={confirmBookingForm}
                    handleSetConfirmBookingForm={handleSetConfirmBookingForm}
                    handleConfirmBooking={handleConfirmBooking}
                    externalVisit={externalVisit}
                    tripartiteEmail={tripartiteEmail}
                    setTripartiteEmail={isOrientation ? undefined : handleSetTripartiteEmail}
                    isOnboarding
                />
            ),
            nextButton: (
                <Button
                    className="min-w-[144px] flex justify-center text-xs font-bold border-2 border-black px-12 h-9 bg-black text-white"
                    onClick={() => {
                        handleConfirmBooking();
                    }}
                    loading={isLoading}
                >
                    {t("finish")}
                </Button>
            ),
        },
        {
            step: 4,
            component: <BookingConfirmed/>,

            backButton: <div></div>,
            nextButton: (
                <Button
                    className="min-w-[144px] flex justify-center text-xs font-bold border-2 border-black px-4 h-9 bg-black text-white"
                    onClick={() => navigate("/dashboard/home")}
                    loading={isLoading}
                >
                    {t("home")} <ArrowRight/>
                </Button>
            ),
        },
    ].reduce((steps, currVal, index) => {
        if (!config) return steps;

        if (!config.client_preferences_screen && index === 3) return steps;

        if (!config.self_reflection && index === 4) return steps;

        if ((!config.flash_reflection && user?.companyName !== "Temenos") && index === 5) return steps;

        return [...steps, currVal];
    }, [] as OnboardingStep[]);

    const handleContentScroll = (e: any) => {
        if (e.target.scrollTop === 0) setScrollBouncerVisibility(true);
        const bottom =
            e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) setScrollBouncerVisibility(false);
    };

    if (!visibleStep || !stepArr.length) return <></>;

    const backButton = stepArr[visibleStep - 1].backButton;
    const nextButton = stepArr[visibleStep - 1].nextButton;

    return (
        <div
            className="flex flex-col items-start h-full px-4 py-5 mx-auto sm:px-16 lg:px-20 max-w-8xl bg-linen_gradient ">
            <FrameModal ref={frameModalRef}/>

            <div className="flex flex-col items-start h-full w-full">
                <StepIndicator
                    currentStep={stepArr[visibleStep - 1].step}
                    totalSteps={4}
                    orientation={selectedCoach?.orientation && (visibleStep === 8 || visibleStep === 9)}
                />
                <div
                    className="flex flex-col flex-1 overflow-scroll w-full scrollbar-hide"
                    onScroll={handleContentScroll}
                >
                    {stepArr[visibleStep - 1].component}
                </div>
                <div className="relative flex flex-row justify-between w-full items-center">
                    {visibleStep ===
                        (specificStepNumbers as SpecificStepNumbers).OBJECTIVES_STEP_NO &&
                        scrollBouncerVisible && <ScrollBouncer/>}
                    {backButton ? (
                        backButton
                    ) : (
                        <Button
                            className="w-36 flex justify-center text-black border-2 border-black text-xs font-bold h-9 px-4"
                            onClick={() => handleStepNavigation()}
                        >
                            {t("back")}
                        </Button>
                    )}
                    {nextButton ? (
                        nextButton
                    ) : (
                        <Button
                            className="w-36 flex justify-center text-white border-2 border-black bg-black text-xs font-bold h-9 px-4"
                            onClick={stepArr[visibleStep - 1].handleNext}
                            loading={isLoading}
                        >
                            {t("next")}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Onboarding;
