import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
    currentComponentId,
    COMPONENTS_KEY,
    ACTION,
    ReactiveCalanderProps,
} from "../Constants/utilities";
import "../CalanderSomeCss/Calander.css";
import {useEffect, useRef, useState, useCallback} from "react";
import {
    DateTableHeader,
} from "../Constants/ConstantsComponents";
import Modal from "../../components/Modal";
import {mappingToBeComponents} from "../ModalComponents/ModalComponentMapping";
import React from "react";
import moment from "moment";
import {isValidController} from "../ModalComponents/SettingsModal/controller";
import {toast} from "react-toastify";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/events.json";
import Fr from "../../locale/fr/events.json";
import {useTranslation} from "react-i18next";
import {
    getCoachEvents,
    deletePersonalUnavailability,
    deleteBookOnBehalf,
    getClientEvent
} from "../../../../services/api/coachApi";
import {EventInput} from "@fullcalendar/core";
import EventContent from "./Components/EventContent";
import LoaderContainer from "../../../../shared/components/LoaderContainer";
import {CalendarIcon, RefreshIcon} from "@heroicons/react/outline";
import LoadingScreen from "../../components/LoadingScreen";
import useAuth from "@hooks/useAuth";
import CalendarsDropdown from "./Components/CalendarsDropdown";
import DatePicker from "react-datepicker";

const ReactiveCalander: React.FC<ReactiveCalanderProps> = ({
                                                               events,
                                                               dispatch,
                                                               customSetting,
                                                               settingDispatch
                                                           }: ReactiveCalanderProps) => {
    i18n.addResourceBundle("en", "events", ENG);
    i18n.addResourceBundle("fr", "events", Fr);
    const {user} = useAuth();
    const {t} = useTranslation("events");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currentKey, setCurrentKey] = useState<any>(currentComponentId);
    const [dateArgs, setDateArgs] = useState<object>({});
    const calendarRef = useRef<any>();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [currentView, setCurrentView] = useState<string>(ACTION.TIME_GRID_WEEK);
    const [occupiedEvents, setOccupiedEvents] = useState<{ coachello_events: EventInput[], personal_unavailability_events: EventInput[], other_busy_events: EventInput[] }>({
        coachello_events: [],
        personal_unavailability_events: [],
        other_busy_events: []
    });
    const [eventAdded, setEventAdded] = useState(false);
    const [isBooking, setIsBooking] = useState(false);
    const [isRescheduling, setIsRescheduling] = useState(false);
    const [reschedulingMeetingDetails, setReschedulingMeetingDetails] = useState<any>({});
    const [connectedCalendars, setConnectedCalendars] = useState([]);

    useEffect(() => {
        const start = moment.utc(calendarRef.current.getApi().currentData.dateProfile.currentRange.start).format("YYYY-MM-DD")
        const end = moment.utc(calendarRef.current.getApi().currentData.dateProfile.currentRange.end).subtract(1, "days").format("YYYY-MM-DD")
        fetchCoachEvents(start, end);
        setEventAdded(false);
    }, [currentDate, currentView, eventAdded])

    const transformCoachelloEvents = (eventsData: any) => {
        return Object.entries(eventsData).flatMap(([date, events]) => {
            return (events as any[]).map((event: any): EventInput => {
                return {
                    id: event.id,
                    start: `${date}T${event.slot.split('-')[0]}`,
                    end: `${date}T${event.slot.split('-')[1]}`,
                    title: event.company_name,
                    description: event.description,
                    backgroundColor: "#f9d3d6",
                    borderColor: "#fd0054",
                    textColor: "#fd0054",
                    bookedByCoach: event.booked_by_coach
                };
            });
        });
    };

    const transformOtherEvents = (eventsData: any) => {
        return Object.entries(eventsData).flatMap(([date, events]) => {
            return (events as any[]).map((event: any): EventInput => {
                return {
                    start: `${date}T${event.split('-')[0]}`,
                    end: `${date}T${event.split('-')[1]}`,
                    title: "Other Events",
                    display: "background",
                };
            });
        });
    };

    const transformPersonalUnavailability = (eventsData: any) => {
        return Object.entries(eventsData).flatMap(([date, events]) => {
            return (events as any[]).map((event: any): EventInput => {
                return {
                    id: `${event.id}_${date}`,
                    start: `${date}T${event.slot.split('-')[0]}`,
                    end: `${date}T${event.slot.split('-')[1]}`,
                    title: event.title,
                    type: "personal_unavailability",
                    backgroundColor: "#ddebc9",
                    borderColor: "#b4e1c6"
                };
            });
        });
    };

    const handleUpdateSetting = () => {
        const start = moment.utc(calendarRef.current.getApi().currentData.dateProfile.currentRange.start).format("YYYY-MM-DD")
        const end = moment.utc(calendarRef.current.getApi().currentData.dateProfile.currentRange.end).subtract(1, "days").format("YYYY-MM-DD")
        fetchCoachEvents(start, end)
    }

    const fetchCoachEvents = useCallback(async (start: string, end: string) => {
        setIsLoading(true);
        try {
            const res = await getCoachEvents(start, end);
            if (res.error) return;
            setConnectedCalendars(res.connected_calendars);
            const transformedCoachelloEvents = transformCoachelloEvents(res.data.client_meetings);
            const transformedOtherEvents = transformOtherEvents(res.data.other_busy_events);
            const transformedPersonalUnavailabilityEvents = transformPersonalUnavailability(res.data.personal_unavailability_events);
            setOccupiedEvents({
                coachello_events: transformedCoachelloEvents,
                personal_unavailability_events: transformedPersonalUnavailabilityEvents,
                other_busy_events: transformedOtherEvents
            });
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    function filterEventsByDate(date: Date, busyEvents: any[]): any[] {
        return busyEvents.filter(event => {
            const eventStart = new Date(event.start);
            return eventStart.toDateString() === date.toDateString();
        });
    }

    function isTimeSlotAvailable(tempObj: { start: string; end: string }, busyEvents: any[]): boolean {
        const tempStart = moment(tempObj.start);
        const tempEnd = moment(tempObj.end);

        for (let event of busyEvents) {
            const eventStart = moment(event.start);
            const eventEnd = moment(event.end);

            if (tempStart.isBetween(eventStart, eventEnd, undefined, '[)') ||
                tempEnd.isBetween(eventStart, eventEnd, undefined, '(]') ||
                tempStart.isSameOrBefore(eventStart) && tempEnd.isSameOrAfter(eventEnd)) {
                return false;
            }
        }
        return true;
    }

    const handleDateClick = (arg: any) => {
        setCurrentKey(COMPONENTS_KEY.modalContainer);

        let startTime = arg?.start;
        let endTime = arg?.end;

        if (startTime.getUTCHours() === 18 && startTime.getUTCMinutes() === 30 &&
            endTime.getUTCHours() === 18 && endTime.getUTCMinutes() === 30 &&
            startTime.getUTCDate() + 1 === endTime.getUTCDate() &&
            startTime.getUTCMonth() === endTime.getUTCMonth() &&
            startTime.getUTCFullYear() === endTime.getUTCFullYear()) {
            startTime = new Date(
                Date.UTC(endTime.getUTCFullYear(), endTime.getUTCMonth(), endTime.getUTCDate(), 1, 30)
            );
            endTime = new Date(
                Date.UTC(endTime.getUTCFullYear(), endTime.getUTCMonth(), endTime.getUTCDate(), 2, 30)
            );
        } else {
            startTime = arg?.start;
            endTime = arg?.end;
        }

        const tempObj = {
            start: startTime,
            end: endTime,
        };

        const dateForFuture = (customSetting as any).daysOfFuture || 100000;
        let afterFollowingDays = moment((customSetting as any).dateOfSubmitted).add(dateForFuture - 1, 'days');

        let errorMessage = t("coach_unavailable_message");
        if (startTime < new Date()) {
            errorMessage = t("previous_date_error")
        } else if (startTime > afterFollowingDays) {
            errorMessage = t("future_date_error")
        }

        const filteredEvents = filterEventsByDate(new Date(tempObj.start), occupiedEvents.other_busy_events);
        const isAvailable = isTimeSlotAvailable(tempObj, filteredEvents);

        if (!isAvailable) {
            toast.error(t("coach_unavailable_message"));
        } else if (customSetting && Object.keys(customSetting).length > 0) {
            if (isValidController(tempObj, customSetting, t)) {
                setDateArgs(tempObj);
                setIsOpen(true);
            } else {
                toast.error(errorMessage);
            }
        } else {
            setDateArgs(tempObj);
            setIsOpen(true);
        }
    }

    const handleSettingsClick = (arg: any) => {
        setCurrentKey(COMPONENTS_KEY.settingsModal);
        setIsOpen(true);
    };

    const handleNextPrevChangeViewBtn = (action: string) => {
        const api = calendarRef.current.getApi();
        if (action === ACTION.NEXT) {
            api.next();
            setCurrentDate(api?.currentData?.currentDate)
        }
        if (action === ACTION.PREV) {
            api.prev();
            setCurrentDate(api?.currentData?.currentDate)
        } else {
            if (action === ACTION.DAY_GRID_MONTH) {
                api.changeView(action)
                setCurrentDate(api?.currentData?.currentDate)
                setCurrentView(action);
            } else if (action === ACTION.TIME_GRID_WEEK) {
                api.changeView(action)
                setCurrentDate(api?.currentData?.currentDate)
                setCurrentView(action);
            } else if (action === ACTION.TIME_GRID_DAY) {
                api.changeView(action)
                setCurrentDate(api?.currentData?.currentDate)
                setCurrentView(action);
            }
        }
    }

    const handleHeaderTitle = () => {
        const locale = i18n.language || 'en';
        const format = locale === 'fr' ? "dddd, D MMMM" : "dddd, DD of MMMM";

        const timezoneOffsetMinutes = currentDate.getTimezoneOffset();

        if (timezoneOffsetMinutes < 0) {
            const localFormattedTime = moment(currentDate).format("YYYY-MM-DD HH:mm:ss");
            const utcFormattedTime = moment.utc(currentDate).format("YYYY-MM-DD HH:mm:ss");

            const isTimeEqualToTimezone = localFormattedTime === utcFormattedTime;
            const formattedDate = isTimeEqualToTimezone
                ? moment.utc(currentDate).format(format)
                : moment(currentDate).format(format);

            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        } else {
            const isTimeEqualToTimezone = currentDate.getTime() === new Date(currentDate.toISOString()).getTime();
            const formattedDate = isTimeEqualToTimezone
                ? moment.utc(currentDate).format(format)
                : moment(currentDate).format(format);

            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        }
    };

    const handleDeleteEvent = (id: number, personalUnavailabilityEvent?: boolean) => {
        setIsBooking(true);
        if (personalUnavailabilityEvent) {
            deletePersonalUnavailability(id).then(() => {
                toast.success(t("delete_success_message"));
            }).catch(() => {
                toast.error(t("delete_failed_message"));
            }).finally(() => {
                setIsBooking(false);
            })
        } else {
            deleteBookOnBehalf(id).then(() => {
                toast.success(t("delete_success_message"));
            }).catch(() => {
                toast.error(t("delete_failed_message"));
            }).finally(() => {
                setIsBooking(false);
            })
        }
        setTimeout(() => {
            handleUpdateSetting();
        }, 1000);
    }

    const handleReschedule = (meetingId: number) => {
        let tempObj;
        getClientEvent(user!.id, meetingId).then((res) => {
            tempObj = {
                start: res.event_start_time,
                end: res.event_end_time,
            };
            setReschedulingMeetingDetails(res);
            setDateArgs(tempObj);
            setIsRescheduling(true);
            setCurrentKey(COMPONENTS_KEY.modalContainer);
            setIsOpen(true);
        }).catch((e) => {
            console.error(e);
        });
    }

    const handleDateChange = (date: Date) => {
        setCurrentDate(date);

        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.gotoDate(date);
        }
    };

    return (
        <div className="h-1/4 p-5 bg-[#F8F5F1]">
            <div className="w-full flex justify-center">
                <div className="w-full flex flex-col md:flex-row justify-between items-center px-2 pt-2 pb-6">
                    <div className="flex flex-col sm:flex-row items-center">
                        <div className="title pb-2 sm:pb-0">
                            <h3 className="font-bold text-lg sm:text-xl">{handleHeaderTitle()}</h3>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="px-2 sm:px-10 flex gap-2">
                                <button onClick={() => handleNextPrevChangeViewBtn(ACTION.PREV)}
                                        className="btn custom-arrow-icon-btn"><i className="fa fa-angle-left "></i>
                                </button>
                                <div className="z-10">
                                    <DatePicker
                                        selected={currentDate}
                                        onChange={handleDateChange}
                                        customInput={
                                            <button className="btn custom-arrow-icon-btn">
                                                <CalendarIcon className="h-6 w-6 text-black hover:text-red"/>
                                            </button>
                                        }
                                    />
                                </div>
                                <button onClick={() => handleNextPrevChangeViewBtn(ACTION.NEXT)}
                                        className="btn custom-arrow-icon-btn"><i className="fa fa-angle-right "></i>
                                </button>
                            </div>

                            <div className="inline-flex rounded-md shadow-sm" role="group">
                                {/*<button onClick={() => handleNextPrevChangeViewBtn(ACTION.DAY_GRID_MONTH)} className={`btn calander-custom-btn border-2 rounded-l-lg ${ACTION.DAY_GRID_MONTH === currentView ? "text-red-500 border-red-500" : "text-black border-black"}`}> {t("view_names.month")}</button>*/}
                                <button onClick={() => handleNextPrevChangeViewBtn(ACTION.TIME_GRID_WEEK)}
                                        className={`btn calander-custom-btn border-y-2 border-l-2 rounded-l-lg ${ACTION.TIME_GRID_WEEK === currentView ? "text-red-500 border-red-500" : "text-black border-black"}`}> {t("view_names.week")}</button>
                                <button onClick={() => handleNextPrevChangeViewBtn(ACTION.TIME_GRID_DAY)}
                                        className={`btn calander-custom-btn border-2 rounded-r-lg ${ACTION.TIME_GRID_DAY === currentView ? "text-red-500 border-red-500" : "text-black border-black"}`}> {t("view_names.day")}</button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2 md:mt-0">
                        <div className="flex flex-col md:flex-row justify-end items-center gap-3">
                            <div className="flex items-center justify-center">
                                <div
                                    className="h-auto gap-2 flex items-center justify-center font-normal text-black rounded-md text-sm sm:text-base hover:border-red-500">
                                    <CalendarsDropdown options={connectedCalendars} title={t("connected_calendars")}
                                                       onClose={handleUpdateSetting}/>
                                </div>
                            </div>
                            <div className="flex items-center justify-center gap-3">
                                <div
                                    className="flex items-center btn custom-arrow-icon-btn text-black hover:text-red cursor-pointer">
                                    <RefreshIcon className="h-5 w-5 sm:h-6 sm:w-6" onClick={handleUpdateSetting}/>
                                </div>
                                <button onClick={handleSettingsClick}
                                        className="btn h-9 gap-0.5 sm:gap-2 flex items-center justify-center border-2 font-bold text-black rounded-md border-black px-3 py-1.5 sm:py-3 text-sm sm:text-base hover:border-red-500 hover:text-red-500">
                                    <i className="fa fa-cog text-xl"></i> {t("settings")}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-10">
                    <div className="text-center">
                        <p className="text-red font-bold">{t("loading_events")}</p>
                        <LoaderContainer/>
                    </div>
                </div>
            )}
            {isBooking && (
                <div className="-mt-40">
                    <LoadingScreen headerText={t("loading_screen.text1")} subText={t("loading_screen.text2")}/>
                </div>
            )}

            <FullCalendar
                ref={calendarRef}
                plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                events={[...occupiedEvents.other_busy_events, ...occupiedEvents.personal_unavailability_events, ...occupiedEvents.coachello_events, ...events]}
                eventBackgroundColor="#fcf0c2"
                eventBorderColor="#fcf0c2"
                eventContent={(e: any) => <EventContent eventInfo={e} onClick={handleDeleteEvent}
                                                        onReschedule={handleReschedule}/>}
                eventOverlap={true}
                navLinkDayClick={handleDateClick}
                selectable={true}
                editable={false}
                select={handleDateClick}
                allDaySlot={false}
                headerToolbar={false}
                dayMaxEvents={false}
                slotMaxTime={"23:00:00"}
                slotMinTime={"06:00:00"}
                slotDuration={"00:15:00"}
                height={750}
                dayHeaderContent={({date}) => <DateTableHeader date={date}/>}
            />

            <Modal
                title=""
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false)
                    setCurrentKey("")
                    setIsRescheduling(false)
                }}
            >
                {currentKey ? (
                    React.createElement(mappingToBeComponents[currentKey], {
                        eventArgs: dateArgs,
                        dispatch,
                        settingDispatch,
                        customSetting,
                        setIsOpen,
                        eventAdd: handleUpdateSetting,
                        updateCalendar: currentKey === COMPONENTS_KEY.settingsModal ? handleUpdateSetting : undefined,
                        setIsBooking: setIsBooking,
                        rescheduleEvent: currentKey === COMPONENTS_KEY.modalContainer ? isRescheduling : undefined,
                        rescheduleDetails: (currentKey === COMPONENTS_KEY.modalContainer && isRescheduling) ? reschedulingMeetingDetails : undefined,
                    })
                ) : (
                    <></>
                )}
            </Modal>
        </div>
    );
};

export default ReactiveCalander;
