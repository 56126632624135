import ICoach, {ICoachSession} from "../../../../../models/ICoach";
import {useTranslation} from "react-i18next";
import {
    AcademicCapIcon,
    BriefcaseIcon,
    DocumentTextIcon,
    GlobeAltIcon,
    MailIcon,
    ThumbUpIcon,
    UserIcon,
    VideoCameraIcon
} from "@heroicons/react/outline";
import React, {useEffect, useState} from "react";
import DetailCard from "./DetailCard";
import RingPieChart from "../../../../../shared/components/RingPieChart";
import TemplateChartSection from "../../../../../shared/components/TemplateChartSection";
import CompanyReviewCard from "./CompanyReviewCard";
import TagList from "./TagList";
import i18n from "../../../../../utils/lib/i18n";
import {ELanguageFr} from "../../../../../constants/meta/ELanguageFr";
import {ESpecializationFr} from "../../../../../constants/meta/ESpecializationFr";
import {useNavigate} from "react-router-dom";
import {PencilIcon} from "@heroicons/react/solid";
import dayjs from "dayjs";
import SessionDetailsModal from "../../../../../shared/components/SessionDetailsModal";

interface Props {
    coach: ICoach;
}

const CoachCard = ({coach}: Props) => {
    const {t} = useTranslation("coachInsights");
    const navigate = useNavigate();
    const [coachSessions, setCoachSessions] = useState<ICoachSession[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        if (coach.sessions && coach.sessions.length > 0) {
            const filteredSessions = coach.sessions.filter(session =>
                session.avg_rating > 0 ||
                session.client_feedback.length > 0 ||
                session.client_review.length > 0
            );

            const sortedSessions = filteredSessions.sort((a, b) => (
                b.client_review.length + b.client_feedback.length) - (a.client_review.length + a.client_feedback.length));

            setCoachSessions(sortedSessions);
        } else {
            setCoachSessions([]);
        }
    }, [coach.sessions]);


    const copyAndNavigate = (text: string, openInNewTab = false) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopySuccess('Copied!');
            if (openInNewTab) {
                window.open(text, '_blank');
            }
        }, () => setCopySuccess('Failed to copy'));
    };

    const handleUpdateCoach = (coachId: number) => {
        navigate(`/dashboard/update-coach/${coachId}`);
    };

    const ProfileData = () => {
        return (
            <>
                <div className="flex space-x-2 items-center mb-2">
                    <h2 className="text-base font-semibold">{coach.first_name} {coach.last_name}</h2>
                    <button
                        title={t("text.update")}
                        onClick={() => {
                            handleUpdateCoach(coach.id)
                        }}
                        className="flex items-center hover:bg-gray-200 rounded"
                    >
                        <PencilIcon className="text-black cursor-pointer h-4 w-4"/>
                    </button>

                </div>
                <div className="flex space-x-1 mb-2">
                    {coach.email &&
                        <button
                            title={t("text.email")}
                            onClick={() => copyAndNavigate(coach.email, false)}
                            className="flex items-center hover:bg-gray-200 rounded"
                        >
                            <MailIcon className="h-4 w-4"/>
                        </button>}
                    {coach.website &&
                        <button
                            title={t("text.web")}
                            onClick={() => copyAndNavigate(coach.website, true)}
                            className="flex items-center hover:bg-gray-200 rounded"
                        >
                            <GlobeAltIcon className="h-4 w-4"/>
                        </button>}
                    {coach.video_url && (
                        <button
                            title={t("text.en_vid_url")}
                            onClick={() => window.open(`/play-video?url=${coach.video_url}`, '_blank')}
                            className="flex items-center bg-green-100 hover:bg-green-200 rounded p-0.5"
                        >
                            <VideoCameraIcon className="h-4 w-4 text-green-500"/>
                            <span className="text-xs">EN</span>
                        </button>
                    )}
                    {coach.video_url_fr && (
                        <button
                            title={t("text.fr_vid_url")}
                            onClick={() => window.open(`/play-video?url=${coach.video_url_fr}`, '_blank')}
                            className="flex items-center bg-green-100 hover:bg-green-200 rounded "
                        >
                            <VideoCameraIcon className="h-4 w-4 text-green-500"/>
                            <span className="text-xs">FR</span>
                        </button>
                    )}
                    {coach.video_url_de && (
                        <button
                            title={t("text.de_vid_url")}
                            onClick={() => window.open(`/play-video?url=${coach.video_url_de}`, '_blank')}
                            className="flex items-center bg-green-100 hover:bg-green-200 rounded"
                        >
                            <VideoCameraIcon className="h-4 w-4 text-green-500"/>
                            <span className="text-xs">DE</span>
                        </button>
                    )}
                    {coach.cv_url && (
                        <button
                            onClick={() => copyAndNavigate(coach.cv_url || '', true)}
                            title={t("text.cv_download")}
                            className="flex items-center bg-gray-100 hover:bg-gray-200 rounded"
                        >
                            <DocumentTextIcon className="h-4 w-4 text-gray-500"/>
                            <span>CV</span>
                        </button>
                    )}
                </div>
                <div className={`space-y-2 ${isExpanded ? 'text-center' : 'text-center md:text-left'}`}>
                    <div><strong>{t("text.phone")}:</strong> {coach.phone_number}</div>
                    <div><strong>{t("text.location")}:</strong> {coach.city}</div>
                    <div><strong>{t("text.timezone")}:</strong> {coach.timezone}</div>
                    <hr className="w-full"/>
                    {coach.last_session && (
                        <div className="flex items-center space-x-2 text-sm font-medium text-gray-600 mt-1">
                            <span><strong>{t("text.last_session")}:</strong> {new Date(coach.last_session).toLocaleDateString()}</span>
                        </div>
                    )}
                </div>
            </>
        )
    }

    const DetailCards = () => {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {typeof coach.avg_rating === 'number' && coach.avg_rating > 0 && (
                    <div className="col-span-1 rounded">
                        <DetailCard
                            icon={<ThumbUpIcon className="h-5 w-5 text-black-500"/>}
                            value={`${(Math.round((coach.avg_rating / 2) * 100) / 100)}/5`}
                            title={t("text.review")}
                        />
                    </div>
                )}
                {/*// @ts-ignore*/}
                {coach.coachee > 0 && <div className="col-span-1 rounded">
                    <DetailCard
                        icon={<UserIcon className="h-5 w-5 text-black-500"/>}
                        value={coach.coachee}
                        title={t("text.clients")}
                    />
                </div>}
                {coach.years_experience && <div className="col-span-1 rounded">
                    <DetailCard
                        icon={<BriefcaseIcon className="h-5 w-5 text-black-500"/>}
                        value={coach.years_experience + " yr"}
                        title={t("text.experience")}
                    />
                </div>}
                {coach.years_coaching && <div className="col-span-1 rounded">
                    <DetailCard
                        icon={<AcademicCapIcon className="h-5 w-5 text-black-500"/>}
                        value={(() => {
                            const currentYear = dayjs().year();
                            const currentMonth = dayjs().month() + 1;
                            const startYear = parseInt(coach.years_coaching, 10);

                            let yearsCoaching;

                            if (startYear === currentYear) {
                                yearsCoaching = 1;
                            } else if (currentMonth >= 7) {
                                yearsCoaching = currentYear - startYear + 1;
                            } else {
                                yearsCoaching = currentYear - startYear;
                            }

                            return `${yearsCoaching} yr`;
                        })()}
                        title={t("text.coaching")}
                    />
                </div>}
            </div>
        )
    }

    const LanguageAndSpecifications = () => {
        return (
            <div className="col-span-1 lg:col-span-4">
                {coach.languages && coach.languages.length > 0 &&
                    <TagList label={t("text.languages")}
                             tags={coach.languages.map((lang) => {
                                 return i18n.language === "fr"
                                     ? ELanguageFr[lang.language as keyof typeof ELanguageFr]
                                     : lang.language;
                             })}
                             primary={coach.languages.find(lang => lang.primary)?.language}
                    />}
                <TagList label={t("text.specializations")} tags={coach.specializations
                    .map(specialization => {
                        return i18n.language === "fr"
                            ? ESpecializationFr[
                                specialization.specialization as keyof typeof ESpecializationFr
                                ]
                            : specialization.specialization
                    })}/>
                {coach.certified_by &&
                    <TagList label={t("text.certified_by")} tags={[coach.certified_by]} primary={coach.certified_by}/>}
            </div>
        )
    }

    const SessionModal = ({entry}: { entry: any }) => {
        const [isOpen, setIsOpen] = useState(false);

        const openModal = () => setIsOpen(true);
        const closeModal = () => setIsOpen(false);

        return (
            <>
                <span
                    onClick={entry.client_count > 0 ? () => openModal() : undefined}
                    className={`px-1 bg-green-50/70 rounded-md ${entry.client_count > 0 ? "hover:cursor-pointer" : ""}`}>{`${entry.client_count} ${t("sessions_conducted.clients")}`}
                </span>
                {isOpen && <SessionDetailsModal companyId={entry.id} coachId={coach.id} groupId={null} isOpen={isOpen}
                                                onRequestClose={closeModal}/>}
            </>
        )
    }

    return (
        <div className="card-container flex flex-col md:flex-row mt-3 relative bg-warm-light shadow-sm pb-10">
            <div className={`w-full ${isExpanded ? 'md:w-1/4' : 'md:w-1/6'}`}>
                <div className="flex flex-col items-center p-4">
                    <img src={coach.image_url} alt={`${coach.first_name}'s profile`}
                         className="w-24 h-24 rounded-full object-cover mb-4"/>
                    {isExpanded && <ProfileData/>}
                </div>
            </div>
            <div className={`w-full ${isExpanded ? 'md:w-3/4' : 'md:w-5/6'}`}>
                {isExpanded ? (
                    <>
                        <div className="mb-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4">
                                <LanguageAndSpecifications/>
                                <div className="lg:col-span-2">
                                    <DetailCards/>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {coach.sessions && coach.sessions.length > 0 && <div className="rounded col-span-1">
                                    <TemplateChartSection
                                        title={t("sessions_conducted.title")}
                                        toolTip={t("sessions_conducted.tooltip")}
                                        textSize=""
                                    >
                                        <RingPieChart
                                            customLegendStyle="style2"
                                            data={coach.sessions
                                                ?.sort((a, b) => b.session_count - a.session_count)
                                                .map((entry) => ({
                                                    label: entry.name,
                                                    value: entry.session_count,
                                                    additionalLabel: <SessionModal entry={entry}/>
                                                }))
                                            }
                                            paddingAngle={10}
                                            innerRadius={70}
                                            outerRadius={100}
                                            style2label={t("sessions_conducted.total")}
                                            textSize="text-xs"
                                        />
                                    </TemplateChartSection>
                                </div>}
                                {coachSessions.length > 0 && (
                                    <div className="rounded col-span-1">
                                        <TemplateChartSection
                                            title={t("company_feedback.title")}
                                            toolTip={t("company_feedback.tooltip")}
                                            textSize=""
                                        >
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                                {coachSessions.map((session, index) => (
                                                    <div key={index}
                                                         className="bg-white p-3 rounded-lg shadow-sm border border-gray-200">
                                                        <CompanyReviewCard
                                                            name={session.name}
                                                            rating={Math.round((session.avg_rating / 2) * 100) / 100}
                                                            feedbacks={session.client_feedback}
                                                            reviews={session.client_review}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </TemplateChartSection>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="grid grid-cols-1 lg:grid-cols-9 gap-4">
                        <div className="flex flex-col md:items-start items-center col-span-1 md:col-span-2">
                            <ProfileData/>
                        </div>
                        <div className="lg:col-span-5 col-span-1">
                            <LanguageAndSpecifications/>
                        </div>
                        <div className="lg:col-span-2">
                            <DetailCards/>
                        </div>
                    </div>
                )}
            </div>
            {/*@ts-ignore*/}
            {coach.coachee > 0 &&
                <div className="absolute bottom-3 right-4">
                    <span
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="text-red-600 text-xs cursor-pointer hover:underline font-semibold"
                    >
                        {isExpanded ? t("text.show_less") : t("text.load_more")}
                    </span>
                </div>
            }
        </div>
    )
}

export default CoachCard;
