import React, {ReactNode} from "react";
import ReactTooltip from "react-tooltip"
import InformationIcon from "./InformationIcon";
import NoDataIcon from "@icons/noDataIcon.png";
import {useTranslation} from "react-i18next";
import Loader from "./Loader/Loader";
import {DataState} from "../../constants/enums";

interface TemplateChartSectionProps {
    title?: string;
    toolTip?:  string | ReactNode,
    children?: ReactNode;
    dataState?: DataState;
    textSize?: string;
    isLoading?: boolean;
    titleBarChildren?: any;
}

const TemplateChartSection: React.FC<TemplateChartSectionProps> = ({
                                                                       title = "",
                                                                       toolTip = "",
                                                                       children,
                                                                       dataState = DataState.DATA,
                                                                       textSize = "text-lg",
                                                                       isLoading = false,
                                                                       titleBarChildren
                                                                   }) => {
    const {t} = useTranslation('commons');

    const noDataText1 = dataState === DataState.NO_DATA
        ? t('no_data_card.text1')
        : dataState === DataState.NO_DATA_WITH_FILTERS
            ? t('no_data_card.text3')
            : "";

    const noDataText2 = dataState === DataState.NO_DATA
        ? t('no_data_card.text2')
        : dataState === DataState.NO_DATA_WITH_FILTERS
            ? t('no_data_card.text2')
            : "";

    return(
        <div className="bg-white rounded p-0 w-full h-full">
            <div className="flex justify-between gap-2 items-center p-3 px-5">
                <div className="flex gap-1 items-center text-black w-full justify-between">
                    <div className="flex justify-between items-center">
                    <span className={`font-lexend md:${textSize} font-bold`}>{title} </span> {titleBarChildren && titleBarChildren}
                    </div>
                    <div className="flex flex-row items-center">
                        {
                            toolTip && <InformationIcon
                                data-for={title}
                                data-tip={"hover on me will keep the tooltip"}
                            />
                        }
                        <ReactTooltip
                            className="w-60 bg-black backdrop-opacity-100 whitespace-pre-line"
                            delayHide={500}
                            id={title}
                            place="bottom"
                            effect="solid"
                            html={true}
                        >
                            {toolTip}
                        </ReactTooltip>
                    </div>
                </div>

            </div>
            <hr className="m-0"/>
            <div className="p-5 relative overflow-auto flex flex-col justify-center h-[calc(100%-64px)]">
                {dataState && (dataState === DataState.NO_DATA_WITH_FILTERS || dataState === DataState.NO_DATA || isLoading) && (
                    <div
                        className="z-10 absolute inset-0 flex flex-col items-center justify-center text-center p-7 gap-2 overflow-hidden">
                        {isLoading ? <Loader/> :
                            <>
                                <img src={NoDataIcon} width={40} height={40} alt="No Data Icon"/>
                                <h4 className="md:text-sm text-xs">{noDataText1}</h4>
                                <p className="md:text-sm text-xs">{noDataText2}</p>
                            </>
                        }
                    </div>
                )}
                <div style={{filter: (dataState !== DataState.DATA || isLoading) ? 'blur(12px)' : 'none'}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default TemplateChartSection;
