import React from 'react';
import ReactModal from "react-modal";
import {Progress} from "rsuite";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/groups.json";
import Fr from "../../../locale/fr/groups.json";
import {useTranslation} from "react-i18next";

interface Props {
    isOpen: boolean;
    progress: number;
}

const ProgressBarModal: React.FC<Props> = ({isOpen, progress}) => {
    i18n.addResourceBundle("en", "groups:create_group", ENG);
    i18n.addResourceBundle("fr", "groups:create_group", Fr);
    const {t} = useTranslation("groups:create_group");
    const roundedProgress = Math.round(progress);

    return <ReactModal
        isOpen={isOpen}
        contentLabel="Progress Bar"
        ariaHideApp={false}
        closeTimeoutMS={200}
        className="bg-white rounded-lg shadow-lg py-4 max-w-2xl mx-auto transition-transform transform-gpu ease-out duration-200"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
        <>
            <div className="px-4 flex justify-between items-center mb-2">
                <div className="flex items-center gap-2">
                    <h2 className="text-base font-semibold text-gray-900">{t("create_group.progress_bar.title")}</h2>
                </div>
            </div>
            <p className="px-4 text-sm text-gray-600">{t("create_group.loading_screen.text2")}</p>
            <div>
                <div className="flex justify-between items-center px-4">
              <span className="text-sm text-gray-600">
                {roundedProgress < 100 ? t("create_group.progress_bar.text1") : t("create_group.progress_bar.text2")}
              </span>
                    <span className="text-sm text-gray-600">
                {roundedProgress}%
              </span>
                </div>
                <Progress.Line percent={progress} strokeColor="rgb(253 0 84)" showInfo={false}/>
            </div>
        </>
    </ReactModal>
}

export default ProgressBarModal;