import React from 'react';
import './ConnectingSpinner.css';

interface SpinnerProps {
    color1?: string;
    color2?: string;
}

const ConnectingSpinner: React.FC<SpinnerProps> = ({color1 = '#FD0054', color2 = '#FFFFFF'}) => {
    return (
        <div
            className="loader"
            style={{
                '--color1': color1,
                '--color2': color2
            } as React.CSSProperties}
        >
        </div>
    );
};

export default ConnectingSpinner;
