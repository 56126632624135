import moment from "moment";
import dayjs from "dayjs";
import { ACTION } from "./utilities";
import i18n from "../../../../utils/lib/i18n";
import ENG from "../../locale/eng/events.json";
import Fr from "../../locale/fr/events.json";
import { useTranslation } from "react-i18next";

export function DateTableHeader(arg: any) {
  i18n.addResourceBundle("en", "events", ENG);
  i18n.addResourceBundle("fr", "events", Fr);
  const {t} = useTranslation("events");

  const dateOfWeek = new Date(arg?.date).toLocaleString('en', {
    weekday: "long",
  });

  const day = moment(arg?.date).format("DD");

  return (
    <div>
      <div>{day}</div>
      <div>{t(`day_names_short.${dateOfWeek.toLowerCase()}`)}</div>
    </div>
  );
}

export function EventReducer(events: any, action: any) {
  switch (action?.type) {
    case ACTION.ADD_EVENTS:
      return [...events, action?.payLoad];
    default:
      return events;
  }
}

export function CustomeSettingsReducer(settings: any, action: any){

  switch (action?.type) {
    case ACTION.ADD_NEW_SETTINGS:
      return action?.payLoad;
    default:
      return settings;
  }
  
}

export const formatDate = (date:any) => {
  if (date) {
    let OriginalTime:any = moment(date, "HH:mm")
    return dayjs(OriginalTime["_d"])
  }
}

export const formatMinit = (date:any) => {
  if (date) {
    let OriginalTime:any = moment(date, "mm")
    return dayjs(OriginalTime["_d"])
  }
}