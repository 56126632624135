import {useEffect, useRef, useState} from "react";
import {CalendarIcon, ClockIcon} from "@heroicons/react/outline";
import {DatePicker, TimePicker, Typography} from "antd";
import {formatDate} from "../../Constants/ConstantsComponents";
import dayjs, {Dayjs} from 'dayjs';
import moment from "moment";
import './TimeSlotFrequencyCSS.css';

const {RangePicker} = DatePicker;

interface itemProps {
    title: string;
    params: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    bookOnBehalf?: boolean;
    isDisabled?: boolean;
    multiDaySelection?: boolean;
    note?: string;
}

const TimeSlotsFrequency: React.FC<itemProps> = ({
                                                     title,
                                                     params,
                                                     setFieldValue,
                                                     bookOnBehalf,
                                                     isDisabled,
                                                     multiDaySelection = false,
                                                     note = ""
                                                 }: itemProps) => {
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
        params?.startDate ? dayjs(params?.startDate) : null,
        params?.endDate ? dayjs(params?.endDate) : null
    ]);
    const lastAvailableDay = params?.lastAvailableDate ? dayjs(params.lastAvailableDate) : null;
    const [startTimeForBOB, setStartTimeForBOB] = useState<Dayjs | null>(params?.startTime);
    const [endTimeForBOB, setEndTimeForBOB] = useState<Dayjs | null>(params?.endTime);
    const hasRunRef = useRef(false);
    const lastAvailableDate = params?.lastAvailableDate ? moment(params.lastAvailableDate) : null;

    useEffect(() => {
        if (!bookOnBehalf) return;
        if (startTimeForBOB && bookOnBehalf) {
            if (startTimeForBOB === params?.startTime) {
                if (hasRunRef.current) return;
                const end = (params?.startTime).add(1, 'hour')
                setEndTimeForBOB(end);
                setFieldValue("endTime", end);
            } else {
                const end = startTimeForBOB.add(1, 'hour');
                setEndTimeForBOB(end);
                setFieldValue("endTime", end);
            }
        }
        hasRunRef.current = true;
    }, [startTimeForBOB]);

    return (
        <div>
            <Typography className="font-bold pb-1"> {title} </Typography>
            {note && <Typography className="mb-1">{note}</Typography>}
            {multiDaySelection ? (
                <div className="flex flex-row w-full">
                    <div className="flex flex-row w-full icon-text">
                        <CalendarIcon className="text-red"/>
                        <RangePicker
                            showTime={{format: 'HH:mm'}}
                            format="YYYY-MM-DD HH:mm"
                            className="w-full text hover:border-red focus:border-red"
                            //dropdownClassName="custom-calendar-dropdown"
                            value={dateRange}
                            onChange={(dates) => {
                                if (!dates) return;
                                const [start, end] = dates as [Dayjs, Dayjs];
                                setDateRange([start, end]);
                                setFieldValue("startDate", start);
                                setFieldValue("endDate", end);
                            }}
                            disabled={isDisabled}
                            autoFocus={false}
                            suffixIcon={false}
                            disabledDate={(current) => {
                                const today = dayjs().startOf('day');
                                if (lastAvailableDate) {
                                    return current.isBefore(today) || current.isAfter(lastAvailableDay);
                                }
                                return current.isBefore(today);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div className="flex flex-row w-full">
                    <div className="flex flex-row w-full icon-text">
                        <CalendarIcon className="text-red"/>
                        {/* <Typography className="pr-1"> {params?.date} </Typography> */}
                        <DatePicker
                            allowClear={false}
                            className="w-2/3 hover:border-red focus:border-red"
                            format={"ddd D, MMM"}
                            defaultValue={formatDate(params?.date)}
                            suffixIcon={false}
                            disabled={isDisabled}
                            onChange={(date) => {
                                if (!date) return;
                                setFieldValue("date", date);
                            }}
                            disabledDate={(current) => {
                                const today = moment().startOf('day');
                                if (lastAvailableDate) {
                                    return current < today || current > lastAvailableDate;
                                }
                                return current < today;
                            }}
                        />
                    </div>
                    <div className="flex flex-row w-full icon-text">
                        <ClockIcon className="text-red"/>
                        {/* {moment(props.datetime).format('HH:mm')}  */}
                        {/* <Typography> {params?.startTime} - {params?.endTime}</Typography> */}
                        <TimePicker
                            allowClear={false}
                            className="w-1/3 hover:border-red focus:border-red"
                            format={"HH:mm"}
                            defaultValue={formatDate(params?.startTime)}
                            suffixIcon={false}
                            onChange={(time) => {
                                if (!time) return;
                                setFieldValue("startTime", time);
                                setStartTimeForBOB(time);
                            }}
                            disabled={isDisabled}
                        />
                        <span>-</span>
                        <TimePicker
                            allowClear={false}
                            className="w-1/3 hover:border-red focus:border-red"
                            format={"HH:mm"}
                            defaultValue={formatDate(params?.endTime)}
                            suffixIcon={false}
                            value={bookOnBehalf ? endTimeForBOB : undefined}
                            disabled={bookOnBehalf}
                            onChange={(time) => {
                                if (!time) return;
                                if (startTimeForBOB && startTimeForBOB < time) {
                                    setFieldValue("endTime", time);
                                }
                            }}
                            disabledHours={() => {
                                if (!startTimeForBOB) return [];
                                return [...Array(startTimeForBOB.hour()).keys()];
                            }}
                            disabledMinutes={(selectedHour) => {
                                if (!startTimeForBOB) return [];
                                if (selectedHour === startTimeForBOB.hour()) {
                                    return [...Array(startTimeForBOB.minute()).keys()];
                                }
                                return [];
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimeSlotsFrequency;
