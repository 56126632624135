import CoachProfileForm from "../../forms/CoachProfileForm";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "@hooks/useNavTitle";
import useAuth from "@hooks/useAuth";

const Profile = ({...props}) => {

  const {t} = useTranslation();
  useNavTitle(t(`layout:sidebar.${props.navTitle}`));
    const {user} = useAuth()

  return (
    <div className="m-5">
      <div className="w-full md:w-2/3 mx-auto">
        <div className="bg-white relative mt-24 flex flex-col rounded">
          <CoachProfileForm coach_id={user?.id!}/>
        </div>
      </div>
    </div>
  );
};

export default Profile;
