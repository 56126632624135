import React, {useEffect, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {IThreadItem, useAICoaching} from "../../utils/AICoachingContext";
import {AIModel} from "../../enums";
import {createPracticeSessions, processChatPrompt} from "../../actions";
import {useTranslation} from "react-i18next";
import useAuth from "@hooks/useAuth";
import Loading from "./Loading";

interface EdgePanelProps {
    items: IThreadItem[];
    setPracticeSessions: (sessions: any[]) => void;
    createPracticeMode?: boolean
    setIsPLoading: (isPLoading: boolean) => void;
    selectedId: number;
    setSelectedId: (selectedId: number) => void;
}

interface PracticeSuggestionsProps {
    suggestion: IThreadItem[];
    onSelect: (item: IThreadItem) => void;
    collapsed: boolean;
    selectedId: number;
}

const EdgePanel: React.FC<EdgePanelProps> = ({
                                                 items,
                                                 setPracticeSessions,
                                                 createPracticeMode,
                                                 setIsPLoading,
                                                 selectedId,
                                                 setSelectedId
                                             }) => {
    const {
        selectedThreadItem,
        setSelectedThreadItem,
        state,
        setState,
        setMessages,
        createNewThread,
        setCustomChatSection,
        addMessage,
        setParentThreadItem,
        setVoiceState
    } = useAICoaching()
    const [isHovered, setIsHovered] = useState(false);
    const {t} = useTranslation("ai_coaching");
    const [isGLoading, setIsGLoading] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const {user} = useAuth();
    const client_id = user?.id;

    const spinGMessages = [
        t("spin_message.preparing_multiple_practice_sessions"),
        t("spin_message.generating_practice_sessions"),
        t("spin_message.finalizing_your_practice_sessions")
    ]

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsHovered(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setIsHovered(false);
    }, [selectedThreadItem])

    const handleGeneratePracticeSessions = async () => {
        setIsGLoading(true);
        if (client_id && selectedThreadItem) {
            const data = await createPracticeSessions(client_id, selectedThreadItem.id);
            const formattedPracticeSessions = data.practice_scenarios
                .filter((session: any) => session.title !== null)
                .map((session: any) => ({
                    id: null,
                    practiceId: session.id,
                    title: session.title,
                    subTitle: session.objective
                }));
            setPracticeSessions(formattedPracticeSessions);
        }
        setIsGLoading(false);
    }

    const handleOnSelect = async (item: IThreadItem) => {
        setIsHovered(false);
        if (state !== 'practice') {
            setParentThreadItem(selectedThreadItem);
        }
        if (item.id) {
            setMessages([]);
            setSelectedThreadItem(item);
            if (item.practiceId) setSelectedId(item.practiceId);
            setState("practice");
        } else {
            setIsPLoading(true);
            setCustomChatSection(undefined)
            const newThread = await createNewThread(AIModel.practice, undefined, item.practiceId)
            const promptResponse = await processChatPrompt(newThread.id, '')
            const updatedSessions = items.map((session: any) =>
                session.practiceId === item.practiceId ? {
                    id: newThread.id,
                    practiceId: session.practiceId,
                    title: session.title,
                    subTitle: session.subTitle
                } : session
            );
            setPracticeSessions(updatedSessions);
            setIsPLoading(false);
            if (item.practiceId) setSelectedId(item.practiceId);
            setMessages([])
            addMessage({
                id: Math.random().toString(),
                content: promptResponse.reply,
                role: "assistant"
            })
            console.log(item.practiceId);
        }
    }


    return (
        <div ref={containerRef}
             className="my-2 border border-black z-10 relative rounded-lg group mx-auto"
        >
            <span
                className="p-2 cursor-pointer text-black group-hover:text-gray font-semibold text-xs px-4 flex gap-2 items-center"
                onClick={() => {
                    setIsHovered(prevState => !prevState)
                    setVoiceState("not-active");
                    if (createPracticeMode && !items.length) handleGeneratePracticeSessions()
                }}> {createPracticeMode && !items.length ? t("try_practice_session") : t("practice_session")}
                <div
                    className="w-5 h-5">
                    <svg width="20" height="20" viewBox="0 0 70 72" fill="none" xmlns="http://www.w3.org/2000/svg"
                        // className="transition-all duration-300 fill-none group-hover:fill-current stroke-current"
                    >
                        <path
                            d="M46.81 66.2838H46.7981C46.3835 66.2838 45.9853 66.1147 45.6965 65.8168C45.4076 65.519 45.2535 65.1149 45.2669 64.7003L45.2714 64.3321C45.2714 64.2902 45.2714 64.1629 45.2714 64.1225C45.2535 59.6429 44.2028 37.291 25.1811 37.291C24.3339 37.291 23.6484 36.6055 23.6484 35.7584C23.6484 34.9112 24.3339 34.2257 25.1811 34.2257C44.2028 34.2257 45.2535 11.8739 45.2714 7.39422C45.2714 7.35381 45.2714 7.22659 45.2714 7.18468L45.2669 6.81649C45.2535 6.4019 45.4076 5.99779 45.6965 5.69994C46.2742 5.10425 47.3279 5.10425 47.9056 5.69994C48.1945 5.99779 48.3546 6.4019 48.3412 6.81649L48.3367 7.18468C48.3367 7.19366 48.3367 7.20264 48.3367 7.21162C48.3352 7.23706 48.3352 7.264 48.3322 7.28945C48.3352 7.32387 48.3367 7.3583 48.3367 7.39422C48.3546 11.8739 49.4038 34.2257 68.4255 34.2257C69.2727 34.2257 69.9582 34.9112 69.9582 35.7584C69.9582 36.6055 69.2727 37.291 68.4255 37.291C49.4038 37.291 48.3546 59.6429 48.3367 64.1225V64.124V64.1285C48.3367 64.1615 48.3352 64.1944 48.3322 64.2273C48.3352 64.2617 48.3367 64.2962 48.3367 64.3321L48.3412 64.7003C48.3546 65.1149 48.2005 65.519 47.9116 65.8168C47.6227 66.1147 47.2246 66.2838 46.81 66.2838ZM33.7243 35.7584C41.4219 38.8416 45.0753 46.0513 46.804 52.5395C48.5327 46.0513 52.1847 38.8416 59.8823 35.7584C52.1847 32.6752 48.5327 25.4655 46.804 18.9773C45.0753 25.4655 41.4219 32.6752 33.7243 35.7584Z"
                            fill="currentColor"/>
                        <path
                            d="M11.2794 29.6953C11.2734 29.6953 11.2659 29.6953 11.2599 29.6953C10.4412 29.6863 9.77516 29.0352 9.74822 28.2135C9.74673 28.1716 9.74074 27.989 9.74523 27.6971C9.7138 26.175 9.395 21.3975 6.70092 18.663C5.38831 17.3309 3.71049 16.6829 1.57169 16.6829C0.724556 16.6829 0.0390625 15.9974 0.0390625 15.1502C0.0390625 14.3031 0.724556 13.6176 1.57169 13.6176C3.73594 13.6176 5.42722 12.9545 6.74582 11.591C9.54766 8.69341 9.73775 3.6031 9.74373 2.43268C9.74373 2.39825 9.74373 2.36682 9.74373 2.33689C9.74373 2.24559 9.74224 2.1932 9.74224 2.18871C9.71979 1.5571 10.088 0.977872 10.6687 0.727922C11.2434 0.479468 11.9214 0.609682 12.3645 1.06019C12.6399 1.34008 12.797 1.73371 12.806 2.12585H12.812C12.8135 2.25756 12.815 2.44764 12.809 2.68562C12.8509 4.29309 13.2056 8.95085 15.8533 11.6374C17.1659 12.9695 18.8437 13.6176 20.9825 13.6176C21.8297 13.6176 22.5152 14.3031 22.5152 15.1502C22.5152 15.9974 21.8297 16.6829 20.9825 16.6829C18.8183 16.6829 17.1255 17.3459 15.8069 18.7094C13.0006 21.6115 12.8135 26.7123 12.809 27.8723C12.809 27.9022 12.809 27.9306 12.809 27.9576C12.809 28.0519 12.8105 28.1057 12.8105 28.1117C12.8329 28.7418 12.4648 29.3211 11.884 29.571C11.6895 29.6548 11.4844 29.6953 11.2794 29.6953ZM7.20831 15.1502C7.83393 15.5498 8.41465 16.0258 8.95048 16.5796C9.97123 17.6348 10.7241 18.877 11.2764 20.1507C11.8301 18.877 12.583 17.6348 13.6038 16.5796C14.1381 16.0258 14.7203 15.5498 15.3459 15.1502C14.7203 14.7506 14.1381 14.2746 13.6038 13.7224C12.583 12.6657 11.8316 11.4249 11.2779 10.1542C10.7121 11.4534 9.9383 12.7181 8.88313 13.7897C8.36676 14.3136 7.80849 14.7671 7.20831 15.1502Z"
                            fill="currentColor"/>
                        <path
                            d="M26.4259 71.3965C26.4169 71.3965 26.4064 71.3965 26.3974 71.3965C25.5832 71.3831 24.9217 70.732 24.8947 69.9148C24.8932 69.8789 24.8887 69.7187 24.8932 69.4598C24.8573 67.5844 24.3424 64.359 22.5718 62.563C21.596 61.5706 20.3402 61.0887 18.7358 61.0887C17.8886 61.0887 17.2031 60.4032 17.2031 59.5561C17.2031 58.7089 17.8886 58.0234 18.7358 58.0234C20.3582 58.0234 21.6244 57.5295 22.6048 56.5148C24.9815 54.0587 24.8917 49.347 24.8902 49.2991C24.8678 48.669 25.236 48.0898 25.8167 47.8398C26.3989 47.5899 27.0709 47.7231 27.514 48.1721C27.7894 48.452 27.9465 48.8426 27.954 49.2348H27.9585C27.96 49.347 27.96 49.5116 27.9555 49.7182C28.0034 51.6026 28.5257 54.7741 30.2754 56.5492C31.2513 57.5415 32.507 58.0234 34.1115 58.0234C34.9586 58.0234 35.6441 58.7089 35.6441 59.5561C35.6441 60.4032 34.9586 61.0887 34.1115 61.0887C32.489 61.0887 31.2228 61.5826 30.2425 62.5974C27.8657 65.0535 27.9555 69.7651 27.957 69.813C27.9794 70.4431 27.6113 71.0224 27.0305 71.2723C26.836 71.3561 26.6294 71.3965 26.4259 71.3965ZM23.7662 59.5561C24.1344 59.824 24.4816 60.1278 24.8094 60.4661C25.4799 61.159 26.0083 61.9493 26.4229 62.771C26.8389 61.9493 27.3673 61.159 28.0378 60.4661C28.3656 60.1278 28.7128 59.824 29.081 59.5561C28.7128 59.2882 28.3656 58.9843 28.0378 58.6461C27.3688 57.9531 26.8404 57.1643 26.4259 56.3441C25.9978 57.1853 25.4515 57.9935 24.7555 58.7C24.4442 59.0173 24.1149 59.3016 23.7662 59.5561Z"
                            fill="currentColor"/>
                    </svg>
                </div>
            </span>
            {isHovered && (
                <div
                    className={twMerge(
                        "absolute bottom-9 left-1/2 transform -translate-x-1/2 mb-1 w-96 bg-white rounded-md border border-[#D6CAB3] py-4 px-2 min-h-[148px] max-h-96"
                    )}
                >
                    {/*<div className="flex justify-between items-center p-2 pb-1 pl-2">*/}
                    {/*    <span className="font-semibold text-black ">{t("practice_session")}</span>*/}
                    {/*    <CloseButton*/}
                    {/*        className="rounded-md h-5 w-5 font-bold p-1 hover:bg-practice hover:text-white relative"*/}
                    {/*        onClick={() => setIsHovered(false)}></CloseButton>*/}
                    {/*</div>*/}
                    {/*<hr className="mt-0 bg-gray-200 h-0.5 border-0"/>*/}
                    {
                        (isGLoading) ?
                            (<div className="flex flex-col w-full items-center justify-center h-full ">
                                    <Loading messages={spinGMessages} intervalTime={1200}
                                             spinner_size="32"
                                             center={true}/></div>
                            ) : (
                                <div className="overflow-y-auto min-h-16 max-h-72 px-2 pb-3 ">
                                    <PracticeSuggestions suggestion={items} onSelect={handleOnSelect}
                                                         collapsed={false} selectedId={selectedId}/>
                                </div>
                            )
                    }
                </div>
            )}
        </div>
    );
};

export default EdgePanel;

const PracticeSuggestions: React.FC<PracticeSuggestionsProps> = ({suggestion, onSelect, collapsed, selectedId}) => {
    return (
        <div className="flex flex-col w-full items-center">
            <div className="flex flex-col gap-2 w-full">
                {suggestion.map((item, index) => (
                    <div
                        key={index}
                        className={`hover:bg-red/10 hover:border-red flex flex-col gap-0.5 rounded-md border-[1px] ${selectedId === item.practiceId ? 'border-red bg-[#FFE6EE]' : ' border-practice cursor-pointer'}`}
                        onClick={() => {
                            if (selectedId !== item.practiceId) {
                                onSelect(item);
                            }
                        }}
                    >
                        <p className="font-dm_sans m-0 text-xs px-4 pt-2 text-gray-600 font-semibold">
                            {item.title}
                        </p>
                        <p className="font-dm_sans m-0 text-xs px-4 pb-2 text-gray-400 font-medium opacity-70">
                            {item.subTitle}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

