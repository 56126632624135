import {useEffect, useMemo, useState} from "react";

import {useNavigate} from "react-router-dom";
import {PlusIcon} from "@heroicons/react/outline";

import SearchInput from "./components/SearchInput";
import GroupTable from "./components/GroupTable";
import Modal from "../../components/Modal";
import Button from "../../../../shared/components/Button";

import {deleteGroup, getGroups} from "../../../../services/api/companyApi";
import useAuth from "../../../../utils/hooks/useAuth";

import {IGroup} from "../../../../models/IGroup";
import {TGroupTableRow} from "../../../../constants/types";

import defaultLogo from "@img/coachello.svg";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "@hooks/useNavTitle";
import ENG from "../../locale/eng/groups.json";
import Fr from "../../locale/fr/groups.json";
import i18n from "../../../../utils/lib/i18n";
import {ERole} from "../../../../constants/enums";
import {toast} from "react-toastify";

const Groups = ({...props}) => {
    i18n.addResourceBundle("en", "groups", ENG);
    i18n.addResourceBundle("fr", "groups", Fr);
    const {t} = useTranslation("groups");
    const lng = i18n.language;
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));

    const {user} = useAuth();
    const navigate = useNavigate();

    const [groups, setGroups] = useState<IGroup[]>([]);
    const [searchText, setSearchText] = useState("");
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
        useState(false);
    const [deletingGroup, setDeletingGroup] = useState<IGroup>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllGroups();
    }, []);

    const getAllGroups = () => {
        if (!user) return;
        setIsLoading(true);
        getGroups(user.companyId)
            .then((res) => setGroups(res))
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleCreateGroupNavigation = () => {
        navigate("/dashboard/groups/create");
    };

    const handleEditGroupNavigation = (groupId: number) => {
        navigate(`/dashboard/groups/${groupId}/edit`);
    };

    const handleDeleteConfirmation = (groupId: number) => {
        const group = findGroupById(groupId);
        setDeletingGroup(group);
        setDeleteConfirmationModalOpen(true);
    };

    const handleDeleteGroup = () => {
        if (!deletingGroup || !user) return;
        deleteGroup(user.companyId, deletingGroup?.id)
            .then((response) => {
                if (response.error) {
                    toast.error(t(response.message));
                } else {
                    toast.success(t(response.message));
                }
                getAllGroups();
                setDeleteConfirmationModalOpen(false);
            })
            .catch((err) => console.log(err));
    };

    const findGroupById = (groupId: number) => {
        return groups.find((group) => group.id === groupId);
    };

    const tableData = useMemo(() => {
        return groups
            .filter((group) => {
                const text = searchText.toLowerCase();
                return (
                    group.name.toLowerCase().includes(text) ||
                    group.medium?.toLowerCase().includes(text) ||
                    group.coachees.some(
                        (coachee) =>
                            coachee.first_name?.toLowerCase().includes(text) ||
                            coachee.last_name?.toLowerCase().includes(text)
                    )
                );
            })
            .map((group) => {
                const medium = group?.medium && (group?.medium?.charAt(0)?.toUpperCase() + group?.medium?.slice(1));
                const program = group.program;
                const groupExpiration = group.group_expiration;
                return {
                    id: group.id,
                    groupName: {
                        name: group.name,
                        logo: group.logo || defaultLogo,
                    },
                    aiCoachAccess: group.ai_coach_type && group.ai_coach_type.value ? t(`ai_coach_type.${group.ai_coach_type.value}`) : t("ai_coach_type.human_only"),
                    members: group.coachees.length,
                    credits: group.credits,
                    creditLimitPerUser: group.credit_limit_per_user,
                    medium,
                    program: !program || program.name === "" ? "-" : (lng === "fr" ? program.name_fr : program.name),
                    groupExpiration:
                        !groupExpiration || groupExpiration === "" ? "-" : groupExpiration,
                    colorCode: group.color_code,
                    createdAt: group.created_at?.split(" ")[0],
                    creditUsage: group.credit_usage ? group.credit_usage : 0,
                };
            });
    }, [groups, searchText, lng]);

    return (
        <div className="bg-linen_gradient flex flex-col gap-4 p-6 h-full w-full">
            <div className="flex flex-col bg-white rounded border-gray-50 border-[1px] pb-4">
                <div className="flex flex-row justify-between items-center w-full p-4">
                    <p className="font-bold text-black text-lg">{t("text0")}</p>
                    <div className="flex flex-row gap-3">
                        
                        {/* <DatePicker /> */}
                        {/* <div className="cursor-pointer h-[36px] w-[36px] bg-black p-1.5 rounded-[5px] border-[1px] border-black">
              <FilterIcon className="text-white" />
            </div>
            <div className="cursor-pointer h-[36px] w-[36px] bg-white p-1.5 rounded-[5px] border-[1px] border-black">
              <RefreshIcon className="text-black" />
            </div> */}

                        {
                            (!user || ![ERole.GroupAdmin].includes(user.role)) && <>
                                <SearchInput
                                    searchText={searchText}
                                    onChange={(value) => setSearchText(value)}
                                />
                                <button
                                    className="h-[36px] w-max flex flex-row items-center gap-2 rounded-[5px] border-[1px] border-red py-2 px-2"
                                    onClick={handleCreateGroupNavigation}
                                >
                                    <PlusIcon className="text-red h-full"/>
                                    <p className="text-red text-sm font-bold">{t("text1")}</p>
                                </button>
                            </>
                        }
                    </div>
                </div>
                <GroupTable
                    onDeleteGroup={handleDeleteConfirmation}
                    onEditGroup={handleEditGroupNavigation}
                    tableData={tableData as TGroupTableRow[]}
                    isLoading={isLoading}
                />
            </div>
            <Modal
                className="w-96"
                title="Confirmation"
                isOpen={deleteConfirmationModalOpen}
                onClose={setDeleteConfirmationModalOpen}
            >
                <p className="mb-4">{t("confirm.text0")}</p>
                <div className="flex justify-end gap-4">
                    <Button
                        className="text-black border-2 border-black px-4 py-1"
                        onClick={handleDeleteGroup}
                    >
                        {t("confirm.text1")}
                    </Button>
                    <Button
                        className="text-white bg-black px-4 py-1"
                        onClick={() => {
                            setDeleteConfirmationModalOpen(false);
                        }}
                    >
                        {t("confirm.text2")}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default Groups;
