import {Panel} from "rsuite";

type SuggestionsProps = {
    onSelectSuggestion: (suggestion: string) => void;
    suggestions: {
        title: string;
        subTitle: string;
    }[];
    collapsed: boolean;
    isPractice: boolean;
};

const Suggestions = ({
                         suggestions,
                         onSelectSuggestion,
                         collapsed,
                         isPractice
                     }: SuggestionsProps) => {
    return (
        <Panel collapsible expanded={!collapsed}>
            <div className="flex flex-col w-full items-end">
                <div className="grid grid-cols-1 gap-2">
                    {suggestions.map(({title, subTitle}, index) => (
                        <div
                            key={index}
                            className={`hover:bg-red/10 flex flex-col gap-0.5 rounded-xl border-[1px] ${isPractice ? 'border-practice' : 'border-red'}`}
                            onClick={() => onSelectSuggestion(title)}
                        >
                            <p className="cursor-pointer font-dm_sans m-0 text-xs px-4 pt-2 text-red font-semibold">
                                {title}
                            </p>
                            <p className="cursor-pointer font-dm_sans m-0 text-xs px-4 pb-2 text-red font-medium opacity-70">
                                {subTitle}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </Panel>
    );
};

export default Suggestions;
