import {Dispatch, SetStateAction} from "react";
import Select from "react-select";
import {
    RefreshIcon,
    SortAscendingIcon,
    SortDescendingIcon,
} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";
import Search from "../../../../../shared/components/Table/Search";

type FilterOption = {
    label: string;
    value: number;
}

enum SORT_OPTION {
    CLIENT_COUNT = 'client_count',
    AVG_RATING = 'avg_rating',
    NONE = ''
}

interface FilterBarProps {
    companies: FilterOption[];
    selectedCompanies: FilterOption[];
    setSelectedCompanies: Dispatch<SetStateAction<FilterOption[]>>;
    sortOption: SORT_OPTION;
    setSortOption: Dispatch<SetStateAction<SORT_OPTION>>;
    sortOrder: 'asc' | 'desc';
    setSortOrder: Dispatch<SetStateAction<'asc' | 'desc'>>;
    globalFilter: string;
    setGlobalFilter: Dispatch<SetStateAction<string>>;
    onReset: () => void;
}

const FilterBar: React.FC<FilterBarProps> = ({ companies, selectedCompanies, setSelectedCompanies, sortOption, setSortOption, sortOrder, setSortOrder, globalFilter, setGlobalFilter, onReset}) => {
    const { t } = useTranslation("coachInsights");

    const handleSortChange = (selectedOption: any) => {
        setSortOption(selectedOption ? selectedOption.value : SORT_OPTION.NONE);
    };

    const handleOrderToggle = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const sortOptions = [
        { label: t("filter.no_of_clients"), value: SORT_OPTION.CLIENT_COUNT },
        { label: t("filter.avg_rating"), value: SORT_OPTION.AVG_RATING }
    ];

    return (
        <div className="bg-white rounded border-warm-dark border-[1px] p-2 px-4 mb-5 mt-2 flex flex-col md:flex-row flex-grow justify-between items-center gap-5">
            <Select
                isClearable
                isSearchable={true}
                className="w-[300px] z-20"
                placeholder={t("filter.placeholder_company")}
                noOptionsMessage={() => t("filter.no_option")}
                value={selectedCompanies}
                options={companies}
                onChange={(value) => setSelectedCompanies(value as FilterOption[])}
                menuPlacement="bottom"
                isMulti
            />
            <div className="flex-grow flex items-center justify-end gap-2">
                <Search
                    value={globalFilter ?? ""}
                    onChange={(value) => setGlobalFilter(String(value || ""))}
                />
                <Select
                    isClearable
                    isSearchable={false}
                    className="w-[200px]"
                    options={sortOptions}
                    onChange={handleSortChange}
                    value={sortOptions.find(option => option.value === sortOption)}
                    placeholder={t("filter.placeholder_sort")}
                />
                <button
                    title={`${sortOrder === 'asc' ? t("filter.desc") : t("filter.asc")}`}
                    onClick={handleOrderToggle}
                    className={`w-10 h-10 hover:shadow-lg bg-white rounded-md p-2 border-2 border-black flex items-center justify-center ${sortOption === SORT_OPTION.NONE ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={sortOption === SORT_OPTION.NONE}
                >
                    {sortOrder === 'asc' ? (
                        <SortAscendingIcon className="text-black w-5 h-5" />
                    ) : (
                        <SortDescendingIcon className="text-black w-5 h-5" />
                    )}
                </button>
                <button
                    title={t("filter.reset")}
                    onClick={onReset}
                    className="w-10 h-10 hover:shadow-lg bg-white rounded-md p-2 border-2 border-black flex items-center justify-center"
                >
                    <RefreshIcon className="text-black w-5 h-5" />
                </button>
            </div>
        </div>
    )
}

export default FilterBar;
