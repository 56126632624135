import React, {Dispatch, SetStateAction, useState} from "react";
import Select, {StylesConfig} from "react-select";
import i18n from "../../../../../utils/lib/i18n";
import ENG from "../../../locale/eng/coaching.json";
import Fr from "../../../locale/fr/coaching.json";
import {useTranslation} from "react-i18next";
import useAuth from "@hooks/useAuth";
import ChartSection from "../../../../../shared/components/ChartSection";
import RingPieChart from "../../../../../shared/components/RingPieChart";
import {Colors} from "../../../../../constants/enums";
import {Filters} from "../../../../../constants/types";
import NoDataWrapper from "../../../../../shared/components/NoDataWrapper"
import NoDataIcon from "@icons/noDataIcon.png";
import TemplateChartSection from "../../../../../shared/components/TemplateChartSection";
import {DUMMY_CHART_DATA} from "../../../../../constants/data/dummyChartData";

const chartStyles = {
    ONBOARDING_COLORS: [Colors.green, Colors.purple, Colors.yellowLight, Colors.pink, Colors.yellow],
}

interface props {
    data: Record<any, any>[];
    groups: Record<any, any>[];
    setFilters: Dispatch<SetStateAction<Filters>>;
    isFiltered?: boolean ;
    noDataText?: string;
}


type FilterOption = {
    label: string;
    value: number;
};

enum DataState {
    NO_DATA = 'NO_DATA',
    NO_DATA_WITH_FILTERS = 'NO_DATA_WITH_FILTERS',
    DATA = 'DATA'
}

const OverallProgress: React.FC<props> = (props) => {
    const {user} = useAuth();
    i18n.addResourceBundle("en", "performance", ENG);
    i18n.addResourceBundle("fr", "performance", Fr);
    const {t} = useTranslation("performance");

    const [selectedGroup, setSelectedGroup] = useState<FilterOption>();

    const selectStyle: StylesConfig = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: '#F2EBE3',
            '&:hover': {
                borderColor: state.isFocused ? '#e6dcd1' : '#e6dcd1',
            },
            borderWidth: 2,
            boxShadow: "",
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: '200px',
            overflow: 'auto',
          }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: '200px',
            overflowY: 'auto',
          }),
    }

    const groupFilter = <div className="flex justify-end px-5">
        <Select
            styles={selectStyle}
            isClearable
            isSearchable={false}
            className="z-30 min-w-[200px]"
            placeholder={t("text27")}
            noOptionsMessage={() => t("text28")}
            value={selectedGroup}
            options={props.groups}
            onChange={(value) => {
                const selectedVal = value as FilterOption
                setSelectedGroup(selectedVal);
                const filters: Filters = {
                    group_ids: selectedVal ? [selectedVal.value] : []
                }
                props.setFilters(filters)
            }}
            menuPlacement="bottom"
        />

    </div>

    const get_data_state = (isNoData: boolean, isFiltered: undefined | boolean) => {
        if (isNoData && !isFiltered) { return DataState.NO_DATA }
        if (isNoData && isFiltered) { return DataState.NO_DATA_WITH_FILTERS }
        else { return DataState.DATA }
    }

    const onboardingDataState = get_data_state(props.data?.every(entry => entry.count === 0), props.isFiltered);

    return (
        <div className="relative w-1/2 overflow-hidden flex-grow">
           <TemplateChartSection
               title={t("text22")}
               toolTip={t("text26")}
               dataState={onboardingDataState}
           >
                <RingPieChart
                    customLegendStyle="style2"
                    showPercentageLabel
                    hideValueInLegend
                    data={
                        onboardingDataState === DataState.DATA ? (
                            props.data?.map((entry, index) => {
                                return {
                                    label: t(`tables:onboarding_status.${entry.key}`),
                                    value: entry.count,
                                    color: chartStyles.ONBOARDING_COLORS[index]
                                }
                            })
                        ) : (
                            DUMMY_CHART_DATA.slice(0, 4).map((dataItem, index) => {
                                return {
                                    label: dataItem.label,
                                    value: dataItem.value,
                                    color: chartStyles.ONBOARDING_COLORS[index]
                                }
                            })
                        )
                    }
                    paddingAngle={10}
                    innerRadius={70}
                    outerRadius={100}
                    // style2label={t("text24")}
                />
            </TemplateChartSection>
        </div>
    );
};

export default OverallProgress;
