import {startOfYear, subMonths} from "date-fns";
import moment from "moment";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {CalendarIcon} from "@heroicons/react/outline";
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import DropdownWrapper from "./DropdownWrapper";
import CalenderButton from "./Table/CalenderButton";
import {CalendarSelectionType} from "../../models";
import {Colors} from "../../constants/enums";
import {de, enUS, fr} from "date-fns/locale";
import {useTranslation} from "react-i18next";
import ENG from "../../locales/eng/general.json";
import Fr from "../../locales/fr/general.json";

interface PresetFilter {
    label: string;
    months?: number;
    yearStart?: boolean;
}

const PRESET_FILTERS: PresetFilter[] = [
    {label: "date_range.three_month_filter", months: 3},
    {label: "date_range.six_month_filter", months: 6},
    {label: "date_range.twelve_month_filter", months: 12},
    {label: "date_range.current_year_filter", yearStart: true}
];

const DATE_FORMAT = "DD/MM/YYYY";
const DATE_SEPARATOR = "/";
const RANGE_SEPARATOR = " - ";

const formatDateRange = (start: Date | null, end: Date | null): string => {
    if (!start || !end) return "";
    return `${moment(start).format(DATE_FORMAT)} - ${moment(end).format(DATE_FORMAT)}`;
};

const getFilteredStartDate = (filter: PresetFilter): Date => {
    const endDate = new Date();
    if (filter.yearStart) {
        return startOfYear(endDate);
    }
    return filter.months ? subMonths(endDate, filter.months) : endDate;
};

const formatPartialDateInput = (value: string): string => {
    const numbers = value.replace(/\D/g, '');
    const parts = [];

    const day = numbers.slice(0, 2);
    const month = numbers.slice(2, 4);
    const year = numbers.slice(4, 8);

    if (day) parts.push(day);
    if (month) parts.push(month);
    if (year) parts.push(year);

    return parts.join(DATE_SEPARATOR);
};

const formatRangeInput = (value: string): string => {
    const [startPart, endPart] = value.split(RANGE_SEPARATOR);

    const formattedStart = startPart ? formatPartialDateInput(startPart) : '';
    const formattedEnd = endPart ? formatPartialDateInput(endPart.trim()) : '';

    if (formattedEnd) {
        return `${formattedStart}${RANGE_SEPARATOR}${formattedEnd}`;
    }

    return formattedStart;
};

const MyDateRange = ({
                         state,
                         setState,
                         style
                     }: {
    state: CalendarSelectionType[];
    setState: Dispatch<SetStateAction<CalendarSelectionType[]>>;
    style?: string;
}) => {
    const {i18n} = useTranslation();
    i18n.addResourceBundle("en", "general", ENG);
    i18n.addResourceBundle("fr", "general", Fr);
    const {t} = useTranslation("general");
    const languageToLocaleMap: { [key: string]: Locale } =
        {
            en: enUS,
            de: de,
            fr: fr,
        }
    const currentLocale = languageToLocaleMap[i18n.language] || enUS;
    const [isOpen, setIsOpen] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>("");
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const [localState, setLocalState] = useState<CalendarSelectionType[]>(state);
    const [label, setLabel] = useState<string>("");
    const [hasValue, setHasValue] = useState<boolean>(false);

    const displayValue = useMemo(() => {
        if (isCalendarOpen && inputValue) {
            return inputValue;
        }
        const value = formatDateRange(localState[0]?.startDate, localState[0]?.endDate);
        return localState[0]?.startDate ? value : t("date_range.all_time_filter");
    }, [localState, t, isCalendarOpen, inputValue]);

    const handleQuickFilter = (filter: PresetFilter) => {
        const newStartDate = getFilteredStartDate(filter);
        const newEndDate = new Date();

        setSelectedFilter(filter.label);
        setInputValue('');
        setLocalState([{startDate: newStartDate, endDate: newEndDate, key: "selection"}]);
    };

    const handleDateChange = (selection: any) => {
        const {startDate, endDate} = selection;
        if (startDate && endDate) {
            setLocalState([selection]);
            setInputValue(formatDateRange(startDate, endDate));
            setSelectedFilter("");
        }
    };

    const handleClear = () => {
        setSelectedFilter("");
        setInputValue("");
        const clearedState = [{startDate: null, endDate: null, key: "selection"}];
        setLocalState(clearedState);
        setState(clearedState);
    };

    const handleApply = () => {
        if (localState[0].startDate && localState[0].endDate) {
            setState(localState);
            setIsCalendarOpen(false);
            setIsOpen(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target;
        const newValue = input.value;
        const cursorPosition = input.selectionStart || 0;

        // Calculate the number of separators before the cursor in the original value
        const originalSeparatorsBeforeCursor = (
            newValue.slice(0, cursorPosition).match(/[-/]/g) || []
        ).length;

        const formattedValue = formatRangeInput(newValue);
        setInputValue(formattedValue);

        const newSeparatorsBeforeCursor = (
            formattedValue.slice(0, cursorPosition).match(/[-/]/g) || []
        ).length;

        const adjustedPosition = cursorPosition + (newSeparatorsBeforeCursor - originalSeparatorsBeforeCursor);

        const [startStr, endStr] = formattedValue.split(RANGE_SEPARATOR);

        if (startStr?.length === 10 && endStr?.length === 10) {
            const start = moment(startStr, DATE_FORMAT).toDate();
            const end = moment(endStr, DATE_FORMAT).toDate();

            if (start && end && !isNaN(start.getTime()) && !isNaN(end.getTime())) {
                setLocalState([{startDate: start, endDate: end, key: "selection"}]);
                setSelectedFilter("");
            }
        }

        requestAnimationFrame(() => {
            if (inputRef.current) {
                inputRef.current.selectionStart = adjustedPosition;
                inputRef.current.selectionEnd = adjustedPosition;
            }
        });
    };

    useEffect(() => {
        if (displayValue === t("date_range.all_time_filter")) {
            setSelectedFilter("");
            setInputValue("");
            setLabel(displayValue);
            setHasValue(false);
        }
    }, [displayValue]);

    useEffect(() => {
        setLocalState(state);
        setIsOpen(!isOpen);
        setLabel(displayValue);
        setHasValue(!!localState[0]?.startDate);
    }, [state]);

    return (
        <div className="m-auto">
            <DropdownWrapper button={
                <div className="flex gap-3 sm:flex-row flex-col">
                    <CalenderButton
                        type="text"
                        className={style}
                        readOnly
                        value={label}
                        hasValue={hasValue}
                        onClear={handleClear}
                        onClick={() => {
                            setIsOpen(!isOpen);
                            setIsCalendarOpen(false);
                        }}
                    />
                </div>}
                             body={
                                 <div className="w-[250px]">
                                     <div className="px-4 pt-2">
                                         <div className="relative flex items-center w-full">
                                             <input
                                                 ref={inputRef}
                                                 type="text"
                                                 className={`w-full h-[25px] px-2 py-2 font-normal text-gray-500  border border-gray-200 rounded-[4px] focus:outline-none focus:ring-1 focus:ring-red-400 ${displayValue === t("date_range.all_time_filter") ? "text-center" : ""} ${style}`}
                                                 value={displayValue === t("date_range.all_time_filter") ? t("date_range.pick_date_range") : displayValue}
                                                 onChange={handleInputChange}
                                                 placeholder="DD/MM/YYYY - DD/MM/YYYY"
                                                 onFocus={() => setIsCalendarOpen(true)}
                                                 onKeyDown={(e) => {
                                                     if (e.key === "Enter" && localState[0]?.startDate && localState[0]?.endDate) {
                                                         handleApply();
                                                     }
                                                 }}
                                             />
                                             <button
                                                 onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                                                 className="absolute right-2 focus:outline-none"
                                             >
                                                 <CalendarIcon
                                                     className="w-4 h-4 text-gray-300 stroke-[2.5px] hover:text-gray-600"/>
                                             </button>
                                         </div>
                                     </div>

                                     {isCalendarOpen && (
                                         <div className="z-50 flex items-center justify-center">
                                             <div
                                                 className="absolute left-[-135%] top-0 bg-white rounded-lg shadow-lg"
                                                 onClick={(e) => e.stopPropagation()}
                                             >
                                                 <DateRange
                                                     editableDateInputs={true}
                                                     onChange={(item) => handleDateChange(item.selection)}
                                                     moveRangeOnFirstSelection={false}
                                                     rangeColors={[Colors.red]}
                                                     locale={currentLocale}
                                                     showDateDisplay={false}
                                                     minDate={moment().subtract(5, 'years').toDate()}
                                                     maxDate={moment().add(5, 'years').toDate()}
                                                     className="border border-gray-200 rounded-lg"
                                                     // @ts-ignore
                                                     ranges={localState}
                                                 />
                                             </div>
                                         </div>
                                     )}
                                     <hr className="w-full mb-0"/>
                                     <div className="flex flex-col border-b-2">
                                         {PRESET_FILTERS.map((filter) => (
                                             <label
                                                 key={filter.label}
                                                 className={`flex items-center hover:bg-[#DEEBFF] cursor-pointer px-1 py-2 ${
                                                     selectedFilter === filter.label ? "bg-[#FFE6EE]" : "bg-white"
                                                 }`}
                                             >
                                                 <input
                                                     type="radio"
                                                     name="quickFilter"
                                                     value={filter.label}
                                                     checked={selectedFilter === filter.label}
                                                     onChange={() => {
                                                         handleQuickFilter(filter);
                                                         setIsCalendarOpen(false)
                                                     }}
                                                     className={`w-4 h-4 ml-2 appearance-none border-2 rounded-full transition-all relative
                                        ${
                                                         selectedFilter === filter.label
                                                             ? "bg-[#FD0054] border-[#FD0054] after:absolute after:w-[8px] after:h-[8px] after:bg-white after:rounded-full after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2"
                                                             : "bg-white border-gray-300"
                                                     }`}
                                                 />
                                                 <span className=" ml-2 font-normal text-gray-700">
                                      {t(filter.label)}
                                    </span>
                                             </label>
                                         ))}
                                     </div>

                                     <div className="flex justify-end px-4 py-1">
                                         <button
                                             onClick={handleApply}
                                             className="px-4 py-1 text-sm font-medium text-white bg-black rounded-md"
                                         >
                                             {t("date_range.apply")}
                                         </button>
                                     </div>
                                 </div>
                             }
                             bodyWrapperClassName=" "
                             isOpen={isOpen}
            />
        </div>
    );
};

export default MyDateRange;
