import {CSSProperties, useCallback} from "react";

import {Widget} from "@typeform/embed-react";
import {useTranslation} from "react-i18next";
import useAuth from "../../utils/hooks/useAuth";


type Props = {
    style?: CSSProperties;
    onSubmit?: () => void;
};

const AssessmentAI = ({style, onSubmit}: Props) => {
    const {t} = useTranslation("general");
    const {user: authUser} = useAuth();

    const handleFormSubmit = useCallback((event: any) => {
        onSubmit && onSubmit();
        // Perform actions with the form data
        // event sample =>  {
        //     "response_id": "vee9ixyemvk0vo1xuvee9i69d79ueifq",
        //     "responseId": "vee9ixyemvk0vo1xuvee9i69d79ueifq",
        //     "formId": "TjGf5Xk3"
        // }
    }, []);;

    if (!authUser) {
        return <h2>{t("try_again")}</h2>;
    } else {
        return (
            <Widget
                onSubmit={handleFormSubmit}
                hidden={{
                    useremail: authUser?.email,
                    userlastname: authUser?.lastName,
                    userfirstname: authUser?.firstName,
                    companyname: authUser?.companyName,
                }}
                id={"KOGEbEkd"}
                style={
                    style ? style : {width: "100%", height: "60vh", flex: "1 1 auto"}
                }
            />
        );
    }
};

export default AssessmentAI;
