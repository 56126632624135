import {Typography} from "antd";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TimeSlotsFrequency from "../Components/TimeSlotFrequency";
import {useTranslation} from "react-i18next";
import InputFormik from "../../../../../shared/components/formik/InputFormik/InputFormik";
import SelectFormik from "../../../../../shared/components/formik/SelectFormik";
import SubmitFormik from "../../../../../shared/components/formik/SubmitFormik/SubmitFormik";
import moment from "moment";
import {IClientCompany} from "../../../../../constants/types"

const BookOnBehalf = (params: any) => {
    const {t} = useTranslation("events");

    const clientOptions = params?.clients;

    const formattedClientOptions = clientOptions.map((client: IClientCompany) => ({
        id: client.id,
        label: `${client.name} (${client.company})`,
    }));

    return (
        <>
            <div className="w-full">
                <Formik
                    initialValues={
                        {
                            ...params?.initials?.bookOnBehalf,
                            date: moment(params.date),
                            startTime: moment(params.startTime),
                            endTime: moment(params.endTime)
                        }
                    }
                    validationSchema={Yup.object({
                        remarks: Yup.string().required(t("required")),
                        client: Yup.object().required(t("required")),
                    })}
                    onSubmit={(values, action) => {
                        const adjustedDate = values.date
                        const adjustedStartTime = values.startTime
                        const adjustedEndTime = values.endTime
                        params._handleSubmit({
                            ...values,
                            isBloked: false,
                            isBooked: true,
                            date: adjustedDate,
                            startTime: adjustedStartTime,
                            endTime: adjustedEndTime,
                            action,
                        })
                    }}
                >
                    {({setFieldValue}) => (
                        <Form className="p-5">
                            <div className="w-full flex flex-col gap-5 py-4">
                                <div>
                                    <Typography className="font-bold">
                                        {t("book_behalf.client")}
                                    </Typography>
                                    <div>
                                        <div>
                                            <SelectFormik
                                                name="client"
                                                labelKey="label"
                                                valueKey="id"
                                                isMulti={false}
                                                options={formattedClientOptions}
                                                placeholder={t("select")}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <TimeSlotsFrequency title={t("book_behalf.blocked_frequency")} params={params} /> */}

                                <div>
                                    <Typography className="font-bold">
                                        {t("book_behalf.subject_remarks")}
                                    </Typography>
                                    <div>
                                        <InputFormik
                                            label=""
                                            name="remarks"
                                            onKeyUp={(e: any) => {
                                            }}
                                            className="p-3 border-solid border border-[#B3B3B3] rounded"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <TimeSlotsFrequency
                                        title={t("book_behalf.blocked_frequency")}
                                        params={params}
                                        setFieldValue={setFieldValue}
                                        note={t("book_behalf.note")}
                                        bookOnBehalf
                                    />
                                </div>

                                <div>
                                    <SubmitFormik
                                        className="dashboard-button w-full bg-black py-3 justify-center"
                                        text={t("applyBtn")}
                                        onSubmittingText="Updating..."
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default BookOnBehalf;
