import {DropdownWrapper} from "@components";
import {useTranslation} from "react-i18next";
import useAuth from "@hooks/useAuth";
import Select from "react-select";
import {useEffect, useState} from "react";
import metaApi from "../../services/api/metaApi";
import storage from "../../services/storage/localStorage";
import {ERole} from "../../constants/enums";
import UserDropdownItemWrapper from "./UserDropdownItemWrapper";
import {Link} from "react-router-dom";
import {useConfig} from "../../utils/providers/AppConfigProvider";
import logoImgSm from "@img/coachello.svg";
import logoImg from "@img/coachelloLogo.png";
import Img from "../../shared/components/Img";
import defaultProfileImg from "@img/icons/default-profile.png";
import {AICoachType} from "../../modules/dashboard/pages/AICoaching/enum";

const Navbar = ({navTitle}: { navTitle?: string }) => {
    const {t} = useTranslation("layout");
    const {logout, user, switchRole} = useAuth();
    const {config, fetchConfig} = useConfig();
    const [switchToCoachee, setSwitchToCoachee] = useState(
        user?.role == ERole.Coachee
    );

    const [selectedOption, setSelectedOption] = useState<any>({
        label: user?.companyName,
        value: user?.companyId,
    });
    const [companies, setCompanies] = useState<any[]>([]);

    // handle onChange event of the dropdown
    const handleChange = async (e: any) => {
        setSelectedOption(e);

        let obj = storage.get("user");
        obj.companyId = e.value;
        obj.companyName = e.label;
        storage.set("user", obj);
        await fetchConfig(obj)
        window.location.reload();
    };

    // TODO : remove this later
    useEffect(() => {
        if (user?.role === ERole.Coachee && user?.aiCoachType !== AICoachType.ai_only && user?.aiCoachType !== AICoachType.ai_augmented && user?.aiCoachType !== AICoachType.human_only && user?.aiCoachType !== AICoachType.hybrid){
            logout()
        }
    }, []);


    useEffect(() => {
        if (user?.role != ERole.Coachee && switchToCoachee) {
            switchRole(ERole.Coachee)
        } else if (user?.role == ERole.Coachee && !switchToCoachee) {
            const role = user.roles?.find(role => role !== ERole.Coachee)
            switchRole(role || ERole.HrAdmin)

        }

    }, [switchToCoachee]);
    useEffect(() => {
        if (companies.length == 0) {
            metaApi
                .getCompanies()
                .then((res) => {
                    if (isCrfeg()) {
                        setCompanies([{
                            label: 'CRFEG-CRFE',
                            value: 91,
                        },
                            {
                                label: 'CRFEG-LFS',
                                value: 92,
                            },
                            {
                                label: 'CRFEG-LUNEX',
                                value: 93,
                            }
                        ])

                    } else {
                        setCompanies(
                            res.map((obj: any) => {
                                return {
                                    label: obj.name,
                                    value: obj.id,
                                };
                            })
                        );
                    }

                })
                .catch((e) => {
                    console.log("api error");
                });
        }

    }, []);

    const isCrfeg = () => {
        return ((user?.role == ERole.HrAdmin || user?.role == ERole.ItAdmin) && ['CRFEG-LUNEX', 'CRFEG-LFS', 'CRFEG-CRFE'].includes(user?.companyName))
    }

    return (
        <nav
            className="flex items-center justify-between px-1.5 md:px-6 lg:px-6 xl::px-6 py-1.5 bg-white fixed w-full z-40 border-warm-dark border-0 border-b-[1px]">
            <div className="flex flex-row gap-3 items-center">
                <div className="w-10 md:w-44 m-0">
                    <Img className="max-h-8" triggerPx={768} srcSmall={config?.app_logo ? config?.app_logo : logoImgSm}
                         srcLarge={config?.app_logo ? config?.app_logo : logoImg}/>
                </div>
                <div className="leading-relaxed inline-block mr-4 py-2 whitespace-nowrap ml-10">
                    <h4 className="font-bold text-black">{navTitle || ""}</h4>
                </div>
            </div>
            <div className="flex items-center gap-8">
                {/*Hack for super admin*/}
                {user?.role == ERole.SuperAdmin || isCrfeg() ? (
                    <Select
                        isSearchable
                        placeholder="Select a company"
                        className="min-w-[200px] z-50"
                        value={selectedOption}
                        options={companies}
                        onChange={handleChange}
                        menuPlacement="top"
                    />
                ) : undefined}

                {(user?.roles?.length && user?.roles?.length > 1) ?
                    <div className="flex font-bold text-md items-center gap-3">

                        <div className="inline-block py-2 px-1 bg-warm-light rounded-2xl whitespace-nowrap">
                          <span>
                            <input
                                onChange={(e) => setSwitchToCoachee(false)}
                                type="radio"
                                id="noshow"
                                className="hidden peer"
                                checked={!switchToCoachee}
                            />
                            <label
                                htmlFor="noshow"
                                className="bg-warm-light rounded-2xl py-1 px-3 select-none cursor-pointer peer-checked:bg-red peer-checked:text-white font-normal"
                            >
                             {t("navbar.text6")}
                            </label>
                          </span>
                            <span>
                            <input
                                onChange={(e) => setSwitchToCoachee(true)}
                                type="radio"
                                id="attended"
                                className="hidden peer"
                                checked={switchToCoachee}
                            />
                            <label
                                htmlFor="attended"
                                className="bg-warm-light rounded-2xl py-1 px-3 select-none cursor-pointer peer-checked:bg-red peer-checked:text-white font-normal"
                            >
                             {t("navbar.text5")}
                            </label>
                          </span>
                        </div>
                    </div>
                    : undefined}
                {/* <div className="lg:mr-20 mr-10 relative">
                    <span className="w-3 h-3 rounded-full bg-red absolute right-0 top-0"/>
                    <Svg Icon={notificationIcon}/>
                </div> */}
                <DropdownWrapper
                    button={
                        <div className="cursor-pointer flex items-center justify-between gap-1.5 group">
                            <img
                                className="w-8 h-8 rounded-full object-cover group-hover:ring-4 group-hover:ring-red"
                                src={
                                    user?.imageUrl ||
                                    defaultProfileImg
                                }
                            />
                            <span className=""> {t("navbar.text1")},</span>
                            <span className="hidden sm:block">
                {" "}
                                {user?.firstName} {user?.lastName}!
              </span>
                            <svg
                                className="ml-1 w-4 h-4"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </div>
                    }
                    body={
                        <>
                            <div className="py-1">
                                <UserDropdownItemWrapper
                                    allowedRoles={[ERole.Coach]}
                                    userRole={user?.role}
                                >
                                    <Link
                                        to="profile"
                                        className="text-gray-700 block px-4 py-2 text-sm w-full"
                                    >
                                        {t("navbar.text3")}
                                    </Link>
                                </UserDropdownItemWrapper>
                                {/*todo:Include with the Calendar update*/}
                                {/*<UserDropdownItemWrapper userRole={user?.role}>*/}
                                {/*    <Link to="settings"*/}
                                {/*          className="text-gray-700 block px-4 py-2 text-sm w-full">{t('navbar.text4')}*/}
                                {/*    </Link>*/}
                                {/*</UserDropdownItemWrapper>*/}
                                <hr/>
                                <UserDropdownItemWrapper userRole={user?.role}>
                                    <button
                                        onClick={() => logout()}
                                        className="text-gray-700 block px-4 py-2 text-sm w-full"
                                    >
                                        {t("navbar.text2")}
                                    </button>
                                </UserDropdownItemWrapper>
                            </div>
                        </>
                    }
                />
            </div>
        </nav>
    );
};

export default Navbar;
//
// const Navbar = () => {
//     const [navbarOpen, setNavbarOpen] = React.useState(false);
//
//     return (<>
//         <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3">
//             <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
//                 <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
//                     <Link
//                         className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
//                         to="#">
//                         <h4>Coaching</h4>
//
//                     </Link>
//                     <button
//                         className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
//                         type="button"
//                         onClick={() => setNavbarOpen(!navbarOpen)}>
//                         <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
//                              xmlns="http://www.w3.org/2000/svg">
//                             <path fill-rule="evenodd"
//                                   d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
//                                   clip-rule="evenodd"></path>
//                         </svg>
//
//                     </button>
//                 </div>
//                 <div
//                     className={
//                         "lg:flex flex-grow items-center" +
//                         (navbarOpen ? " flex" : " hidden")
//                     }
//
//                 >
//                     <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
//                         <li className="nav-item">
//                             <div
//                                 className="px-3 py-2 flex items-center text-xs font-bold leading-snug hover:opacity-75"
//                             >
//                                 <i className="fab fa-facebook-square text-lg leading-lg opacity-75"></i><span
//                                 className="ml-2">Share</span>
//                             </div>
//                         </li>
//                         <li className="nav-item">
//
//
//
//
//                         </li>
//
//                     </ul>
//                 </div>
//                 <DropdownWrapper button={
//                     <div
//                         className="px-3 py-2 flex items-center text-xs font-bold leading-snug">
//                         <img className="w-10 h-10 rounded-full"
//                              src="https://images.unsplash.com/photo-1610397095767-84a5b4736cbd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"/>
//                         <span
//                             className="ml-2">Lasitha</span>
//                         <span
//                             className="ml-2">Jayawardana</span>
//                         <svg className="ml-1 w-4 h-4" aria-hidden="true" fill="currentColor"
//                              viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                             <path fill-rule="evenodd"
//                                   d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                                   clip-rule="evenodd"></path>
//                         </svg>
//                     </div>
//                 } body={<>
//                     <div className="py-1" role="none">
//
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Edit</a>
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Duplicate</a>
//                     </div>
//                     <div className="py-1" role="none">
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Archive</a>
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Move</a>
//                     </div>
//                     <div className="py-1" role="none">
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Share</a>
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Add to favorites</a>
//                     </div>
//                     <div className="py-1" role="none">
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Delete</a>
//                     </div>
//                 </>
//                 }/>
//             </div>
//         </nav>
//
//         <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
//             <div className="container flex flex-wrap justify-between items-center mx-auto">
//                 <a href="https://flowbite.com/" className="flex items-center">
//                     <img src="https://flowbite.com/docs/images/logo.svg" className="mr-3 h-6 sm:h-9"
//                          alt="Flowbite Logo"/>
//                         <span
//                             className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
//                 </a>
//                 <div className="flex md:order-2">
//                     <button type="button"
//                             className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Get
//                         started
//                     </button>
//                     <button  onClick={() => setNavbarOpen(!navbarOpen)}  type="button"
//                             className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//                             >
//                         <span className="sr-only">Open main menu</span>
//                         <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
//                              xmlns="http://www.w3.org/2000/svg">
//                             <path fill-rule="evenodd"
//                                   d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
//                                   clip-rule="evenodd"></path>
//                         </svg>
//                     </button>
//                 </div>
//                 <div className={"justify-between items-center w-full md:flex md:w-auto md:order-1" +
//                     (navbarOpen ? " flex" : " hidden")}
//                      id="navbar-cta">
//                     <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
//                         <li>
//                             <a href="#"
//                                className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white"
//                                aria-current="page">Home</a>
//                         </li>
//                         <li>
//                             <a href="#"
//                                className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
//                         </li>
//
//                     </ul>
//                 </div>
//             </div>
//         </nav>
//
//     </>
//     );
// };
//
// export default Navbar;
