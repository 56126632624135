import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import ObjectiveStep from "./ObjectiveStep";
import Button from "../../../shared/components/Button";
import { getAssessmentScore } from "../../../services/api/teams/AssessmentActions";
import useAuth from "../../../utils/hooks/useAuth";
import {
    setClientObjectives,
} from "../../../services/api/teams/GuideActions";
import {TAssessmentScore, TSelfReflection} from "../../../constants/types";
import selfReflectionData from "../../../assets/selfReflection.json";
import { IMeta } from "../../../models/IMeta";
import i18n from "../../../utils/lib/i18n";
import HomeENG from "../locale/eng/home.json";
import HomeFR from "../locale/fr/home.json";
import {Objective} from "../pages/AICoaching/types";

type Props = {
    setIsOpen:(isOpen:boolean)=>void
    initialObjectives?: Objective[] | null;
}
const ObjectiveModalContent = ({ setIsOpen, initialObjectives }:Props) => {
    i18n.addResourceBundle("en", "home", HomeENG);
    i18n.addResourceBundle("fr", "home", HomeFR);
    const { t } = useTranslation("home");
    const { user } = useAuth();
    const client_id = user!.id;
    const [assessmentScore, setAssessmentScore] = useState<TAssessmentScore | {}>(
        {}
    );
    const [objectivesValidated, setObjectivesValidated] = useState(false);
    const [selectedObjectives, setSelectedObjectives] = useState<IMeta[]>(initialObjectives || []);
    const [loading, setLoading] = useState(false);

    const handleGetAssessmentScore = async () => {
        const res = await getAssessmentScore(client_id);
        setAssessmentScore(res);
    };

    const handleSubmission = () => {
        const objectiveData = {
            objectives: selectedObjectives,
        };
        setLoading(true);
        setClientObjectives(objectiveData)
            .then(() => {
                setIsOpen(false);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="h-full overflow-y-scroll">
            <ObjectiveStep
                hideChatGuide
                selfReflections={selfReflectionData as TSelfReflection[]}
                assessmentScore={assessmentScore as TAssessmentScore}
                handleGetAssessmentScore={handleGetAssessmentScore}
                onValidated={setObjectivesValidated}
                selectedObjectives={selectedObjectives}
                setSelectedObjectives={setSelectedObjectives}
            />
            <div className="w-full flex justify-center">
                <Button
                    disabled={!objectivesValidated}
                    className={twMerge(
                        "w-36 flex justify-center border-2 bg-black text-white border-black text-xs font-bold h-max px-4 py-2",
                        "transition-all duration-500",
                        !objectivesValidated
                            ? "cursor-not-allowed bg-transparent text-black opacity-25"
                            : ""
                    )}
                    onClick={objectivesValidated ? handleSubmission : undefined}
                    loading={loading}
                >
                    {t("objectives.submit")}
                </Button>
            </div>
        </div>
    );
};

export default ObjectiveModalContent;
