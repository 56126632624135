import React from "react";

import "react-circular-progressbar/dist/styles.css";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import CoachingObjective from "./CoachingObjective";

import {
  TAssessmentScore,
  TSelfReflection,
} from "../../../../../constants/types";
import { IMeta } from "../../../../../models/IMeta";

import careerDevelopmentImage from "@img/dashboard/welcome/img/career_development.png";
import workEnvironmentImage from "@img/dashboard/welcome/img/work_environment.png";
import jobPerformanceImage from "@img/dashboard/welcome/img/job_performance.png";
import leadershipImage from "@img/dashboard/welcome/img/leadership.png";
import communicationImage from "@img/dashboard/welcome/img/communication.png";
import qualityOfLifeImage from "@img/dashboard/welcome/img/quality_of_life.png";
import selfAwarenessImage from "@img/dashboard/welcome/img/self_awareness.png";
import stressAndAnxietyImage from "@img/dashboard/welcome/img/stress_and_anxiety.png";

type Props = {
  assessmentScore: TAssessmentScore;
  hideObjectives?: boolean;
  onSelectObjective?: (objective: IMeta) => void;
  selectedObjectives?: IMeta[];
  lng?: string;
} & TSelfReflection;

const SelfReflectionCard: React.FC<Props> = ({
  title,
  title_fr,
  percentage,
  coachingObjectives,
  apiResponseField,
  assessmentScore,
  hideObjectives,
  onSelectObjective,
  selectedObjectives = [],
  lng,
}) => {
  const imageMap = new Map([
    ["career", careerDevelopmentImage],
    ["intellect", workEnvironmentImage],
    ["organisation", jobPerformanceImage],
    ["leadership", leadershipImage],
    ["communication", communicationImage],
    ["worklife", qualityOfLifeImage],
    ["assertivity", selfAwarenessImage],
    ["self_esteem", stressAndAnxietyImage],
  ]);

  const scorePercentage = assessmentScore[apiResponseField];
  const displayPercentage = scorePercentage ? +scorePercentage : percentage;

  return (
    <li className="relative h-full">
      <div className="bg-white rounded-md relative py-5 px-4 h-full flex flex-col">
        <div className="w-full overflow-hidden bg-white rounded-t-lg aspect-w-3 aspect-h-2">
          <div className="flex flex-col justify-between">
            <div className="flex flex-row items-center basis-2/3 overflow-hidden">
              <div className="basis-1/2 flex justify-center h-full">
                <div className="overflow-hidden bg-center bg-cover flex justify-end items-end">
                  <img
                    src={imageMap.get(apiResponseField)}
                    alt=""
                    className="object-contain pointer-events-none h-full"
                  />
                </div>
              </div>
              <div className="basis-1/2 flex justify-center">
                <div className="w-2/3">
                  <CircularProgressbar
                    value={displayPercentage}
                    text={+displayPercentage ? `${displayPercentage}%` : ""}
                    strokeWidth={12}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "round",
                      textSize: "20px",
                      pathTransitionDuration: 1,
                      pathColor: +displayPercentage ? "#EF476F" : "#ffffff",
                      textColor: "#000000",
                      trailColor: +displayPercentage ? "#fdedf1" : "#ffffff",
                    })}
                  />
                </div>
              </div>
            </div>
            <p className="font-bold text-xl font-dm_sans">
              {lng === "fr" ? title_fr : title}
            </p>
          </div>
        </div>
        {!hideObjectives && coachingObjectives.length ? (
          <div className="flex flex-col gap-2 mt-5">
            {coachingObjectives.map((objective, index) => (
              <CoachingObjective
                key={index}
                lng={lng}
                objective={objective}
                onClick={() =>
                  onSelectObjective && onSelectObjective(objective)
                }
                selectedObjectives={selectedObjectives}
              />
            ))}
          </div>
        ) : null}
      </div>
    </li>
  );
};

export default SelfReflectionCard;
