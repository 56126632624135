import React, {useState} from "react";
import Select, {components} from "react-select";
import {useTranslation} from "react-i18next";
import ReactModal from "react-modal";
import {addCompanyManager} from "../../../../../services/api/companyApi";
import useAuth from "@hooks/useAuth";
import {AiOutlinePlus} from "react-icons/ai";
import {toast} from "react-toastify";

interface ManagerPickerFieldProps {
    value: any;
    onChange: (value: any) => void;
    managerOptions: any[];
    onCreateManagerOption: (manager: any) => void;
    isMultiSelection?: boolean;
}

const ManagerPickerField: React.FC<ManagerPickerFieldProps> = ({
                                                                   value,
                                                                   onChange,
                                                                   managerOptions,
                                                                   onCreateManagerOption,
                                                                   isMultiSelection = false,
                                                               }) => {
    const {t} = useTranslation("groups:create_group");

    const {user} = useAuth();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            borderColor: "#cbd5e0",
            boxShadow: "none",
            "&:hover": {borderColor: "#a0aec0"},
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    const validateForm = () => {
        const errors = {
            first_name: "",
            last_name: "",
            email: "",
        };

        if (!newManager.first_name) {
            errors.first_name = t("manager.first_name_required");
        }

        if (!newManager.last_name) {
            errors.last_name = t("manager.last_name_required");
        }

        if (!newManager.email) {
            errors.email = t("manager.email_required");
        } else if (!/\S+@\S+\.\S+/.test(newManager.email)) {
            errors.email = t("manager.email_invalid");
        }

        setFormErrors(errors);

        return !errors.first_name && !errors.last_name && !errors.email;
    };

    const handleCreate = () => {
        if (validateForm()) {
            setIsAdding(true);
            addCompanyManager(user?.companyId!, newManager)
                .then((res) => {
                    const newOption = {
                        id: res.data.id,
                        name: res.data.name,
                    };
                    onCreateManagerOption(newOption);
                })
                .catch((err) => {
                    toast.error(t("create_group.text71", {email: newManager.email}));
                })
            setIsAdding(false);
            setIsModalOpen(false);
            setNewManager({
                first_name: "",
                last_name: "",
                email: "",
            });
        }
    };

    const options = managerOptions.map((option) => ({
        value: option.id,
        label: option.name,
    }));

    const [newManager, setNewManager] = useState({
        first_name: "",
        last_name: "",
        email: "",
    });
    const [formErrors, setFormErrors] = useState<{
        first_name: string;
        last_name: string;
        email: string;
    }>({
        first_name: "",
        last_name: "",
        email: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setNewManager({
            ...newManager,
            [name]: value,
        });

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name as keyof typeof prevErrors]: value ? "" : t(`manager.${name}_required`),
        }));
    };

    const customMenuList = (props: any) => (
        <components.MenuList {...props}>
            {props.children}
            <div className="p-1 border-t">
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="flex flex-row items-center justify-center bg-white text-xs text-green-600 rounded-md hover:bg-gray-200 transition duration-200 p-1.5 w-full">
                    <AiOutlinePlus className="w-3 h-3 mr-0.5"/>
                    {t("manager.add_manager")}
                </button>
            </div>
        </components.MenuList>
    );

    return (
        <div className="w-52">
            <Select
                value={
                    value
                        ? Array.isArray(value)
                            ? value.map((v) => ({
                                value: v.id,
                                label: v.name,
                            }))
                            : {value: value.id, label: value.name}
                        : null
                }
                onChange={(selected) => {
                    if (Array.isArray(selected)) {
                        const selectedValue = selected.map((opt) => ({
                            id: opt.value,
                            name: opt.label,
                        }));
                        onChange(selectedValue);
                    } else if (selected && "label" in selected) {
                        const selectedValue = {
                            id: selected.value,
                            name: selected.label,
                        };
                        onChange(selectedValue);
                    } else {
                        onChange(null);
                    }
                }}
                options={options}
                isMulti={isMultiSelection}
                styles={customStyles}
                placeholder={t("manager.placeholder")}
                className="text-sm"
                components={{MenuList: customMenuList}}
            />

            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel={t("add_manager")}
                ariaHideApp={false}
                className="bg-white p-6 rounded-md shadow-lg w-96"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
                <h2 className="text-base font-semibold mb-4">{t("manager.add_manager")}</h2>

                <div className="mb-2">
                    <input
                        type="text"
                        name="first_name"
                        placeholder={t("manager.first_name")}
                        value={newManager.first_name}
                        onChange={handleInputChange}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.first_name && <p className="text-red-500 text-xs">{formErrors.first_name}</p>}
                </div>

                <div className="mb-2">
                    <input
                        type="text"
                        name="last_name"
                        placeholder={t("manager.last_name")}
                        value={newManager.last_name}
                        onChange={handleInputChange}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.last_name && <p className="text-red-500 text-xs">{formErrors.last_name}</p>}
                </div>

                <div className="mb-2">
                    <input
                        type="email"
                        name="email"
                        placeholder={t("manager.email")}
                        value={newManager.email}
                        onChange={handleInputChange}
                        className="border p-2 w-full rounded-md"
                    />
                    {formErrors.email && <p className="text-red-500 text-xs">{formErrors.email}</p>}
                </div>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={() => setIsModalOpen(false)}
                        disabled={isAdding}
                        className="px-4 py-2 bg-gray-200 rounded-md"
                    >
                        {t("manager.cancel")}
                    </button>
                    <button
                        onClick={handleCreate}
                        disabled={isAdding}
                        className="px-4 py-2 bg-black text-white rounded-md"
                    >
                        {isAdding ? t("manager.adding") : t("manager.add")}
                    </button>
                </div>
            </ReactModal>
        </div>
    );
};

export default ManagerPickerField;
