import React from 'react';
import {
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    ResponsiveContainer,
    Tooltip
} from 'recharts';
import {RadarDataItem} from "../../constants/types";
import CustomTooltip from "./BarChart/CustomTooltip";
import {getOrderIndexArr} from "../../utils/helpers/arr";

interface Props {
    data: RadarDataItem[];
    innerRadius?: number;
    outerRadius?: number;
    showPercentageLabel?: boolean;
    fillColor?: string;
    tooltipDataKey?: string;
    radarName?: string;
}

interface DataItem {
    label: string;
    value: number;
    color?: string;
    additionalLabel?: any;
}


const getColourArr = (data: DataItem[]) => {

    const orderIndexes = getOrderIndexArr(data.map((entry, i) => {
        return entry.value
    }))
    const len = data.length + 1
    const potion = 1 / len
    return data.map((value, i) => {
        return `rgba(180, 225, 198,${(orderIndexes[i] + 1) * potion})`
    })
}

const CustomLegendWithPercentage: React.FC<{ data: DataItem[], layoutDirection?: string }> = ({
                                                                                                  data = [],
                                                                                                  layoutDirection = 'row'
                                                                                              }) => {
    const defaultColors = getColourArr(data);
    return (
        <ul>
            {data.map((entry: any, index: number) => (
                <li key={`item-${index}`}>
                    {layoutDirection == 'row' ? (<div className="flex flex-row p-2 py-3 gap-2">
                        <div className="w-8 h-8"
                             style={{backgroundColor: entry.color ? entry.color : defaultColors[index]}}/>
                        <div className="flex flex-col">
                            <span className="font-lexend text-start text-black"> {entry.label}</span>
                            <span className="font-lexend text-start font-bold text-black pl-2"> {entry.value}%</span>
                        </div>
                    </div>) : (
                        <div className="flex flex-row p-1.5 items-center justify-between">
                            <div className="flex flex-row gap-2 items-center">
                                <div className="w-4 h-4 rounded-full"
                                     style={{backgroundColor: entry.color ? entry.color : defaultColors[index]}}/>
                                <span>{entry.label}</span>
                            </div>
                            <span className="self-end font-bold pl-2">{entry.value}%</span>
                        </div>
                    )}
                </li>
            ))}
        </ul>
    );
};

const renderCustomTick = (props: any) => {
    const { x, y, payload, textAnchor } = props;
    const limit = 15;
    const value: string = payload.value.toString();

    if (value.length <= limit || textAnchor === "middle" || textAnchor === "start") {
        return (
            <text x={x} y={y} textAnchor={textAnchor} fill="black" fontSize={14}>
                {value}
            </text>
        );
    }

    const [firstLine, secondLine] = value.split(' ').reduce(
        ([line1, line2], word) => {
            return (line1.length + word.length + 1 <= limit)
                ? [line1 + (line1 ? ' ' : '') + word, line2]
                : [line1, line2 + (line2 ? ' ' : '') + word];
        },
        ['', '']
    );

    return (
        <text x={x} y={y} textAnchor={textAnchor} fill="black" fontSize={14}>
            <tspan x={x} dy={0}>{firstLine}</tspan>
            {secondLine && <tspan x={x} dy={15}>{secondLine}</tspan>}
        </text>
    );
};

const CustomRadarChart: React.FC<Props> = ({
                                               data,
                                               outerRadius = 120,
                                               showPercentageLabel = false,
                                               fillColor,
                                               tooltipDataKey,
                                               radarName
                                           }) => {
    const highlightColor = 'rgba(243, 235, 226, 0.6)'
    const containerWidth = 2 * outerRadius + 100;
    const containerHeight = 2 * outerRadius + 100;
    return (
        <div className="relative h-full">
            {/*<div className="w-1/3">*/}
            {/*    <CustomLegendWithPercentage data={data}/>*/}
            {/*</div>*/}
            <ResponsiveContainer width={"100%"}
                                 height={containerHeight}>

                <RadarChart
                    cx="50%"
                    cy="50%"
                    outerRadius={outerRadius}
                    width={containerWidth}
                    height={containerHeight}
                    data={data}
                >
                    <PolarGrid/>
                    <PolarAngleAxis dataKey="label" tick={renderCustomTick}/>
                    <PolarRadiusAxis tick={{ fill: '#7f7f7f', fontSize: 12 }}/>
                    <Tooltip content={<CustomTooltip customDataKey={tooltipDataKey} customDataKeyColor="#ACC7E8"/>}
                             cursor={{fill: highlightColor, strokeWidth: 1}}/>
                    <Radar
                        name={radarName}
                        dataKey="value"
                        stroke={fillColor}
                        fill={fillColor}
                        fillOpacity={0.6}
                    />
                    {radarName && <Legend iconType="square"/>}
                    {/*<Line name="pv of pages" type="monotone" dataKey="pv" stroke="#8884d8"/>*/}
                    {/*<Line name="uv of pages" type="monotone" dataKey="uv" stroke="#82ca9d"/>*/}

                </RadarChart>
            </ResponsiveContainer>
        </div>
    )
};

export default CustomRadarChart;
