import React, { useEffect, useState } from 'react'
import useAuth from "@hooks/useAuth";
import { useNavTitle } from "@hooks/useNavTitle";
import { useTranslation } from "react-i18next";
import { getInsights, getInsightsLabels } from '../../../../services/api/companyApi';
import InsightCard from './component/InsightCard';
import selfImg from './../../../../assets/img/dashboard/insights/Group.png'
import i2Img from './../../../../assets/img/dashboard/insights/illustration.png'
import i3Img from './../../../../assets/img/dashboard/insights/illustration(1).png'
import i4Img from './../../../../assets/img/dashboard/insights/illustration(2).png'
import i5Img from './../../../../assets/img/dashboard/insights/Isolation_Mode.png'
import i6Img from './../../../../assets/img/dashboard/insights/Group.png'
import InsightAction from "./component/InsightAction";
import Modal from "../../components/Modal";
import LoaderContainer from '../../../../shared/components/LoaderContainer';
import moment, { Moment } from "moment";
import ENG from "../../locale/eng/insights.json";
import Fr from "../../locale/fr/insights.json";
import i18n from "../../../../utils/lib/i18n";
import { Dropdown } from 'rsuite';
import { CalendarIcon } from "@heroicons/react/outline";
import "./insights.scss"
import NoDataWrapper from '../../../../shared/components/NoDataWrapper';

function HRInsights({ ...props }) {
    const { user } = useAuth();

    i18n.addResourceBundle("en", "insights", ENG);
    i18n.addResourceBundle("fr", "insights", Fr);
    const { t } = useTranslation("insights");
    useNavTitle(t(`layout:sidebar.${props.navTitle}`));

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTranslating, setIsTranslating] = useState(false);
    const [isNoData, setIsNoData] = useState(false);
    const [isError, setIsError] = useState(false)
    const [positiveInsights, setPositiveInsights] = useState([]);
    const [negativeInsights, setNegativeInsights] = useState([]);
    const [labels, setLabels] = useState<{ label: string; month: string; value: number }[]>([]);
    const lng = moment.locale();
    const [selectedMonth, setSelectedMonth] = useState({ label: moment().locale(lng).subtract(1, 'months').format("YYYY-MMM"), month: moment().locale(lng).subtract(1, 'months').format("YYYY-M"), value: moment().subtract(1, 'months').month() });
    const posImgs = [selfImg, i2Img]
    const negImgs = [i3Img, i4Img, i5Img, i6Img]

    // Fetch insights data
    const fetchInsights = async (companyId: number, lng: string, month: string) => {
        try {
            const data = await getInsights(companyId, lng, month);
            const positive = data.Positive_Insights || [];
            const negative = data.Negative_Insights || [];
            if (positive.length === 0 && negative.length === 0) {
                setIsNoData(true);
                return;
            }
            setPositiveInsights(positive);
            setNegativeInsights(negative);
        } catch (error) {
            console.error('Error fetching insights:', error);
            setIsError(true)
        }
    };

    // Convert labels
    const convertLabels = (lng: string ) => {
        setSelectedMonth({ label: moment(selectedMonth.month, "YYYY-M").locale(lng).format("YYYY-MMM"), month: selectedMonth.month, value: selectedMonth.value });
        
        const updatedLabels = labels.map((e: any, index: number) => {
            return {
                label:moment(e.month, "YYYY-M").locale(lng).format("YYYY-MMM"),
                month : e.month,
                value: index + 1
            };
        });
        setLabels(updatedLabels);
    };

    // Fetch insights labels
    const fetchLabels = async (companyId: number, lng: string) => {
        try {
            const data = await getInsightsLabels(companyId);
            if (data && data.History_Labels) {
                setLabels(
                    data.History_Labels.map((e: any, index: number) => {
                    return { 
                        label: moment(e.History_Label, "YYYY-M").locale(lng).format("YYYY-MMM"),
                        month : e.History_Label,
                        value: index + 1 };
                    })
                );
            }
        } catch (error) {
            console.error('Error fetching insights labels:', error);
            setIsError(true)
        }
    };

    // Fetch insights labels on user change
    useEffect(() => {
        if (!user) return;
        fetchLabels(user.companyId, lng);
    }, [user]);

    // Fetch insights data on language change
    useEffect(() => {
        if (!user) return;
        setIsTranslating(true);
        convertLabels(lng);
        fetchInsights(user.companyId, lng, selectedMonth.month)
            .then(() => setIsTranslating(false));
    }, [lng])

    // Fetch insights data on selected month or user change
    useEffect(() => {
        if (!user) return;
        setIsLoading(true);
        fetchInsights(user.companyId, lng, selectedMonth.month)
            .then(() => { setIsLoading(false); });

    }, [user, selectedMonth.month]);

    // Handle month change
    const handleChange = (e: any) => {
        setSelectedMonth({ label: e.label, month: e.month, value: e.value });
    };

    const handleRetry = () => {
        setIsError(false);
        setIsLoading(true);
        if (user) {
            fetchInsights(user.companyId, lng, selectedMonth.month)
                .then(() => { setIsLoading(false); })
                .catch(() => { setIsError(true); });
        }
    };


    if (isLoading) {
        return (
            <div className='pt-20 m-auto p-5'>
                <LoaderContainer />
                <h5 className='text-center pt-4' style={{ fontWeight: 'bold' }}>{t("text2")} {selectedMonth.label}</h5>
            </div>
        )
    }
    else if (isTranslating) {
        return (
            <div className='pt-20 m-auto p-5'>
                <LoaderContainer />
                <h5 className='text-center pt-4' style={{ fontWeight: 'bold' }}>{t("text7")}</h5>
            </div>
        )
    }
    else if (isError) {
        return (
            <div className='pt-20 m-auto p-5 text-center'>
                <h5 className='pt-4 pb-5' style={{ fontWeight: 'bold' }}>{t("text8")}</h5>
                <button className='retry-button' onClick={handleRetry}>{t("text9")}</button>
            </div>
        )
    }
    else if (isNoData) {
        return (
            <div className='m-auto p-5'>
                <NoDataWrapper />
            </div>
        )
    }
    else {
        return (
            <div className='m-auto p-5'>
                <div className="flex">
                    <Dropdown
                        title={selectedMonth.label}
                        icon={<CalendarIcon style={{ width: '20px', height: '20px', marginRight: "10px" }} />}
                        menuStyle={{
                            maxHeight: '400px',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        {labels.slice().reverse().map((option: { label: string, month: string, value: number }) => (
                            <Dropdown.Item
                                key={option.value}
                                onClick={() => handleChange(option)}
                                style={{ width: "145px" }}
                            >
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </div>
                <h3 className="mb-4 mt-4 text-black font-semibold">{t("text3")}</h3>
                <div className="grid md:grid-cols-2 gap-5">
                    {positiveInsights.map((insight: { Category: string, Insight: string, Enhancement: string }, index: number) => (
                        <InsightCard
                            img={posImgs[index]}
                            topic={insight.Category}
                            title={insight.Insight}
                            content={insight.Enhancement}
                            icon={<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16" r="16" fill="#B4E1C6" />
                                <g clip-path="url(#clip0_12609_10048)">
                                    <path
                                        d="M22.3996 12.8L17.6764 17.4944C17.0383 18.1286 16.719 18.4461 16.3228 18.4461C15.9266 18.4461 15.6079 18.1286 14.9692 17.4937L14.8156 17.3414C14.1775 16.7065 13.8575 16.3885 13.4614 16.3885C13.0652 16.3885 12.7458 16.7065 12.1078 17.3421L9.59961 19.84M22.3996 12.8V16.3494M22.3996 12.8H18.8271"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_12609_10048">
                                        <rect width="15.36" height="15.36" fill="white" transform="translate(8.32031 8.31999)" />
                                    </clipPath>
                                </defs>
                            </svg>}
                        />

                    ))}
                </div>
                <h3 className="mb-4 mt-4 text-black font-semibold">{t("text4")}</h3>
                <div className="grid md:grid-cols-2 gap-5">
                    {negativeInsights.map((insight: { Category: string, Insight: string, Mitigation: string }, index: number) => (
                        <InsightCard
                            img={negImgs[index]}
                            topic={insight.Category}
                            title={insight.Insight}
                            content={insight.Mitigation}
                            action={index == 3 && <button onClick={() => setIsOpen(prevState => !prevState)}
                                className="dashboard-button bg-red">{t("text5")}</button>}
                            icon={<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16.5" r="16" fill="#FECBC4" />
                                <g clip-path="url(#clip0_12609_8913)">
                                    <path
                                        d="M22.3996 20.34L17.6764 15.6456C17.0383 15.0113 16.719 14.6939 16.3228 14.6939C15.9266 14.6939 15.6079 15.0113 14.9692 15.6462L14.8156 15.7985C14.1775 16.4334 13.8575 16.7515 13.4614 16.7515C13.0652 16.7515 12.7458 16.4334 12.1078 15.7979L9.59961 13.3M22.3996 20.34V16.7905M22.3996 20.34H18.8271"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_12609_8913">
                                        <rect width="15.36" height="15.36" fill="white" transform="translate(8.32031 8.81999)" />
                                    </clipPath>
                                </defs>
                            </svg>}
                        />

                    ))}
                </div>
                <Modal
                    className="mx-auto w-[400px] md:w-[700px] lg:w-[1000px]"
                    title={<h4 className="text-black font-semibold">{t("text6")}</h4>}
                    isOpen={isOpen}
                    onClose={setIsOpen}
                >
                    <InsightAction />
                </Modal>
            </div>
        )
    }
}

export default HRInsights;